<template>
  <div
    class="draw-part"
    ref="drawPartRef"
    @click="handleCanvasContainer"
    :style="isNotShowTitle ? drawClass : notDrawClass"
  >
    <div class="category-sticky" ref="topInfoRef">
      <div class="bigpart-category flex">
        <div>
          <div
            class="fold-canvas bigpart-info-title inline-block mr10"
            @click="isShowPaiban = !isShowPaiban"
          >
            <m-folder-btn
              size="16px"
              :class="{ 'unfolder-btn': isShowPaiban }"
            ></m-folder-btn>
          </div>
          <span
            class="bigpart-info-title"
            @click="isShowPaiban = !isShowPaiban"
          >
            <span class="fs16 color-o-9">{{ bigPlankInfo }}</span>
          </span>
          <div v-if="isScaleTop && isFolderInfo" class="inline-block">
            <a-button
              type="link"
              class="flex flex-cross--center"
              @click="isFolderInfo = !isFolderInfo"
            >
              <m-folder-btn
                type="primary"
                size="16px"
                class="ml3"
                :class="{ 'right-btn': isFolderInfo }"
              ></m-folder-btn>
            </a-button>
          </div>
          <div v-if="isScaleTop && !isFolderInfo" class="inline-block">
            <a-button
              type="link"
              class="flex flex-cross--center"
              @click="isFolderInfo = !isFolderInfo"
              >{{ $t('common.moreInfo') }}
              <m-folder-btn
                type="primary"
                size="16px"
                class="ml3"
                :class="{ 'left-btn': !isFolderInfo }"
              ></m-folder-btn>
            </a-button>
          </div>
          <div
            v-else
            class="inline-block"
            @click="isShowPaiban = !isShowPaiban"
          >
            <span class="bigpart-info-title"
              >{{ $t('arrangedPage.plankCount') }}：<span class="bigpart-info">
                {{ drawData.counts }}</span
              ></span
            >
            <span class="bigpart-info-title"
              >{{ $t('arrangedPage.rollCount') }}：<span class="bigpart-info">{{
                drawData.fanbanCount
              }}</span></span
            >
            <span class="bigpart-info-title"
              >{{ $t('common.rate') }}：<span class="bigpart-info">{{
                drawData.usedRate
                  ? drawData.usedRate >= 1
                    ? `${(100).toFixed(2)}%`
                    : `${(drawData.usedRate * 100).toFixed(2)}%`
                  : '0%'
              }}</span></span
            >
            <span class="bigpart-info-title"
              >{{ $t('arrangedPage.useArea') }}：<span class="bigpart-info"
                >{{
                  drawData.usedArea
                    ? (Number(drawData.usedArea.toString()) / 1000000).toFixed(
                        2
                      )
                    : 0
                }}m²</span
              ></span
            >
            <!-- <span v-if="isScaleTop" class="">jhhkfhjkah</span> -->
            <span v-show="isHighPlank" class="high-plank mr15">{{
              $t('common.heighGloss')
            }}</span>
          </div>
        </div>
        <div
          v-if="
            ((drawData.counts > 10 && !isOnlyShowSurplus) ||
              filterList.length > 10) &&
            isShowPaiban
          "
          class="pageable"
        >
          <a-select
            v-model="countPerPage"
            dropdownMatchSelectWidth
            dropdownClassName="tex-dir-select"
            :getPopupContainer="
              (triggerNode) => {
                return triggerNode.parentNode
              }
            "
            @change="handleChangePerPage"
          >
            <a-select-option
              v-for="line in perPageList"
              :key="line.value"
              :value="line.value"
            >
              {{ line.label }}
            </a-select-option>
          </a-select>
          <a-button
            class="pre-btn mh6"
            @click="handlePrePage"
            :disabled="currentPape == 1"
          >
            <span
              class="iconfont icon-arrow"
              style="transform: rotate(-90deg)"
            ></span>
          </a-button>
          <a-select
            v-model="currentPape"
            :dropdownMatchSelectWidth="false"
            :getPopupContainer="
              (triggerNode) => {
                return triggerNode.parentNode
              }
            "
            @change="handleChangeCurrentPage"
          >
            <a-select-option
              v-for="page in totalPage"
              :key="page"
              :value="page"
              popper-class="current-page"
            >
              {{ page }}/{{ totalPage }}
            </a-select-option>
          </a-select>
          <a-button
            class="next-btn ml6"
            @click="handleNextPage"
            :disabled="currentPape == totalPage"
          >
            <span
              class="iconfont icon-arrow"
              style="transform: rotate(90deg)"
            ></span>
          </a-button>
        </div>
      </div>
    </div>
    <div class="canvas-box" v-show="isShowPaiban">
      <canvas
        :id="`canvas${canvasKey}`"
        :ref="`canvas${canvasKey}`"
        @mousedown="clickCanvas"
        @mouseup="clickUpCanvasFn"
        @mousemove="movePlank"
        @mousewheel="wheelScale"
        @contextmenu.prevent
        @dblclick="gotoSubtle"
      ></canvas>
    </div>
    <div
      :style="`top: ${canvasBtn.top}px; left: ${canvasBtn.left}px`"
      v-if="canvasBtn.data"
      class="bigpart-operation"
    >
      <div
        @click="useSurplus()"
        class="w100"
        :title="translateLang('arrangedPage.useSurplus.title')"
      >
        <span class="iconfont icon-yuliao"></span>
        <span style="width: 80%" class="inline-block ellipsis">{{
          $t('arrangedPage.useSurplus.title')
        }}</span>
      </div>
      <div
        @click="gotoSubtleByBtn()"
        class="w100"
        :title="translateLang('arrangedPage.subtleArrange')"
      >
        <span class="iconfont icon-check"></span>
        <span style="width: 80%" class="inline-block ellipsis">{{
          $t('arrangedPage.subtleArrange')
        }}</span>
      </div>
      <div
        @click="addNewBigpart()"
        class="w100"
        :title="translateLang('arrangedPage.addPlank')"
      >
        <span class="iconfont icon-add"></span>
        <span style="width: 80%" class="inline-block ellipsis">{{
          $t('arrangedPage.addPlank')
        }}</span>
      </div>
      <div
        @click="deleteBigpart()"
        class="w100"
        :title="translateLang('arrangedPage.deletePlank')"
      >
        <span class="iconfont el-icon-delete"></span>
        <span style="width: 80%" class="inline-block ellipsis">{{
          $t('arrangedPage.deletePlank')
        }}</span>
      </div>
    </div>
    <div
      class="fixed p10 bg-dark color-f br4"
      v-show="lockHoverInfo.isShow"
      :style="{
        top: `${lockHoverInfo.top}px`,
        left: `${lockHoverInfo.left}px`,
      }"
    >
      <span v-if="lockHoverInfo.isLocked">
        {{ $t('arrangedPage.lockTip1') }}<br />{{ $t('arrangedPage.lockTip2') }}
      </span>
      <span v-else>
        {{ $t('arrangedPage.unlockTip1') }}<br />{{
          $t('arrangedPage.unlockTip2')
        }}
      </span>
    </div>
  </div>
</template>

<script>
import LockedImg from '@/assets/jiesuo.png'
import UnLockedImg from '@/assets/suoding.png'
import { DrawPlankColorJs } from '@/data/plank'
import { rolloverPlank } from '@/util/LayoutFuncs.js'
import { rotatePart } from '@/util/LayoutTool.js'
import EventBus from '@/util/bus'
import { translate } from '@/util/commonFun'
import {
  calcClosePlank,
  calcPlankSize,
  isSamePart,
} from '@/util/commonFuncs.js'
import {
  buryPointApi,
  calcClosePoint,
  compareTowNum,
  toDecimal,
} from '@/util/commonFuncs.js'
import {
  checkOneBigpart,
  checkOnePlank,
  dealPlankPoly,
} from '@/util/dealPaibanData.js'
import {
  dealCurrentPointDistance,
  drawActivePlankOtherInfo,
  drawPlankEmptyDistance,
  drawPlankHSDetailInfo,
} from '@/util/dealPlankExtraDraw'
import { Plank, plankDrawFactory } from '@/util/drawPlank'
import { DrawLittlePlankSize } from '@/util/drawPlank'
import {
  calcBigpartArea,
  circleText,
  dealCurveHoles,
  getPathsMaxAreaObj,
  getThroughPathObj,
} from '@/util/drawPlankFuncs'
import {
  PlankControl,
  clearPlankToBigPlank,
  isExistPriority,
  mousePointISInElement,
  partIsConflict,
} from '@/util/plankCommonFuncs'
import { polyContain } from '@/util/polygonRelation.js'
import { Loading } from 'element-ui'
import { mapMutations, mapState } from 'vuex'

import { checkPrelayoutSettingChanged } from '../materialsList/util/preLayoutDeal'
import mFolderBtn from './component/m-folder-btn'
import {
  genAwaitStoreData,
  getPlankImage,
} from './component/m-paiban-store/utlis'
import { checkCurrentDragPlankDataIsLoad } from './component/m-paiban-store/utlis'
import { getPlankMaxIndexByFinalDrawData } from './util'

// 默认缩放比例
// const defaultScale = 1
const defaultScale = 6
// 大板和大板边框的默认偏移值
const defaultDeviation = 5
// 下刀顺序距边的默认距离
const defaultTextWidth = 3
// 记录大板之间的间距
const defaultPadding = 65
// 下刀点距边的默认距离
const defaultDotWidth = 5
// 小板件的线宽
const partLineWidth = 1
// 用于防抖
let _debounceTime = null

export default {
  components: {
    mFolderBtn,
  },
  props: {
    isShowPartSize: {
      type: Boolean,
    },
    drawData: {
      type: [Object, Array],
    },
    canvasKey: {
      type: Number,
    },
    canvasMaxWidth: {
      type: Number,
    },
    canvasMaxHeight: {
      type: Number,
    },
    isCuttingOrder: {
      type: Boolean,
    },
    activePlankID: {
      type: Number,
    },
    activePlankIndex: {
      type: Number,
    },
    startNum: {
      type: Number,
      default: 1,
    },
    clearConflict: {
      type: Function,
    },
    isOnlyShowSurplus: {
      type: Boolean,
      default: false,
    },
    isBatchBujian: {
      type: Boolean,
      default: false,
    },
    batchBujianList: {
      type: Array,
      default: () => [],
    },
    /** 计算统计翻板数以及当前大板翻板数 */
    calcFanbanCount: {
      type: Function,
    },
    /** 重新计算下刀点个顺序 */
    calcCutPositionOrder: {
      type: Function,
    },
    /** 是否显示删除按钮，默认展示 */
    isShowDelete: {
      type: Boolean,
      default: true,
    },
    /** 余料不参与利用率计算 */
    isUnuseSurplus: {
      type: Boolean,
      default: true,
    },
    /** 停用板件吸附 */
    isUsePlankAdsorption: {
      type: Boolean,
      default: false,
    },
    /** 排版页 数据默认收起 */
    isDrawPartCollapsed: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      filterList: [],
      drawClass: {
        height: '0px',
        marginBottom: '0px',
        overflow: 'hidden',
      },
      notDrawClass: {},
      isNotShowTitle: false,
      // 是否显示锁定logo
      isShowLock: false,
      UnLockedImgInstance: null,
      LockedImgInstance: null,
      // 锁定的hoverInfo
      lockHoverInfo: {
        isShow: false,
        top: 0,
        left: 0,
        isLocked: false,
      },
      isFirst: true,
      LockedImg,
      UnLockedImg,
      // 是否显示绘制区域 默认不显示
      isShowPaiban: true,
      // 记录canvas的dom元素
      canvasDom: {},
      // 记录canvas绘制上下文
      ctx: {},
      // 记录选择的板件
      activePlank: null,
      // 记录激活的大板
      activeBigpart: null,
      // 记录点击时激活板件的起始点
      plankStartPoint: null,
      // 是否正在移动板件
      isMovingPlank: false,
      // 记录绘制时的起始点
      startLeft: 0,
      startTop: 0,
      // 是否处于缩放状态
      isScaling: false,
      // 缩放比例
      oriScale: 6,
      scale: defaultScale,
      // 相对于默认缩放比例的比例
      scalePercent: 1,
      // 上次的缩放比例
      lastScale: 1,
      // 大板和大板边框的偏移值
      deviation: 5,
      // 下刀点距边的距离
      dotWidth: 5,
      // 下刀顺序距边的距离
      textWidth: 3,
      // 记录大板之间的间距
      padding: 65,
      // 记录大板长宽
      plankWidth: 0,
      plankHeight: 0,
      // 记录绘制时的大板长宽
      scaleWidth: 0,
      scaleHeight: 0,
      // 记录当前绘制每一行绘制几个大板
      perNum: 0,
      // 记录右键拖拽板件时, 鼠标偏移量
      canvasOffset: null,
      // 记录是否在拖拽画布
      isMovingCavnas: false,
      // 记录右键点击的位置
      canvasRightDrag: {},
      // 键盘按下事件
      keyDownEvent: null,
      // 键盘松开事件
      keyUpEvent: null,
      // 鼠标滚轮事件
      mouseWheelEvent: null,
      // 鼠标松开事件
      mouseupEvent: null,
      activePlankOff: null,
      // 记录鼠标移动时鼠标的位置
      dragPosition: null,
      plankRealOff: null,
      canvasBtn: {
        left: 0,
        top: 0,
        data: null,
      },
      // 用于键盘控制时判断是否在获取选中板件的线段
      isActivePlankLine: false,
      plankMapImg: {}, // 板件映射img
      imageTempMousedownFlag: false, // 生成图片是否被鼠标点击的标记
      mousePointOffsetImage: {}, // 记录点击到图片的鼠标距离图片左上角的距离
      isDrawImage: false, // 是否把选中项绘制成图片
      isUseImage: true, // 是否使用点击生成图片的模式（用于测试，测试通过后可以只取代码中为true的逻辑）
      hasMoved: false, // 点击生成的图片是否已经移动过
      // 记录canvasKey
      refKey: null,
      // 用于canvas尺寸防抖
      canvasTime: null,
      // 每页张数选择列表
      perPageList: [
        {
          label: `10${translate('common.unit')}`,
          value: 10,
        },
        {
          label: `20${translate('common.unit')}`,
          value: 20,
        },
        {
          label: `30${translate('common.unit')}`,
          value: 30,
        },
        {
          label: `50${translate('common.unit')}`,
          value: 50,
        },
      ],
      // 用于保存每页多少张大板
      countPerPage: 20,
      // 用于保存当前页数
      currentPape: 1,
      // 保存总共多少页
      totalPage: 1,
      // 保存当前绘制大板
      sliceDrawData: [],
      // 记录板件空白位置距离
      emptyDistance: false,
      // 记录点击的位置
      mousePoint: null,
      // 记录孔槽详细信息
      hsDetailInfoList: [],
      // 记录孔槽详细绘制后是否再需要重新绘制一次,防止过多的重绘
      isRenderHSDetail: false,
      // 操作板件
      plankControl: null,
      // 点击点的offsetX和offsetY信息
      clickPointOffset: {},
      p1: {
        x: 0,
        y: 0,
      },
      p2: {
        x: 0,
        y: 0,
      },
      // 重叠部分的点位数组
      intersect: [],
      bujianList: [],
      // 余料库tooltip
      surStoreTooltip: [],
      isRenderSSTooltip: false,
      isScaleTop: false,
      isFolderInfo: true,
      activePartUniqueId: '',
    }
  },
  computed: {
    ...mapState([
      'ncSetting',
      'userInfo',
      'hasLblAuth',
      'isDragingPlank',
      // 'beDraggedPlank',
      'tempStorage',
      'tempImgPos',
      'surplusParams',
      'offsetPointbetweenCacheImage',
      'highlightFlip',
      'isHistoryStatus',
      'historyOrder',
      'selectStandardPlank',
      'preLayoutData',
      'oriLayoutSetting',
      'preLayoutSetting',
    ]),
    showHighGlossTipType() {
      if (this.isHistoryStatus && this.isHighPlank) {
        return this.historyOrder.plank_info.highgloss_direction
          ? this.historyOrder.plank_info.highgloss_direction
          : 'back'
      } else if (!this.isHistoryStatus && this.isHighPlank) {
        return this.ncSetting.highgloss_direction
      } else {
        return false
      }
    },
    bigPlankInfo() {
      let name =
        this.drawData.thick + this.drawData.matCode + this.drawData.texture
      if (
        this.drawData.otherPlate &&
        Object.keys(this.drawData.otherPlate).length > 0
      ) {
        name += `（${translate('common.specialPlank')}）`
      } else {
        name += `（${translate('common.standardPlank')}）`
      }
      return name
    },
    // 是否是高光板
    isHighPlank() {
      const data = this.sliceDrawData
      for (let i = 0; i < data.length; i++) {
        for (let j = 0; j < data[i].parts.length; j++) {
          if (data[i].parts[j]?.is_high_gloss_plank) {
            return true
          }
        }
      }
      return false
    },
  },
  methods: {
    ...mapMutations([
      'changeDragingPlank',
      'deleteTempStorage',
      'addTempStorage',
      'changeTempStorage',
      'setSurplusParams',
    ]),
    // 判断是否需要保留两位小数
    judgeDecimal(num) {
      const isDecimal = String(num).indexOf('.') + 1
      //小数点的位数
      const decimalPlaces = String(num).length - isDecimal
      if (isDecimal && decimalPlaces > 2) {
        num = num.toFixed(2)
      }
      return num
    },
    /**
     * 绘制小板尺寸
     * @param { Object } part 小板数据
     * @param { Object } rect 小板的绘制属性
     */
    drawPartSize(ctx, part, rect) {
      const realWidth = this.judgeDecimal(part.realRect.width)
      const realHeight = this.judgeDecimal(part.realRect.height)
      const fontSize = 14 * this.scalePercent
      // 宽度的字体宽度
      const widthTextW = ctx.measureText(realWidth).width
      // 高度的字体宽度
      const heightTextW = ctx.measureText(realHeight).width
      // 高度文字的x、y
      const partHeight = {
        x: rect.x + this.textWidth,
        y: rect.y + this.tranlateSizeFromScale(realHeight / 2),
      }
      // 宽度文字的x、y
      const partWidth = {
        x: rect.x + this.tranlateSizeFromScale(realWidth / 2) - widthTextW / 2,
        y: rect.y + this.tranlateSizeFromScale(realHeight),
      }
      const specialPartWidth = {
        x: rect.x + this.tranlateSizeFromScale(realWidth / 2) - widthTextW / 2,
        y: rect.y + this.tranlateSizeFromScale(realHeight / 2) - fontSize / 2,
      }
      const specialPartHeight = {
        x: rect.x + this.tranlateSizeFromScale(realWidth / 2) - heightTextW / 2,
        y: rect.y + this.tranlateSizeFromScale(realHeight / 2) + fontSize,
      }
      // 普通矩形
      if (this.isShowPartSize) {
        if (!part.path) {
          // 绘制宽度文字底色
          this.drawPartSizeBg(
            ctx,
            partWidth.x,
            partWidth.y - fontSize,
            widthTextW,
            fontSize
          )
          // 绘制高度文字底色
          this.drawPartSizeBg(
            ctx,
            partHeight.x,
            partHeight.y - fontSize,
            heightTextW,
            fontSize
          )
          ctx.fillStyle = '#333'
          ctx.font = `bold ${fontSize}px 'PingFangSC-Regular, PingFang SC'`
          ctx.fillText(`${realWidth}`, partWidth.x, partWidth.y)
          ctx.fillText(`${realHeight}`, partHeight.x, partHeight.y)
        } else {
          // 绘制宽度文字底色
          this.drawPartSizeBg(
            ctx,
            specialPartWidth.x,
            specialPartWidth.y - fontSize,
            widthTextW,
            fontSize
          )
          // 绘制高度文字底色
          this.drawPartSizeBg(
            ctx,
            specialPartHeight.x,
            specialPartHeight.y - fontSize,
            heightTextW,
            fontSize
          )
          ctx.fillStyle = '#333'
          ctx.font = `bold ${fontSize}px 'PingFangSC-Regular, PingFang SC'`
          ctx.fillText(`${realWidth}`, specialPartWidth.x, specialPartWidth.y)
          ctx.fillText(
            `${realHeight}`,
            specialPartHeight.x,
            specialPartHeight.y
          )
        }
      }
    },
    // 绘制小板尺寸底色
    drawPartSizeBg(ctx, startX, startY, width, height) {
      ctx.beginPath()
      ctx.fillStyle = '#fff'
      ctx.rect(startX, startY, width, height)
      ctx.fill()
      ctx.closePath()
    },
    // 将size通过scale进行转换
    tranlateSizeFromScale(size) {
      return (size / defaultScale) * this.scalePercent
    },
    // 把size恢复成没有进行缩放前的值
    restoreScaleSize(scaleSize) {
      return (scaleSize / this.scalePercent) * defaultScale
    },
    handleChangePerPage(item) {
      this.countPerPage = item
      this.currentPape = 1
      this.startDraw()
      this.$emit('handleChangePerPage', item)
    },
    handleChangeCurrentPage(item) {
      this.currentPape = item
    },
    // 上一页
    handlePrePage() {
      this.currentPape -= 1
      this.startDraw()
    },
    // 下一页
    handleNextPage() {
      this.currentPape += 1
      this.startDraw()
    },
    startDraw() {
      if (this.isOnlyShowSurplus) {
        this.filterList = this.drawData.data.filter((item) => {
          return item.surplusInfo && !item.surplusInfo.isNotSurplus
        })
        if (!this.filterList.length) {
          this.isNotShowTitle = true
        } else {
          this.isNotShowTitle = false
        }
      } else {
        this.isNotShowTitle = false
      }
      this.$nextTick(() => {
        this.canvasDom = this.$refs[`canvas${this.canvasKey}`]
        const topInfoRef = this.$refs['topInfoRef']
        this.canvasDom.width =
          topInfoRef.offsetWidth < 988 ? 988 : topInfoRef.offsetWidth
        this.isScaleTop = topInfoRef.offsetWidth <= 988
        this.ctx = this.canvasDom.getContext('2d')
        this.totalPage = Math.ceil(this.drawData.counts / this.countPerPage)

        this.sliceDrawData =
          this.drawData.counts <= 10
            ? this.isOnlyShowSurplus
              ? this.drawData.data.filter((item) => {
                  return item.surplusInfo && !item.surplusInfo.isNotSurplus
                })
              : this.drawData.data
            : (this.isOnlyShowSurplus
                ? this.drawData.data.filter((item) => {
                    return item.surplusInfo && !item.surplusInfo.isNotSurplus
                  })
                : this.drawData.data
              ).slice(
                (this.currentPape - 1) * this.countPerPage,
                this.currentPape * this.countPerPage
              )
        // 初始化板件控制
        this.plankControl = new PlankControl(this, this.sliceDrawData)

        this.drawData.data.forEach((item) => {
          item.usedRate >= 1 ? (item.usedRate = 1) : item.usedRate
        })
        this.draw()
      })
    },
    // 鼠标双击事件, 前往精细排版
    gotoSubtle(e) {
      if (this.ncSetting.isPreLayout) {
        const hasPreLayoutSettingChanged = checkPrelayoutSettingChanged(
          this.oriLayoutSetting.setting_value,
          this.preLayoutSetting.setting_value
        )
        if (hasPreLayoutSettingChanged) {
          this.$emit('onGotoSubtleErr')
          return
        }
      }
      if (this.$props.isBatchBujian) {
        this.$message.warning('请先退出批量补件模式')
        return
      }
      let arr = this.sliceDrawData.filter(
        (v) => e.offsetX >= v.startX && e.offsetY >= v.startY
      )
      // 判断鼠标位置是否在大板所处的位置
      // 如果点到了第一块大板之前的区域, 是没有大板信息的, 所以直接弹回原位置重新绘制
      if (arr.length == 0) {
        this.activePlank.startX = this.plankStartPoint.x
        this.activePlank.startY = this.plankStartPoint.y
        this.plankRealOff = null

        this.renderAll()
        return
      }
      // 找到原数据中的bigpart
      // 找到当前鼠标位置的最后一个大板
      let targetBigpart = arr[arr.length - 1]
      let bigpart = this.sliceDrawData.find(
        (v) => v.stockKey == targetBigpart.stockKey
      )
      // 如果找到了大板则继续执行后面的逻辑, 否则弹回原位置重新绘制
      if (bigpart) {
        // 如果鼠标位置在大板上, 则继续执行后面的逻辑, 否则弹回原位置重新绘制
        if (
          e.offsetX <= bigpart.startX + bigpart.width + 2 * this.deviation &&
          e.offsetY <=
            bigpart.startY + bigpart.height + this.padding + 2 * this.deviation
        ) {
          this.emptyDistance = false
          this.$emit('gotoSubtle', bigpart)
        }
      }
    },
    // 更多操作里面前往精细排版
    gotoSubtleByBtn() {
      if (this.ncSetting.isPreLayout) {
        const hasPreLayoutSettingChanged = checkPrelayoutSettingChanged(
          this.oriLayoutSetting.setting_value,
          this.preLayoutSetting.setting_value
        )
        if (hasPreLayoutSettingChanged) {
          this.$emit('onGotoSubtleErr')
          return
        }
      }
      if (this.$props.isBatchBujian) {
        this.$message.warning('请先退出批量补件模式')
        return
      }
      this.emptyDistance = false
      this.$emit('gotoSubtle', this.canvasBtn.data)
    },
    // 更多操作里面使用余料
    useSurplus() {
      if (
        this.activeBigpart.surplusInfo &&
        Object.keys(this.activeBigpart.surplusInfo).length > 0 &&
        !this.activeBigpart.surplusInfo.isNotSurplus
      ) {
        this.$message({
          type: 'info',
          message: '该大板已经为余料大板, 不可继续替换余料!',
        })
        return
      }
      this.emptyDistance = false
      this.$emit('useSurplus', this.canvasBtn.data, this.canvasKey)
    },
    // 更多操作里面添加大板
    addNewBigpart() {
      let bigpart = this.canvasBtn.data
      this.emptyDistance = false
      this.$emit('addNewBigpart', bigpart, this.canvasKey)
      this.startDraw()
    },
    dealNewBigpart() {
      let height = this.calcPerParts() + 200
      this.canvasDom.height = height

      this.renderAll()
    },
    dealDeleteBigpart() {
      this.canvasBtn.data = null
      let height = this.calcPerParts() + 200
      this.canvasDom.height = height

      this.renderAll()
    },
    // 更多操作里面删除大板
    deleteBigpart() {
      // 判断是否是当前材质颜色厚度下面的最后一块板件
      if (this.drawData.data.length <= 1) {
        return this.$message.error(
          this.translateLang('common.deleteBigPlankTip')
        )
      }
      this.emptyDistance = false
      let bigpart = this.canvasBtn.data
      this.$emit('deleteBigpart', bigpart, this.canvasKey, this.preLayoutData)
    },
    // 鼠标按下事件
    clickCanvas(e) {
      // 保存起始offset信息
      this.clickPointOffset.x = e.offsetX
      this.clickPointOffset.y = e.offsetY

      // 左键
      if (e.button == 0) {
        if (this.isMovingCavnas) return
        // 表明可以把选中项绘制成图片
        this.isDrawImage = true
        this.clickEvent = e
        this.$emit('clearAllActive')
        // 点击大板是这个数据为null
        this.activePlank = this.setActivePlank(e)
        const mousePoint = {
          x: e.offsetX,
          y: e.offsetY,
        }
        const bigPart = this.getCurrentBigPart(mousePoint)
        this.$emit(
          'recordActivePlank',
          this.activePlank,
          this.canvasKey,
          bigPart
        )
        // 获取点击范围是属于哪个大板
        if (!bigPart) return
        // 判断是否点击到锁上
        const result = this.jundgeLocation(mousePoint, bigPart.lockPath)
        if (result && (bigPart.isLocked || bigPart.isActive)) {
          bigPart.isLocked = this.lockHoverInfo.isLocked = !bigPart.isLocked
          if (!bigPart.isLocked) {
            this.$message.success(translate('lockModal.lockSeccess'))
            this.renderAll()
            this.lockHoverInfo.isShow = false
            bigPart.isActive = false
          } else {
            this.$message.success(translate('lockModal.lockSeccessTip'))
            this.renderAll()
          }
          this.$emit('handleClickLock')
        }
        const onPlank =
          mousePoint.x <= bigPart.startX + bigPart.width + 2 * this.deviation &&
          mousePoint.y <=
            bigPart.startY + bigPart.height + this.padding + 2 * this.deviation
        if (!onPlank) {
          const data = this.drawData.data
          data.forEach((item) => {
            item.isActive = false
          })
        }
        // 点击在小板上面
        if (this.activePlank) {
          // 这个打印不删，用于测试服排查错误
          console.log(JSON.parse(JSON.stringify(this.activePlank)))
          this.$emit('handleChangeRemarksAble', false)
          // 如果激活了板件则记录当前的位置, 以便移动时的使用
          this.isMovingPlank = true
          this.$emit('forbidUserSelect', true)
          // 记录当前板件的起始坐标
          this.plankStartPoint = {
            x: this.activePlank.startX,
            y: this.activePlank.startY,
          }
          // 记录选中板件的起始点和鼠标位置的差距
          this.sliceDrawData.forEach((item) => {
            this.syncStockKey(item)
          })
          const activeBigpart = this.sliceDrawData.find(
            (v) => v.stockKey == this.activePlank.stockKey
          )
          this.setActiveBigPart(activeBigpart)
          this.activePlankOff = {
            x: this.restoreScaleSize(e.offsetX) - this.activePlank.startX,
            y: this.restoreScaleSize(e.offsetY) - this.activePlank.startY,
          }
          // 计算当前点击的位置, 鼠标位置相对于小板的偏移值
          this.plankRealOff = {
            x:
              this.restoreScaleSize(
                e.offsetX - this.activeBigpart.startX - this.deviation
              ) - this.activePlank.startX,
            y:
              this.restoreScaleSize(
                e.offsetY -
                  this.activeBigpart.startY -
                  this.padding -
                  this.deviation
              ) - this.activePlank.startY,
          }
        } else {
          // 如果没有选中小板且未点击在锁上，执行拖动大板操作 判断并未点击到三个点上
          if (!result && !this.canvasBtn.data) {
            this.dragBigPlank(e)
          }
        }
      }
      // 右键
      if (e.button == 2) {
        this.dragBigPlank(e)
      }
    },
    // 拖动大板操作
    dragBigPlank(e) {
      if (this.isMovingPlank) return
      this.emptyDistance = false
      this.canvasBtn.data = null
      this.isMovingCavnas = true
      this.canvasRightDrag = {
        x: e.offsetX,
        y: e.offsetY,
      }
    },
    // 重置板件状态
    resetPlankStatus() {
      this.isMovingPlank = false
      this.dragPosition = null
      this.activePlankOff = null
    },

    // 获取鼠标位置到canvas的偏移量
    getOffsetBetweenPointAndCanvas(e, downEvent, needMagic) {
      // const canvasDom = this.$refs[`canvas${this.canvasKey}`]
      if (downEvent) {
        // 此时说明用初始值e和当前e的差值进行计算（之所以引入这样的计算方法，是因为用e.x - dom.getBoundingClientRect().left这样的算法经常会有1px的误差）

        return {
          offsetX: e.x - downEvent.x + this.clickEvent.offsetX,
          offsetY: e.y - downEvent.y + this.clickEvent.offsetY,
        }
      }

      const canvasDom = document.querySelector(`#canvas${this.canvasKey}`)
      if (!canvasDom) {
        return {}
      }
      const domInfo = canvasDom.getBoundingClientRect()
      let magicNumber = 0
      if (needMagic) {
        magicNumber = 1
      }
      return {
        offsetX: e.x - domInfo.left + magicNumber,
        offsetY: e.y - domInfo.top + magicNumber,
      }
    },
    // 计算小板的startX和startY
    calcuPlankStartPos(bigpart, offsetX, offsetY, offsetObj) {
      let plankRealOff = offsetObj ||
        this.plankRealOff || {
          x: 0,
          y: 0,
        }
      return {
        startX:
          this.restoreScaleSize(offsetX - bigpart.startX - this.deviation) -
          plankRealOff.x,
        startY:
          this.restoreScaleSize(
            offsetY - bigpart.startY - this.padding - this.deviation
          ) - plankRealOff.y,
      }
    },
    // 把板件推到大板里面
    pushActivePlankToParts(bigpart, isCacheToCanvas) {
      // 这儿还是针对从缓存去区拖到canvas的情况
      let index = bigpart.parts.findIndex((item) => {
        return isSamePart(item, this.activePlank)
      })
      if (index !== -1) {
        // 此时说明找到了，直接返回
        return
      }
      this.$emit('clearAllStyle', this.activePlank)
      bigpart.parts.push(this.activePlank)
      calcBigpartArea(bigpart, this.ncSetting, this.$props.isUnuseSurplus)
      // 将对应标识也作相应的更改
      this.activePlank.stockKey = bigpart.stockKey
      this.activePlank.stockNum = bigpart.stockNum
      if (isCacheToCanvas) {
        if (!isExistPriority(this.activePlank)) {
          // 重新计算下刀点
          this.$props.calcCutPositionOrder()
        }
        // 删除暂存区内相应的板件
        this.$store.commit(
          'awaitPaibanStore/deleteCurrentDragPlank',
          this.$store.state.awaitPaibanStore.currentDragPlank.plank.partUniqueId
        )
        this.$store.commit('awaitPaibanStore/setCurrentDragPlank', null)
      }
      // 判断大板是否可以进行正反切换
      this.judgeAbleFanban(bigpart)
      this.$props.calcFanbanCount()
    },
    /**
     * 处理canvas里面plank信息
     * @param {*} options
     * @param {*} isCacheToCanvas 是否是从缓存区到canvas
     */
    dealCanvasPlankMsg(options, isCacheToCanvas) {
      let { offsetX, offsetY, startX, startY } = options
      const oriActivePlank = JSON.parse(JSON.stringify(this.activePlank))
      if (this.ncSetting.glass_setting) return
      // 找到当前点击的位置属于哪一个大板, 找到起始坐标小于当前鼠标位置的所有大板, 取最后一个大板
      let arr = this.sliceDrawData.filter(
        (v) => offsetX >= v.startX && offsetY >= v.startY
      )
      // 判断鼠标位置是否在大板所处的位置
      // 如果点到了第一块大板之前的区域, 是没有大板信息的, 所以直接弹回原位置重新绘制
      if (arr.length == 0) {
        this.plankRealOff = null

        this.showImageTemp(false)
        this.isDrawImage = false
        this.reRenderPlank()

        this.renderAll()
        this.$emit('changePaibanData', false)

        return
      }
      // 找到原数据中的bigpart
      // 找到当前鼠标位置的最后一个大板
      let targetBigpart = arr[arr.length - 1]
      let bigpart = this.sliceDrawData.find(
        (v) => v.stockKey == targetBigpart.stockKey
      )
      const oriBigpart = JSON.parse(JSON.stringify(this.activeBigpart))
      // 如果找到了大板则继续执行后面的逻辑, 否则弹回原位置重新绘制
      if (bigpart) {
        // 如果鼠标位置在大板上, 则继续执行后面的逻辑, 否则弹回原位置重新绘制
        if (bigpart.isLocked) {
          this.$message.error(translate('arrangedPage.plankLockErr'))
          this.plankRealOff = null
          this.showImageTemp(false)
          this.isDrawImage = false
          this.reRenderPlank()
          this.renderAll()
          this.$emit('changePaibanData', false)
          return
        }
        if (
          offsetX <= bigpart.startX + bigpart.width + 2 * this.deviation &&
          offsetY <=
            bigpart.startY + bigpart.height + this.padding + 2 * this.deviation
        ) {
          if (!startX) {
            let offsetObj = null
            if (isCacheToCanvas) {
              offsetObj = {
                x: this.restoreScaleSize(this.offsetPointbetweenCacheImage.x),
                y: this.restoreScaleSize(this.offsetPointbetweenCacheImage.y),
              }
            }
            let obj = this.calcuPlankStartPos(
              bigpart,
              offsetX,
              offsetY,
              offsetObj
            )
            // 拖动板件(未触发板件吸附) startX/Y保留设置中的小数位数
            startX = Number(obj.startX.toFixed(this.ncSetting.decimal))
            startY = Number(obj.startY.toFixed(this.ncSetting.decimal))
          }

          this.activePlank.startX = startX
          this.activePlank.startY = startY
          this.hasMoved = false
          this.plankRealOff = null
          // 重新处理选中板件的轮廓路径
          dealPlankPoly(this.activePlank)
          buryPointApi('layout', 'move_plate')
          let flag = false
          let newBigpart = false
          // 判断鼠标点击左键抬起和点击位置是否一样 一样就不需要吸附
          const { x, y } = this.clickPointOffset
          const t = !!(x === offsetX && y === offsetY)
          let idx = 0
          if (isCacheToCanvas) {
            const stock = this.activePlank.stockKey
              .split(':')
              .slice(0, -1)
              .join(':')
            if (bigpart.stockKey.includes(stock)) {
              if (this.$props.isUsePlankAdsorption) {
                const result = calcClosePlank(this.activePlank, bigpart)
                if (result && !t) {
                  this.p1 = result.p1
                  this.p2 = result.p2
                  const { x, y } = result
                  this.activePlank.startX = x
                  this.activePlank.startY = y
                  if (result.intersect) {
                    this.intersect = result.intersect
                  }
                  // 重新处理选中板件的轮廓路径
                  dealPlankPoly(this.activePlank)
                }
              }
              flag = checkOnePlank(this.activePlank, bigpart)
              if (flag) {
                this.setActiveBigPart(bigpart)
                this.pushActivePlankToParts(bigpart, isCacheToCanvas)
                this.renderAll()
                return
              } else {
                this.setActivePlankData(null)
                this.$message.info(translate('arrangedPage.areaWarning'))
                this.renderAll()
                return
              }
            } else {
              this.setActivePlankData(null)
              this.$message.info('不符合大板规格，无法放置板件')
              this.renderAll()
              return
            }
          } else {
            // 如果移动后的板件还是在当前选中的大板上, 则直接判断是否冲突
            if (bigpart.stockKey == this.activePlank.stockKey) {
              if (this.$props.isUsePlankAdsorption) {
                const result = calcClosePlank(this.activePlank, bigpart)
                if (result && !t) {
                  this.p1 = result.p1
                  this.p2 = result.p2
                  const { x, y } = result
                  this.activePlank.startX = x
                  this.activePlank.startY = y
                  if (result.intersect) {
                    this.intersect = result.intersect
                  }
                  // 重新处理选中板件的轮廓路径
                  dealPlankPoly(this.activePlank)
                }
              }

              flag = checkOnePlank(this.activePlank, bigpart)
              if (flag) {
                this.pushActivePlankToParts(bigpart, isCacheToCanvas)
              }
            } else {
              // 如果不在, 则将选中板件加到新的大板数据里面, 原大板删除对应数据
              if (this.activeBigpart) {
                for (let i = 0; i < this.activeBigpart.parts.length; ++i) {
                  if (
                    isSamePart(this.activeBigpart.parts[i], this.activePlank)
                  ) {
                    this.activeBigpart.parts.splice(i, 1)
                    idx = i
                    // 原大板板件被删除后也需要重新判断是否是双面板
                    this.judgeAbleFanban(this.activeBigpart)
                    break
                  }
                }
              } else {
                this.setActiveBigPart(bigpart)
              }
              this.pushActivePlankToParts(bigpart, isCacheToCanvas)
              newBigpart = true
            }
            if (this.activeBigpart) {
              if (this.$props.isUsePlankAdsorption) {
                const result = calcClosePlank(this.activePlank, bigpart)
                if (result && !t) {
                  const { x, y } = result
                  this.activePlank.startX = x
                  this.activePlank.startY = y
                  if (result.intersect) {
                    this.intersect = result.intersect
                  }
                  // 重新处理选中板件的轮廓路径
                  dealPlankPoly(this.activePlank)
                }
              }

              flag = checkOnePlank(this.activePlank, bigpart)
              if (!flag) {
                for (let i = 0; i < bigpart.parts.length; ++i) {
                  if (isSamePart(bigpart.parts[i], this.activePlank)) {
                    bigpart.parts.splice(i, 1)
                    break
                  }
                }
                this.activeBigpart.parts.splice(idx, 0, this.activePlank)
                // 板件移动失败需要重置大板序号避免之前的处理导致板件内部记录的序号错误
                this.activePlank.stockNum = this.activeBigpart.stockNum
                // 如果冲突，重新绘制当前板件
                this.reRenderPlank()
              }
              checkOneBigpart(this.activeBigpart)
              if (newBigpart) {
                checkOneBigpart(bigpart)
                // 重新计算之前的大板优化率
                calcBigpartArea(
                  this.activeBigpart,
                  this.ncSetting,
                  this.$props.isUnuseSurplus
                )
                // 重新判断之前的大板是否可以进行正反切换
                this.judgeAbleFanban(this.activeBigpart)
                this.setActiveBigPart(bigpart)
                // 重新计算新的大板的优化率
                calcBigpartArea(
                  bigpart,
                  this.ncSetting,
                  this.$props.isUnuseSurplus
                )
                // 判断新的大板是否可以进行正反切换
                this.judgeAbleFanban(bigpart)
              }
            }
          }
        } else {
          this.reRenderPlank()
          this.$emit('changePaibanData', false)
        }
      } else {
        this.reRenderPlank()
        this.$emit('changePaibanData', false)
      }

      // 隐藏图片
      this.showImageTemp(false)
      // 绘制图片设置为false
      this.isDrawImage = false
      // 判断完冲突后, 重新绘制
      this.renderAll()
      this.$emit('recordPlankMove', {
        key: '移动小板',
        dataArr: [
          {
            type: 'form',
            current: {
              partName: this.activePlank.partName,
              canvasIndex: bigpart.canvasIndex,
              startX: this.activePlank.startX,
              startY: this.activePlank.startY,
            }, // 当前表单数据
            ori: {
              partName: oriActivePlank.partName,
              canvasIndex: oriBigpart?.canvasIndex,
              startX: oriActivePlank.startX,
              startY: oriActivePlank.startY,
            }, // 原始表单数据
            compareMsg: [
              {
                title:
                  bigpart.canvasIndex === oriBigpart?.canvasIndex
                    ? `${this.activePlank.partName}-大板序号：${bigpart.canvasIndex}`
                    : this.activePlank.partName,
                keys: ['canvasIndex', 'startX', 'startY'],
              },
            ], // 要比较的内容
            formTextObj: {
              partName: '板件名称',
              canvasIndex: '大板序号',
              startX: '横坐标',
              startY: '纵坐标',
            }, // key对应的意思
            formValueObj: {}, // value对应的意思
          },
        ],
      })
    },
    clickUpCanvasFn(e) {
      // if (e.button == 0) {
      //   if (!this.isUseImage) {
      //     this.clickUpCanvas(e)
      //   }
      // }
      if (e.button == 2 || e.button == 0) {
        this.clickUpCanvas(e)
      }
    },
    // 鼠标松开事件
    clickUpCanvas(e) {
      // 左键
      if (e.button == 0) {
        // if(this.isMovingCavnas) return
        if (this.isMovingCavnas) {
          if (this.isMovingPlank) return
          this.isMovingCavnas = false
          this.canvasOffset = null
          return
        }
        // 这个值是准确的
        let { offsetX, offsetY } = this.getOffsetBetweenPointAndCanvas(
          e,
          this.clickEvent
        )
        if (!this.isUseImage) {
          offsetX = e.offsetX
          offsetY = e.offsetY
        }
        this.resetPlankStatus()
        if (this.activePlank && this.activeBigpart) {
          if (this.activeBigpart.isLocked || this.ncSetting.glass_setting)
            return
          // 处理canvas里面的板件信息
          this.dealCanvasPlankMsg({
            offsetX,
            offsetY,
          })
          const data = this.drawData.data
          // 当前激活大板为true其他大板为false
          data.forEach((item) => {
            item.isActive = item.stockKey === this.activeBigpart.stockKey
          })
          // 点了小板显示锁
          if (!this.activeBigpart.isLocked) {
            this.loadLockedImg(
              this.ctx,
              this.activeBigpart.lockStartX,
              this.activeBigpart.lockStartY,
              this.activeBigpart.isLocked,
              this.activeBigpart.lockWidthHeight
            )
          }
        }
      }
      // 右键
      if (e.button == 2) {
        if (this.isMovingPlank) return
        this.isMovingCavnas = false
        this.canvasOffset = null
      }
    },
    // 重新绘制当前板件
    reRenderPlank() {
      if (!this.activePlank) return
      this.activePlank.startX = this.plankStartPoint.x
      this.activePlank.startY = this.plankStartPoint.y
      dealPlankPoly(this.activePlank)
    },
    // 鼠标移动事件
    movePlank(e) {
      // todo 需要被删除
      const point = {
        x: e.offsetX,
        y: e.offsetY,
      }
      if (!this.activePlank) {
        if (_debounceTime) {
          clearTimeout(_debounceTime)
        }
        _debounceTime = setTimeout(() => {
          const activePart = this.plankControl.getActivePart(e)
          const conflict = partIsConflict(activePart)
          this.$emit(
            'onConflictPart',
            conflict && !this.activePlank ? activePart : null
          )
        }, 200)
      }

      if (this.activePlank && !this.isMovingPlank) {
        if (_debounceTime) {
          clearTimeout(_debounceTime)
        }
        _debounceTime = setTimeout(() => {
          // 绘制孔槽等的详细信息
          // 我知道你会疑惑上面不是判断了this.activePlank有值才会进入，为啥这里又要判断，这一步是因为这里是宏任务，这里的this.activePlank可能存在null的情况
          if (this.activeBigpart && this.activePlank) {
            this.hsDetailInfoList = drawActivePlankOtherInfo(
              this.activeBigpart,
              point,
              this.activePlank,
              {
                deviation: this.deviation,
                padding: this.padding,
                scalePercent: this.scalePercent,
                defaultScale: defaultScale,
              },
              'paiban'
            )
          }
          if (this.isRenderHSDetail) {
            this.renderAll()
            this.isRenderHSDetail = false
          }
          if (this.hsDetailInfoList.length) {
            this.mousePoint = point
            this.renderAll()
            this.isRenderHSDetail = true
          }
        }, 50)
      } else {
        this.hsDetailInfoList = []
      }
      if (this.isUseImage) {
        this.isMovingPlank = false
      }
      if (this.isMovingPlank) {
        this.$emit('changePaibanData', true)
        if (_debounceTime) {
          clearTimeout(_debounceTime)
        }
        if (this.ncSetting.glass_setting) {
          _debounceTime = setTimeout(() => {
            this.$message.error('生产线为玻璃切割机时，板件无法被移动!')
          }, 100)
          return
        }
        if (this.activePlank) {
          this.dragPosition = point
          this.renderAll()
        }
      }
      if (this.isMovingCavnas) {
        this.canvasOffset = {
          x: e.offsetX - this.canvasRightDrag.x,
          y: e.offsetY - this.canvasRightDrag.y,
        }

        this.renderAll()
        for (let i = 0; i < this.sliceDrawData.length; ++i) {
          let data = this.sliceDrawData[i]
          data.startX += this.canvasOffset.x
          data.startY += this.canvasOffset.y
        }
        this.canvasRightDrag = {
          x: e.offsetX,
          y: e.offsetY,
        }
      }
      const bigPart = this.getCurrentBigPart(point)
      if (!bigPart) return
      const result = this.jundgeLocation(point, bigPart.lockPath)
      if (bigPart.isLocked || bigPart.isActive) {
        this.changeLockHover(e, bigPart, result)
      }
      _debounceTime = setTimeout(() => {
        if (
          bigPart.surplusInfo &&
          !bigPart.surplusInfo.isNotSurplus &&
          bigPart.surplusInfo?.branch_name
        ) {
          let { startX, startY } = bigPart
          let width = bigPart.width + 2 * this.deviation
          let surStore = bigPart.surplusInfo?.branch_name
          if (
            point.x > startX &&
            point.x <= startX + width &&
            point.y <= startY + this.padding / 2 &&
            this.calcTextSize(surStore) >= width
          ) {
            this.surStoreTooltip = [{ msg: surStore, color: '#fff' }]
            if (this.isRenderSSTooltip) {
              this.renderAll()
              this.isRenderSSTooltip = false
            }
            if (this.surStoreTooltip.length) {
              this.mousePoint = point
              this.renderAll()
              this.isRenderSSTooltip = true
            }
          } else {
            this.surStoreTooltip = []
            this.renderAll()
          }
        }
      }, 50)
    },
    // 鼠标是否在暂存区内
    pointIsInDropArea(e) {
      let areaContentDom = document.querySelector('.temp-area-content')
      const areaContentMsg = areaContentDom.getBoundingClientRect()
      const xmin = areaContentMsg.left
      const xmax = areaContentMsg.left + areaContentMsg.width
      const ymin = areaContentMsg.top
      const ymax = areaContentMsg.top + areaContentMsg.height
      let { x, y } = e
      if (xmin <= x && x <= xmax && ymin <= y && y <= ymax) {
        return true
      }
      return false
    },
    // 页面滚动时重新绘制
    scrollCanvas(canvasInfo) {
      if (!this.imageTempMousedownFlag) {
        // 滚动的时候，隐藏图片，绘制板件
        this.showImageTemp(false)
        this.isDrawImage = false
      }
      this.canvasBtn.data = null
      let scrollOff = {
        x: 0,
        y: 0,
      }
      if (canvasInfo.top < 114) {
        let a = 114 - canvasInfo.y
        scrollOff.y = a
      }
      if (canvasInfo.left < 16) {
        let b = 16 - canvasInfo.left
        scrollOff.x = b
      }
      this.scrollDraw(scrollOff)
      this.startLeft = scrollOff.x
      this.startTop = scrollOff.y
    },
    // 滚轮缩放
    wheelScale(e) {
      if (e.ctrlKey) {
        this.emptyDistance = false
        this.scaleCanvas(e)
      }
    },

    draw() {
      const standardPlank = this.sliceDrawData.find(
        (item) => !item.otherPlate && !item.surplusInfo
      )
      if (
        this.selectStandardPlank &&
        Object.keys(this.selectStandardPlank).length
      ) {
        this.plankWidth = !this.ncSetting.xyReverse
          ? this.selectStandardPlank.plankWidth
          : this.selectStandardPlank.plankHeight
        this.plankHeight = !this.ncSetting.xyReverse
          ? this.selectStandardPlank.plankHeight
          : this.selectStandardPlank.plankWidth
      } else if (standardPlank) {
        this.plankWidth = standardPlank.plankWidth
        this.plankHeight = standardPlank.plankHeight
      } else {
        this.plankWidth =
          this.ncSetting.drawPlankWidth ?? this.ncSetting.panelSize.plankWidth
        this.plankHeight =
          this.ncSetting.drawPlankHeight ?? this.ncSetting.panelSize.plankHeight
      }
      this.scaleWidth = this.plankWidth / defaultScale
      this.scaleHeight = this.plankHeight / defaultScale
      // 计算每一个大板的绘制起点
      let canvasHeight = this.calcPerParts() + 200
      this.canvasDom.height = canvasHeight
      // 开始绘制
      this.startDrawCanvas()
    },

    calcPerParts() {
      let canvasWidth = this.canvasDom.width
      // 记录两个大板之间的横向距离
      let partsPadding = 76 * this.scalePercent
      let canvasHeight = 0
      // 记录是第几行的大板
      let lineNum = 1
      // 记录每一行所有大板的高度
      let lineHeight = {}
      // 记录最高的高度
      for (let i = 0; i < this.sliceDrawData.length; ++i) {
        let bigpart = this.sliceDrawData[i]
        let recordHeight = 0
        let recordWidth = 0
        if (bigpart.surplusInfo) {
          let surplusWidth = bigpart.surplusInfo.width
          let surplusHeight = bigpart.surplusInfo.height
          recordHeight = this.tranlateSizeFromScale(Number(surplusHeight))
          recordWidth = this.tranlateSizeFromScale(Number(surplusWidth))
        } else {
          recordHeight = this.tranlateSizeFromScale(Number(bigpart.plankHeight))
          recordWidth = this.tranlateSizeFromScale(Number(bigpart.plankWidth))
        }

        bigpart.height = recordHeight
        bigpart.width = recordWidth
        if (lineHeight[lineNum]) {
          if (recordHeight + 2 * this.padding > lineHeight[lineNum]) {
            lineHeight[lineNum] = recordHeight + 2 * this.padding
          }
        } else {
          lineHeight[lineNum] = recordHeight + 2 * this.padding
        }

        if (i == 0) {
          bigpart.startX = this.padding
          bigpart.startY = 33 * this.scalePercent
        } else {
          let lastpart = this.sliceDrawData[i - 1]
          if (
            bigpart.width + lastpart.startX + lastpart.width + partsPadding >
            canvasWidth
          ) {
            bigpart.startX = this.padding
            bigpart.startY =
              lastpart.startY + 2 * this.deviation + lineHeight[lineNum]
            lineNum += 1
            lineHeight[lineNum] = bigpart.height + 2 * this.padding
          } else {
            bigpart.startX =
              this.sliceDrawData[i - 1].startX +
              this.sliceDrawData[i - 1].width +
              partsPadding
            bigpart.startY = lastpart.startY
          }
        }
      }
      for (let i in lineHeight) {
        canvasHeight += lineHeight[i]
      }
      return canvasHeight + 30
    },

    startDrawCanvas() {
      for (let i = 0; i < this.sliceDrawData.length; ++i) {
        let data = this.sliceDrawData[i]
        data.canvasIndex =
          this.startNum + (this.currentPape - 1) * this.countPerPage + i + 1
        // 绘制一个大板
        this.drawOneBigpart(data)
      }
    },
    // 绘制一个大板的所有东西
    drawOneBigpart(bigpart, activePlank) {
      if (this.isOnlyShowSurplus && !bigpart.surplusInfo) return
      if (!Object.keys(bigpart).length) return
      let strokeColor = '#333'
      let strokeLine = 1
      this.ctx.fillStyle = '#eee'
      if (
        this.activeBigpart &&
        this.activeBigpart.stockKey == bigpart.stockKey // stockKey: "T01:高光_多层实木:18.9:1"
      ) {
        strokeColor = '#f00'
        strokeLine = 2
      } else {
        strokeColor = 'rgba(0,0,0,0.3)'
        strokeLine = 1
      }
      let left = bigpart.startX
      let top = bigpart.startY
      // 边框是比真正的大板要大一圈的圆角矩形, 而且顶部还有其他信息, 所以长宽多10, 起始点y要多30
      this.strokeRoundRect(
        this.ctx,
        left - strokeLine,
        top - strokeLine + this.padding / 2,
        bigpart.width + 2 * this.deviation + 2 * strokeLine,
        bigpart.height + 2 * this.deviation + this.padding / 2 + 2 * strokeLine,
        0,
        strokeLine,
        strokeColor
      )
      this.ctx.fillStyle = '#ECECEC'
      let newLeft = left + this.deviation
      let newTop = top + this.deviation + this.padding
      if (bigpart.surplusInfo && Object.keys(bigpart.surplusInfo).length > 0) {
        let surplus = bigpart.surplusInfo
        if (surplus.shape && surplus.shape == 'lshape') {
          let trimSide = this.isHistoryStatus
            ? window.sessionStorage.getItem('trimSide')
            : this.ncSetting.trimSide
          let x3 = this.ncSetting.xyReverse
            ? Number(surplus.y5)
            : Number(surplus.x3)
          let y3 = this.ncSetting.xyReverse
            ? Number(surplus.x5)
            : Number(surplus.y3)
          let x4 = this.ncSetting.xyReverse
            ? Number(surplus.y4)
            : Number(surplus.x4)
          let y4 = this.ncSetting.xyReverse
            ? Number(surplus.x4)
            : Number(surplus.y4)
          let x5 = this.ncSetting.xyReverse
            ? Number(surplus.y3)
            : Number(surplus.x5)
          let y5 = this.ncSetting.xyReverse
            ? Number(surplus.x3)
            : Number(surplus.y5)
          let newWidth = surplus.width
          let newHeight = surplus.height
          if (
            this.ncSetting.xyReverse &&
            (this.ncSetting.startPosition == '右下角' ||
              this.ncSetting.startPosition == '左上角')
          ) {
            x3 = newWidth - x3
            x4 = newWidth - x4
            x5 = newWidth - x5

            this.ctx.beginPath()
            this.ctx.moveTo(newLeft, newTop)

            this.ctx.lineTo(
              newLeft + this.tranlateSizeFromScale(newWidth),
              newTop
            )
            this.ctx.lineTo(
              newLeft + this.tranlateSizeFromScale(newWidth),
              newTop + this.tranlateSizeFromScale(newHeight)
            )

            this.ctx.lineTo(
              newLeft + this.tranlateSizeFromScale(x3),
              newTop + this.tranlateSizeFromScale(y3)
            )

            this.ctx.lineTo(
              newLeft + this.tranlateSizeFromScale(x4),
              newTop + this.tranlateSizeFromScale(y4)
            )

            this.ctx.lineTo(
              newLeft + this.tranlateSizeFromScale(x5),
              newTop + this.tranlateSizeFromScale(y5)
            )
          } else {
            y3 = newHeight - y3
            y4 = newHeight - y4
            y5 = newHeight - y5
            this.ctx.beginPath()
            // 根据修边方向(xy互换不处理，高光板只生效右下、左上修边) 设置不同缺口方向
            const isHighGPlank =
              bigpart.parts[0]?.is_high_gloss_plank ??
              bigpart.stockKey.includes('高光_')
            if (
              trimSide === 'topRight' &&
              !this.ncSetting.xyReverse &&
              !isHighGPlank
            ) {
              this.ctx.moveTo(
                newLeft,
                newTop + this.tranlateSizeFromScale(newHeight)
              )

              this.ctx.lineTo(
                newLeft + this.tranlateSizeFromScale(x3),
                newTop +
                  this.tranlateSizeFromScale(newHeight) -
                  this.tranlateSizeFromScale(y3)
              )

              this.ctx.lineTo(
                newLeft + this.tranlateSizeFromScale(x4),
                newTop +
                  this.tranlateSizeFromScale(newHeight) -
                  this.tranlateSizeFromScale(y4)
              )

              this.ctx.lineTo(
                newLeft + this.tranlateSizeFromScale(x5),
                newTop +
                  this.tranlateSizeFromScale(newHeight) -
                  this.tranlateSizeFromScale(y5)
              )
              this.ctx.lineTo(
                newLeft + this.tranlateSizeFromScale(newWidth),
                newTop
              )
              this.ctx.lineTo(newLeft, newTop)
            } else if (
              trimSide === 'bottomLeft' &&
              !this.ncSetting.xyReverse &&
              !isHighGPlank
            ) {
              this.ctx.moveTo(
                newLeft + this.tranlateSizeFromScale(newWidth),
                newTop
              )

              this.ctx.lineTo(
                newLeft +
                  this.tranlateSizeFromScale(newWidth) -
                  this.tranlateSizeFromScale(x3),
                newTop + this.tranlateSizeFromScale(y3)
              )

              this.ctx.lineTo(
                newLeft +
                  this.tranlateSizeFromScale(newWidth) -
                  this.tranlateSizeFromScale(x4),
                newTop + this.tranlateSizeFromScale(y4)
              )

              this.ctx.lineTo(
                newLeft +
                  this.tranlateSizeFromScale(newWidth) -
                  this.tranlateSizeFromScale(x5),
                newTop + this.tranlateSizeFromScale(y5)
              )
              this.ctx.lineTo(
                newLeft,
                newTop + this.tranlateSizeFromScale(newHeight)
              )
              this.ctx.lineTo(
                newLeft + this.tranlateSizeFromScale(newWidth),
                newTop + this.tranlateSizeFromScale(newHeight)
              )
            } else if (trimSide === 'topLeft' && !this.ncSetting.xyReverse) {
              this.ctx.moveTo(
                newLeft + this.tranlateSizeFromScale(newWidth),
                newTop + this.tranlateSizeFromScale(newHeight)
              )

              this.ctx.lineTo(
                newLeft +
                  this.tranlateSizeFromScale(newWidth) -
                  this.tranlateSizeFromScale(x3),
                newTop +
                  this.tranlateSizeFromScale(newHeight) -
                  this.tranlateSizeFromScale(y3)
              )

              this.ctx.lineTo(
                newLeft +
                  this.tranlateSizeFromScale(newWidth) -
                  this.tranlateSizeFromScale(x4),
                newTop +
                  this.tranlateSizeFromScale(newHeight) -
                  this.tranlateSizeFromScale(y4)
              )

              this.ctx.lineTo(
                newLeft +
                  this.tranlateSizeFromScale(newWidth) -
                  this.tranlateSizeFromScale(x5),
                newTop +
                  this.tranlateSizeFromScale(newHeight) -
                  this.tranlateSizeFromScale(y5)
              )
              this.ctx.lineTo(newLeft, newTop)
              this.ctx.lineTo(
                newLeft + this.tranlateSizeFromScale(newWidth),
                newTop
              )
            } else {
              this.ctx.moveTo(newLeft, newTop)

              this.ctx.lineTo(
                newLeft + this.tranlateSizeFromScale(x3),
                newTop + this.tranlateSizeFromScale(y3)
              )

              this.ctx.lineTo(
                newLeft + this.tranlateSizeFromScale(x4),
                newTop + this.tranlateSizeFromScale(y4)
              )

              this.ctx.lineTo(
                newLeft + this.tranlateSizeFromScale(x5),
                newTop + this.tranlateSizeFromScale(y5)
              )
              this.ctx.lineTo(
                newLeft + this.tranlateSizeFromScale(newWidth),
                newTop + this.tranlateSizeFromScale(newHeight)
              )
              this.ctx.lineTo(
                newLeft,
                newTop + this.tranlateSizeFromScale(newHeight)
              )
            }
          }

          this.ctx.closePath()
          this.ctx.fill()
        } else {
          this.ctx.fillRect(
            newLeft,
            newTop,
            this.tranlateSizeFromScale(surplus.width),
            this.tranlateSizeFromScale(surplus.height)
          )
        }
      } else {
        // 绘制真正的大板
        this.ctx.fillRect(newLeft, newTop, bigpart.width, bigpart.height)
      }
      // 添加大板锁定icon的信息
      bigpart.lockStartX = newLeft + bigpart.width + 15 * this.scalePercent
      bigpart.lockEndX = bigpart.lockStartX + bigpart.lockWidthHeight
      bigpart.lockStartY = newTop - 30 * this.scalePercent
      bigpart.lockEndY = bigpart.lockStartY + bigpart.lockWidthHeight
      bigpart.lockWidthHeight = 24 * this.scalePercent
      bigpart.lockPath = [
        { X: bigpart.lockStartX, Y: bigpart.lockEndY },
        { X: bigpart.lockEndX, Y: bigpart.lockEndY },
        { X: bigpart.lockEndX, Y: bigpart.lockStartY },
        { X: bigpart.lockStartX, Y: bigpart.lockStartY },
      ]
      if (bigpart.isLocked) {
        this.loadLockedImg(
          this.ctx,
          bigpart.lockStartX,
          bigpart.lockStartY,
          bigpart.isLocked,
          bigpart.lockWidthHeight
        )
      }
      // 绘制顶部信息(正反切换和优化率)
      this.drawTopInfo(bigpart)
      // 板件重叠的绘制，可用于后续的问题排查，特保留
      // this.intersect.forEach((seg) => {
      //   this.ctx.moveTo(
      //     newLeft + this.tranlateSizeFromScale(seg.ps.x),
      //     newTop + this.tranlateSizeFromScale(seg.ps.y)
      //   )
      //   this.ctx.lineTo(
      //     newLeft + this.tranlateSizeFromScale(seg.pe.x),
      //     newTop + this.tranlateSizeFromScale(seg.pe.y)
      //   )
      // })
      this.ctx.stroke()
      // 绘制小板
      // 记录是否有激活板件, 激活的板件最后绘制
      let finalPart = null
      // 绘制夹手
      this.drawClampHand(this.ctx, bigpart.clampHandInfo, newLeft, newTop)
      for (let i = 0; i < bigpart.parts.length; ++i) {
        let part = bigpart.parts[i]
        if (this.activePlank && isSamePart(part, this.activePlank)) {
          finalPart = part
          continue
        }
        this.drawParts(this.ctx, part, newLeft, newTop, bigpart, activePlank)
      }
      if (finalPart) {
        if (this.isUseImage) {
          if (this.isDrawImage) {
            if (_debounceTime) {
              clearTimeout(_debounceTime)
            }
            // 大板锁定状态 || 玻璃切割机生产线 取消生成图片 并且重新绘制所有小板数据
            const isLocked = bigpart.isLocked,
              isGlass = this.ncSetting.glass_setting
            if (isLocked || isGlass) {
              _debounceTime = setTimeout(() => {
                isLocked
                  ? this.$message.error(
                      translate('arrangedPage.lockPartEditTip')
                    )
                  : this.$message.error(
                      translate('arrangedPage.glassEquipmentTip')
                    )
              }, 100)
              this.drawParts(
                this.ctx,
                finalPart,
                newLeft,
                newTop,
                bigpart,
                activePlank
              )
              return
            }
            // if (this.ncSetting.glass_setting) {
            //   _debounceTime = setTimeout(() => {
            //     this.$message.error('生产线为玻璃切割机时，板件无法被移动!')
            //   }, 100)
            //   this.drawParts(this.ctx, finalPart, newLeft, newTop, bigpart)
            //   return
            // }
            // 绘制当前小板的图片
            this.drawOnePartImageForMove(
              this.ctx,
              this.activePlank,
              newLeft,
              newTop,
              bigpart
            )
          } else {
            // 如果绘制了小板的图片，那么当前板件就不需要绘制了
            this.drawParts(
              this.ctx,
              finalPart,
              newLeft,
              newTop,
              bigpart,
              activePlank
            )
          }
        } else {
          this.drawParts(
            this.ctx,
            finalPart,
            newLeft,
            newTop,
            bigpart,
            activePlank
          )
        }
      }
      if (
        this.activeBigpart &&
        this.activeBigpart.stockKey == bigpart.stockKey
      ) {
        this.drawAddIcon(bigpart)
        // 激活时出现锁的icon
        this.loadLockedImg(
          this.ctx,
          bigpart.lockStartX,
          bigpart.lockStartY,
          bigpart.isLocked,
          bigpart.lockWidthHeight
        )
      }
    },
    // 获取到鼠标距离图片左上角的距离
    setMousePointOffsetImage(x, y) {
      this.mousePointOffsetImage = {
        x,
        y,
      }
    },
    // 生成点击板件对应的dom
    genClickPlankImageDom() {
      let wrap = document.querySelector(
        `.select-plank-image-wrap-${this.canvasKey}`
      )
      if (wrap) {
        return
      }
      wrap = document.createElement('div')
      wrap.className = `select-plank-image-wrap select-plank-image-wrap-${this.canvasKey}`
      wrap.style.position = 'fixed'
      wrap.style.zIndex = 1000
      let mask = document.createElement('div')
      mask.style.position = 'absolute'
      mask.style.width = '100%'
      mask.style.height = '100%'
      mask.style.left = 0
      mask.style.top = 0
      mask.style.zIndex = 2
      // mask.style.background = 'red'

      wrap.appendChild(mask)
      document.body.appendChild(wrap)
    },
    // 图片模板是否在canvas里面
    imageTempIsInCanvas(imageDom) {
      const canvasDom = document.querySelector(`#canvas${this.canvasKey}`)
      const canvasInfo = canvasDom.getBoundingClientRect()
      let imageDomInfo = {
        x: parseFloat(imageDom.style.left),
        y: parseFloat(imageDom.style.top),
        width: parseFloat(imageDom.offsetWidth),
        height: parseFloat(imageDom.offsetHeight),
      }

      if (
        imageDomInfo.x >= canvasInfo.x &&
        imageDomInfo.y >= canvasInfo.y &&
        imageDomInfo.x + imageDomInfo.width <=
          canvasInfo.x + canvasInfo.width &&
        imageDomInfo.y + imageDomInfo.height <= canvasInfo.y + canvasInfo.height
      ) {
        return true
      }
      return false
    },
    /** 添加板件到暂存库 */
    async handleAddPlankToAwaitStore(activePlank, activeBigPlank) {
      // 清除大板上的指定小板
      const flag = clearPlankToBigPlank(activePlank, activeBigPlank)
      if (!flag) return
      // 重新生成板件的图片 默认放大3倍
      const imgUrl = getPlankImage(activePlank, false, {
        defaultScale,
        scale: 3,
        plankLineWidth: 1,
      })
      //生成本地暂存库数据并将数据添加到本地暂存库中
      const data = await genAwaitStoreData(
        activePlank,
        this.preLayoutData,
        imgUrl
      )
      this.$store.commit('awaitPaibanStore/setPlankToStore', data)
      // 进行板件处理后的固定操作
      buryPointApi('layout', 'plate_staged')
      this.$emit('recordActivePlank', null)
      // 计算优化率
      calcBigpartArea(
        activeBigPlank,
        this.ncSetting,
        this.$props.isUnuseSurplus
      )
      // 判断是否需要正反面切换
      this.judgeAbleFanban(activeBigPlank)
      this.activePlank = null
      this.setActiveBigPart(null)
      // 重新统计翻板数
      this.$props.calcFanbanCount()
      this.renderAll()
    },
    // 点击生成的图片鼠标松开
    imageTempMouseUp(e) {
      // 如果是拖到暂存区
      if (mousePointISInElement(e, 'paiban-await-store', 'id')) {
        this.showImageTemp(false)
        this.handleAddPlankToAwaitStore(this.activePlank, this.activeBigpart)
        return
      }
      let imageWrap = document.querySelector(
        `.select-plank-image-wrap-${this.canvasKey}`
      )
      // 如果不在当前canvas，就直接回到原来的位置
      if (!this.imageTempIsInCanvas(imageWrap)) {
        this.$message({
          message: '无法放置, 请核对板件材质、颜色和厚度属性!',
          type: 'error',
        })
        // 隐藏图片
        this.showImageTemp(false)
        this.isDrawImage = false
        this.reRenderPlank()
        this.renderAll()
        return
      }

      this.setMousePointOffsetImage(0, 0)
      this.resetPlankStatus()
      // 这儿进行一列逻辑处理(复用以前的逻辑)
      this.clickUpCanvas(e)
    },
    // 绘制一块小板件的图片用于拖动
    async drawOnePartImageForMove(
      oldCtx,
      part,
      bigPankStartX,
      bigPankStartY,
      bigpart
    ) {
      // 如果某个小板正处于点击按下状态，那么就直接返回，否则canvas滚动的时候，生成的图片会跳跃
      if (this.imageTempMousedownFlag) {
        return
      }
      // 转换宽高
      let width = Math.ceil(
        this.tranlateSizeFromScale(part.rect.width) + partLineWidth * 1
      )
      let height = Math.ceil(
        this.tranlateSizeFromScale(part.rect.height) + partLineWidth * 1
      )

      // 这个是测试canvas
      const canvas = document.createElement('canvas')
      canvas.style.position = 'fixed'
      canvas.style.left = '10px'
      canvas.style.top = '10px'
      canvas.style.background = '#ececec'
      canvas.style.display = 'none'
      // 为了显示完全选中的红色边框，宽高各加上 this.deviation
      canvas.width = width
      canvas.height = height
      document.body.appendChild(canvas)

      let boxWrap = document.querySelector('.box-wrap')
      if (!boxWrap) {
        boxWrap = document.createElement('div')
        boxWrap.classList.add('box-wrap')
        document.body.appendChild(boxWrap)
      }
      boxWrap.innerHTML = ''
      boxWrap.appendChild(canvas)

      const newCtx = canvas.getContext('2d')
      let togglePart = JSON.parse(JSON.stringify(part))
      togglePart.startX = 0
      togglePart.startY = 0
      // 对ctx的样式进行延续
      newCtx.strokeStyle = oldCtx.strokeStyle
      newCtx.lineWidth = oldCtx.lineWidth
      newCtx.fillStyle = oldCtx.fillStyle
      this.drawParts(
        newCtx,
        togglePart,
        partLineWidth / 2,
        partLineWidth / 2,
        bigpart
      )
      this.genClickPlankImageDom()
      const base64 = canvas.toDataURL('image/png', {
        quality: 1,
        format: 'jpeg',
      })
      let image = document.querySelector(
        `.select-plank-image-${this.canvasKey}`
      )
      if (!this.plankMapImg) {
        this.plankMapImg = {}
      }
      // 如果没有缓存，或者缩放比例发生了变化，将生成的img进行缓存（由于有旋转问题，暂时不用图片缓存）
      // if (!this.plankMapImg[plankKey] || this.scalePercent !== this.lastScale) {
      //   this.plankMapImg[plankKey] = base64
      // }

      let imageWrap = document.querySelector(
        `.select-plank-image-wrap-${this.canvasKey}`
      )

      if (!image) {
        // 没有图片就创造图片
        image = document.createElement('img')
        image.className = `select-plank-image select-plank-image-${this.canvasKey}`
        // 把拖动事件挂载wrap上
        imageWrap.appendChild(image)
        this.handleImageTempMouseUp = (e) => {
          this.imageTempMouseUp(e)
        }

        this.handleImageTempMouseDown = (e) => {
          this.setMousePointOffsetImage(e.offsetX, e.offsetY)
          // 设置标记为true
          this.imageTempMousedownFlag = true
        }
        imageWrap.addEventListener('mouseup', this.handleImageTempMouseUp)
        imageWrap.addEventListener('mousedown', this.handleImageTempMouseDown)
      }
      image.style.display = 'block'

      // image.src = this.plankMapImg[plankKey]
      image.src = base64
      const distanceBetweenPointAndPart = this.getDistanceBetweenPointAndPart(
        part,
        bigPankStartX,
        bigPankStartY
      )
      this.setMousePointOffsetImage(
        distanceBetweenPointAndPart.x,
        distanceBetweenPointAndPart.y
      )
      // 由于有线宽的原因，所以必须减去一半的线宽
      imageWrap.style.left =
        this.clickEvent.x -
        distanceBetweenPointAndPart.x -
        partLineWidth / 2 +
        'px'
      // 由于有线宽的原因，所以必须减去一半的线宽
      imageWrap.style.top =
        this.clickEvent.y -
        distanceBetweenPointAndPart.y -
        partLineWidth / 2 +
        'px'

      // 设置图片显示标记为true
      this.imageTempMousedownFlag = true
    },
    // 获取鼠标所在位置到板件的top和left的距离
    getDistanceBetweenPointAndPart(part, bigPankStartX, bigPankStartY) {
      const pos = this.windowToCanvas(
        this.clickEvent.clientX,
        this.clickEvent.clientY
      )
      let rect = this.genPartsRect(part, bigPankStartX, bigPankStartY)
      return {
        x: pos.x - rect.x,
        y: pos.y - rect.y,
      }
    },

    // 绘制大板右下角加号操作
    drawAddIcon(bigpart) {
      this.ctx.fillStyle = '#ECECEC'
      let newLeft =
        bigpart.startX +
        3 * this.deviation +
        bigpart.width +
        2 * this.scalePercent
      let newTop = bigpart.startY + this.padding + this.deviation
      let long = 40 * this.scalePercent
      let r = 2.5 * this.scalePercent
      this.ctx.fillRect(newLeft, newTop, long, long)

      this.ctx.fillStyle = '#666'
      this.ctx.beginPath()
      this.ctx.arc(
        newLeft + 9 * this.scalePercent,
        newTop + 20 * this.scalePercent,
        r,
        0,
        Math.PI * 2,
        true
      )
      this.ctx.closePath()
      this.ctx.fill()

      this.ctx.beginPath()
      this.ctx.arc(
        newLeft + 18 * this.scalePercent,
        newTop + 20 * this.scalePercent,
        r,
        0,
        Math.PI * 2,
        true
      )
      this.ctx.closePath()
      this.ctx.fill()

      this.ctx.beginPath()
      this.ctx.arc(
        newLeft + 27 * this.scalePercent,
        newTop + 20 * this.scalePercent,
        r,
        0,
        Math.PI * 2,
        true
      )
      this.ctx.closePath()
      this.ctx.fill()
    },

    /**
     * 绘制大板顶部信息包括正反切换和优化率
     * @param { Object } bigpart 大板信息
     */
    drawTopInfo(bigpart) {
      let fontSize = 14 * this.scalePercent
      this.ctx.font = `bold ${fontSize}px 'PingFangSC-Regular, PingFang SC' `
      let { startX, startY } = bigpart
      let width = bigpart.width + 2 * this.deviation
      const isSurplus = bigpart.surplusInfo && !bigpart.surplusInfo.isNotSurplus
      const isLshape =
        bigpart.surplusInfo && bigpart.surplusInfo.shape == 'lshape'
      const plankSize = calcPlankSize(bigpart, isLshape)
      let usedRate =
        '【' +
        bigpart.canvasIndex +
        '】' +
        `${((bigpart.usedRate > 1 ? 1 : bigpart.usedRate) * 100).toFixed(2)}` +
        '%' +
        (isLshape
          ? ` [${plankSize.longW}(${plankSize.shortW})X${plankSize.longH}(${plankSize.shortH})]`
          : `  (${bigpart.plankWidth}X${bigpart.plankHeight})`)
      const rateStr =
        '【' +
        bigpart.canvasIndex +
        '】' +
        `${((bigpart.usedRate > 1 ? 1 : bigpart.usedRate) * 100).toFixed(2)}` +
        '%'
      const sizeStr = isLshape
        ? ` ${plankSize.longW}(${plankSize.shortW})X${plankSize.longH}(${plankSize.shortH})`
        : `  (${bigpart.plankWidth}X${bigpart.plankHeight})`
      let surStore =
        bigpart.surplusInfo?.branch_name == '-'
          ? ''
          : bigpart.surplusInfo?.branch_name
      // 如果surStore的值为-，设置为空
      let copySurStore = bigpart.surplusInfo?.branch_name
      if (isSurplus && surStore) {
        for (let i = 0; i <= copySurStore.length; i++) {
          if (this.calcTextSize(copySurStore.slice(0, i)) >= width) {
            surStore = `${surStore.slice(0, i - 1)}...`
            break
          }
        }
      }
      if (bigpart.ableToggle) {
        if (this.isHighPlank) {
          // 绘制正
          this.ctx.fillStyle = '#18a8c7'
          this.ctx.fillRect(
            startX,
            startY + this.padding / 2,
            width / 2,
            this.padding / 2
          )
          // 绘制正字
          this.ctx.fillStyle = '#fff'
          let textWidth = this.calcTextSize(translate('common.front'))
          let padding = (width / 2 - textWidth) / 2
          this.ctx.fillText(
            translate('common.front'),
            startX + padding,
            startY + 22 * this.scalePercent + this.padding / 2
          )

          // 绘制反
          this.ctx.fillStyle = '#d7d7d7'
          this.ctx.fillRect(
            startX + width / 2,
            startY + this.padding / 2,
            width / 2,
            this.padding / 2
          )
          this.ctx.fillStyle = '#666'
          // 绘制反字
          this.ctx.fillText(
            translate('common.back'),
            startX + width / 2 + padding,
            startY + 22 * this.scalePercent + this.padding / 2
          )

          // 绘制优化率
          this.ctx.fillStyle = '#fff'
          this.ctx.fillRect(
            startX + width / 2,
            startY - 33 * this.scalePercent,
            width / 2,
            this.padding / 2
          )
          this.ctx.fillStyle = '#333'
          textWidth = this.calcTextSize(usedRate)
          this.ctx.beginPath()
          if (textWidth + (isSurplus ? 24 : 10) > width) {
            if (isSurplus) {
              this.ctx.strokeStyle = '#000'
              circleText(
                this.ctx,
                startX + 12 * this.scalePercent,
                surStore
                  ? startY - 19 * this.scalePercent
                  : startY - 5 * this.scalePercent,
                10 * this.scalePercent,
                '余',
                this.scalePercent
              )
            }
            this.ctx.fillText(
              rateStr,
              isSurplus ? startX + 24 * this.scalePercent : startX,
              isSurplus && surStore ? startY - 14 * this.scalePercent : startY
            )
            this.ctx.fillText(
              sizeStr,
              startX,
              isSurplus && surStore
                ? startY + 8 * this.scalePercent
                : startY + 24 * this.scalePercent
            )
            if (isSurplus && surStore) {
              this.ctx.fillText(
                surStore,
                startX,
                startY + 28 * this.scalePercent
              )
            }
          } else {
            padding = (width / 3 - textWidth) / 2
            // 绘制优化率文字
            if (isSurplus) {
              this.ctx.strokeStyle = '#000'
              circleText(
                this.ctx,
                startX + 12 * this.scalePercent,
                surStore
                  ? startY + 4 * this.scalePercent
                  : startY + 18 * this.scalePercent,
                10 * this.scalePercent,
                '余',
                this.scalePercent
              )
            }
            this.ctx.fillText(
              usedRate,
              isSurplus ? startX + 24 * this.scalePercent : startX,
              isSurplus && surStore
                ? startY + 8 * this.scalePercent
                : startY + 24 * this.scalePercent
            )
            if (isSurplus && surStore) {
              this.ctx.fillText(
                surStore,
                startX,
                startY + 28 * this.scalePercent
              )
            }
          }
          return
        }
        if (bigpart.sideType == 1) {
          // 绘制正
          this.ctx.fillStyle = '#18a8c7'
          this.ctx.fillRect(
            startX,
            startY + this.padding / 2,
            width / 2,
            this.padding / 2
          )
          // 绘制正字
          this.ctx.fillStyle = '#fff'
          let textWidth = this.calcTextSize(translate('common.front'))
          let padding = (width / 2 - textWidth) / 2
          this.ctx.fillText(
            translate('common.front'),
            startX + padding,
            startY + 22 * this.scalePercent + this.padding / 2
          )

          // 绘制反
          this.ctx.fillStyle = '#fff'
          this.ctx.fillRect(
            startX + width / 2,
            startY + this.padding / 2,
            width / 2,
            this.padding / 2
          )
          this.ctx.fillStyle = 'rgba(0, 0, 0, 0.6)'
          // 绘制反字
          this.ctx.fillText(
            translate('common.back'),
            startX + width / 2 + padding,
            startY + 22 * this.scalePercent + this.padding / 2
          )

          // 绘制优化率
          this.ctx.fillStyle = '#fff'
          this.ctx.fillRect(
            startX,
            startY - 33 * this.scalePercent,
            width,
            this.padding / 2
          )
          this.ctx.fillStyle = '#333'
          textWidth = this.calcTextSize(usedRate)
          this.ctx.beginPath()
          if (textWidth + (isSurplus ? 24 : 10) > width) {
            if (isSurplus) {
              this.ctx.strokeStyle = '#000'
              circleText(
                this.ctx,
                startX + 12 * this.scalePercent,
                surStore
                  ? startY - 19 * this.scalePercent
                  : startY - 5 * this.scalePercent,
                10 * this.scalePercent,
                '余',
                this.scalePercent
              )
            }
            this.ctx.fillText(
              rateStr,
              isSurplus ? startX + 24 * this.scalePercent : startX,
              isSurplus && surStore ? startY - 14 * this.scalePercent : startY
            )
            this.ctx.fillText(
              sizeStr,
              startX,
              isSurplus && surStore
                ? startY + 8 * this.scalePercent
                : startY + 24 * this.scalePercent
            )
            if (isSurplus && surStore) {
              this.ctx.fillText(
                surStore,
                startX,
                startY + 28 * this.scalePercent
              )
            }
          } else {
            padding = (width / 3 - textWidth) / 2
            // 绘制优化率文字
            if (isSurplus) {
              this.ctx.strokeStyle = '#000'
              circleText(
                this.ctx,
                startX + 12 * this.scalePercent,
                surStore
                  ? startY + 4 * this.scalePercent
                  : startY + 18 * this.scalePercent,
                10 * this.scalePercent,
                '余',
                this.scalePercent
              )
            }
            this.ctx.fillText(
              usedRate,
              isSurplus ? startX + 24 * this.scalePercent : startX,
              isSurplus && surStore
                ? startY + 8 * this.scalePercent
                : startY + 24 * this.scalePercent
            )
            if (isSurplus && surStore) {
              this.ctx.fillText(
                surStore,
                startX,
                startY + 28 * this.scalePercent
              )
            }
          }
        } else {
          // 绘制正
          this.ctx.fillStyle = '#fff'
          this.ctx.fillRect(
            startX,
            startY + this.padding / 2,
            width / 2,
            this.padding / 2
          )
          // 绘制正字
          this.ctx.fillStyle = 'rgba(0, 0, 0, 0.6)'
          let textWidth = this.calcTextSize(translate('common.front'))
          let padding = (width / 2 - textWidth) / 2
          this.ctx.fillText(
            translate('common.front'),
            startX + padding,
            startY + 22 * this.scalePercent + this.padding / 2
          )

          // 绘制反
          this.ctx.fillStyle = '#18a8c7'
          this.ctx.fillRect(
            startX + width / 2,
            startY + this.padding / 2,
            width / 2,
            this.padding / 2
          )
          this.ctx.fillStyle = '#fff'
          // 绘制反字
          this.ctx.fillText(
            translate('common.back'),
            startX + width / 2 + padding,
            startY + 22 * this.scalePercent + this.padding / 2
          )

          // 绘制优化率
          this.ctx.fillStyle = '#fff'
          this.ctx.fillRect(
            startX + width / 2,
            startY - 33 * this.scalePercent,
            width / 2,
            this.padding / 2
          )
          this.ctx.fillStyle = '#333'
          textWidth = this.calcTextSize(usedRate)
          this.ctx.beginPath()
          if (textWidth + (isSurplus ? 24 : 10) > width) {
            if (isSurplus) {
              this.ctx.strokeStyle = '#000'
              circleText(
                this.ctx,
                startX + 12 * this.scalePercent,
                surStore
                  ? startY - 19 * this.scalePercent
                  : startY - 5 * this.scalePercent,
                10 * this.scalePercent,
                '余',
                this.scalePercent
              )
            }
            this.ctx.fillText(
              rateStr,
              isSurplus ? startX + 24 * this.scalePercent : startX,
              isSurplus && surStore ? startY - 14 * this.scalePercent : startY
            )
            this.ctx.fillText(
              sizeStr,
              startX,
              isSurplus && surStore
                ? startY + 8 * this.scalePercent
                : startY + 24 * this.scalePercent
            )
            if (isSurplus && surStore) {
              this.ctx.fillText(
                surStore,
                startX,
                startY + 28 * this.scalePercent
              )
            }
          } else {
            padding = (width / 3 - textWidth) / 2
            // 绘制优化率文字
            if (isSurplus) {
              this.ctx.strokeStyle = '#000'
              circleText(
                this.ctx,
                startX + 12 * this.scalePercent,
                surStore
                  ? startY + 4 * this.scalePercent
                  : startY + 18 * this.scalePercent,
                10 * this.scalePercent,
                '余',
                this.scalePercent
              )
            }
            this.ctx.fillText(
              usedRate,
              isSurplus ? startX + 24 * this.scalePercent : startX,
              isSurplus && surStore
                ? startY + 8 * this.scalePercent
                : startY + 24 * this.scalePercent
            )
            if (isSurplus && surStore) {
              this.ctx.fillText(
                surStore,
                startX,
                startY + 28 * this.scalePercent
              )
            }
          }
        }
      } else {
        // 绘制优化率
        this.ctx.fillStyle = '#cfcfcf'
        this.ctx.fillRect(
          startX,
          startY + this.padding / 2,
          width,
          this.padding / 2
        )
        this.ctx.fillStyle = '#333'
        let headWidth = this.calcTextSize(usedRate)
        this.ctx.beginPath()
        // 如果当前大板是余料板
        if (headWidth + (isSurplus ? 24 : 10) > width) {
          if (isSurplus) {
            this.ctx.strokeStyle = '#000'
            circleText(
              this.ctx,
              startX + 12 * this.scalePercent,
              surStore
                ? startY - 19 * this.scalePercent
                : startY - 5 * this.scalePercent,
              10 * this.scalePercent,
              '余',
              this.scalePercent
            )
          }
          this.ctx.fillText(
            rateStr,
            isSurplus ? startX + 24 * this.scalePercent : startX,
            isSurplus && surStore ? startY - 14 * this.scalePercent : startY
          )
          this.ctx.fillText(
            sizeStr,
            startX,
            isSurplus && surStore
              ? startY + 8 * this.scalePercent
              : startY + 24 * this.scalePercent
          )
          if (isSurplus && surStore) {
            this.ctx.fillText(surStore, startX, startY + 28 * this.scalePercent)
          }
        } else {
          // 绘制优化率文字
          if (isSurplus) {
            this.ctx.strokeStyle = '#000'
            circleText(
              this.ctx,
              startX + 12 * this.scalePercent,
              surStore
                ? startY + 4 * this.scalePercent
                : startY + 18 * this.scalePercent,
              10 * this.scalePercent,
              '余',
              this.scalePercent
            )
          }
          this.ctx.fillText(
            usedRate,
            isSurplus ? startX + 24 * this.scalePercent : startX,
            isSurplus && surStore
              ? startY + 8 * this.scalePercent
              : startY + 24 * this.scalePercent
          )
          if (isSurplus && surStore) {
            this.ctx.fillText(surStore, startX, startY + 28 * this.scalePercent)
          }
        }

        // 绘制单面
        let textWidth = this.calcTextSize(translate('common.singlePlank'))
        let padding = (width - textWidth) / 2
        this.ctx.fillStyle = 'rgba(255, 255, 255, 0.9)'
        this.ctx.fillRect(
          startX,
          startY + this.padding / 2,
          width,
          this.padding / 2
        )
        this.ctx.fillStyle = 'rgba(0, 0, 0, 0.6)'
        // 绘制单面板文字
        this.ctx.fillText(
          translate('common.singlePlank'),
          startX + padding,
          startY + 22 * this.scalePercent + this.padding / 2
        )
        bigpart.fanbanCount = 0
        this.$props.calcFanbanCount()
      }
    },

    // 判断大板是否可以进行正反切换
    judgeAbleFanban(bigpart) {
      // 弹框

      let toggleFlag = false

      const sideArr = bigpart.parts
        .filter((p) => p.specialType !== 'supplus')
        .map((part) => [
          ...part.slots,
          ...part.holes,
          ...(part.curveHoles ? part.curveHoles : []),
          ...(part.millInfo ? part.millInfo : []),
          ...(part.handleSlopes ?? []),
        ])
      // 通孔通槽双面加工，大板显示正反
      const holeArr = bigpart.parts
        .filter((p) => p.specialType !== 'supplus')
        .map((part) => [
          ...part.holes,
          // ...(part.curveHoles ? part.curveHoles : []),
        ])
      const slotArr = bigpart.parts
        .filter((p) => p.specialType !== 'supplus')
        .map((part) => [
          ...part.slots,
          ...(part.millInfo ? part.millInfo : []),
          ...(part.handleSlopes ?? []),
        ])
      const holeToggle = holeArr
        .flat(1)
        .some((v) => v.deep * 1 + 0.001 >= bigpart.thick)
      const slotToggle = slotArr
        .flat(1)
        .some((v) => v.deep * 1 + 0.001 >= bigpart.thick)
      toggleFlag = sideArr.flat(1).every((v) => v.side == 1)
      bigpart.ableToggle =
        !toggleFlag ||
        (this.ncSetting.throughTowSideToCut && holeToggle) ||
        (this.ncSetting.through_slot_two_side_cut && slotToggle)
    },

    // 获取文字宽度, 用于中心绘制
    calcTextSize(text) {
      let span = document.createElement('span')
      let result = {}
      result.width = span.offsetWidth
      result.height = span.offsetHeight
      span.style.visibility = 'hidden'
      span.style.fontSize = `14px`
      span.style.fontFamily = 'PingFangSC-Regular, PingFang SC'
      span.style.display = 'inline-block'
      document.body.appendChild(span)
      if (typeof span.textContent != 'undefined') {
        span.textContent = text
      } else {
        span.innerText = text
      }
      result.width =
        parseFloat(window.getComputedStyle(span).width) - result.width
      // result.height = parseFloat(window.getComputedStyle(span).height) - result.height;
      document.body.removeChild(span)
      let width = result.width * this.scalePercent
      return width
    },
    /**
     * 生成小板的rect
     * @param {*} part 小板数据
     * @param {*} startX 大板的绘制起始横坐标
     * @param {*} startY 大板的绘制起始纵坐标
     */
    genPartsRect(part, bigPankStartX, bigPankStartY) {
      let rect = {
        x: bigPankStartX + this.tranlateSizeFromScale(part.startX),
        y: bigPankStartY + this.tranlateSizeFromScale(part.startY),
        width: this.tranlateSizeFromScale(part.rect.width),
        height: this.tranlateSizeFromScale(part.rect.height),
      }
      return rect
    },
    /**
     * 绘制小板
     * @param { Object } part 小板数据
     * @param { Number } bigPankStartX 大板的绘制起始横坐标
     * @param { Number } bigPankStartY 大板的绘制起始纵坐标
     */
    drawParts(ctx, part, bigPankStartX, bigPankStartY) {
      const startX =
        bigPankStartX + (part.startX / defaultScale) * this.scalePercent
      const startY =
        bigPankStartY + (part.startY / defaultScale) * this.scalePercent
      const plank = new Plank(
        ctx,
        part,
        startX,
        startY,
        defaultScale,
        this.scalePercent,
        { plankLineWidth: this.plankLineWidth ?? 1 }
      )
      // 激活板件或者入库时需要设置板件激活颜色
      if (
        this.$props.isBatchBujian &&
        this.bujianList.some((bujian) => isSamePart(bujian, part))
      ) {
        plank.setFillStyle(DrawPlankColorJs.active)
      } else {
        if (
          this.activePlank &&
          isSamePart(this.activePlank, part) &&
          !this.$props.isBatchBujian
        ) {
          plank.setFillStyle(DrawPlankColorJs.active)
        }
        if (part.plankMerge) {
          plank.setFillStyle(DrawPlankColorJs.plankMerge)
        }
      }
      const notDraw = []
      // 某些不绘制的实例
      if (!this.isShowPartSize) notDraw.push('plankSize')
      if (!this.isCuttingOrder) notDraw.push('cutOrder')
      const draws = plankDrawFactory(
        ctx,
        part,
        startX,
        startY,
        defaultScale,
        this.scalePercent,
        {
          dotWidth: this.dotWidth,
          textWidth: this.textWidth,
          fontSize: 14,
        },
        notDraw
      )
      plank.addStrategy(Object.keys(draws).map((it) => draws[it]))
      plank.draw()
    },

    // drawClampHand
    drawClampHand(ctx, clampHandInfo, bigPankStartX, bigPankStartY) {
      const {
        aio_fixture_setting,
        enable_integration_device: enableIntegrationDevice,
      } = this.ncSetting
      const { aio_avoid_fixture } = aio_fixture_setting
      // 防止从排版历史进入，当前生产线未开启夹手会将夹手绘制出来
      if (!aio_avoid_fixture || !enableIntegrationDevice) return
      clampHandInfo?.forEach((item) => {
        const startX =
          bigPankStartX + (item.startX / defaultScale) * this.scalePercent
        const startY =
          bigPankStartY + (item.startY / defaultScale) * this.scalePercent
        this.ctx.fillStyle = DrawPlankColorJs.normal
        this.ctx.strokeStyle = DrawPlankColorJs.normal
        const plank = new Plank(
          ctx,
          item,
          startX,
          startY,
          defaultScale,
          this.scalePercent,
          { plankLineWidth: this.plankLineWidth ?? 1 }
        )
        plank.draw()
      })
    },

    /**
     * 该方法用来绘制一个有填充色的圆角矩形
     * @param cxt:canvas的上下文环境
     * @param x:左上角x轴坐标
     * @param y:左上角y轴坐标
     * @param width:矩形的宽度
     * @param height:矩形的高度
     * @param radius:圆的半径
     * @param fillColor:填充颜色
     **/
    fillRoundRect(cxt, x, y, width, height, radius, /*optional*/ fillColor) {
      //圆的直径必然要小于矩形的宽高
      if (2 * radius > width || 2 * radius > height) {
        return false
      }

      cxt.save()
      cxt.translate(x, y)
      //绘制圆角矩形的各个边
      this.drawRoundRectPath(cxt, width, height, radius)
      cxt.fillStyle = fillColor || '#000' //若是给定了值就用给定的值否则给予默认值
      cxt.fill()
      cxt.restore()
    },

    /**
     * 该方法用来绘制圆角矩形
     * @param cxt:canvas的上下文环境
     * @param x:左上角x轴坐标
     * @param y:左上角y轴坐标
     * @param width:矩形的宽度
     * @param height:矩形的高度
     * @param radius:圆的半径
     * @param lineWidth:线条粗细
     * @param strokeColor:线条颜色
     **/
    strokeRoundRect(
      cxt,
      x,
      y,
      width,
      height,
      radius,
      /*optional*/ lineWidth,
      /*optional*/ strokeColor
    ) {
      //圆的直径必然要小于矩形的宽高
      if (2 * radius > width || 2 * radius > height) {
        return false
      }

      cxt.save()
      cxt.translate(x, y)
      //绘制圆角矩形的各个边
      this.drawRoundRectPath(cxt, width, height, radius)
      cxt.lineWidth = lineWidth || 1 //若是给定了值就用给定的值否则给予默认值2
      cxt.strokeStyle = strokeColor || '#000'
      cxt.stroke()
      cxt.restore()
    },

    drawRoundRectPath(cxt, width, height, radius) {
      cxt.beginPath(0)
      //从右下角顺时针绘制，弧度从0到1/2PI
      cxt.arc(width - radius, height - radius, radius, 0, Math.PI / 2)
      //矩形下边线
      cxt.lineTo(radius, height)
      //左下角圆弧，弧度从1/2PI到PI
      cxt.arc(radius, height - radius, radius, Math.PI / 2, Math.PI)
      //矩形左边线
      cxt.lineTo(0, radius)
      //左上角圆弧，弧度从PI到3/2PI
      cxt.arc(radius, radius, radius, Math.PI, (Math.PI * 3) / 2)
      //上边线
      cxt.lineTo(width - radius, 0)
      //右上角圆弧
      cxt.arc(width - radius, radius, radius, (Math.PI * 3) / 2, Math.PI * 2)
      //右边线
      cxt.lineTo(width, height - radius)
      cxt.closePath()
    },

    /**
     * 设置激活板件
     * @param { Object } e 事件对象
     * @returns
     */
    setActivePlank(e) {
      let mousePoint = {
        x: e.offsetX,
        y: e.offsetY,
      }
      // 找到当前点击的位置属于哪一个大板, 找到起始坐标小于当前鼠标位置的所有大板, 取最后一个大板
      let arr = this.sliceDrawData.filter(
        (v) => mousePoint.x >= v.startX && mousePoint.y >= v.startY
      )
      if (arr.length == 0) return null
      // 判断鼠标位置是否在大板所处的位置
      let targetBigpart = arr[arr.length - 1]
      // 找到原数据中的bigpart
      let bigpart = this.sliceDrawData.find(
        (v) => v.stockKey == targetBigpart.stockKey
      )
      // 如果没有找到大板, 则清除之前激活的板件, return
      if (!bigpart) {
        this.setActiveBigPart(null)
        this.activePlank = null
        return null
      }
      if (
        mousePoint.x >
          bigpart.startX +
            2 * this.deviation +
            bigpart.width +
            2 * this.scalePercent &&
        mousePoint.x <
          bigpart.startX +
            2 * this.deviation +
            bigpart.width +
            42 * this.scalePercent &&
        mousePoint.y > bigpart.startY + this.padding + this.deviation &&
        mousePoint.y <
          bigpart.startY +
            this.padding +
            this.deviation +
            40 * this.scalePercent
      ) {
        let extraBtnX =
          bigpart.startX +
          2 * this.deviation +
          bigpart.width +
          2 * this.scalePercent -
          e.offsetX
        let extraBtnY =
          bigpart.startY + this.padding + this.deviation - e.offsetY
        if (this.activeBigpart && bigpart.isActive) {
          this.canvasBtn.left = e.pageX + extraBtnX
          this.canvasBtn.top = e.pageY + extraBtnY + 40 * this.scalePercent
          this.canvasBtn.data = bigpart
          return null
        }
      }
      this.canvasBtn.data = null
      // 如果找到了, 则判断点击的位置
      if (
        mousePoint.x <= bigpart.startX + bigpart.width + 2 * this.deviation &&
        mousePoint.y <=
          bigpart.startY + bigpart.height + this.padding + 2 * this.deviation
      ) {
        // 判断点击的是否为大板, 还是顶部信息
        if (
          mousePoint.y >= bigpart.startY + this.padding &&
          mousePoint.y <=
            bigpart.startY + bigpart.height + this.padding + 2 * this.deviation
        ) {
          this.setActiveBigPart(bigpart)
          // 当前激活大板为true其他大板为false
          const data = this.drawData.data
          // 当前激活大板为true其他大板为false
          data.forEach((item) => {
            item.isActive = item.stockKey === bigpart.stockKey
          })
          this.emptyDistance = dealCurrentPointDistance(
            bigpart,
            mousePoint,
            this.deviation,
            this.padding,
            this.scalePercent,
            defaultScale,
            'paiban'
          )
          this.mousePoint = mousePoint
          // const targetData = bigpart.parts.concat([])
          for (let i = 0; i < bigpart.parts.length; ++i) {
            let part = bigpart.parts[i]
            let rect = {
              x:
                bigpart.startX +
                this.deviation +
                this.tranlateSizeFromScale(part.startX),
              y:
                bigpart.startY +
                this.deviation +
                this.padding +
                this.tranlateSizeFromScale(part.startY),
            }
            let newPos = {
              x: this.restoreScaleSize(mousePoint.x - rect.x),
              y: this.restoreScaleSize(mousePoint.y - rect.y),
            }
            let curvePath = []
            if (part.path) {
              const curveHolesArr = part.curveHoles?.map((it) => it.path)
              curvePath = [...part.path]
              if (curveHolesArr && curveHolesArr.length && !!curveHolesArr[0]) {
                curvePath.push(...curveHolesArr)
              }
            } else {
              curvePath = [
                [
                  { x: 0, y: 0 },
                  { x: part.rect.width, y: 0 },
                  {
                    x: part.rect.width,
                    y: part.rect.height,
                  },
                  { x: 0, y: part.rect.height },
                ],
              ]
            }
            let isInpoly = curvePath
              .map((curve) => this.isInPolygon(newPos, curve))
              .filter((e) => e)
            if (isInpoly.length == 1) {
              this.emptyDistance = false
              this.activePlank = part
              this.clearConflict()
              this.renderAll()
              return part
            }
          }
          this.renderAll()
          return null
        } else {
          this.emptyDistance = false
          this.setActiveBigPart(null)
          const holeArr = bigpart.parts
            .filter((p) => p.specialType !== 'supplus')
            .map((part) => [
              ...part.holes,
              // ...(part.curveHoles ? part.curveHoles : []),
            ])
          const slotArr = bigpart.parts
            .filter((p) => p.specialType !== 'supplus')
            .map((part) => [
              ...part.slots,
              ...(part.millInfo ? part.millInfo : []),
              ...(part.handleSlopes ?? []),
            ])
          const holeToggle = holeArr
            .flat(1)
            .some((v) => v.deep * 1 + 0.001 >= bigpart.thick)
          const slotToggle = slotArr
            .flat(1)
            .some((v) => v.deep * 1 + 0.001 >= bigpart.thick)
          const isReverePlank = bigpart.parts.some((part) => part.reverePlank)
          if (bigpart && bigpart.ableToggle) {
            if (bigpart.parts[0].is_high_gloss_plank)
              return this.$message.error('高光板禁止翻板')
            if (
              ((this.ncSetting.throughTowSideToCut && holeToggle) ||
                (this.ncSetting.through_slot_two_side_cut && slotToggle)) &&
              !isReverePlank
            )
              return this.$message.error('大板上存在通孔/通槽，不建议翻版！')
            if (
              mousePoint.x >= bigpart.startX &&
              mousePoint.x <= bigpart.startX + bigpart.width / 2
            ) {
              if (bigpart.sideType == -1) {
                bigpart.sideType = 1
                this.toggleBigpartData(bigpart)

                this.renderAll()
              }
            }
            if (
              mousePoint.x >= bigpart.startX + bigpart.width / 2 &&
              mousePoint.x <= bigpart.startX + bigpart.width
            ) {
              if (bigpart.sideType == 1) {
                bigpart.sideType = -1
                this.toggleBigpartData(bigpart)

                this.renderAll()
              }
            }
          }
          return null
        }
      } else {
        this.emptyDistance = false
        this.setActiveBigPart(null)

        this.renderAll()
        return null
      }
    },

    // 旋转板件
    rotatePlank() {
      rotatePart(this.activePlank)
      dealPlankPoly(this.activePlank)
      checkOneBigpart(this.activeBigpart)
      this.renderAll()
    },

    // 板件翻面
    reverePlank() {
      if (this.isHighPlank) return this.$message.error('高光板禁止翻板')
      rolloverPlank(this.activePlank, true)
      dealPlankPoly(this.activePlank)
      checkOneBigpart(this.activeBigpart)
      this.judgeAbleFanban(this.activeBigpart)
      this.renderAll()
      // 板件翻面后重新计算翻板数
      this.$props.calcFanbanCount()
    },
    // 修改大板余料信息
    changeBigpart(surplus, minX, maxY) {
      let obj = {
        id: surplus.id,
        width: this.ncSetting.xyReverse
          ? Number(surplus.long)
          : Number(surplus.width),
        height: this.ncSetting.xyReverse
          ? Number(surplus.width)
          : Number(surplus.long),
        area: Number(surplus.area),
        ggid: surplus.ggid,
        is_plus: true,
        branch_name: surplus.branch_name,
        branch_no: surplus.branch_no,
      }
      if (surplus.shape == 1) {
        let lObj = {
          x3: Number(surplus.min_width),
          y3: Number(surplus.long),
          x4: Number(surplus.min_width),
          y4: Number(surplus.min_long),
          x5: Number(surplus.width),
          y5: Number(surplus.min_long),
          shape: 'lshape',
        }
        obj = Object.assign(obj, lObj)
      }

      this.setActiveBigPart(this.getActiveBigPart(this.activeBigpart.stockKey))
      this.activeBigpart.surplusInfo = obj

      let bigPlank = []
      // 存储板件边框减去修边值，用于计算大板剩余距离
      let bigPlankShrinkPolygon = []
      let plankOffset =
        this.ncSetting.panelSize.plankEdgeOff -
        this.ncSetting.knife.diameter / 2
      const plankEdge = this.ncSetting.panelSize.plankEdgeOff
      if (surplus.shape && surplus.shape == 'lshape') {
        let x3 = this.ncSetting.xyReverse
          ? Number(surplus.y5)
          : Number(surplus.x3)
        let y3 = this.ncSetting.xyReverse
          ? Number(surplus.x5)
          : Number(surplus.y3)
        let x4 = this.ncSetting.xyReverse
          ? Number(surplus.y4)
          : Number(surplus.x4)
        let y4 = this.ncSetting.xyReverse
          ? Number(surplus.x4)
          : Number(surplus.y4)
        let x5 = this.ncSetting.xyReverse
          ? Number(surplus.y3)
          : Number(surplus.x5)
        let y5 = this.ncSetting.xyReverse
          ? Number(surplus.x3)
          : Number(surplus.y5)
        let width = surplus.width
        let height = surplus.height
        y3 = height - y3
        y4 = height - y4
        y5 = height - y5
        bigPlank = [
          // [
          //   { X: -plankOffset, Y: -plankOffset },
          //   { X: x3 + plankOffset, Y: -plankOffset },
          //   { X: x4 + plankOffset, Y: y4 - plankOffset },
          //   { X: x5 + plankOffset, Y: y5 - plankOffset },
          //   { X: width + plankOffset, Y: height + plankOffset },
          //   { X: -plankOffset, Y: height + plankOffset },
          // ],
          [
            { X: plankOffset, Y: plankOffset },
            { X: x3, Y: plankOffset },
            { X: x4, Y: y4 },
            { X: x5 - plankOffset, Y: y5 },
            { X: width - plankOffset, Y: height - plankOffset },
            { X: plankOffset, Y: height - plankOffset },
          ],
        ]
        bigPlankShrinkPolygon = [
          { x: plankEdge, y: plankEdge },
          { x: x3, y: plankEdge },
          { x: x4, y: y4 },
          { x: x5 - plankEdge, y: y5 },
          { x: width - plankEdge, y: height - plankEdge },
          { x: plankEdge, y: height - plankEdge },
        ]
      } else {
        let width = obj.width
        let height = obj.height
        bigPlank = [
          // [
          //   { X: -plankOffset, Y: -plankOffset },
          //   { X: width + plankOffset, Y: -plankOffset },
          //   { X: width + plankOffset, Y: height + plankOffset },
          //   { X: -plankOffset, Y: height + plankOffset },
          // ],
          [
            { X: plankOffset, Y: plankOffset },
            { X: width - plankOffset, Y: plankOffset },
            { X: width - plankOffset, Y: height - plankOffset },
            { X: plankOffset, Y: height - plankOffset },
          ],
        ]
        bigPlankShrinkPolygon = [
          { x: plankEdge, y: plankEdge },
          { x: width - plankEdge, y: plankEdge },
          { x: width - plankEdge, y: height - plankEdge },
          { x: plankEdge, y: height - plankEdge },
        ]
      }

      this.activeBigpart.bigPlank = bigPlank
      this.activeBigpart.customSurplus = true
      this.activeBigpart.bigPlankShrinkPolygon = bigPlankShrinkPolygon
      for (let i = 0; i < this.activeBigpart.parts.length; ++i) {
        let plank = this.activeBigpart.parts[i]
        plank.surplusInfo = obj
        if (this.ncSetting.xyReverse) {
          plank.startX -= maxY - plankOffset
          plank.startY -= minX + plankOffset - obj.height
        } else {
          plank.startX -= minX - plankOffset
          plank.startY -= maxY + plankOffset - obj.height
        }
        dealPlankPoly(plank)
      }
      // 添加余料后修改大板和小板的plankWidth plankHeight
      let w = obj.width
      let h = obj.height
      this.activeBigpart.parts.forEach((part) => {
        part.plankWidth = w
        part.plankHeight = h
      })
      this.activeBigpart.plankWidth = w
      this.activeBigpart.plankHeight = h
      let newHeight = this.calcPerParts() + 200
      this.canvasDom.height = newHeight
      checkOneBigpart(this.activeBigpart)

      this.renderAll()
      this.$emit('closeSurplusDialog')
      this.$message({
        type: 'success',
        message: '使用余料成功!',
      })
      this.canvasBtn.data = null
    },

    toggleBigpartData(bigpart) {
      for (let i = 0; i < bigpart.parts.length; ++i) {
        let plank = bigpart.parts[i]
        rolloverPlank(plank)
        dealPlankPoly(plank)
        checkOneBigpart(bigpart)
        this.judgeAbleFanban(bigpart)
        this.renderAll()
      }
    },

    // 判断点是否在多边形内部
    isInPolygon(checkPoint, polygonPoints) {
      let counter = 0
      let i
      let xinters
      let p1, p2
      let pointCount = polygonPoints.length
      p1 = polygonPoints[0]

      for (i = 1; i <= pointCount; i++) {
        p2 = polygonPoints[i % pointCount]
        if (
          checkPoint.x > Math.min(p1.x, p2.x) &&
          checkPoint.x <= Math.max(p1.x, p2.x)
        ) {
          if (checkPoint.y <= Math.max(p1.y, p2.y)) {
            if (p1.x != p2.x) {
              xinters =
                ((checkPoint.x - p1.x) * (p2.y - p1.y)) / (p2.x - p1.x) + p1.y
              if (p1.y == p2.y || checkPoint.y <= xinters) {
                counter++
              }
            }
          }
        }
        p1 = p2
      }
      if (counter % 2 == 0) {
        return false
      } else {
        return true
      }
    },

    // 清除之前选中的板件样式
    clearLastStyle(activePlank) {
      this.activePlank = activePlank
      for (let item of this.sliceDrawData) {
        this.drawOneBigpart(item)
      }
    },

    // 清除上一次激活板件的绘制
    clearLastActive() {
      // 重新上一个激活板件所在的大板
      if (!this.activePlank) return
      let lastActive = this.sliceDrawData.find(
        (v) => v.stockKey == this.activePlank.stockKey
      )
      if (lastActive) {
        this.ctx.clearRect(
          this.startLeft,
          this.startTop - this.scaleHeight - this.padding,
          this.canvasMaxWidth + this.scaleWidth + 2 * this.padding,
          this.canvasMaxHeight + this.scaleHeight + this.padding
        )
        this.activePlank = null
        let viewWidth = this.canvasMaxWidth + this.startLeft
        let viewHeight = this.canvasMaxHeight + this.startTop
        // 记录最后绘制的大板, 解决层级问题
        let finalData = {}
        for (let i = 0; i < this.sliceDrawData.length; ++i) {
          let data = this.sliceDrawData[i]
          if (
            data.startX > viewWidth ||
            data.startY > viewHeight ||
            data.startX < this.startLeft - this.scaleWidth - 2 * this.padding ||
            data.startY < this.startTop - this.scaleHeight - 4 * this.padding
          )
            continue
          if (lastActive.stockKey == data.stockKey) {
            finalData = data
            continue
          }
          this.drawOneBigpart(data)
        }

        this.drawOneBigpart(finalData)
      }
    },

    // 搜索过后选中板件
    setNewActivePlank(plank) {
      this.activePlank = plank
      this.activePartUniqueId = plank.partUniqueId
      this.activePlankIDChangeFunc(plank.plankID)
      this.renderAll()
      this.startDraw()
      this.$nextTick(() => {
        this.$emit('getBigPlankH', this.activeBigpart?.startY)
      })
    },
    // 选中大板
    setNewActiveBigpart(bigPart) {
      // let bigpart = this.sliceDrawData.find(
      //   (v) => v.stockKey == bigPart.stockKey
      // )
      this.setActiveBigPart(bigPart)
      const index = this.drawData.data.findIndex(
        (plank) =>
          plank.canvasIndex === this.activeBigpart.canvasIndex &&
          plank.stockNum === this.activeBigpart.stockNum
      )
      this.currentPape = Math.ceil((index + 1) / this.countPerPage)
      this.renderAll()
      this.startDraw()
      this.$nextTick(() => {
        this.$emit('getBigPlankH', this.activeBigpart?.startY)
      })
    },
    /**
     * 滚动时, 重新绘制
     * @param { Object } scrollOff 滚动条的滚动偏移量
     */
    scrollDraw(scrollOff) {
      // 清除可视范围里的所有绘制, 包括超出可视区域绘制的大板
      this.ctx.clearRect(
        this.startLeft,
        this.startTop - this.scaleHeight - this.padding,
        this.canvasMaxWidth + this.scaleWidth + 2 * this.padding,
        this.canvasMaxHeight + this.scaleHeight + this.padding
      )
      let viewWidth = this.canvasMaxWidth + scrollOff.x
      let viewHeight = this.canvasMaxHeight + scrollOff.y
      for (let i = 0; i < this.sliceDrawData.length; ++i) {
        let data = this.sliceDrawData[i]
        if (
          data.startX > viewWidth ||
          data.startY > viewHeight ||
          data.startX < this.startLeft - this.scaleWidth - 2 * this.padding ||
          data.startY < this.startTop - this.scaleHeight - 4 * this.padding
        ) {
          continue
        }
        this.drawOneBigpart(data)
      }
    },

    /**
     * 滚轮缩放事件
     * @param { Object } event 事件对象
     */
    scaleCanvas(event, toolScale, isReset = false) {
      // 判断滚轮方向
      // let direction = true
      if (isReset) this.scale = this.oriScale = 6
      else {
        if (event.wheelDelta > 0) {
          if (toolScale) {
            this.scale += this.oriScale * toolScale
          } else {
            this.scale += this.oriScale * 0.1
          }
        } else {
          if (this.scale - this.oriScale * 0.5 < 0.0001) return

          if (toolScale) {
            this.scale += this.oriScale * toolScale
          } else {
            this.scale -= this.oriScale * 0.1
          }

          // direction = false
        }
      }

      this.ctx.clearRect(
        this.startLeft,
        this.startTop - this.scaleHeight - this.padding,
        this.canvasMaxWidth + this.scaleWidth + 2 * this.padding,
        this.canvasMaxHeight + this.scaleHeight + this.padding
      )
      // 记录上一次缩放的比例
      this.lastScale = this.scalePercent
      // 计算缩放的比例
      this.scalePercent = this.scale / defaultScale
      this.$emit('scaleChange', { defaultScale, scale: this.scalePercent })
      // 计算各种间距和大板宽度缩放后的值
      this.scaleWidth = this.tranlateSizeFromScale(this.plankWidth)
      this.scaleHeight = this.tranlateSizeFromScale(this.plankHeight)
      // 只需要通过 this.scalePercent 进行缩放，因为这些默认值都是手动设置的
      this.padding = defaultPadding * this.scalePercent
      this.deviation = defaultDeviation * this.scalePercent
      this.dotWidth = defaultDotWidth * this.scalePercent
      this.textWidth = defaultTextWidth * this.scalePercent
      // 计算可视区域
      let viewWidth = this.canvasMaxWidth + this.startLeft
      let viewHeight = this.canvasMaxHeight + this.startTop
      // 抄的网上的, 看不懂(我好菜, 给我答案都看不懂)(现在懂了)

      // 这儿是缩放逻辑,pos记录的鼠标位置到canvas左边和上面的距离
      let pos = this.windowToCanvas(event.clientX, event.clientY)
      for (let i = 0; i < this.sliceDrawData.length; ++i) {
        let data = this.sliceDrawData[i]

        let recordHeight = 0
        let recordWidth = 0
        if (data.surplusInfo) {
          let surplusWidth = data.surplusInfo.width
          let surplusHeight = data.surplusInfo.height
          recordHeight = this.tranlateSizeFromScale(Number(surplusHeight))
          recordWidth = this.tranlateSizeFromScale(Number(surplusWidth))
        } else if (data.specialInfo) {
          recordHeight = this.tranlateSizeFromScale(data.specialInfo.outHeight)
          recordWidth = this.tranlateSizeFromScale(data.specialInfo.outWidth)
        } else {
          recordHeight = this.tranlateSizeFromScale(Number(this.plankHeight))
          recordWidth = this.tranlateSizeFromScale(Number(this.plankWidth))
        }
        // 设置大板的宽高
        data.height = recordHeight
        data.width = recordWidth

        let newPos = {
          // 把鼠标到大板的距离变成缩放前的大小
          x: ((pos.x - data.startX) / this.lastScale).toFixed(2),
          y: ((pos.y - data.startY) / this.lastScale).toFixed(2),
        }
        // this.scalePercent * newPos.x 是指把鼠标打大板的距离按照新的缩放比例重新进行计算
        // pos.x - this.scalePercent * newPos.x 就是大板相对于canvas新的起始坐标（因为缩放是以鼠标所在的位置为基准的）
        data.startX = pos.x - this.scalePercent * newPos.x
        data.startY = pos.y - this.scalePercent * newPos.y
        // 如果超出试图范围，就不用绘制了
        if (
          data.startX > viewWidth ||
          data.startY > viewHeight ||
          data.startX < this.startLeft - this.scaleWidth - 2 * this.padding ||
          data.startY < this.startTop - this.scaleHeight - 4 * this.padding
        ) {
          continue
        }
        this.drawOneBigpart(data)
      }
    },

    /*坐标转换*/
    windowToCanvas(x, y) {
      let box = this.canvasDom.getBoundingClientRect() //这个方法返回一个矩形对象，包含四个属性：left、top、right和bottom。分别表示元素各边与页面上边和左边的距离
      // box.width - this.canvasDom.width 不是等于0吗？？？
      return {
        x: x - box.left - (box.width - this.canvasDom.width) / 2,
        y: y - box.top - (box.height - this.canvasDom.height) / 2,
      }
    },

    // 重新绘制
    renderAll() {
      const clearX = this.startLeft
      const clearY = this.startTop - this.scaleHeight - this.padding
      const clearWidth =
        this.canvasMaxWidth + this.scaleWidth + 2 * this.padding
      const clearHeight = this.canvasMaxHeight + this.scaleHeight + this.padding
      this.ctx.clearRect(clearX, clearY, clearWidth, clearHeight)
      let viewWidth = this.canvasMaxWidth + this.startLeft
      let viewHeight = this.canvasMaxHeight + this.startTop
      let finalData = null
      for (let i = 0; i < this.sliceDrawData.length; ++i) {
        let data = this.sliceDrawData[i]
        if (
          data.startX > viewWidth ||
          data.startY > viewHeight ||
          data.startX < this.startLeft - this.scaleWidth - 2 * this.padding ||
          data.startY < this.startTop - this.scaleHeight - 4 * this.padding
        )
          continue
        if (this.activePlank && this.activePlank.stockKey == data.stockKey) {
          finalData = data
          continue
        }
        this.drawOneBigpart(data)
      }
      if (finalData) {
        this.drawOneBigpart(finalData)
      }
      // 绘制空白位置距离
      drawPlankEmptyDistance(this.ctx, this.emptyDistance, this.mousePoint)
      // 绘制孔槽详细信息
      drawPlankHSDetailInfo(this.ctx, this.hsDetailInfoList, this.mousePoint)
      // 绘制余料库名称tooltip
      drawPlankHSDetailInfo(this.ctx, this.surStoreTooltip, this.mousePoint)
    },

    // 判断哪些是需要进行距离计算的选段
    judgeDirectionLine(
      activeArr,
      partArr,
      activePath,
      activePlank,
      part,
      direction
    ) {
      // 记录板件间隙
      let distanceArr = []
      let gap = this.ncSetting.panelSize.layoutGap
      let { activeTop, activeBottom, activeLeft, activeRight } = activeArr
      let { partTop, partBottom, partLeft, partRight } = partArr
      let maxNum = 0
      let minNum = 0
      if (direction == 'up' || direction == 'down') {
        maxNum = Math.max(partLeft, activeLeft)
        minNum = Math.min(partRight, activeRight)
      } else {
        maxNum = Math.max(partTop, activeTop)
        minNum = Math.min(partBottom, activeBottom)
      }
      // 判断交集的方法, 取两个区间(a, b)(c, d), 如果a和c的最大值小于b和d的最小值, 说明存在交集
      // 注意精度问题
      // 当最大的坐标小于最小的坐标且不相等时, 才会进入判断
      if (maxNum < minNum && Math.abs(maxNum - minNum) > 0.0001) {
        // 如果记录板件与选中板件都为矩形, 则直接求两个板件之间的距离
        if (!activePlank.path && !part.path) {
          if (direction == 'up') {
            // 如果比较板件的顶部小于选中板件的底部, 则在按上的时候不需要继续计算距离
            if (activeBottom < partTop) return { msg: 'continue' }
            // 如果是已经贴合了, 则直接记录最小的板件间隙的值, 当前不可移动, 直接跳出循环
            if (Math.abs(activeTop - partBottom + gap) < 0.001) {
              distanceArr.push(gap)
              return {
                msg: 'break',
                data: distanceArr,
              }
            }
            let distance = activeTop - partBottom
            distanceArr.push(distance)
            return {
              msg: 'continue',
              data: distanceArr,
            }
          }
          if (direction == 'down') {
            // 如果比较板件的底部小于选中板件的顶部, 则在按下的时候不需要继续计算距离
            if (partBottom < activeTop) return 'continue'
            // 如果是已经贴合了, 则直接记录最小的板件间隙的值, 当前不可移动, 直接跳出循环
            if (Math.abs(partTop - activeBottom + gap) < 0.001) {
              distanceArr.push(gap)
              return {
                msg: 'break',
                data: distanceArr,
              }
            }
            let distance = partTop - activeBottom
            distanceArr.push(distance)
            return {
              msg: 'continue',
              data: distanceArr,
            }
          }
          if (direction == 'left') {
            // 如果比较板件的
            if (activeLeft < partRight) return { msg: 'continue' }
            if (Math.abs(activeLeft - partRight + gap) < 0.001) {
              distanceArr.push(gap)
              return {
                msg: 'break',
                data: distanceArr,
              }
            }
            let distance = activeLeft - partRight
            distanceArr.push(distance)
            return {
              msg: 'continue',
              data: distanceArr,
            }
          }
          if (direction == 'right') {
            if (activeRight > partLeft) return { msg: 'continue' }
            if (Math.abs(partLeft - activeRight + gap) < 0.001) {
              distanceArr.push(gap)
              return {
                msg: 'break',
                data: distanceArr,
              }
            }
            let distance = partLeft - activeRight
            distanceArr.push(distance)
            return {
              msg: 'continue',
              data: distanceArr,
            }
          }
        }
        // 记录交集
        let range = {}
        if (direction == 'up' || direction == 'down') {
          range.x1 = maxNum
          range.x2 = minNum
        } else {
          range.y1 = maxNum
          range.y2 = minNum
        }

        // 记录比较板件的边框点位
        let partPath = []
        if (part.path) {
          // 先判断小板是否处于比较板件的内部
          if (part.path.length > 1) {
            // 如果是在内部, 则判断是否存在挖洞, 则循环判断每一个选中的板件是否在某一个挖洞内部
            let flag = polyContain([part.polyArr[0]], [activePlank.polyArr[0]])
            if (flag) {
              for (let i = 0; i < part.path.length; ++i) {
                if (i == 0) continue
                let newFlag = polyContain(
                  [part.polyArr[i]],
                  [activePlank.polyArr[0]]
                )
                if (newFlag) {
                  partPath = part.path[i]
                  break
                }
              }
            } else {
              partPath = part.path[0]
            }
          } else {
            partPath = part.path[0]
          }
        } else {
          partPath = [
            { x: 0, y: 0 },
            { x: part.rect.width, y: 0 },
            {
              x: part.rect.width,
              y: part.rect.height,
            },
            { x: 0, y: part.rect.height },
          ]
        }

        // 记录比较板件的所有在交集范围内的横线线段
        let partLineArr = this.getPlankLine(
          partPath,
          part.startX,
          part.startY,
          direction,
          range
        )
        // 根据方向获取选中板件的线段
        // 如果选中的板件是有挖洞的, 则需要重新计算选中板件的点位, 判断和其他板件是否有包含关系
        if (activePlank.path && activePlank.path.length > 1) {
          // 如果是在内部, 则判断是否存在挖洞, 则循环判断每一个选中的板件是否在某一个挖洞内部
          let flag = polyContain([activePlank.polyArr[0]], [part.polyArr[0]])
          if (flag) {
            for (let i = 0; i < activePlank.path.length; ++i) {
              if (i == 0) continue
              let newFlag = polyContain(
                [activePlank.polyArr[i]],
                [part.polyArr[0]]
              )
              if (newFlag) {
                activePath = activePlank.path[i]
                break
              }
            }
          }
        }
        this.isActivePlankLine = true
        let activeLineArr = this.getPlankLine(
          activePath,
          activePlank.startX,
          activePlank.startY,
          direction,
          range
        )
        this.isActivePlankLine = false
        distanceArr = this.calcDistance(
          activeLineArr,
          partLineArr,
          direction,
          true
        )
        return {
          msg: 'finish',
          data: distanceArr,
          activeLineArr: activeLineArr,
        }
      }
      return { msg: 'continue' }
    },

    // 键盘按下事件
    keyDownFuncs() {
      return (e) => {
        if (e.ctrlKey) {
          if (!(e.keyCode == 67 || e.keyCode == 86)) {
            e.preventDefault()
            this.isScaling = true
          }
        }
        if (
          (e.code == 'ArrowUp' ||
            e.code == 'ArrowDown' ||
            e.code == 'ArrowLeft' ||
            e.code == 'ArrowRight') &&
          this.isMovingPlank
        ) {
          e.preventDefault()
          return
        }
        if (
          this.activePlank &&
          (e.code == 'ArrowUp' ||
            e.code == 'ArrowDown' ||
            e.code == 'ArrowLeft' ||
            e.code == 'ArrowRight')
        ) {
          e.preventDefault()
          if (this.activeBigpart && this.activeBigpart.isLocked) {
            return this.$message.error(
              translate('arrangedPage.lockPartEditTip')
            )
          } else {
            if (this.ncSetting.glass_setting) {
              return this.$message.error(
                translate('arrangedPage.glassEquipmentTip')
              )
            }
          }
          let direction = ''
          switch (e.code) {
            case 'ArrowUp':
              direction = 'up'
              break
            case 'ArrowDown':
              direction = 'down'
              break
            case 'ArrowLeft':
              direction = 'left'
              break
            case 'ArrowRight':
              direction = 'right'
              break
          }
          // 记录所有距离
          let distanceArr = []
          // 记录移动前的位置
          const { startX, startY } = this.activePlank
          let bigpart = this.sliceDrawData.find(
            (v) => v.stockKey == this.activePlank.stockKey
          )

          // 计算当前选中板件上下左右四个方向的坐标
          let activeArr = {
            activeLeft: this.activePlank.startX,
            activeRight: this.activePlank.startX + this.activePlank.rect.width,
            activeTop: this.activePlank.startY,
            activeBottom:
              this.activePlank.startY + this.activePlank.rect.height,
          }

          // 获取选中板件的边框点位
          let activePath = []
          if (this.activePlank.path) {
            activePath = this.activePlank.path[0]
          } else {
            activePath = [
              { x: 0, y: 0 },
              {
                x: this.activePlank.rect.width,
                y: 0,
              },
              {
                x: this.activePlank.rect.width,
                y: this.activePlank.rect.height,
              },
              {
                x: 0,
                y: this.activePlank.rect.height,
              },
            ]
          }

          if (!bigpart) return
          // 循环判断和当前大板中其余板件的距离
          for (let i = 0; i < bigpart.parts.length; ++i) {
            // 不会和自己进行比较
            if (isSamePart(bigpart.parts[i], this.activePlank)) continue
            let part = bigpart.parts[i]
            // 计算出当前做比较的板件的上下左右四个方向的坐标
            let partArr = {
              partLeft: part.startX,
              partRight: part.startX + part.rect.width,
              partTop: part.startY,
              partBottom: part.startY + part.rect.height,
            }
            let result = this.judgeDirectionLine(
              activeArr,
              partArr,
              activePath,
              this.activePlank,
              part,
              direction
            )
            if (result.data) {
              distanceArr = [...distanceArr, ...result.data]
            }
            if (result.msg == 'continue') continue
            if (result.msg == 'break') break
          }

          let distanceArr2 = []
          let bigPartLineArr = this.dealBigPlankLine(bigpart.bigPlank[0])
          this.isActivePlankLine = true
          let activeLineArr = this.getPlankLine(
            activePath,
            this.activePlank.startX,
            this.activePlank.startY,
            direction
          )
          this.isActivePlankLine = false
          distanceArr2 = this.calcBigPlankDistance(
            activeLineArr,
            bigPartLineArr,
            direction
          )

          // 取数组中的最小值
          let distance = 99999
          let distance2 = Math.min(...distanceArr2)
          if (compareTowNum(distance2, 20, '>=')) distance2 = 20

          if (distanceArr.length > 0) {
            let plankDistance = Math.min(...distanceArr)
            if (compareTowNum(plankDistance, 0, '>=')) {
              distance = plankDistance
              // 如果是和其他板件进行比较, 则需要考虑板件间隙
              let gap = this.ncSetting.panelSize.layoutGap
              gap = gap == 0 ? 0.01 : gap
              // 如果距离超过了板件间隙, 则判断减去板件间隙之后, 还可以移动多少
              if (compareTowNum(distance, gap, '>=')) {
                distance = distance - gap
                if (distance < 0) distance = 0
                if (distance > 0) {
                  if (compareTowNum(distance, 20, '>=')) {
                    distance = 20
                  }
                }
              } else {
                distance = 0
              }
            }
          }

          distance = compareTowNum(distance, distance2, '<=')
            ? distance
            : distance2

          if (direction == 'up') {
            this.activePlank.startY -= distance
          }
          if (direction == 'down') {
            this.activePlank.startY += distance
          }
          if (direction == 'left') {
            this.activePlank.startX -= distance
          }
          if (direction == 'right') {
            this.activePlank.startX += distance
          }
          dealPlankPoly(this.activePlank)
          // 每一次移动都重新判断一次板件冲突情况
          const isConflict = checkOneBigpart(bigpart)
          // 检查是否出现冲突
          if (isConflict || this.activePlank.plankMerge) {
            this.activePlank.startX = startX
            this.activePlank.startY = startY
            // this.open3()
            // 清除所有冲突状态
            bigpart.parts.forEach((part) => {
              part.plankMerge = false
            })
            dealPlankPoly(this.activePlank)
            checkOneBigpart(bigpart)
          }
          this.renderAll()
        }
      }
    },
    dealBigPlankLine(path) {
      let arr = []
      for (let i = 0; i < path.length; ++i) {
        let point = path[i]
        let nextPoint = i == path.length - 1 ? path[0] : path[i + 1]
        if (point.X == nextPoint.X && point.Y == nextPoint.Y) continue
        let pathLine = {
          x1: point.X,
          y1: point.Y,
          x2: nextPoint.X,
          y2: nextPoint.Y,
        }
        arr.push(pathLine)
      }
      return arr
    },
    // 计算板件和大板的距离
    calcBigPlankDistance(activeLineArr, bigPartLineArr, direction) {
      let distanceArr = []
      for (let i = 0; i < activeLineArr.length; ++i) {
        let line1 = activeLineArr[i]
        for (let k = 0; k < bigPartLineArr.length; ++k) {
          let line2 = bigPartLineArr[k]
          if (this.checkRangeIntersect(line1, line2, direction)) {
            if (direction == 'up') {
              if (compareTowNum(line1.y1, line1.y2, '>=')) {
                let distance = line1.y2 - line2.y1
                if (compareTowNum(distance, 0, '>=')) {
                  distanceArr.push(distance)
                }
              } else {
                let distance = line1.y1 - line2.y1
                if (compareTowNum(distance, 0, '>=')) {
                  distanceArr.push(distance)
                }
              }
            }
            if (direction == 'down') {
              if (compareTowNum(line1.y1, line1.y2, '>=')) {
                let distance = line1.y1 - line2.y1
                if (compareTowNum(distance, 0, '<=')) {
                  distanceArr.push(-distance)
                }
              } else {
                let distance = line1.y2 - line2.y1
                if (compareTowNum(distance, 0, '<=')) {
                  distanceArr.push(-distance)
                }
              }
            }
            if (direction == 'left') {
              if (compareTowNum(line1.x1, line1.x2, '>=')) {
                let distance = line1.x2 - line2.x1
                if (compareTowNum(distance, 0, '>=')) {
                  distanceArr.push(distance)
                }
              } else {
                let distance = line1.x1 - line2.x1
                if (compareTowNum(distance, 0, '>=')) {
                  distanceArr.push(distance)
                }
              }
            }
            if (direction == 'right') {
              if (compareTowNum(line1.x1, line1.x2, '>=')) {
                let distance = line1.x1 - line2.x1
                if (compareTowNum(distance, 0, '<=')) {
                  distanceArr.push(-distance)
                }
              } else {
                let distance = line1.x2 - line2.x1
                if (compareTowNum(distance, 0, '<=')) {
                  distanceArr.push(-distance)
                }
              }
            }
          }
        }
      }
      return distanceArr
    },
    // 获取大板边框
    getBigPlankPath(bigpart) {
      // 计算大板修边
      let plankOffset = Math.abs(
        this.ncSetting.knife.diameter / 2 -
          this.ncSetting.panelSize.plankEdgeOff
      )
      let bigPartsPath = []
      let plankWidth = 0
      let plankHeight = 0
      if (
        bigpart.isLocked &&
        bigpart.surplusInfo &&
        Object.keys(bigpart.surplusInfo).length > 0
      ) {
        let surplusInfo = bigpart.surplusInfo
        // 如果为L形余料
        if (surplusInfo.shape && surplusInfo.shape == 'lshape') {
          let x3 = this.ncSetting.xyReverse
            ? Number(surplusInfo.y5)
            : Number(surplusInfo.x3)
          let y3 = this.ncSetting.xyReverse
            ? Number(surplusInfo.x5)
            : Number(surplusInfo.y3)
          let x4 = this.ncSetting.xyReverse
            ? Number(surplusInfo.y4)
            : Number(surplusInfo.x4)
          let y4 = this.ncSetting.xyReverse
            ? Number(surplusInfo.x4)
            : Number(surplusInfo.y4)
          let x5 = this.ncSetting.xyReverse
            ? Number(surplusInfo.y3)
            : Number(surplusInfo.x5)
          let y5 = this.ncSetting.xyReverse
            ? Number(surplusInfo.x3)
            : Number(surplusInfo.y5)
          let newWidth = surplusInfo.width
          let newHeight = surplusInfo.height
          plankWidth = newWidth
          plankHeight = newHeight
          if (
            this.ncSetting.xyReverse &&
            (this.ncSetting.startPosition == '右下角' ||
              this.ncSetting.startPosition == '左上角')
          ) {
            x3 = newWidth - x3
            x4 = newWidth - x4
            x5 = newWidth - x5

            bigPartsPath = [
              {
                x: -plankOffset,
                y: -plankOffset,
              },
              {
                x: newWidth + plankOffset,
                y: -plankOffset,
              },
              {
                x: newWidth + plankOffset,
                y: newHeight + plankOffset,
              },
              {
                x: x3 - plankOffset,
                y: y3 + plankOffset,
              },
              {
                x: x4 - plankOffset,
                y: y4 + plankOffset,
              },
              {
                x: x5 - plankOffset,
                y: y5 + plankOffset,
              },
            ]
          } else {
            bigPartsPath = [
              {
                x: -plankOffset,
                y: -plankOffset,
              },
              {
                x: x3 + plankOffset,
                y: y3 - plankOffset,
              },
              {
                x: x4 + plankOffset,
                y: y4 - plankOffset,
              },
              {
                x: x5 + plankOffset,
                y: y5 + plankOffset,
              },
              {
                x: newWidth + plankOffset,
                y: newHeight + plankOffset,
              },
              {
                x: -plankOffset,
                y: newHeight + plankOffset,
              },
            ]
          }
        } else {
          bigPartsPath = [
            { x: -plankOffset, y: -plankOffset },
            {
              x: Number(surplusInfo.width) + plankOffset,
              y: -plankOffset,
            },
            {
              x: Number(surplusInfo.width) + plankOffset,
              y: Number(surplusInfo.height) + plankOffset,
            },
            {
              x: -plankOffset,
              y: Number(surplusInfo.height) + plankOffset,
            },
          ]
          plankWidth = surplusInfo.width
          plankHeight = surplusInfo.height
        }
      } else {
        bigPartsPath = [
          { x: -plankOffset, y: -plankOffset },
          {
            x: this.plankWidth + plankOffset,
            y: -plankOffset,
          },
          {
            x: this.plankWidth + plankOffset,
            y: this.plankHeight + plankOffset,
          },
          {
            x: -plankOffset,
            y: this.plankHeight + plankOffset,
          },
        ]
        plankWidth = this.plankWidth
        plankHeight = this.plankHeight
      }
      return {
        bigPartsPath,
        bigPartArr: {
          bigpartTop: -plankOffset,
          bigpartLeft: -plankOffset,
          bigpartRight: plankOffset + plankWidth,
          bigpartBottom: plankOffset + plankHeight,
        },
      }
    },
    // 获取一个板件的所有线段
    getPlankLine(path, startX, startY, direction, range) {
      // 将点位处理成顺时针点位, 然后求相邻两点的垂直向量
      // 判断点位是否是顺时针点位, 通过clipper判断面积, 面积为正数则为逆时针点位, 但是由于canvas原点在左上角, 所以要反过来判断顺逆时针
      let area = ClipperLib.JS.AreaOfPolygon(path)
      if (area < 0) {
        path.reverse()
      }
      // 求垂直的单位向量
      let lineArr = []
      let pathLen = path.length
      for (let k = 0; k < pathLen; ++k) {
        let pathLine = {}
        let point = path[k]
        let nextPoint = k == pathLen - 1 ? path[0] : path[k + 1]
        if (point.x == nextPoint.x && point.y == nextPoint.y) continue
        let a = {
          x: point.x + startX,
          y: point.y + startY,
        }
        let b = {
          x: nextPoint.x + startX,
          y: nextPoint.y + startY,
        }
        // 计算当前线段向量的垂直单位向量
        let c = {
          x: b.x - a.x,
          y: b.y - a.y,
        }
        // 计算方式, 已知向量(x, y) 设要求的单位垂直向量为(a, b)单位向量的摸长为1, 也就是a² + b² = 1, 然后和已知向量垂直就有x * a + y * b = 0, 解二元方程
        // 则a = Math.sqrt( Math.pow(y, 2) / ( Math.pow(x, 2) + Math.pow(y, 2) ) )
        // b = Math.sqrt( 1 - Math.pow(a, 2) )
        let aa = Math.sqrt(
          Math.pow(c.y, 2) / (Math.pow(c.x, 2) + Math.pow(c.y, 2))
        )
        let bb = Math.sqrt(1 - Math.pow(aa, 2))
        if (c.x > 0 && c.y < 0) {
          aa = -aa
          bb = -bb
        }
        if (c.x < 0 && c.y < 0) {
          aa = -aa
        }
        if (c.x > 0 && c.y > 0) {
          bb = -bb
        }
        if (c.x > 0 && c.y == 0) {
          bb = -bb
        }
        if (c.x == 0 && c.y < 0) {
          aa = -aa
        }
        let cd = {}
        if (direction == 'up') {
          cd = { x: 0, y: -1 }
        }
        if (direction == 'down') {
          cd = { x: 0, y: 1 }
        }
        if (direction == 'left') {
          cd = { x: -1, y: 0 }
        }
        if (direction == 'right') {
          cd = { x: 1, y: 0 }
        }
        let cos =
          (aa * cd.x + bb * cd.y) /
          (Math.sqrt(aa * aa + bb * bb) * Math.sqrt(cd.x * cd.x + cd.y * cd.y))
        let angle = (180 * Math.acos(cos)) / Math.PI
        if (
          (compareTowNum(angle, 0, '>=') && compareTowNum(angle, 90, '<=')) ||
          !this.isActivePlankLine
        ) {
          pathLine = {
            x1: a.x,
            y1: a.y,
            x2: b.x,
            y2: b.y,
          }
          // 将pathLine的值按照区间从小到大正常排列之后再进行判断
          if (direction == 'up' || direction == 'down') {
            if (range) {
              if (this.checkRangeIntersect(pathLine, range, direction)) {
                if (pathLine.x1 != pathLine.x2) lineArr.push(pathLine)
              }
            } else {
              if (pathLine.x1 != pathLine.x2) lineArr.push(pathLine)
            }
          }
          if (direction == 'left' || direction == 'right') {
            if (range) {
              if (this.checkRangeIntersect(pathLine, range, direction)) {
                if (pathLine.y1 != pathLine.y2) lineArr.push(pathLine)
              }
            } else {
              if (pathLine.y1 != pathLine.y2) lineArr.push(pathLine)
            }
          }
        }
      }
      return lineArr
    },
    // 计算两条线段的距离
    calcDistance(activeLineArr, partLineArr, direction) {
      let distanceArr = []
      for (let i = 0; i < activeLineArr.length; ++i) {
        let line1 = activeLineArr[i]

        for (let k = 0; k < partLineArr.length; ++k) {
          let line2 = partLineArr[k]
          if (direction == 'up' || direction == 'down') {
            // 先判断点是否在另外一条线段上的区间内, 如果再, 才去求距离
            let a,
              b,
              c,
              d = {}
            let startY = 0
            // 判断line1和line2是否平行
            let isPingxing =
              (line1.x2 - line1.x1) * (line2.y2 - line2.y1) -
              (line2.x2 - line2.x1) * (line1.y2 - line1.y1)
            // 如果两条线段平行, 则直接求任意一点到另一条线段的距离
            if (isPingxing == 0) {
              // 如果是水平的横线, 则直接计算纵坐标的差值
              // 计算直线斜率, 由于平行, 计算任意一条线段的斜率即可, 以line1为准
              let k = (line1.y2 - line1.y1) / (line1.x2 - line1.x1)
              // line1对应的直线则为 y - line1.y1 = k * (x - line1.x1)
              // line2对应的直线则为 y - line2.y1 = k * (x - line2.x1)
              // 化为一般式则是 -kx + y - line1.y1 + k * line1.x1 = 0和-kx + y - line2.y1 + k * line2.x1 = 0
              // 平行线之间的距离则为
              let distance =
                Math.abs(
                  -line1.y1 + k * line1.x1 - (-line2.y1 + k * line2.x1)
                ) / Math.sqrt(Math.pow(k, 2) + 1)
              distanceArr.push(distance)
              continue
            }
            // point.x1在另一条point2内部
            let isInRange = false
            // 将两条线段的横坐标从小到大排列
            let newLine1 = JSON.parse(JSON.stringify(line1))
            if (line1.x1 > line1.x2) {
              newLine1.y1 = line1.y2
              newLine1.y2 = line1.y1
              newLine1.x1 = line1.x2
              newLine1.x2 = line1.x1
            }
            let newLine2 = JSON.parse(JSON.stringify(line2))
            if (line2.x1 > line2.x2) {
              newLine2.y1 = line2.y2
              newLine2.y2 = line2.y1
              newLine2.x1 = line2.x2
              newLine2.x2 = line2.x1
            }
            if (newLine1.x1 > newLine2.x1 && newLine1.x1 < newLine2.x2) {
              // 由该点延伸出一条直线, 求与另一条线段的交点, 然后纵坐标相减求出距离
              // 该点为(x1, y1), 上下移动时延伸出向量, 也就是(0, y1), 和另一条线段的向量(x2 - x1, y2 - y1), 求得交点(x, y), 距离则为|y - y1|
              a = {
                x: newLine1.x1,
                y: newLine1.y1,
              }
              b = { x: newLine1.x1, y: 0 }
              c = {
                x: newLine2.x1,
                y: newLine2.y1,
              }
              d = {
                x: newLine2.x2,
                y: newLine2.y2,
              }
              startY = newLine1.y1
              isInRange = true
            }
            // newLine1.x2在另一条point2内部
            if (newLine1.x2 > newLine2.x1 && newLine1.x2 < newLine2.x2) {
              a = {
                x: newLine1.x2,
                y: newLine1.y2,
              }
              b = { x: newLine1.x2, y: 0 }
              c = {
                x: newLine2.x1,
                y: newLine2.y1,
              }
              d = {
                x: newLine2.x2,
                y: newLine2.y2,
              }
              startY = newLine1.y2
              isInRange = true
            }
            // newLine2.x1在point1内部
            if (newLine2.x1 > newLine1.x1 && newLine2.x1 < newLine1.x2) {
              a = {
                x: newLine2.x1,
                y: newLine2.y1,
              }
              b = { x: newLine2.x1, y: 0 }
              c = {
                x: newLine1.x1,
                y: newLine1.y1,
              }
              d = {
                x: newLine1.x2,
                y: newLine1.y2,
              }
              startY = newLine2.y1
              isInRange = true
            }
            if (newLine2.x2 > newLine1.x1 && newLine2.x2 < newLine1.x2) {
              a = {
                x: newLine2.x2,
                y: newLine2.y2,
              }
              b = { x: newLine2.x2, y: 0 }
              c = {
                x: newLine1.x1,
                y: newLine1.y1,
              }
              d = {
                x: newLine1.x2,
                y: newLine1.y2,
              }
              startY = newLine2.y2
              isInRange = true
            }
            if (!isInRange) continue
            let denominator =
              (b.x - a.x) * (d.y - c.y) - (d.x - c.x) * (b.y - a.y)
            if (denominator == 0) continue
            // 如果平行, 则直接求两条线段的纵坐标距离
            let b1 = (b.y - a.y) * a.x + (a.x - b.x) * a.y
            let b2 = (d.y - c.y) * c.x + (c.x - d.x) * c.y
            let y = (b2 * (b.y - a.y) - b1 * (d.y - c.y)) / denominator
            let distance = Math.abs(y - startY)
            distanceArr.push(distance)
          }
          if (direction == 'left' || direction == 'right') {
            // 先判断点是否在另外一条线段上的区间内, 如果再, 才去求距离
            let a,
              b,
              c,
              d = {}
            let startX = 0
            // 判断line1和line2是否平行
            let isPingxing =
              (line1.x2 - line1.x1) * (line2.y2 - line2.y1) -
              (line2.x2 - line2.x1) * (line1.y2 - line1.y1)
            // 如果两条线段平行, 则直接求任意一点到另一条线段的距离
            if (isPingxing == 0) {
              // 如果为垂直的竖线, 则直接计算横坐标的差值
              if (line1.x1 == line1.x2) {
                let distance = Math.abs(line1.x1 - line2.x1)
                distanceArr.push(distance)
                continue
              } else {
                // 计算直线斜率, 由于平行, 计算任意一条线段的斜率即可, 以line1为准
                let k = (line1.y2 - line1.y1) / (line1.x2 - line1.x1)
                // line1对应的直线则为 y - line1.y1 = k * (x - line1.x1)
                // line2对应的直线则为 y - line2.y1 = k * (x - line2.x1)
                // 化为一般式则是 -kx + y - line1.y1 + k * line1.x1 = 0和-kx + y - line2.y1 + k * line2.x1 = 0
                // 平行线之间的距离则为
                let distance =
                  Math.abs(
                    -line1.y1 + k * line1.x1 - (-line2.y1 + k * line2.x1)
                  ) / Math.sqrt(Math.pow(k, 2) + 1)
                distanceArr.push(distance)
                continue
              }
            }
            // point.x1在另一条point2内部
            let isInRange = false
            // 将两条线段的纵坐标从小到大排列
            let newLine1 = JSON.parse(JSON.stringify(line1))
            if (line1.y1 > line1.y2) {
              newLine1.y1 = line1.y2
              newLine1.y2 = line1.y1
              newLine1.x1 = line1.x2
              newLine1.x2 = line1.x1
            }
            let newLine2 = JSON.parse(JSON.stringify(line2))
            if (line2.y1 > line2.y2) {
              newLine2.y1 = line2.y2
              newLine2.y2 = line2.y1
              newLine2.x1 = line2.x2
              newLine2.x2 = line2.x1
            }
            if (newLine1.y1 > newLine2.y1 && newLine1.y1 < newLine2.y2) {
              // 由该点延伸出一条直线, 求与另一条线段的交点, 然后纵坐标相减求出距离
              // 该点为(x1, y1), 上下移动时延伸出向量, 也就是(0, y1), 和另一条线段的向量(x2 - x1, y2 - y1), 求得交点(x, y), 距离则为|y - y1|
              a = {
                x: newLine1.x1,
                y: newLine1.y1,
              }
              b = { x: 0, y: newLine1.y1 }
              c = {
                x: newLine2.x1,
                y: newLine2.y1,
              }
              d = {
                x: newLine2.x2,
                y: newLine2.y2,
              }
              startX = newLine1.x1
              isInRange = true
            }
            // newLine1.x2在另一条point2内部
            if (newLine1.y2 > newLine2.y1 && newLine1.y2 < newLine2.y2) {
              a = {
                x: newLine1.x2,
                y: newLine1.y2,
              }
              b = { x: 0, y: newLine1.y2 }
              c = {
                x: newLine2.x1,
                y: newLine2.y1,
              }
              d = {
                x: newLine2.x2,
                y: newLine2.y2,
              }
              startX = newLine1.x2
              isInRange = true
            }
            // newLine2.x1在point1内部
            if (newLine2.y1 > newLine1.y1 && newLine2.y1 < newLine1.y2) {
              a = {
                x: newLine2.x1,
                y: newLine2.y1,
              }
              b = { x: 0, y: newLine2.y1 }
              c = {
                x: newLine1.x1,
                y: newLine1.y1,
              }
              d = {
                x: newLine1.x2,
                y: newLine1.y2,
              }
              startX = newLine2.x1
              isInRange = true
            }
            if (newLine2.y2 > newLine1.y1 && newLine2.y2 < newLine1.y2) {
              a = {
                x: newLine2.x2,
                y: newLine2.y2,
              }
              b = { x: 0, y: newLine2.y2 }
              c = {
                x: newLine1.x1,
                y: newLine1.y1,
              }
              d = {
                x: newLine1.x2,
                y: newLine1.y2,
              }
              startX = newLine2.x2
              isInRange = true
            }
            if (!isInRange) continue
            let denominator =
              (b.x - a.x) * (d.y - c.y) - (d.x - c.x) * (b.y - a.y)
            if (denominator == 0) continue
            let b1 = (b.y - a.y) * a.x + (a.x - b.x) * a.y
            let b2 = (d.y - c.y) * c.x + (c.x - d.x) * c.y
            let x = (b2 * (b.x - a.x) - b1 * (d.x - c.x)) / denominator
            let distance = Math.abs(x - startX)
            distanceArr.push(distance)
          }
        }
      }
      return distanceArr
    },
    // 判断两个线段是否横坐标是否有交叉位置, 或者纵坐标有交叉位置
    checkRangeIntersect(line1, line2, direction) {
      // direction为up或者down时, 判断横坐标是否有交集
      if (direction == 'up' || direction == 'down') {
        let newLine1 = {}
        let newLine2 = {}
        if (line1.x1 > line1.x2) {
          newLine1.x1 = line1.x2
          newLine1.x2 = line1.x1
        } else {
          newLine1.x1 = line1.x1
          newLine1.x2 = line1.x2
        }
        if (line2.x1 > line2.x2) {
          newLine2.x1 = line2.x2
          newLine2.x2 = line2.x1
        } else {
          newLine2.x1 = line2.x1
          newLine2.x2 = line2.x2
        }
        if (
          Math.max(newLine1.x1, newLine2.x1) <
          Math.min(newLine1.x2, newLine2.x2)
        ) {
          return true
        } else {
          return false
        }
      } else {
        // direction为left或者right时, 判断纵坐标是否有交集
        let newLine1 = {}
        let newLine2 = {}
        if (line1.y1 > line1.y2) {
          newLine1.y1 = line1.y2
          newLine1.y2 = line1.y1
        } else {
          newLine1.y1 = line1.y1
          newLine1.y2 = line1.y2
        }
        if (line2.y1 > line2.y2) {
          newLine2.y1 = line2.y2
          newLine2.y2 = line2.y1
        } else {
          newLine2.y1 = line2.y1
          newLine2.y2 = line2.y2
        }
        if (
          Math.max(newLine1.y1, newLine2.y1) <
          Math.min(newLine1.y2, newLine2.y2)
        ) {
          return true
        } else {
          return false
        }
      }
    },
    // 键盘松开事件
    keyUpFuncs() {
      return () => {
        this.isScaling = false
      }
    },
    mouseWheelFuncs() {
      return (e) => {
        if (e.ctrlKey) {
          e.preventDefault()
        }
      }
    },
    // 鼠标松开事件（这儿针对把板件拖动到暂存区、把图片从暂存区拖动到canvas的情况）
    mouseUpFuncs() {
      return async (e) => {
        if (e.button === 0) {
          this.isDrawImage = false
        }
        // 把图片拖动到 canvas 里面,需先判断是否是可拖动状态/是否目标是一个图片/只对当前数据在内部的canvas元素做以下处理
        if (
          this.isDragingPlank &&
          e.target.tagName == 'IMG' &&
          mousePointISInElement(e, `canvas${this.$props.canvasKey}`, 'id')
        ) {
          buryPointApi('layout', 'plate_staged')
          let pos = this.windowToCanvas(e.clientX, e.clientY)
          const dragData = await checkCurrentDragPlankDataIsLoad(
            this.$store.state.awaitPaibanStore.currentDragPlank
          )
          const parts = this.drawData.data.map((item) => item.parts).flat(1)
          // 使用锯切时 禁止拖动待排库异形
          if (!dragData?.plank) return
          if (dragData.plank.path && parts.every((part) => part.isUseSaw)) {
            // 启用锯片切割后不支持直接在排版添加异形板件，如需添加请前往开料清单添加并排版
            this.$message.warning(
              translate('cuttingDock.sawEngraving.tempPaiabanTip')
            )
            return
          }
          let plank = dragData.plank
          this.activePlank = plank
          let { offsetX, offsetY } = this.getOffsetBetweenPointAndCanvas(
            e,
            null,
            true
          )
          // 给板件设置新的index顺序参数
          this.activePlank.index = getPlankMaxIndexByFinalDrawData()
          // 找到当前点击的位置属于哪一个大板, 找到起始坐标小于当前鼠标位置的所有大板, 取最后一个大板
          let arr = this.sliceDrawData.filter(
            (v) => pos.x >= v.startX && pos.y >= v.startY
          )
          // 判断鼠标位置是否在大板所处的位置
          // 如果点到了第一块大板之前的区域, 是没有大板信息的, 所以直接弹回原位置重新绘制
          if (arr.length == 0) {
            this.$message({
              message: translate('arrangedPage.dragWarning'),
              type: 'info',
            })
            this.changeDragingPlank(false)
            this.setActivePlankData(null)
            return
          }
          // 找到原数据中的bigpart
          // 找到当前鼠标位置的最后一个大板
          let targetBigpart = arr[arr.length - 1]
          let bigpart = this.sliceDrawData.find(
            (v) => v.stockKey == targetBigpart.stockKey
          )
          // // 如果找到了大板则继续执行后面的逻辑, 否则弹回原位置重新绘制
          if (bigpart) {
            // 如果鼠标位置在大板上, 则继续执行后面的逻辑, 否则弹回原位置重新绘制
            if (
              pos.x <= bigpart.startX + bigpart.width + 2 * this.deviation &&
              pos.y <=
                bigpart.startY +
                  bigpart.height +
                  this.padding +
                  2 * this.deviation
            ) {
              if (
                bigpart.matCode == plank.matCode &&
                bigpart.thick == plank.thick &&
                bigpart.texture == plank.texture
              ) {
                this.dealCanvasPlankMsg(
                  {
                    offsetX,
                    offsetY,
                  },
                  true
                )
              } else {
                this.$message({
                  message: translate('arrangedPage.dragErrTip'),
                  type: 'error',
                })
                this.setActivePlankData(null)
                this.changeDragingPlank(false)
              }
            }
          } else {
            this.$message({
              message: translate('arrangedPage.dragWarning'),
              type: 'info',
            })
            this.setActivePlankData(null)
            this.changeDragingPlank(false)
          }
        }
        // 如果是左键
        if (e.button == 0) {
          // 不要放到上面
          this.resetImageTempFlag()
        }
      }
    },
    // 截取板件生成图片
    cuttingPlank() {
      // 计算当前激活板件的位置
      this.activePlankOff = null
      let imageTempDom = document.querySelector(
        `.select-plank-image-wrap-${this.canvasKey} img`
      )
      let imgUrl = imageTempDom.src
      let title = `${this.activePlank.thick}${this.activePlank.matCode}(${this.activePlank.texture})`
      let obj = {
        imgUrl: imgUrl,
        title: title,
        data: this.activePlank,
      }
      let img = new Image()
      img.src = imgUrl
      let that = this
      img.onload = function () {
        let scaleW = Number(this.width) / 138
        let scaleH = Number(this.height) / 140
        if (this.width <= 138 && this.height <= 140) {
          if (scaleW >= scaleH) {
            obj.width = '100%'
          } else {
            obj.height = '100%'
          }
        }
        if (this.width > 138 && this.height > 140) {
          if (scaleW >= scaleH) {
            obj.width = Number(this.width) / scaleW + 'px'
            obj.height = Number(this.height) / scaleW + 'px'
          } else {
            obj.width = Number(this.width) / scaleH + 'px'
            obj.height = Number(this.height) / scaleH + 'px'
          }
        }
        if (this.width <= 138 && this.height > 140) {
          obj.width = Number(this.width) / scaleH + 'px'
          obj.height = Number(this.height) / scaleH + 'px'
        }
        if (this.width > 138 && this.height <= 140) {
          obj.width = Number(this.width) / scaleW + 'px'
          obj.height = Number(this.height) / scaleW + 'px'
        }
        // 从精细排版返回排版时如果没有保存, 在返回排版时会把暂存区板件释放出来
        that.addTempStorage(obj)
        that.changeTempStorage()
      }
    },
    // 打开精细页面或离开页面时, 移除window上的监听事件
    clearWindowEvent() {
      window.removeEventListener('keydown', this.keyDownEvent)
      window.removeEventListener('keyup', this.keyUpEvent)
      window.removeEventListener('mouseup', this.mouseupEvent)
      window.removeEventListener('mousemove', this.windowMouseMove)
      window.removeEventListener('mousewheel', this.mouseWheelEvent, {
        passive: false,
      })
    },
    // window里面的mouse移动
    windowMouseMove(e) {
      if (this.imageTempMousedownFlag) {
        this.hasMoved = true
        // 只有鼠标点下的时候才设置image的位置
        const imageWrap = document.querySelector(
          `.select-plank-image-wrap-${this.canvasKey}`
        )
        imageWrap.style.left =
          e.x - this.mousePointOffsetImage.x - partLineWidth / 2 + 'px'
        imageWrap.style.top =
          e.y - this.mousePointOffsetImage.y - partLineWidth / 2 + 'px'
      }
    },
    // 退出精细排版时, 重新设置监听事件
    addWindowEvent() {
      this.startDraw()
      // 监听键盘ctrl键和上下左右键位
      this.keyDownEvent = this.keyDownFuncs()
      window.addEventListener('keydown', this.keyDownEvent)
      // 监听键盘按键松开事件
      this.keyUpEvent = this.keyUpFuncs()
      window.addEventListener('keyup', this.keyUpEvent)
      this.mouseupEvent = this.mouseUpFuncs()
      // 监听鼠标松开事件
      window.addEventListener('mouseup', this.mouseupEvent)
      window.addEventListener('mousemove', this.windowMouseMove)
    },
    // 重置图片标记（在各种mouseup的事件里面调用）
    resetImageTempFlag() {
      this.imageTempMousedownFlag = false
    },
    // 设置图片temp的显隐
    showImageTemp(bool) {
      let wrap = document.querySelector(
        `.select-plank-image-wrap-${this.canvasKey}`
      )
      if (!wrap) {
        return
      }
      if (bool) {
        wrap.style.display = 'block'
      } else {
        wrap.style.display = 'none'
      }
    },
    clearImageTempEvent() {
      const imageWrap = document.querySelector(
        `.select-plank-image-wrap-${this.canvasKey}`
      )
      if (!imageWrap) {
        return
      }
      imageWrap.removeEventListener('mouseup', this.handleImageTempMouseUp)
      imageWrap.removeEventListener('mousedown', this.handleImageTempMouseDown)
      document.body.removeChild(imageWrap)
    },
    getActivePlank() {
      return this.activePlank
    },
    // 初始化预加载锁的logo解决图片闪烁
    async initPlankLock() {
      this.UnLockedImgInstance = await this.loadImageAsync(UnLockedImg)
      this.LockedImgInstance = await this.loadImageAsync(LockedImg)
    },
    // 异步加载图片
    async loadImageAsync(src) {
      return new Promise((resolve, reject) => {
        const img = new Image()
        img.src = src
        img.onload = () => resolve(img)
        img.onerror = (error) => reject(error)
      })
    },
    // 加载读取锁定大板图片
    loadLockedImg(ctx, x, y, isLocked, lockWidthHeight) {
      let lockedImgInstance
      lockedImgInstance = isLocked
        ? this.UnLockedImgInstance
        : this.LockedImgInstance
      ctx.drawImage(lockedImgInstance, x, y, lockWidthHeight, lockWidthHeight)
    },
    // 判断位置
    jundgeLocation(mousePoint, path) {
      if (mousePoint && path) {
        let point = new ClipperLib.IntPoint(mousePoint.x, mousePoint.y)
        const result = ClipperLib.Clipper.PointInPolygon(point, path)
        return result
      }
    },
    //获取当前大板
    getCurrentBigPart(mousePoint) {
      let arr = this.sliceDrawData.filter(
        (v) => mousePoint.x >= v.startX && mousePoint.y >= v.startY
      )
      if (arr.length == 0) return null
      // 判断鼠标位置是否在大板所处的位置
      let targetBigpart = arr[arr.length - 1]
      // 找到原数据中的bigpart
      let bigpart = this.sliceDrawData.find(
        (v) => v.stockKey == targetBigpart.stockKey
      )
      return bigpart
    },
    // 更改锁的hover效果
    changeLockHover(e, bigPart, result) {
      if (result) {
        this.lockHoverInfo.isShow = true
        this.lockHoverInfo.top = e.pageY + 24 * this.scalePercent
        this.lockHoverInfo.left = e.pageX - 94
        this.lockHoverInfo.isLocked = bigPart.isLocked
      } else {
        this.lockHoverInfo.isShow = false
      }
    },
    syncStockKey(plank) {
      if (!plank) return
      // 检查大板和小板的stockNum是否一致，不一致将大板stockNum赋值给小板
      const plankStockKey = plank.stockKey
      plank.parts.forEach((part) => {
        const partStockKey = part.stockKey
        if (partStockKey != plankStockKey) {
          part.stockKey = plankStockKey
        }
      })
      return plank
    },
    handleChangePaibanWay() {
      this.sliceDrawData.forEach((item) => {
        if (item.ableToggle && item.sideType == -1) {
          item.sideType = 1
          this.toggleBigpartData(item)
        }
      })
    },
    activePlankIDChangeFunc(val) {
      this.activePlank = this.drawData.data
        .map((e) => e.parts)
        .flat(1)
        .find(
          (part) =>
            part.plankID === val &&
            part.index == this.activePlankIndex &&
            part.partUniqueId == this.activePartUniqueId
        )
      if (!this.activePlank) return
      const activeBigPlank = this.drawData.data.find((plank) => {
        const idArr = plank.parts.map((part) => part.plankID)
        const indexArr = plank.parts.map((part) => part.index)
        return plank.parts.some(
          (part) =>
            part.plankID == this.activePlank.plankID &&
            part.index == this.activePlank.index &&
            part.partUniqueId == this.activePlank.partUniqueId
        )
      })
      this.setActiveBigPart(activeBigPlank)
      if (this.activeBigpart) {
        this.isShowPaiban = true
        const index = this.drawData.data.findIndex(
          (plank) =>
            plank.canvasIndex == this.activeBigpart.canvasIndex &&
            plank.stockNum == this.activeBigpart.stockNum
        )
        this.currentPape = Math.ceil((index + 1) / this.countPerPage)
      }
    },
    /** 点击整个画布容器 */
    handleCanvasContainer() {
      this.$emit('scaleChange', { defaultScale, scale: this.scalePercent })
    },
    /** 设置活跃大板 */
    setActiveBigPart(val) {
      this.activeBigpart = val
      this.$emit('setActiveBigPart', val)
    },
    /** 设置活跃小板的值 */
    setActivePlankData(val) {
      this.activePlank = val
    },
    translateLang(key) {
      return translate(key)
    },
    /**
     * @description 为什么会有这个函数，因为在其他的页面可能会存在将store中的数据重置的问题，虽然数据还是那些数据但是对象引用丢失了
     * @param stockKey
     */
    getActiveBigPart(stockKey) {
      return this.drawData.data.find((item) => item.stockKey === stockKey)
    },
    /** 操作画布的显示 */
    collapseCanvas(op) {
      if (op) {
        // 收起画布
        this.isShowPaiban = false
      } else {
        // 展开画布
        this.isShowPaiban = true
      }
    },
  },
  async mounted() {
    // 接受全局排版重新排版事件
    EventBus.$on('handleChangePaibanWay', this.handleChangePaibanWay)
    await this.initPlankLock()
    this.startDraw()
    this.refKey = this.canvasKey
    // 监听鼠标滚轮事件
    this.mouseWheelEvent = this.mouseWheelFuncs()
    window.addEventListener('mousewheel', this.mouseWheelEvent, {
      passive: false,
    })
    this.addWindowEvent()
  },
  watch: {
    batchBujianList: {
      handler(val) {
        this.bujianList = val
        this.ctx.clearRect && this.renderAll()
      },
      deep: true,
      immediate: true,
    },
    isCuttingOrder: {
      handler() {
        this.renderAll()
      },
    },
    activePlankID: {
      handler(val) {
        this.activePlankIDChangeFunc(val)
        this.renderAll()
        this.startDraw()
        this.$nextTick(() => {
          this.$emit('getBigPlankH', this.activeBigpart?.startY)
        })
      },
    },
    activePlankIndex: {
      handler(val) {
        this.activePlank = this.drawData.data
          .map((e) => e.parts)
          .flat(1)
          .find(
            (part) =>
              part.plankID === this.activePlankID &&
              part.index == val &&
              part.partUniqueId == this.activePartUniqueId
          )
        if (!this.activePlank) return
        const activeBigPlank = this.drawData.data.find((plank) => {
          const idArr = plank.parts.map((part) => part.plankID)
          const indexArr = plank.parts.map((part) => part.index)
          return plank.parts.some(
            (part) =>
              part.plankID == this.activePlank.plankID &&
              part.index == this.activePlank.index &&
              part.partUniqueId == this.activePlank.partUniqueId
          )
        })

        this.setActiveBigPart(activeBigPlank)
        if (this.activeBigpart) {
          this.isShowPaiban = true
          const index = this.drawData.data.findIndex(
            (plank) =>
              plank.canvasIndex == this.activeBigpart.canvasIndex &&
              plank.stockNum == this.activeBigpart.stockNum
          )
          this.currentPape = Math.ceil((index + 1) / this.countPerPage)
        }
        this.renderAll()
        this.startDraw()
        this.$nextTick(() => {
          this.$emit('getBigPlankH', this.activeBigpart?.startY)
        })
      },
    },
    canvasMaxWidth: {
      handler(val) {
        if (this.canvasTime) {
          clearTimeout(this.canvasTime)
        }
        this.canvasTime = setTimeout(() => {
          this.renderAll()
          this.startDraw()
        }, 500)
      },
    },
    currentPape: {
      handler(val) {
        this.startDraw()
        this.$emit('handleChangePage', val)
      },
    },
    imageTempMousedownFlag(val) {
      this.$emit('handleChangeImageTempMousedownFlag', val)
      if (val) {
        // 显示图片
        this.showImageTemp(true)
      }
    },
    drawData: {
      immediate: true,
      handler(val) {
        val.data[0].parts.map((item) => {
          return item.index
        })
      },
    },
    isShowPartSize: {
      handler() {
        this.renderAll()
      },
    },
    isDrawPartCollapsed: {
      handler(val) {
        this.isShowPaiban = !val
      },
      immediate: true,
    },
  },
  beforeDestroy() {
    EventBus.$off('handleChangePaibanWay', this.handleChangePaibanWay)
    this.clearWindowEvent()
    this.changeDragingPlank(false)
    this.clearImageTempEvent()
  },
}
</script>

<style scoped lang="less">
.draw-part {
  min-width: 988px;
  margin-bottom: 16px;
  background-color: #fff;

  span {
    font-family: PingFangSC-Regular, 'PingFang SC';
  }

  .bigpart-category {
    min-width: 988px;
    padding: 10px 16px;
    color: #000;
    font-size: 14px;
    background-color: #e7e7e7;
    cursor: pointer;
    user-select: none;
    position: relative;
    display: flex;
    align-items: center;
    text-wrap: nowrap;
    // justify-content: space-between;
    .high-plank {
      display: inline-block;
      box-sizing: border-box;
      height: 25px;
      padding: 0 10px;
      color: #18a8c7;
      font-weight: 600;
      font-size: 12px;
      font-family: 'Helvetica Neue', Helvetica, 'PingFang SC',
        'Hiragino Sans GB', 'Microsoft YaHei', SimSun, sans-serif;
      line-height: 22px;
      border: 2px solid #18a8c7;
      border-radius: 5px;
    }
    .specular-selection-tips {
      top: 0px;
      right: -330px;
      width: 320px;
      height: 50px;
    }

    .no-page {
      flex-direction: row-reverse;
    }
  }
  .pageable {
    display: flex;
    align-items: center;
    // justify-content: space-between;
    // background-color: #fff;
    width: 220px;

    .pre-btn,
    .next-btn {
      padding: 0 4px;
    }

    .tex-dir-select {
      width: 200px;
    }

    .fold-canvas:hover {
      color: #18a8c7;
    }
  }
  .unfolder-btn {
    transform: rotate(180deg) !important;
  }
  .left-btn {
    transform: rotate(90deg) !important;
  }
  .right-btn {
    transform: rotate(270deg) !important;
  }
  /** 实现粘性布局 */
  .category-sticky {
    position: -webkit-sticky;
    position: sticky;
    top: -3px;
  }

  .bigpart-info-title {
    margin-right: 24px;
    color: rgba(0, 0, 0, 0.6);
    user-select: none;
  }

  .bigpart-info {
    // color: #fb4444;
  }

  .fold-canvas {
    cursor: pointer;
    &:hover span {
      color: #18a8c7;
    }
    transition: all 0.5s;
  }

  .bigpart-operation {
    position: fixed;
    width: 126px;
    padding: 8px;
    background: #fff;
    border-radius: 4px;
    box-shadow: 0 4px 8px 0 rgba(190, 190, 190, 0.31);

    > div {
      display: flex;
      align-items: center;
      width: 110px;
      height: 32px;
      margin: 0 auto;
      color: #333;
      line-height: 32px;
      text-align: center;
      cursor: pointer;

      &:hover {
        background: #edeff3;
      }

      .iconfont {
        margin: 0 9px;
      }
    }

    .disabled-btn {
      color: #999;

      &:hover {
        color: #999;
        background: #fff;
      }
    }
  }
}
</style>

<style lang="less">
.select-plank-image,
.select-plank-image-wrap {
  user-select: none !important;
  -webkit-user-drag: none !important;
}
canvas {
  image-rendering: pixelated;
}
</style>
