import {
  axisList as axisListV1,
  codeList as codeListV1,
} from '../../components/sideHole/module/customFirstConf'
import {
  codeList as codeListV4,
  holeSetting as holeSettingV4,
  slotSetting as slotSettingV4,
} from '../../components/sideHole/module/customFourthConf'
import {
  codeList as codeListV2,
  holeSetting as holeSettingV2,
  slotSetting as slotSettingV2,
} from '../../components/sideHole/module/customSecondConf'
import {
  codeList as codeListV3,
  holeSetting as holeSettingV3,
  lmnSetting as lmnSettingV3,
  pointSetting as pointSettingV3,
  slotSetting as slotSettingV3,
  axisList as sxisListV3,
} from '../../components/sideHole/module/customThirdConf'

const prefixDirection = '侧面换面设置'
// 侧孔机每个key的含义
export const sideHoleFormTextObj = {
  beginCode_templateString: '设备启动代码',
  pauseCode_templateString: '翻面暂停代码',
  endCode_templateString: '设备结束代码',
  frontTurnCode: `${prefixDirection}-上面开始代码`,
  frontTurnEndCode: `${prefixDirection}-上面结束代码`,
  leftTurnCode: `${prefixDirection}-左面开始代码`,
  leftTurnEndCode: `${prefixDirection}-左面结束代码`,
  rightTurnCode: `${prefixDirection}-右面开始代码`,
  rightTurnEndCode: `${prefixDirection}-右面结束代码`,
  backTurnCode: `${prefixDirection}-下面开始代码`,
  backTurnEndCode: `${prefixDirection}-下面结束代码`,
  holeKnife: '加工刀具',
  holeSetting_templateString: '加工指令',
  verticalHoleSetting_templateString: '加工指令',
  slotKnife: '加工刀具',
  slotSetting_templateString: '加工指令',
  verticalSlotSetting_templateString: '加工指令',
  lmnKnife: '加工刀具',
  lmnSetting_templateString: '加工指令',
  mtArcInterpolation: '圆弧插补',
  dpsBrokenBoardSize: '上下破板尺寸',
  mtLineCutCode_templateString: '直槽加工',
  mtArcCutCode_templateString: '圆弧加工',
  xPointSymbol: '升降坐标',
  yPointSymbol: '横向向坐标',
  zPointSymbol: '纵向坐标',
  fileEncoding: '文件编码格式',
  platform: '换行符格式设置',
  fileSuffix: '文件后缀设定',
  guigui_side_drill_enabled: '是否启用',
  xyReverse: 'xy轴互换',
  fileSuffix: '文件后缀',
  verticalEnable: '启用正面加工',
}

// 自定义一
export const oldSettingFormTextObjV1 = (() => {
  let obj = {
    style: '应用模板',
  }
  codeListV1.forEach((code) => {
    obj[code.key] = code.title
  })
  axisListV1.forEach((axis) => {
    obj[axis.key] = axis.title
  })
  return obj
})()

// 自定义二
export const oldSettingFormTextObjV2 = (() => {
  let obj = {}
  codeListV2.forEach((code) => {
    obj[code.key] = code.title
  })
  holeSettingV2.forEach((hole) => {
    obj[hole.key] = hole.title
  })
  slotSettingV2.forEach((slot) => {
    obj[slot.key] = slot.title
  })
  return obj
})()

// 自定义三
export const oldSettingFormTextObjV3 = (() => {
  let obj = {}
  codeListV3.forEach((code) => {
    obj[code.key] = code.title
  })
  holeSettingV3.forEach((hole) => {
    obj[hole.key] = hole.title
  })
  slotSettingV3.forEach((slot) => {
    obj[slot.key] = slot.title
  })
  sxisListV3.forEach((axis) => {
    obj[axis.key] = axis.title
  })

  pointSettingV3.forEach((point) => {
    obj[point.key] = point.title
  })
  return obj
})()

// 自定义四
export const oldSettingFormTextObjV4 = (() => {
  let obj = {
    xPointSymbol: '升降坐标',
    yPointSymbol: '横向坐标',
    zPointSymbol: '纵向坐标',
    xyReverse: 'xy轴互换',
    fileSuffix: '文件后缀',
    zzdCutLast: '主轴刀在最后加工',
    holeEnable: '是否开启',
    holeKnife: '选中刀具',
    slotEnable: '是否开启',
    slotKnife: '选中刀具',
  }
  codeListV4.forEach((code) => {
    obj[code.key] = code.title
  })
  holeSettingV4.forEach((hole) => {
    obj[hole.key] = hole.title
  })
  slotSettingV4.forEach((slot) => {
    obj[slot.key] = slot.title
  })
  return obj
})()

// 侧孔机每个value的含义
export const sideHoleFormValueObj = {
  fileEncoding: {
    'utf-8': 'UTF-8格式',
    ANSI: 'ANSI格式',
  },
  style: {
    '': '默认设置',
    自定义一: '自定义一',
    自定义二: '自定义二',
    自定义三: '自定义三',
    自定义四: '自定义四',
  },
}

export const sideHoleFormValueObjV3 = {
  lmnSetting: {
    lmnFree: '空走',
    lmnCut: '加工部分',
    lmnCutFace: '加工平面代码',
    lmnStop: '气缸收回代码',
  },
}

// 侧孔机-自定义中的特殊value的值的含义
export const sideHoleFormValueObjV4 = {
  lmlSetting: {
    lmlCutCode: '加工命令',
    lmlEnable: '拉米诺开关',
    lmlKnife: '指定用刀',
  },
  mtSetting: {
    // 榫卯
    mtEnable: '榫卯开关',
    mtArcCutCode: '圆弧加工',
    mtLineCutCode: '直槽加工',
    mtArcInterpolation: '圆弧插补',
  },
  mtArcInterpolation: {
    true: '顺时针:G03 逆时针:G02',
    false: '顺时针:G02 逆时针:G03',
  },
}
