<template>
  <div class="sideHole_wapper flex flex-dir--top h100">
    <breadcrumb
      :title="$route.query.line || $t('main.head.productionLine')"
      :childTitle="$t('common.sideHoleDrill')"
      @goBackPage="goBackPage"
    >
      <template v-slot:title>
        <div class="top-nav">
          <span
            v-for="(item, index) in navList"
            :key="index"
            @click="changeNav(item)"
            :class="[nowPage.val == item.val ? 'active-nav' : '', 'nav-item']"
          >
            {{ $t(item.name) }}
          </span>
        </div>
      </template>
      <div v-if="!previewInfo.isPreview">
        <a-button
          @click.native="handleOpenChangeRecord"
          id="equipment_side_hole_change_record"
          class="mr20"
        >
          <!-- 修改记录 -->
          {{ $t('common.modificationRecord') }}
        </a-button>
        <a-button
          @click.native="saveSideHole"
          :disabled="!ableSaveSetting"
          type="primary"
          ><a-icon type="save" />{{ $t('common.saveSetting') }}</a-button
        >
      </div>
      <div v-if="previewInfo.isApply">
        <a-button
          @click.native="saveSideHole"
          :disabled="!ableSaveSetting"
          type="primary"
          ><a-icon type="save" />应用设置</a-button
        >
      </div>
    </breadcrumb>
    <div class="wrap h0 flex-box--1 p16">
      <!-- 侧面加工 -->
      <div class="h100" v-show="nowPage.val == 'sideWorking'">
        <SideWorking
          ref="sideRef"
          :obj="formData"
          :settingData="settingData"
        ></SideWorking>
      </div>

      <!-- 正面加工 -->
      <FrontWorking
        v-show="nowPage.val == 'frontWorking'"
        ref="frontRef"
        :obj="formData"
        :settingData="settingData"
      ></FrontWorking>
      <!-- 旧版设置 -->
      <OldSetting
        v-show="nowPage.val == 'oldSetting'"
        ref="oldSettingRef"
        :obj="oldSetting"
        :enabled="formData.guigui_side_drill_enabled"
      ></OldSetting>
    </div>
    <m-lock-modal
      :visible.sync="isShowVisible"
      :status.sync="lockStatus"
      :verifyPassword="verifyPassword"
      :tempStatus.sync="tempStatus"
      @getLockPassword="handleGetLockPassword"
      @handleChangePasswordState="handleChangePasswordState"
      :lineName="$route.query.line"
    ></m-lock-modal>
    <!-- 修改记录弹窗 -->
    <m-change-record-modal
      :visible.sync="showRecordModal"
      operate_equipment="sideHoleDrill"
      @success="handleSuccess"
      ref="changeRecordModalRef"
    />
  </div>
</template>

<script>
import { getLockStatus } from '@/apis/productionLineLock'
import FrontWorking from '@/components/sideHole/frontWorking.vue'
import {
  axisList as axisListV1,
  codeList as codeListV1,
} from '@/components/sideHole/module/customFirstConf'
import {
  codeList as codeListV4,
  holeSetting as holeSettingV4,
  slotSetting as slotSettingV4,
} from '@/components/sideHole/module/customFourthConf'
import {
  codeList as codeListV2,
  holeSetting as holeSettingV2,
  slotSetting as slotSettingV2,
} from '@/components/sideHole/module/customSecondConf'
import {
  codeList as codeListV3,
  holeSetting as holeSettingV3,
  slotSetting as slotSettingV3,
  axisList as sxisListV3,
} from '@/components/sideHole/module/customThirdConf'
import OldSetting from '@/components/sideHole/oldSetting.vue'
import SideWorking from '@/components/sideHole/sideWorking.vue'
import breadcrumb from '@/components/topBreadCrumb.vue'
import store from '@/store'
import { translate } from '@/util/commonFun'
import { genFinalRecord, thirdPermission } from '@/util/commonFuncs.js'
import {
  addIdForItems,
  addOperateTableToRecord,
  compareDiffForm,
} from '@/util/commonFuncs.js'
import { operateRecordTableType } from '@/util/configure'
import {
  oldSettingFormTextObjV1,
  oldSettingFormTextObjV2,
  oldSettingFormTextObjV3,
  oldSettingFormTextObjV4,
  sideHoleFormTextObj,
  sideHoleFormValueObj,
  sideHoleFormValueObjV3,
  sideHoleFormValueObjV4,
} from '@/util/operateRecordConfigure/sideHoleConfigure'
import { saveDataJsonToOssForRecord } from '@/util/saveFile'
import MChangeRecordModal from '@/views/equipment/component/m-change-record-modal.vue'
import MLockModal from '@/views/equipment/component/m-lock-modal.vue'
import { mapState } from 'vuex'

// 需要补充的字段
const needSupplementField = ['beginCode', 'endCode', 'pauseCode']

export default {
  data() {
    return {
      navList: [
        {
          name: 'sideHoleMachine.flanking',
          val: 'sideWorking',
          ref: 'sideRef',
        },
        {
          name: 'sideHoleMachine.frontProcess',
          val: 'frontWorking',
          ref: 'frontRef',
        },
        {
          name: 'sideHoleMachine.oldSetting',
          val: 'oldSetting',
          ref: 'oldSettingRef',
        },
      ],
      nowPage: {
        name: 'sideHoleMachine.flanking',
        val: 'sideWorking',
        ref: 'sideRef',
      },
      setting_id: -1,
      formData: {
        guigui_side_drill_enabled: false,
      },
      oriFormData: {}, // 原始数据
      //标签数据
      settingData: [],
      saveSettingData: {},

      // 记录是否可以进行保存
      ableSaveSetting: false,
      // 旧版设置
      oldSetting: '',
      oriOldSetting: '',
      lockStatus: 1, // 锁状态
      verifyPassword: true,
      lockPassword: '',
      tempStatus: 1, //默认状态
      isShowVisible: false,
      showRecordModal: false,
    }
  },
  computed: {
    ...mapState('equipEnvironment', ['previewProductLine', 'previewInfo']),
  },
  methods: {
    async handleGetLockPassword() {
      await this.saveSideHole()
    },
    handleChangePasswordState(state) {
      this.verifyPassword = state
    },
    // 切换导航栏
    changeNav(item) {
      if (item.val === 'labellingSetting' && !thirdPermission('/labeling'))
        return
      this.nowPage = item
    },
    saveDataJsonToOssForRecordFn(record, option, cb) {
      saveDataJsonToOssForRecord(record, 'equipment_docking', option, cb)
    },
    compareSideHole(current, ori) {
      const record = []

      // 生成当前list
      const knives = Object.values(current.knives)
      const oriKnives = ori.knives
      // 给record添加表格操作记录
      addOperateTableToRecord(
        knives, // 当前列表
        oriKnives, // 原始列表
        [
          'id',
          'diameter',
          'xOffset',
          'yOffset',
          'zOffset',
          'startCode',
          'endCode',
        ],
        operateRecordTableType.SIDE_HOLE_KNIFE_STORE_TABLE,
        '侧面加工-刀库',
        record
      )
      const verticalKnives = Object.values(current.verticalKnives)
      const oriverticalKnives = ori.verticalKnives

      addOperateTableToRecord(
        verticalKnives, // 当前列表
        oriverticalKnives, // 原始列表
        [
          'id',
          'diameter',
          'x_offset',
          'y_offset',
          'z_offset',
          'startCode',
          'endCode',
          'holeAble',
          'slotAble',
          'stretchAble',
          'hornAble',
          'knifePurpose',
        ],
        operateRecordTableType.SIDE_HOLE_FRONT_KNIFE_STORE_TABLE,
        '正面加工-刀库',
        record,
        'id'
      )
      const compareMsg = [
        {
          title: '侧面加工-设备参数设置',
          keys: [
            'beginCode_templateString',
            'pauseCode_templateString',
            'endCode_templateString',
            'frontTurnCode',
            'frontTurnEndCode',
            'leftTurnCode',
            'leftTurnEndCode',
            'rightTurnCode',
            'rightTurnEndCode',
            'backTurnCode',
            'backTurnEndCode',
          ],
        },
        {
          title: '侧面加工-打孔设置',
          keys: ['holeKnife', 'holeSetting_templateString'],
        },
        {
          title: '收刀指令',
          keys: ['verticalRetractionCode'],
        },
        {
          title: '上下破板尺寸',
          keys: ['dpsBrokenBoardSize'],
        },
        {
          title: '侧面加工-拉槽设置',
          keys: ['slotKnife', 'slotSetting_templateString'],
        },
        {
          title: '正面加工',
          keys: ['verticalEnable'],
        },
        {
          title: '正面加工-打孔设置',
          keys: ['verticalHoleSetting_templateString'],
        },
        {
          title: '正面加工-拉槽设置',
          keys: ['verticalSlotSetting_templateString'],
        },
        {
          title: '正面加工-正/反面通槽分两段加工',
          keys: ['split_long_slot'],
        },
        {
          title: '正面加工-第二段槽长度',
          keys: ['second_slot_length'],
        },
        {
          title: '侧面加工-拉米诺连接设置',
          keys: ['lmnKnife', 'lmnSetting_templateString'],
        },
        {
          title: '侧面加工-榫卯连接设置-直槽加工',
          keys: ['mtLineCutCode_templateString'],
        },
        {
          title: '侧面加工-榫卯连接设置-圆弧加工',
          keys: ['mtArcCutCode_templateString'],
        },
        {
          title: '侧面加工-榫卯连接设置-圆弧插补指令切割弧形',
          keys: ['useArcCode'],
        },
        {
          title: '侧面加工-榫卯连接设置-圆弧插补',
          keys: ['mtArcInterpolation'],
        },
        {
          title: '侧面加工-坐标值',
          keys: ['xPointSymbol', 'yPointSymbol', 'zPointSymbol'],
        },
        {
          title: '侧面加工-文件编码格式',
          keys: ['fileEncoding', 'platform'],
        },
        {
          title: '侧面加工-文件后缀设置',
          keys: ['fileSuffix'],
        },
        {
          title: '侧面加工-升降坐标基准对调',
          keys: ['zElevationCoordReverse'],
        },
        {
          title: '旧版设置-启用旧版侧孔机设置',
          keys: ['guigui_side_drill_enabled'],
        },
      ]

      // 特殊处理一下 templateString
      const currentTemp = {
        ...current,
        beginCode_templateString: current.beginCode.templateString,
        pauseCode_templateString: current.pauseCode.templateString,
        endCode_templateString: current.endCode.templateString,
        holeSetting_templateString: current.holeSetting.templateString,
        slotSetting_templateString: current.slotSetting.templateString,
        lmnSetting_templateString: current.lmnSetting.templateString,
        YMSetting_templateString: current.YMSetting.cutCode.templateString,
        mtLineCutCode_templateString: current.mtLineCutCode.templateString,
        mtArcCutCode_templateString: current.mtArcCutCode.templateString,
        verticalHoleSetting_templateString:
          current.verticalHoleSetting.templateString,
        verticalSlotSetting_templateString:
          current.verticalSlotSetting.templateString,
      }
      const oriTemp = {
        ...ori,
        beginCode_templateString: ori.beginCode.templateString,
        pauseCode_templateString: ori.pauseCode.templateString,
        endCode_templateString: ori.endCode.templateString,
        holeSetting_templateString: ori.holeSetting.templateString,
        slotSetting_templateString: ori.slotSetting.templateString,
        lmnSetting_templateString: ori.lmnSetting.templateString,
        YMSetting_templateString: ori.YMSetting.cutCode.templateString,
        mtLineCutCode_templateString: ori.mtLineCutCode.templateString,
        mtArcCutCode_templateString: ori.mtArcCutCode.templateString,
        verticalHoleSetting_templateString:
          ori.verticalHoleSetting.templateString,
        verticalSlotSetting_templateString:
          ori.verticalSlotSetting.templateString,
      }
      // 比较form的不同项
      const formChangeArr = compareDiffForm(
        currentTemp,
        oriTemp,
        compareMsg,
        sideHoleFormTextObj,
        sideHoleFormValueObj
      )

      if (formChangeArr.length) {
        record.push(...formChangeArr)
      }

      // 刀用途
      // addOperateTableToRecord(
      //   verticalKnives, // 当前列表
      //   oriverticalKnives, // 原始列表
      //   [
      //     'knifeName',
      //     'diameter',
      //     'slotable',
      //     'millable',
      //     'holeable',
      //     'appoint',
      //     'moveSpeed',
      //     'doSpeed',
      //     'startCode',
      //     'endCode',
      //   ],
      //   operateRecordTableType.SIDE_HOLE_OLD_KNIFE_STORE_TABLE_V3,
      //   '旧版设置-自定义三-刀用途',
      //   record,
      //   'verticalKnives'
      // )
      return record
    },
    compareSideHoleOldSetting(current, ori) {
      const record = []

      // 生成当前list
      const knives = current.knives ? Object.values(current.knives) : []
      const oriKnives = ori.knives ? Object.values(ori.knives) : []
      // 给record添加表格操作记录
      addOperateTableToRecord(
        knives, // 当前列表
        oriKnives, // 原始列表
        [
          'id',
          'diameter',
          'type',
          'moveSpeed',
          'doSpeed',
          'startCode',
          'endCode',
        ],
        operateRecordTableType.SIDE_HOLE_OLD_KNIFE_STORE_TABLE_V1,
        '旧版设置-自定义一、二-刀具库',
        record,
        'id'
      )

      const knivesV3 = Object.values(current.settingV3.knives)
      const oriKnivesV3 = Object.values(ori.settingV3.knives)
      // 给record添加表格操作记录
      addOperateTableToRecord(
        knivesV3, // 当前列表
        oriKnivesV3, // 原始列表
        [
          'knifeName',
          'diameter',
          'slotable',
          'millable',
          'holeable',
          'appoint',
          'moveSpeed',
          'doSpeed',
          'startCode',
          'endCode',
        ],
        operateRecordTableType.SIDE_HOLE_OLD_KNIFE_STORE_TABLE_V3,
        '旧版设置-自定义三-刀具库',
        record,
        'knifeName'
      )

      const knivesV4 = Object.values(current.settingV4.knives)
      const oriKnivesV4 = Object.values(ori.settingV4.knives)
      // 给record添加表格操作记录
      addOperateTableToRecord(
        knivesV4, // 当前列表
        oriKnivesV4, // 原始列表
        ['knifeName', 'diameter', 'startCode', 'endCode'],
        operateRecordTableType.SIDE_HOLE_OLD_KNIFE_STORE_TABLE_V4,
        '旧版设置-自定义四-刀具库',
        record,
        'webId'
      )

      // 自定义一比较
      const compareMsgV1 = [
        {
          title: '旧版设置',
          keys: ['style'],
        },
        {
          title: '旧版设置-自定义一-设备参数设置',
          keys: codeListV1
            .map((code) => code.key)
            .concat(axisListV1.map((axis) => axis.key)),
        },
      ]
      // 自定义二比较
      const compareMsgV2 = [
        {
          title: '旧版设置-自定义二-设备参数设置',
          keys: codeListV2.map((code) => code.key),
        },
        {
          title: '旧版设置-自定义二-打孔设置',
          keys: holeSettingV2.map((code) => code.key),
        },
        {
          title: '旧版设置-自定义二-拉槽设置',
          keys: slotSettingV2.map((code) => code.key),
        },
      ]

      // 自定义三比较
      const compareMsgV3 = [
        {
          title: '旧版设置-自定义三-设备参数设置',
          keys: codeListV3.map((code) => code.key),
        },
        {
          title: '旧版设置-自定义三-打孔设置',
          keys: holeSettingV3.map((code) => code.key),
        },
        {
          title: '旧版设置-自定义三-拉槽设置',
          keys: slotSettingV3
            .map((code) => code.key)
            .concat(sxisListV3.map((axis) => axis.key)),
        },
        {
          title: '旧版设置-自定义三-坐标值',
          keys: ['xPointSymbol', 'yPointSymbol', 'zPointSymbol'],
        },
        {
          title: '旧版设置-自定义三-特殊设置',
          keys: ['lmnSetting'],
        },
      ]

      const compareMsgV4 = [
        {
          title: '旧版设置-自定义四-设备参数设置',
          keys: codeListV4.map((code) => code.key),
        },
        {
          title: '旧版设置-自定义四-打孔设置',
          keys: holeSettingV4
            .map((code) => code.key)
            .concat(['holeEnable', 'holeKnife']),
        },
        {
          title: '旧版设置-自定义四-拉槽设置',
          keys: slotSettingV4
            .map((code) => code.key)
            .concat(['slotEnable', 'slotKnife']),
        },
        {
          title: '旧版设置-自定义四-坐标值',
          keys: ['xPointSymbol', 'yPointSymbol', 'zPointSymbol'],
        },
        {
          title: '旧版设置-自定义四-后缀名',
          keys: ['fileSuffix'],
        },
        {
          title: '旧版设置-自定义四-其他-拉米诺',
          keys: ['lmlSetting'],
        },
        {
          title: '旧版设置-自定义四-其他-榫卯',
          keys: ['mtSetting'],
        },
      ]
      // 比较form的不同项
      const formChangeArrV1 = compareDiffForm(
        current,
        ori,
        compareMsgV1,
        oldSettingFormTextObjV1,
        sideHoleFormValueObj
      )
      const formChangeArrV2 = compareDiffForm(
        current.settingV2,
        ori.settingV2,
        compareMsgV2,
        oldSettingFormTextObjV2,
        sideHoleFormValueObj
      )
      const formChangeArrV3 = compareDiffForm(
        current.settingV3,
        ori.settingV3,
        compareMsgV3,
        oldSettingFormTextObjV3,
        { ...sideHoleFormValueObj, ...sideHoleFormValueObjV3 }
      )
      const formChangeArrV4 = compareDiffForm(
        current.settingV4,
        ori.settingV4,
        compareMsgV4,
        oldSettingFormTextObjV4,
        { ...sideHoleFormValueObj, ...sideHoleFormValueObjV4 }
      )

      if (formChangeArrV1.length) {
        record.push(...formChangeArrV1)
      }
      if (formChangeArrV2.length) {
        record.push(...formChangeArrV2)
      }
      if (formChangeArrV3.length) {
        record.push(...formChangeArrV3)
      }
      if (formChangeArrV4.length) {
        record.push(...formChangeArrV4)
      }

      return record
    },
    // 生成最终的操作记录
    genFinalRecord(saveData) {
      let currentForm = this.dealSideData()
      let oldSettingForm = this.$refs['oldSettingRef'].formData
      const oldSettingRecords = this.compareSideHoleOldSetting(
        oldSettingForm,
        this.oriOldSetting
      )
      const sideHoleRecords = this.compareSideHole(
        currentForm,
        this.oriFormData
      )
      const finalRecord = sideHoleRecords.concat(oldSettingRecords)
      if (finalRecord.length) {
        this.saveDataJsonToOssForRecordFn(
          {
            key: '侧孔机',
            value: finalRecord,
          },
          {
            id: this.$route.query.setting_id,
            operate_equipment: 'sideHoleDrill',
            save_data: saveData,
            setting_id: this.$route.query.setting_id,
            operatorPhone: this.previewInfo.operatorPhone,
          },
          () => {
            if (!this.previewInfo.isPreview) {
              // 重置数据
              this.loadSideHoleMachineSetting()
            }
          }
        )
      }
    },
    // 处理加工数据
    dealSideData() {
      this.saveSettingData.ym_setting = [...this.saveSettingData.slot_setting]
      const sawLabel = {
        hole_setting: 'holeSetting',
        lmn_setting: 'lmnSetting',
        slot_setting: 'slotSetting',
        mt_arc_cut_setting: 'mtArcCutCode',
        mt_line_cut_setting: 'mtLineCutCode',
        mt_slanting_arc_cut_setting: 'mtSlantingArcCutCode',
        mt_slanting_line_cut_setting: 'mtSlantingLineCutCode',
        ym_setting: 'YMSetting',
      }
      const frontLabel = {
        vertical_hole_setting: 'verticalHoleSetting',
        vertical_slot_setting: 'verticalSlotSetting',
      }
      const plank = {
        plank_info: ['beginCode', 'endCode', 'pauseCode'],
      }
      let form = JSON.parse(JSON.stringify(this.$refs['sideRef'].formData))

      let verticalForm = JSON.parse(
        JSON.stringify(this.$refs['frontRef'].formData)
      )

      for (let field in plank) {
        const d = this.saveSettingData[field]
        for (let key of plank[field]) {
          let v = form[key]
          let ids = {}
          d.forEach((item) => {
            if (v.templateString?.includes(`{${item.label_name_show}}`)) {
              ids[item.label_name_show] = item.id
            }
          })
          v.headerIds = ids
        }
      }
      for (let i in sawLabel) {
        let value = form[sawLabel[i]]
        let headsId = {}
        this.saveSettingData[i].forEach((item) => {
          if (i == 'ym_setting') {
            if (
              value.cutCode.templateString.indexOf(
                '{' + item.label_name_show + '}'
              ) != -1
            ) {
              headsId[item.label_name_show] = item.id
            }
          } else {
            if (
              value.templateString.indexOf('{' + item.label_name_show + '}') !=
              -1
            ) {
              headsId[item.label_name_show] = item.id
            }
          }
        })
        i == 'ym_setting'
          ? (value.cutCode.headerIds = headsId)
          : (value.headerIds = headsId)
      }
      for (let i in frontLabel) {
        let value = verticalForm[frontLabel[i]]
        let headsId = {}
        this.saveSettingData[i].forEach((item) => {
          if (
            value.templateString.indexOf('{' + item.label_name_show + '}') != -1
          ) {
            headsId[item.label_name_show] = item.id
          }
        })
        value.headerIds = headsId
      }
      let knife = {}
      let verticalKnives = {}
      form.knives.forEach((item) => {
        if (item.id) {
          knife[item.id] = {
            id: item.id,
            diameter: item.diameter,
            startCode: item.startCode,
            endCode: item.endCode,
            xOffset: item.xOffset,
            yOffset: item.yOffset,
            zOffset: item.zOffset,
            webId: item.webId ? item.webId : '',
          }
        }
      })

      verticalForm.verticalKnives.forEach((item) => {
        if (item.id) {
          verticalKnives[item.id] = {
            id: item.id,
            diameter: item.diameter,
            x_offset: item.x_offset,
            y_offset: item.y_offset,
            z_offset: item.z_offset,
            startCode: item.startCode,
            endCode: item.endCode,
            webId: item.webId ? item.webId : '',
            knifePurpose: item.knifePurpose,
            holeAble: item.holeAble,
            slotAble: item.slotAble,
            stretchAble: item.stretchAble,
            hornAble: item.hornAble,
          }
        }
      })

      form.knives = knife
      const verticalSettingObj = {
        verticalHoleSetting: verticalForm.verticalHoleSetting,
        verticalSlotSetting: verticalForm.verticalSlotSetting,
        split_long_slot: verticalForm.split_long_slot,
        second_slot_length: Number(verticalForm.second_slot_length),
        verticalEnable: verticalForm.verticalEnable,
        verticalKnives,
        verticalRetractionCode: verticalForm.verticalRetractionCode,
      }
      form.guigui_side_drill_enabled = this.$refs['oldSettingRef'].isOn
      const result = Object.assign(form, verticalSettingObj)

      return result
    },
    saveSideHole() {
      // 判断锁定状态，打开弹窗
      // 根据获取的状态 锁定状态
      this.lockStatus = this.tempStatus
      this.ableSaveSetting = false
      this.$store.commit('setCurrentProductLineName', this.$route.query.line)
      const data = {
        // TODO 这是旧版保存接口数据
        setting_id: this.setting_id,
        ...store.state.saveInfo,
        platform: 'bluen',
        data: {
          side_drill_setting: this.dealSideData(),
          guigui_side_drill_setting: this.$refs['oldSettingRef'].formData,
        },
      }
      this.$token(
        '/save_side_hole_machine_setting',
        // TODO 这是旧版保存接口数据
        data,
        (res) => {
          this.ableSaveSetting = true
          if (res.status == 1) {
            this.isShowVisible = false
            try {
              this.genFinalRecord(data)
            } catch (error) {
              console.error('error', error)
            }
            if (res.data.phone && res.data.request_ip) {
              let recordData = {
                key: '保存设置',
                operateArr: [
                  {
                    key: '生产线设置',
                    dataArr: [
                      {
                        type: 'form',
                        current: {
                          phone: res.data.phone,
                          time: new Date().toLocaleString(),
                          id: store.state.userInfo.id,
                          ip: res.data.request_ip,
                        },
                        ori: {
                          phone: '',
                          time: '',
                          id: '',
                          ip: '',
                        },
                        compareMsg: [
                          {
                            title: '',
                            keys: ['phone', 'time', 'id', 'ip'],
                          },
                        ],
                        formTextObj: {
                          phone: '手机号',
                          time: '保存时间',
                          id: '用户id',
                          ip: '物理地址',
                        },
                        formValueObj: {},
                      },
                    ],
                  },
                ],
              }
              store.commit('setSaveInfo', {})
              genFinalRecord(recordData, 'equipment_docking', '', () => {})
            }
            this.$message({
              message: translate('common.saveSuccess'),
              duration: 1500,
              type: 'success',
            })
          } else if (res.status == -1) {
            if (store.state.saveInfo.phone) {
              this.$message({
                message: res.msg,
                type: 'error',
                duration: 1500,
                offset: 70,
              })
            }
            this.showSaveModal()
          } else if (res.status === 0) {
            this.$message({
              message: res.msg,
              type: 'error',
              duration: 1500,
              offset: 70,
            })
          }
        }
      )
    },
    showSaveModal() {
      this.lockStatus = this.tempStatus
      this.verifyPassword = true
      this.isShowVisible = true
      return
    },
    goBackPage() {
      let path = '/equipment'
      if (this.previewInfo.isPreview) {
        path = `${path}-preview`
      }

      this.$router.push(path)
    },
    // 补充 headerIds templateString字段
    supplementDataField(data, keys) {
      keys.forEach((k) => {
        if (typeof data[k] === 'string') {
          data[k] = {}
        }
        !data[k].headerIds && (data[k].headerIds = {})
        !data[k].templateString && (data[k].templateString = '')
      })
    },

    loadSideHoleMachineSetting() {
      this.$token(
        '/load_side_hole_machine_setting',
        {
          setting_id: this.setting_id,
        },
        (res) => {
          this.dealSideHoleSetting(res)
          this.oriFormData = JSON.parse(JSON.stringify(this.formData))
          // 后台应用生产线时进入
          if (this.previewProductLine?.side_hole) {
            this.dealSideHoleSetting(
              {
                data: this.previewProductLine?.side_hole,
              },
              true
            )
          }
        }
      )
    },
    handleGetLockStatus() {
      //TODO 进入页面，获取生产线设置锁的状态
      getLockStatus().then((res) => {
        // 拿到的锁状态赋值，当前是锁定还是解锁状态
        this.lockStatus = res?.data?.is_lock || 0
        this.tempStatus = res?.data?.is_lock || 0
      })
    },
    // 打开修改记录弹窗
    handleOpenChangeRecord() {
      this.showRecordModal = true
    },
    // 还原成功的回调
    handleSuccess(saveData) {
      let currentForm = saveData.data.side_drill_setting
      let oldSettingForm = saveData.data.guigui_side_drill_setting
      const oldSettingRecords = this.compareSideHoleOldSetting(
        oldSettingForm,
        this.oriOldSetting
      )
      const sideHoleRecords = this.compareSideHole(
        currentForm,
        this.oriFormData
      )
      const finalRecord = sideHoleRecords.concat(oldSettingRecords)
      if (finalRecord.length) {
        this.saveDataJsonToOssForRecordFn(
          {
            key: '侧孔机',
            value: finalRecord,
          },
          {
            id: this.$route.query.setting_id,
            operate_equipment: 'sideHoleDrill',
            save_data: saveData,
            setting_id: this.$route.query.setting_id,
          },
          () => {
            // 重置数据
            this.loadSideHoleMachineSetting()
            // 记录生产成功后，重新获取修改记录
            this.$refs['changeRecordModalRef'].getData({ isReset: true })
          }
        )
      }
    },

    // 侧孔机设置处理
    dealSideHoleSetting(res, isApply = false) {
      this.ableSaveSetting = true
      let knives = []
      let verticalKnives = []
      let data = res.data.side_drill_setting
      // 将属性中缺失的字段补齐
      this.supplementDataField(data, needSupplementField)
      this.oldSetting = res.data.guigui_side_drill_setting

      // 给自定义四侧孔机刀具增加webId
      res.data.guigui_side_drill_setting.settingV4.knives = addIdForItems(
        res.data.guigui_side_drill_setting.settingV4.knives
      )
      // 如果是要应用别人的设置时，就需要把oriOldSetting的值设为这个用户自己的
      if (!isApply) {
        this.oriOldSetting = JSON.parse(
          JSON.stringify(res.data.guigui_side_drill_setting)
        )
      }
      for (let i in data.knives) {
        knives.push(data.knives[i])
      }
      for (let i in data.verticalKnives) {
        verticalKnives.push(data.verticalKnives[i])
      }
      data.verticalKnives = verticalKnives
      // 给每个刀具添加唯一webId
      data.knives = addIdForItems(knives)
      data.verticalKnives = addIdForItems(verticalKnives)
      this.formData = data
      if (!(this.formData.beginCode instanceof Object)) {
        this.formData.beginCode = {
          headerIds: {},
          templateString: res.data.beginCode,
        }
      }
      if (!(this.formData.endCode instanceof Object)) {
        this.formData.endCode = {
          headerIds: {},
          templateString: res.data.endCode,
        }
      }
      if (!(this.formData.pauseCode instanceof Object)) {
        this.formData.pauseCode = {
          headerIds: {},
          templateString: res.data.pauseCode,
        }
      }
    },
  },
  async mounted() {
    this.handleGetLockStatus()
    this.setting_id = this.$route.query.setting_id
    this.recordKnivesNum = 1
    await this.$getByToken(
      '/get_electronic_saw_label?label_class=sideHoleMachine',
      {},
      (res) => {
        let data = res.data
        data.mt_slanting_line_cut_setting = data.mt_line_cut_setting.map(
          (it) => ({ ...it, label_type: 'mt_slanting_line_cut_setting' })
        )
        data.mt_slanting_arc_cut_setting = data.mt_arc_cut_setting.map(
          (it) => ({ ...it, label_type: 'mt_slanting_arc_cut_setting' })
        )
        this.saveSettingData = data
        let settingData = {
          hole_setting: [],
          lmn_setting: [],
          slot_setting: [],
          vertical_hole_setting: [],
          vertical_slot_setting: [],
          mt_arc_cut_setting: [],
          mt_line_cut_setting: [],
          ym_setting: [],
          mt_slanting_line_cut_setting: [],
          mt_slanting_arc_cut_setting: [],
        }
        for (let i in data) {
          let coorArr = []
          let other = []
          data[i].forEach((item) => {
            if (item.label_name.indexOf('Coord') != -1) {
              coorArr.push(item)
            } else {
              other.push(item)
            }
          })
          settingData[i] = [coorArr, other]
        }
        settingData.plank_info = [data.plank_info]
        settingData.mt_arc_cut_setting = data.mt_arc_cut_setting
        settingData.mt_line_cut_setting = data.mt_line_cut_setting
        settingData.mt_slanting_line_cut_setting =
          data.mt_slanting_line_cut_setting
        settingData.mt_slanting_arc_cut_setting =
          data.mt_slanting_arc_cut_setting
        this.settingData = settingData
      }
    )
    // 获取设置数据
    this.loadSideHoleMachineSetting()
    // this.saveKnifeId = ['T1']
  },
  watch: {},

  components: {
    breadcrumb,
    SideWorking,
    FrontWorking,
    OldSetting,
    MLockModal,
    MChangeRecordModal,
  },
}
</script>

<style lang="less" scoped>
@import '~@/style/engraving.less';
.sideHole_wapper {
  // min-width: 1701px;
  width: 100%;
  .top-nav {
    .nav-item {
      display: inline-block;
      box-sizing: border-box;
      margin-right: 16px;
      padding: 13px 0;
      color: rgba(0, 0, 0, 0.8);
      font-size: 16px;
      cursor: pointer;
    }
    .active-nav {
      box-sizing: border-box;
      color: #18a8c7;
      border-bottom: 3px solid #18a8c7;
    }
  }
  .save-box.ant-btn {
    margin-bottom: 10px;
  }
}

.right {
  width: 50%;

  .tag_title {
    padding: 10px 20px;
    background: #f0f0f0;

    span:nth-child(1) {
      font-weight: 600;
      font-size: 20px;
    }

    span:nth-child(2) {
      color: #7b7b7b;
    }
  }

  .tag_warp {
    padding: 20px;
    border: 1px solid #f0f0f0;
    border-top: none;

    > span {
      display: inline-block;
      margin-right: 20px;
      padding: 5px 20px;
      background-color: #eaf4f6;
      cursor: pointer;
    }
  }
}
</style>
