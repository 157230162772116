import { allTableHeaderList } from '@/components/materialTable/src/config/tableConfig'
import { AwaitStoreImageSize } from '@/data/constant'
import type {
  IBigPlank,
  OutlineKeys,
  PartType,
  PlankTexture,
  PointType,
  PointUpperCase,
} from '@/partTypes'
import store from '@/store'
import { IRootState } from '@/store/types'
import { rolloverPlank, specialSymbol } from '@/util/LayoutFuncs'
import { DocHoleSlot } from '@/util/tag/pdf-draw'
import { convert } from 'encoding'
import FileSaver from 'file-saver'
import Pdf, { TextOptionsLight } from 'jspdf'
// import { cloneDeep } from 'lodash'
import { nanoid } from 'nanoid'
import Vue from 'vue'
import XLSX from 'xlsx'

import { dealExpandSlot, getSlotPositionInfo } from './LayoutTool'
import { getArrayItem, isSamePart, toDecimal } from './commonFuncs'
import {
  checkOnePlank,
  dealBigPlank,
  dealPlankPoly,
  getPlateKnifeDiameter,
} from './dealPaibanData'
import { calcArea, judgeRectIntersect } from './graphCalc'
import { polyContain } from './polygonRelation'

type objToKey<T> = keyof T
// 处理前封边和开门在板件变化的处理
const OPENORFORMERMAP = {
  top: 'bottom',
  bottom: 'top',
  '↑': '↓',
  '↓': '↑',
}
const FORMERLEFTRIGHTTYPE = {
  left: 'right',
  right: 'left',
  '←': '→',
  '→': '←',
}
const FORMERTYPE = {
  doorOpenSide: ['left', 'bottom', 'right', 'top'],
  former: ['←', '↓', '→', '↑'],
}
const textConfig: TextOptionsLight = {
  baseline: 'bottom',
  align: 'center',
}
const TEXTGAP = 4
// 处理封边一类的信息在板件变化时的处理
const edgeInfoStrategies = {
  xyReverse: (edge: string[]) => {
    return `←${edge[3]}↓${edge[2]}→${edge[1]}↑${edge[0]}`
  },
  rotate90: (edge: string[]) => {
    return `←${edge[1]}↓${edge[2]}→${edge[3]}↑${edge[0]}`
  },
  rotate90F: (edge: string[]) => {
    return `←${edge[3]}↓${edge[0]}→${edge[1]}↑${edge[2]}`
  },
  rotate180: (edge: string[]) => {
    return `←${edge[2]}↓${edge[3]}→${edge[0]}↑${edge[1]}`
  },
  upDownFlip: (edge: string[]) => {
    return `←${edge[0]}↓${edge[3]}→${edge[2]}↑${edge[1]}`
  },
  leftRightFlip: (edge: string[]) => {
    return `←${edge[2]}↓${edge[1]}→${edge[0]}↑${edge[3]}`
  },
}
let OTHERPLATE: any
export function dealEdgeInfoChange(
  edgeInfo: string,
  strategies: objToKey<typeof edgeInfoStrategies>
) {
  if (!edgeInfo) return
  const edge = edgeInfo.split(/←|↓|→|↑/).filter((it) => it)
  if (!edge.length) return
  const edgeResult = edgeInfoStrategies[strategies](edge)
  return edgeResult.replace('undefined', '0')
}

// 前封边/开门方向信息修改
const formerStrategies = {
  rotate90: (arr: string[], index: number) => {
    return getArrayItem(arr, index, -1)
  },
  rotate90F: (arr: string[], index: number) => {
    return getArrayItem(arr, index, 1)
  },
  rotate180: (arr: string[], index: number) => {
    return getArrayItem(arr, index, -2)
  },
  upDownFlip: (sign: objToKey<typeof OPENORFORMERMAP>) => {
    return { res: OPENORFORMERMAP[sign] ?? sign }
  },
  leftRightFlip: (sign: objToKey<typeof FORMERLEFTRIGHTTYPE>) => {
    return { res: FORMERLEFTRIGHTTYPE[sign] ?? sign }
  },
  partFlip: <T, K extends keyof T>(tag: T, sign: K) => {
    return { res: tag[sign] ?? sign }
  },
}
const initSymbol = [
  'STRENTCHTWO',
  'MORTISE',
  'CCBTSlot',
  'noSymbol',
  'lightSlot',
  'normalSlot',
  'gratingSlot',
  'BP',
  'CCSlot',
  'exSlot', // 异形编辑的方形孔
]
export function dealFormerEdgeChange(
  sign: objToKey<typeof OPENORFORMERMAP> | objToKey<typeof FORMERLEFTRIGHTTYPE>,
  strategies: Exclude<objToKey<typeof formerStrategies>, 'partFlip'>,
  type: objToKey<typeof FORMERTYPE>
) {
  if (!sign) return
  const dirArr = FORMERTYPE[type]
  if (!dirArr) return
  const idx = dirArr.findIndex((it) => it == sign)
  let result = null
  if (strategies === 'upDownFlip' || strategies === 'leftRightFlip') {
    const target =
      strategies === 'upDownFlip' ? OPENORFORMERMAP : FORMERLEFTRIGHTTYPE
    result = formerStrategies?.['partFlip']?.(
      target,
      sign as objToKey<typeof target>
    )
  } else {
    result = formerStrategies?.[strategies]?.(dirArr, idx)
  }
  return result?.res
}

export function dealSurplusSize(plank: any) {
  // 如果是L形的余料, 则将点位顺时针排列, 找出转折点
  // 转折点前面的两个点的距离为余料的短边宽度, 后两个点位余料的短边长度
  // 判断是否为顺时针点位
  const path = plank.surplusPath[0]

  // 找出转折点
  const point = path.find(
    (v: any) =>
      v.x < plank.rect.width && v.y < plank.rect.height && v.x > 0 && v.y > 0
  )

  // 判断短边方向

  // 原点0.0
  let hasOpoint = false
  // 左下角的点
  let hasHpoint = false
  // 右上角的点
  let hasWpoint = false
  path.forEach((v: { x: number; y: number }) => {
    if (v.x == 0 && v.y == 0) {
      hasOpoint = true
    }
    if (v.x == 0 && v.y == plank.rect.height) {
      hasHpoint = true
    }
    if (v.x == plank.rect.width && v.y == 0) {
      hasWpoint = true
    }
  })
  const isLeft = hasHpoint && hasOpoint
  const isTop = hasOpoint && hasWpoint
  const stockKey = `${plank.texture}:${plank.matCode}:${plank.thick}`
  const d = getPlateKnifeDiameter(stockKey, store.state.ncSetting)
  // 通过转折点, 找出裁剪的余料板件的短边长度和短边宽度
  const min_width = point
    ? isLeft
      ? point.x - d
      : plank.rect.width - point.x - d
    : 0
  const min_long = point
    ? isTop
      ? point.y - d
      : plank.rect.height - point.y - d
    : 0
  const width = plank.oRect.width
  const long = plank.oRect.height
  return {
    min_width: Number(min_width.toFixed(2)),
    min_long: Number(min_long.toFixed(2)),
    width: Number(Number(width).toFixed(2)),
    long: Number(Number(long).toFixed(2)),
  }
}

// 生成单个板件孔槽图
export const PxToPt = (fontSize: number) => fontSize * 0.75
export class GenerateHoleSlotPdf {
  pdf: InstanceType<typeof Pdf>
  constructor(...config: ConstructorParameters<typeof Pdf>) {
    // 创建pdf实例
    this.pdf = new Pdf(...config)
  }
  generate(part: PartType, typeTow?: string) {
    if (!part) return
    part = JSON.parse(JSON.stringify(part))
    // 获取要使用的对象
    const { oRect, partName } = part
    // 解决乱码，添加中文字体
    this.pdf.setFont('msyh')
    this.pdf.setTextColor(0)
    // 获取纸张宽度/高度
    const { width: pageWidth, height: pageHeight } = this.pdf.internal.pageSize

    // 定义需要使用的基本值
    const xBase = typeTow ? pageWidth * 0.18 : pageWidth * (0.1 - 0.0176)
    const yBase = pageWidth * 0.1
    const widthBase = typeTow ? pageWidth * 0.32 : pageWidth * 0.41
    const heightBase = pageHeight * 0.77
    const gap = pageWidth * 0.0176
    // 宽高比
    const ratio = Math.min(widthBase / oRect.width, heightBase / oRect.height)

    // 板件绘制宽高
    const drawWidth = oRect.width * ratio
    const drawHeight = oRect.height * ratio

    // 正面绘制起始位置
    let frontXBase = xBase,
      frontYBase = yBase
    // 反面绘制起始位置
    let oppositeXBase = xBase + widthBase + gap,
      oppositeYBase = yBase
    // 绘制位置尽量居中
    if (drawWidth < widthBase) {
      const w = (widthBase - drawWidth) / 2
      ;(frontXBase += w), (oppositeXBase += w)
    }
    if (drawHeight < heightBase) {
      const h = (heightBase - drawHeight) / 2
      ;(frontYBase += h), (oppositeYBase += h)
    }
    // 绘制标题
    this.pdf.setFontSize(PxToPt(20))
    textConfig.baseline = 'bottom'
    this.pdf.text(
      `${partName}(${oRect.width}×${oRect.height})`,
      pageWidth / 2,
      8,
      textConfig
    )
    // 正面文字
    const textCenterWidth = drawWidth / 2
    this.pdf.text(
      '正面',
      frontXBase + textCenterWidth,
      frontYBase - gap,
      textConfig
    )
    // 反面文字
    this.pdf.text(
      '反面',
      oppositeXBase + textCenterWidth,
      oppositeYBase - gap,
      textConfig
    )
    // 绘制正面板
    this.drawHoleSlot(part, frontXBase, frontYBase, ratio, 'front', typeTow)
    // 绘制反面板
    this.drawHoleSlot(
      part,
      oppositeXBase,
      oppositeYBase,
      ratio,
      'opposite',
      typeTow
    )
  }
  private drawHoleSlot(
    part: PartType,
    startX: number,
    startY: number,
    ratio: number,
    type: 'front' | 'opposite',
    typeTow?: string
  ) {
    if (type === 'opposite') {
      part = JSON.parse(JSON.stringify(part))
      rolloverPlank(part)
    }
    const {
      holes,
      slots,
      oRect,
      oPath,
      curveHoles = [],
      millInfo = [],
      thick,
    } = part
    const { width: pageWidth } = this.pdf.internal.pageSize
    const xBaseFront = pageWidth * 0.02
    const xBaseOpposite = pageWidth * 0.9
    let drawHole = []
    let drawSlot = []
    let curveHole = []
    let millInfoSlot = []
    let spacing = 10
    let amountFront = 0
    let amountOpposite = 0
    // 板件进行翻面后反面孔槽也会成为正面，所以统一取正面孔槽
    drawHole = holes.filter((it) => it.deep >= thick || it.side > 0)
    drawSlot = slots.filter((it) => it.deep >= thick || it.side > 0)
    curveHole = curveHoles.filter((it) => it.deep >= thick || it.side > 0)
    millInfoSlot = millInfo.filter((it) => it.depth >= thick || it.side > 0)
    let mainShape
    const shapes: PointType[][] = []
    if (oPath) {
      let cHoles: PointType[][] = []
      let mSlots: PointType[][] = []
      if (curveHoles.length) {
        cHoles = curveHole.map((it) => it.path)
      }
      if (millInfo.length) {
        mSlots = millInfoSlot.map((it) => it.shape)
      }
      const resPath = [...oPath, ...cHoles, ...mSlots]
      resPath.forEach((it, idx) => {
        const pointArr = it.map((p) => {
          return {
            x: p.x * ratio,
            y: p.y * ratio,
          }
        })
        pointArr[pointArr.length] = { ...pointArr[0] }
        if (idx === 0) {
          mainShape = pointArr
        } else {
          shapes.push(pointArr)
        }
      })
    } else {
      const x = 0
      const y = 0
      const width = oRect.width * ratio
      const height = oRect.height * ratio
      mainShape = { x, y, width, height }
    }
    // 处理绘制孔
    drawHole = drawHole.map((it, idx) => {
      const { diameter, ocenter: center } = it
      const x = center.x * ratio
      const y = center.y * ratio
      const radius = (diameter * ratio) / 2
      return [{ x, y, radius, type: 'hole', holeData: it, idx }]
    })

    drawHole.sort(customSort)

    // 处理绘制槽
    drawSlot = drawSlot.map((it, idx) => {
      const { opt1: pt1, opt2: pt2 } = it
      const slot = {} as any
      let dir
      // 竖拉槽
      if (pt1.x == pt2.x) {
        slot.width = it.width
        slot.x = Math.min(pt1.x, pt2.x) - it.width / 2
        slot.long = Math.abs(pt1.y - pt2.y)
        slot.y = pt1.y > pt2.y ? pt2.y : pt1.y
        dir = 'h'
      }
      // 横拉槽
      if (pt1.y == pt2.y) {
        slot.width = Math.abs(pt1.x - pt2.x)
        slot.y = Math.min(pt1.y, pt2.y) - it.width / 2
        slot.long = it.width
        slot.x = pt1.x > pt2.x ? pt2.x : pt1.x
        dir = 'v'
      }
      const x = slot.x * ratio
      const y = slot.y * ratio
      const width = slot.width * ratio
      const height = slot.long * ratio
      return { x, y, width, height, type: 'slot', slotData: it, idx, dir }
    })
    const holeSlotPath = [...shapes, ...drawHole, ...drawSlot]
    const holeSlot = new DocHoleSlot(mainShape, holeSlotPath, startX, startY)
    // 传字符串255有错，所以这样写
    this.pdf.setFillColor(255 as any)
    // 收集所有文字绘制，完成图形绘制后再绘制文字防止文字绘制被遮挡
    const collectText: any[] = []
    holeSlot.drawHole(this.pdf, drawOtherInfo, true)
    function drawOtherInfo(carrier: InstanceType<typeof Pdf>, shape: any) {
      carrier.setFontSize(PxToPt(10))
      const { type } = shape
      if (!type) return
      if (type === 'hole') {
        const {
          holeData: { ocenter, diameter },
          x,
          y,
          radius,
        } = shape
        const d = y + radius + TEXTGAP
        const t = `${diameter}孔(${+ocenter.x.toFixed(1) * 1},${
          +ocenter.y.toFixed(1) * 1
        })`
        collectText.push({ text: t, x, y: d, config: textConfig })
      } else {
        const {
          slotData: { opt1, opt2, deep, width },
          dir: showDir,
        } = shape
        const [minx, miny, maxx, maxy] = shape.reduce(
          (prev: any, it: any) => {
            return [
              Math.min(prev[0], it.x),
              Math.min(prev[1], it.y),
              Math.max(prev[2], it.x),
              Math.max(prev[3], it.y),
            ]
          },
          [Infinity, Infinity, -Infinity, -Infinity]
        )
        const w = maxx - minx
        const h = maxy - miny
        // 按照宽度定位位置 showDir用于计算
        const dir = w < h ? 'h' : 'v'
        // 获取槽的 上 中 下，三个位置
        let top = { x: minx + w / 2, y: miny - TEXTGAP }
        let center = { x: minx + w / 2, y: miny + h / 2 }
        let bottom = { x: minx + w / 2, y: maxy + TEXTGAP }
        if (dir === 'v') {
          top = { x: minx - TEXTGAP, y: miny + h / 2 }
          center = { x: minx + w / 2, y: miny + h / 2 }
          bottom = { x: maxx + TEXTGAP, y: miny + h / 2 }
        }
        let [pt1, pt2] = [opt1, opt2].sort((a, b) =>
          dir === 'v' ? a.x - b.x : a.y - b.y
        )
        pt1 = { x: +pt1.x.toFixed(1) * 1, y: +pt1.y.toFixed(1) * 1 }
        pt2 = { x: +pt2.x.toFixed(1) * 1, y: +pt2.y.toFixed(1) * 1 }
        const topV = `(${pt1.x},${pt1.y})`
        const bottomV = `(${pt2.x},${pt2.y})`
        const slotSize = pt1.x == pt2.x ? pt1.y - pt2.y : pt1.x - pt2.x
        const [sw, sh] = [slotSize, width]
          .sort((a, b) => a - b)
          .map((it) => +Math.abs(Number(it)).toFixed(1) * 1)
        const centerV = `槽(${+deep.toFixed(1) * 1}x${sw}x${sh})`
        textConfig.baseline = 'middle'
        collectText.push({ text: topV, x: top.x, y: top.y, config: textConfig })
        collectText.push({
          text: centerV,
          x: center.x,
          y: center.y,
          config: textConfig,
        })
        collectText.push({
          text: bottomV,
          x: bottom.x,
          y: bottom.y,
          config: textConfig,
        })
      }
    }
    this.pdf.setTextColor(255, 0, 0)
    // 绘制所有文字
    if (typeTow) {
      collectText.forEach((it, index) => {
        this.pdf.text(String(index + 1), it.x, it.y - 1.5, it.config)
      })
      if (type === 'front') {
        collectText.forEach((it, index) => {
          if (spacing > 200) {
            amountFront += 1
            spacing = 10
          }
          this.pdf.text(
            String(index + 1) + '：' + it.text,
            amountFront === 0 ? xBaseFront : xBaseFront + 30 * amountFront,
            spacing,
            {
              align: 'left',
              baseline: 'middle',
              isInputVisual: true,
            }
          )
          spacing += 10
        })
      } else {
        collectText.forEach((it, index) => {
          if (spacing > 200) {
            amountOpposite += 1
            spacing = 10
          }
          this.pdf.text(
            String(index + 1) + '：' + it.text,
            amountOpposite === 0
              ? xBaseOpposite
              : xBaseOpposite - 30 * amountOpposite,
            spacing,
            {
              align: 'left',
              baseline: 'middle',
              isInputVisual: true,
            }
          )
          spacing += 10
        })
      }
    } else {
      collectText.forEach((it) => {
        this.pdf.text(it.text, it.x, it.y, it.config)
      })
    }
    this.pdf.setTextColor(0)
  }
  save(fileName: string) {
    this.pdf.save(`${fileName}.pdf`)
  }
}
/**
 *
 * @param {Array[][]} fileDataList 数据对象
 * @param {string}  fileName 文件名
 * @param {string?} encoding 文件内容编码格式
 * @param {string?} originFormat 文件原编码格式
 */
export function downloadCsvFile(
  fileDataList: string[][],
  fileName: string,
  encoding = 'GBK',
  originFormat = 'UTF8'
) {
  // let getFlie = XLSX.utils.aoa_to_sheet(fileDataList)
  // // 设置单元格的样式
  // for (let key in getFlie) {
  //   // 遍历出来每一个单元格
  //   // 进行样式设置
  //   if (getFlie[key] instanceof Object) {
  //     // 设置标题的样式
  //     if (key.charAt(1) == '3') {
  //       getFlie[key].s = {
  //         // font: {
  //         //   sz: 16,
  //         //   bold: true,
  //         //   color: {
  //         //     rgb: '0000FF',
  //         //   },
  //         // },
  //         // 边框
  //         // border: {
  //         //   top: {
  //         //     style: 'thin',
  //         //   },
  //         //   bottom: {
  //         //     style: 'thin',
  //         //   },
  //         //   left: {
  //         //     style: 'thin',
  //         //   },
  //         //   right: {
  //         //     style: 'thin',
  //         //   },
  //         // },
  //         // 居中方式
  //         // alignment: {
  //         //   horizontal: 'center', // 水平居中
  //         //   vertical: 'center', // 垂直居中
  //         // },
  //         // 设置背景颜色
  //         fill: {
  //           bgColor: { indexed: 64 }, // 设置背景色
  //           fgColor: { rgb: 'ebebeb' }, // 设置前景色 两个必须设置 少设置一个都没有效果
  //         },
  //       }
  //     }
  //   }
  // }

  // // 生成工作蒲yarn
  // let createBook = XLSX.utils.book_new()
  // XLSX.utils.book_append_sheet(createBook, getFlie, 'sheet1')
  // // 导出Excel, 注意这里用到的是XLSXS对象 是 xlsx-style 否则样式则无效(居中, 边框,字体)
  // let createBookOut = XLSXStyle.write(createBook, {
  //   bookType: 'xlsx',
  //   bookSST: false,
  //   type: 'binary',
  // })
  // fileDataList = fileDataList.map((fileItem) => {
  //   return fileItem.map((item) => {
  //     if (item && item.length > 12) {
  //       return '\t' + item.toString()
  //     } else return item
  //   })
  // })
  const csvData = XLSX.utils.sheet_to_csv(XLSX.utils.aoa_to_sheet(fileDataList))
  const GBKUnit8string = convert(csvData, encoding, originFormat)
  const blob = new Blob([GBKUnit8string], {
    type: `text/csv;charset=${encoding};`,
  })
  FileSaver.saveAs(
    blob,
    `${fileName ? fileName : new Date().toLocaleString()}.csv`
  )
  // FileSaver.saveAs(
  //   new Blob([s2ab(createBookOut)], {
  //     type: 'application/octet-stream',
  //   }),
  //   `${fileName ? fileName : new Date().toLocaleString()}.xlsx` // 文件名
  // )
}
// 设置这个编码格式的 工具方法
function s2ab(s: any) {
  const buf = new ArrayBuffer(s.length)
  const view = new Uint8Array(buf)
  for (let i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xff
  return buf
}
/**
 * 将对象属性值清空
 * @param {Object} obj 需要清除对象
 * @param {Object} specialKeys 对象中需要特殊赋予清除值的属性
 * @returns object
 */
export function clearObjAttr<T extends object>(
  obj: T,
  specialKeys: Partial<{ [P in keyof T]: any }> = {}
) {
  const newObj: T = {} as T
  const keys = Object.keys(obj) as (keyof T)[]
  if (!keys.length) return newObj
  keys.forEach((k) => {
    if (obj[k] && typeof obj[k] === 'object') {
      if (Array.isArray(obj[k])) newObj[k] = [] as any
      else {
        newObj[k] = clearObjAttr(obj[k] as any, specialKeys) as any
      }
    } else {
      newObj[k] = specialKeys[k] ?? ('' as any)
    }
  })
  return newObj
}
/**
 * 处理excel数据
 * @param data excel文件内数据
 * @param tableHeadName 表头name 唯一的标识
 * @param instance 组件实例 this 用于更新某些值
 */
export function dealExcelManifest(
  data: any[],
  tableHeadName: string,
  instance?: InstanceType<typeof Vue>
) {
  switch (tableHeadName) {
    case '补件拆单表':
      data = dealSupplementsManifest(data, tableHeadName, instance)
      break
    // case 'sheet1':
    //   data = dealSupplementsManifestSheet(data, tableHeadName)
    //   break
  }
  return data
}
/**
 * 处理拆单表
 * @param data excel表格数据
 */
const samplePropKey = {
  项目地址: 'address',
  工厂单号: 'factoryOrderNum',
  客户名称: 'clientData',
  订单备注: 'orderRemark',
} as const
// 特殊的分割key用于分割补件清单的料单数据以及其他数据
const splitKeys = ['板号', '房间', '柜体', '板件类型', '开料纹理', '封边']
function dealSupplementsManifest(
  data: any[],
  head: string,
  instance?: InstanceType<typeof Vue>
) {
  const sampleObj = { ...store.state.sampleTableData }
  // 获取需要分割的位置
  const splitIdx = data.findIndex((it) => {
    const vals = Object.values(it) as string[]
    return splitKeys.every((k: any) => vals.some((it) => it === k))
  })
  // 分割数据
  const headData = data.slice(0, splitIdx)
  const manifestData = data.slice(splitIdx + 1)
  // 处理表头的数据
  const dealHeadRowData = (row: any) => {
    const [key, value]: [keyof typeof samplePropKey, string] = row[head]
      .split(':')
      .filter((v: any) => v)
      .map((v: any) => v.trim())
    if (samplePropKey[key]) {
      sampleObj[samplePropKey[key]] = value
    }
  }
  // 处理内容数据
  const partData = [] as any
  const dealManifestRowData = (row: any) => {
    // debugger
    // 获取每一个数据 每一个位置对应什么数据可打开一个柜柜的补件拆单表查看
    const vals = Object.values(row) as string[]
    // 封边信息
    const edge = (vals[10] ?? '').split(/左|下|右|上/).filter((v) => v)
    const obj = {
      面积: '',
      名称: `${vals[1].trim() ? `${vals[1]}_` : ''}${
        vals[2].trim() ? `${vals[2]}_` : ''
      }${vals[3] ?? ''}`,
      宽度: vals[8] ?? '',
      长度: vals[7] ?? '',
      数量: vals[9] ?? '',
      材质: vals[4] ?? '',
      板号: vals[0] ?? '',
      纹理: vals[6] ?? '',
      颜色: vals[5] ?? '',
      备注: vals[13] ?? '',
      左封边: edge[0] ?? 0,
      后封边: edge[1] ?? 0,
      右封边: edge[2] ?? 0,
      前封边: edge[3] ?? 0,
    }
    obj['材质'] = obj['材质'] + ''
    partData.push(obj)
  }
  headData.forEach((row) => {
    dealHeadRowData(row)
  })
  manifestData.forEach((row) => {
    dealManifestRowData(row)
  })
  // 更新样表数据
  store.commit('setSampleTableData', sampleObj)
  // 更新组件的数据
  if (instance) {
    instance.$data.samPleFormData = sampleObj
  }
  return partData
}
function dealSupplementsManifestSheet(data: any[], head: string) {
  const sampleObj = { ...store.state.sampleTableData }
  // 获取需要分割的位置
  // const splitIdx = data.findIndex((it) => {
  //   const vals = Object.values(it) as string[]
  //   return splitKeys.every((k: any) => vals.some((it) => it === k))
  // })

  // 分割数据
  const headData = data.slice(0, 1)
  const manifestData = data.slice(1 + 1)
  // 处理表头的数据
  const dealHeadRowData = (row: any) => {
    const [key, value]: [keyof typeof samplePropKey, string] = row[head]
      .split(':')
      .filter((v: any) => v)
      .map((v: any) => v.trim())
    if (samplePropKey[key]) {
      sampleObj[samplePropKey[key]] = value
    }
  }
  // 处理内容数据
  const partData = [] as any
  const dealManifestRowData = (row: any) => {
    // 获取每一个数据 每一个位置对应什么数据可打开一个柜柜的补件拆单表查看
    const vals = Object.values(row) as string[]
    // 封边信息
    const edge = vals[10] ?? ''.split(/左|下|右|上/).filter((v) => v)
    const obj = {
      // 面积: '',
      名称: vals[4] ?? '',
      厚度: vals[3] ?? '',
      材质: vals[1] ?? '',
      宽度: vals[6] ?? '',
      长度: vals[5] ?? '',
      数量: vals[7] ?? '',
      板号: vals[0] ?? '',
      纹理: vals[8] ?? '',
      颜色: vals[2] ?? '',
      板件条码: vals[13] ?? '',
      左封边: edge[0] ?? 0,
      后封边: edge[1] ?? 0,
      右封边: edge[2] ?? 0,
      前封边: edge[3] ?? 0,
      下载: 'sheet1',
    }
    partData.push(obj)
  }
  // headData.forEach((row) => {
  //   dealHeadRowData(row)
  // })
  manifestData.forEach((row) => {
    dealManifestRowData(row)
  })
  // 更新样表数据
  // store.commit('setSampleTableData', sampleObj)
  // 更新组件的数据
  // instance.$data.samPleFormData = sampleObj
  return partData
}

// 根据料单表头生成name
export function dealOrderName(address: string, order: string) {
  const addressArr = address.split(/,/)
  const orderArr = order?.split(/,/)
  const len = Math.max(addressArr.length, orderArr?.length)
  let order_address = ''
  for (let index = 0; index < len; index++) {
    const o = orderArr[index] ?? ''
    const a = addressArr[index] ?? ''
    order_address += `,${o}${a && o ? '-' : ''}${a}`
  }
  return order_address.slice(1)
}

export function getPathMinMaxPoint(path: { x: number; y: number }[]) {
  return path.reduce(
    (prev, it) => {
      return [
        Math.min(prev[0], it.x),
        Math.min(prev[1], it.y),
        Math.max(prev[2], it.x),
        Math.max(prev[3], it.y),
      ]
    },
    [Infinity, Infinity, -Infinity, -Infinity]
  )
}

export function defineObjProp(obj: any, key: string, value: any, other = {}) {
  Object.defineProperty(obj, key, {
    value,
    writable: true,
    enumerable: false,
    ...other,
  })
}

// 处理开启任务加载页后的任务开启与结束
export function dealTaskLoading(
  startStr: string,
  endStr: string,
  funcType: string,
  _this: any,
  objField = 'taskList'
) {
  const obj = {
    type: funcType,
    startMsg: startStr,
    endMsg: endStr,
    startDone: false,
    endDone: false,
  }
  _this[objField].push(obj)
  return function (type: 'start' | 'end') {
    const target = _this[objField].find(
      (it: typeof obj) => it.type === funcType
    )
    if (type === 'start') {
      target.startDone = true
    } else {
      target.endDone = true
    }
  }
}

export function dealGuimenFGNo(data: any[]) {
  const isGuimen = Boolean(window.sessionStorage.getItem('thinkerx_material'))
  const FGNoArr = data.map((plank) =>
    isGuimen ? plank.groupNo : plank.address
  )
  return Array.from(new Set(FGNoArr).values())
}

// 获取当前浏览器滚动条宽度
export function getBrowserScrollbarWidth() {
  const div = document.createElement('div')
  div.style.width = '100px'
  div.style.height = '100px'
  div.style.overflowY = 'scroll'
  document.body.appendChild(div)
  const scrollbarWidth = div.offsetWidth - div.clientWidth
  document.body.removeChild(div)
  return scrollbarWidth
}

/**
 * 将预排版数据按照不同刀分割单独，组成一个数组
 *
 * @param preLayoutData 预排板数据
 * @param ncSetting nc设置
 */
export function dealMorePlateKnife(preLayoutData: any[], ncSetting: any) {
  const plate_knife_map = ncSetting.plate_knife_map ?? {}
  // 生成一个唯一标识符，防止更改后续其他人会用到的字段
  const isPlateKnife = Symbol('isPlateKnife')
  const plateKnifeMapData = new Map()
  const plateKnifeKeys = Object.keys(plate_knife_map)
  // 筛选有其他开料刀的板件
  plateKnifeKeys.forEach((key) => {
    const current = plate_knife_map[key]
    const [matCode, thick] = key.split(':').filter((it) => it)
    const plateKnife = plate_knife_map[key]
    const _thick = +thick * 1
    // 匹配材质厚度符合的板件
    const list = preLayoutData.filter((it) => {
      const flag = it.matCode === matCode && +it.thick === _thick
      // 用于剔除已经被选中的板件
      if (flag) it[isPlateKnife] = true
      return flag
    })
    if (list.length) {
      // 根据刀径进行分类
      const k = +current.diameter
      if (plateKnifeMapData.has(k)) {
        const target = plateKnifeMapData.get(k)
        target.preLayoutData = [...target.preLayoutData, ...list]
      } else {
        plateKnifeMapData.set(k, { preLayoutData: list, setting: plateKnife })
      }
    }
  })
  // 过滤出未有其他开料刀的板件
  const _preLayoutData = preLayoutData.filter((it) => {
    const flag = it[isPlateKnife]
    if (flag) {
      Reflect.deleteProperty(it, 'isPlateKnife')
    }
    return !flag
  })
  if (_preLayoutData.length) {
    const k = +ncSetting.knife.diameter
    if (plateKnifeMapData.has(k)) {
      const target = plateKnifeMapData.get(k)
      target.preLayoutData = [...target.preLayoutData, ..._preLayoutData]
    } else {
      plateKnifeMapData.set(k, {
        preLayoutData: _preLayoutData,
        setting: ncSetting.knife,
      })
    }
  }
  return plateKnifeMapData
}

// 在所有刀具中根据名称和字段获取指定刀
type KnifeField = NonNullable<Pick<IRootState, 'allKnifes'>['allKnifes']>
export function getKnifeByName(knifeName: string, field: keyof KnifeField) {
  const knifes = store.state.ncSetting && store.state.ncSetting.knifes
  if (!knifes || field === 'holeKnife') return false
  return knifes[field][knifeName]
}

// 获取一个槽的基本信息
export function getSlotBasicInfo(
  basicSlot: PartType['slots'][0],
  field: 'opt' | 'pt' = 'opt'
) {
  const k1 = `${field}1` as 'opt1' | 'pt1'
  const k2 = `${field}2` as 'opt2' | 'pt2'
  const { [k1]: pt1, [k2]: pt2 } = basicSlot
  const slot = {} as any
  let dir
  // 竖拉槽
  if (pt1.x == pt2.x) {
    slot.width = basicSlot.width
    slot.x = Math.min(pt1.x, pt2.x) - basicSlot.width / 2
    slot.long = Math.abs(pt1.y - pt2.y)
    slot.y = pt1.y > pt2.y ? pt2.y : pt1.y
    dir = 'h'
  }
  // 横拉槽
  if (pt1.y == pt2.y) {
    slot.width = Math.abs(pt1.x - pt2.x)
    slot.y = Math.min(pt1.y, pt2.y) - basicSlot.width / 2
    slot.long = basicSlot.width
    slot.x = pt1.x > pt2.x ? pt2.x : pt1.x
    dir = 'v'
  }
  const x = slot.x
  const y = slot.y
  const width = slot.width
  const height = slot.long
  // 槽路径
  const path = [
    { x, y },
    { x: x + width, y },
    { x: x + width, y: y + height },
    { x, y: y + height },
  ]
  return { x, y, width, height, type: 'slot', slotData: basicSlot, dir, path }
}

// 生成板件订单信息
export function genOrderAddressInfo(planks: PartType[]) {
  const obj = {
    address: new Set(),
    factoryOrderNum: new Set(),
    clientData: new Set(),
    orderRemark: new Set(),
    designer: new Set(),
  }
  planks.forEach((plank) => {
    const { address, orderNo, remark, customer_name, designer } = plank
    if (address) {
      obj.address.add(address)
    }
    if (orderNo) {
      obj.factoryOrderNum.add(orderNo)
    }
    if (remark) {
      obj.orderRemark.add(remark)
    }
    if (customer_name) {
      obj.clientData.add(customer_name)
    }
    if (designer) {
      obj.designer.add(designer)
    }
  })
  const formattedObj = Object.fromEntries(
    Object.entries(obj).map(([key, value]) => [
      key,
      Array.from(value).join(','),
    ])
  )
  return formattedObj
}

// 板件列表进行分类
export function dealMaterialList(plankList: any[]) {
  // 平铺所有的小板
  // const parts = plankList.map((plank) => plank.parts).flat(1)
  const parts: any[] = []
  plankList.forEach((plank) => {
    plank.parts.forEach((part: any) => {
      Object.assign(part, { isUnfold: plank.isUnfold })
      parts.push(part)
    })
  })
  // 初始化所有小板的typeSymbol
  parts.forEach((part) => {
    part.symbol =
      part.typeSymbol = `${part.matCode}:${part.thick}:${part.texture}:${part.is_high_gloss_plank}`
  })
  // 对数据进行分类
  const partsObj = parts.reduce((groups, part) => {
    // TODO 高光板的判断修正
    // const highLightStr = part.is_high_gloss_plank ? '@@@@' : ''
    const highLightStr = ''
    const partSymbol = `${highLightStr}${part.typeSymbol}`
    //
    if (!groups[partSymbol]) {
      groups[partSymbol] = []
    }
    groups[partSymbol].push(part)

    return groups
  }, {})
  // 记录所有symbol
  const allSymbol = Object.keys(partsObj)
  const result = allSymbol.map((symbol, index) => ({
    // TODO 高光板的判断修正
    high_plank: partsObj[symbol].every((part: any) => part.is_high_gloss_plank),
    isAllSelect: partsObj[symbol].every((part: any) => part.isSelected),
    isUnfold: partsObj[symbol][0].isUnfold,
    isVirtualHead: true,
    matCode: partsObj[symbol][0].matCode,
    materialBaseTableIndex: index,
    partUniqueId: nanoid(),
    parts: partsObj[symbol],
    symbol,
    texture: partsObj[symbol][0].texture,
    thick: partsObj[symbol][0].thick,
    totalAmount: arrSum(partsObj[symbol].map((part: any) => part.amount)),
    totalArea: arrSum(
      partsObj[symbol].map((part: any) => {
        return (
          (Number(part.oRect.width) *
            Number(part.oRect.height) *
            Number(part.amount)) /
          1000000
        )
      })
    ),
  }))
  return result
}
// 数组求和
export function arrSum(arr: number[]) {
  let result = 0
  arr.forEach((number) => (result += number))
  return result
}
// 自定义排序函数
export function customSort(a: any, b: any) {
  if (a[0].y === b[0].y) {
    // 如果y坐标相同，按照x坐标排序
    return a[0].x - b[0].x
  } else {
    // 按照y坐标排序
    return a[0].y - b[0].y
  }
}
// 数组去重
export function arrDeduplication(arr: number[]) {
  return Array.from(new Set(arr).values())
}
// 根据设置获取板件需要扩张的距离
export function basisSettingExpandPlank(part: PartType, ncSetting: any) {
  const { longPlankExpandedSize: lpes, longPlankShortExpandedSize: lpses } =
    ncSetting.longPlankCuttingSetting
  let longPlankExpandedSize = Number(lpes)
  let longPlankShortExpandedSize = Number(lpses)
  const {
    longPlankMinLength: longSide,
    longPlankShortMinLength: shortSide,
    longPlankCuttingEnable,
    cutSingleDoor,
    cutGeneralPlank,
    generalPlankThick,
    cuttingEquipment,
  } = ncSetting.longPlankCuttingSetting
  const rectWH = [part.rect.width, part.rect.height]
  const partLongSide = Math.max(...Object.values(rectWH))
  const partShortSide = Math.min(...Object.values(rectWH))
  if (
    !(
      longPlankCuttingEnable &&
      partLongSide > longSide &&
      partShortSide > shortSide
    )
  ) {
    longPlankExpandedSize = 0
    longPlankShortExpandedSize = 0
  }
  if (cutSingleDoor && part.type === 'SingleDoor') {
    666 // 门板板件扩张
  } else if (
    cutGeneralPlank &&
    part.type !== 'SingleDoor' &&
    part.thick >= generalPlankThick
  ) {
    // 占位不删 后续可能会有处理
    666 // 常规板板件扩张
  } else {
    longPlankExpandedSize = 0
    longPlankShortExpandedSize = 0
  }
  let expandedX, expandedY
  // 判断赋予x和y长边放量还是短边放量
  if (rectWH[0] > rectWH[1]) {
    expandedX = longPlankExpandedSize
    expandedY = longPlankShortExpandedSize
  } else if (rectWH[0] < rectWH[1]) {
    expandedX = longPlankShortExpandedSize
    expandedY = longPlankExpandedSize
  } else {
    // 宽高相等进行判断
    if (rectWH[0] > longSide) {
      expandedX = longPlankExpandedSize
      expandedY = longPlankExpandedSize
    } else if (rectWH[0] > shortSide) {
      expandedX = longPlankShortExpandedSize
      expandedY = longPlankShortExpandedSize
    } else {
      expandedX = 0
      expandedY = 0
    }
  }
  let contourSizeRecoup =
    ncSetting.glass_setting && ncSetting.glass_setting.contour_size_recoup
  if (cuttingEquipment !== 'glassEquip' || !contourSizeRecoup) {
    contourSizeRecoup = 0
  }
  // 拉直器扩板
  let extendHDis = 0,
    extendVDis = 0
  const allKnifes = store.state.ncSetting && store.state.ncSetting?.knives

  const layoutGap = ncSetting.panelSize.layoutGap
  const symbol = `${part.texture}:${part.matCode}:${part.thick}`
  const diameter = Number(getPlateKnifeDiameter(symbol, ncSetting)) //获取刀直径
  const isPreLayout = ncSetting.isPreLayout
  let selectKnifeDiameter = isPreLayout ? diameter : 0
  for (const slot of part.slots) {
    const selectknife = slot?.knifeName
    if (specialSymbol.includes(slot.symbol)) {
      if (
        slot.processingSequence === 'firstMillSlot' ||
        (slot.symbol === 'arcStraightSlot' && selectknife)
      ) {
        // 铣底圆弧指定刀
        if (
          !!selectknife &&
          !isPreLayout &&
          allKnifes?.[selectknife]?.diameter
        ) {
          selectKnifeDiameter = Number(allKnifes[selectknife].diameter)
        }

        if (selectKnifeDiameter > layoutGap + diameter) {
          if (slot['pt1']['x'] === slot['pt2']['x']) {
            extendVDis = selectKnifeDiameter - (layoutGap + diameter)
          }
          if (slot['pt1']['y'] === slot['pt2']['y']) {
            extendHDis = selectKnifeDiameter - (layoutGap + diameter)
          }
        }
      } else if (initSymbol.includes(slot?.symbol)) {
        if (slot['width'] > layoutGap + diameter) {
          if (slot['pt1']['x'] === slot['pt2']['x']) {
            const slotHeight = Math.abs(slot['pt2']['y'] - slot['pt1']['y'])
            const height = part.rect.height
            // 判断槽是否是通槽 false => 通槽
            const isThroughSlot =
              (slot['symbol'] === 'CCSlot' || slot['symbol'] === 'exSlot') &&
              slotHeight < height
            extendVDis = isThroughSlot
              ? 0
              : slot['width'] - (layoutGap + diameter)
          }
          if (slot['pt1']['y'] === slot['pt2']['y']) {
            const slotWidth = Math.abs(slot['pt2']['x'] - slot['pt1']['x'])
            const width = part.rect.width
            const isThroughSlot =
              (slot['symbol'] === 'CCSlot' || slot['symbol'] === 'exSlot') &&
              slotWidth < width
            extendHDis = isThroughSlot
              ? 0
              : slot['width'] - (layoutGap + diameter)
          }
        }
      }
      break
    }
  }

  const finalExtendDisMap = dealExpandSlot(part, ncSetting, diameter / 2)

  return {
    expandedX,
    expandedY,
    contourSizeRecoup,
    // extendHDis,
    // extendVDis,
    diameter,
    longPlankExpandedSize,
    finalExtendDisMap,
  }
}
// 抽离板件的操作逻辑，将获取当前大板，小板单独分离成一个方法(还需要重写并进行优化，现在是直接复制的)
export class PlankControl {
  // 当前vue的实例，在里面取部分值
  vueInstance: any
  bigPartList: any[]
  defaultScale = 6
  mapFields: { [k: string]: any } = {
    deviation: 'deviation',
    scalePercent: 'scalePercent',
    padding: 'padding',
  }
  constructor(
    vueInstance: any,
    bigPartList?: any[],
    mapFields?: {
      deviation: string
      scalePercent: string
      padding: string
      [k: string]: any
    }
  ) {
    this.vueInstance = vueInstance
    this.bigPartList = bigPartList ?? []
    Object.keys(mapFields ?? {}).forEach((k: string) => {
      this.mapFields[k] = mapFields![k]
    })
  }
  // 获取当前点击位置的大板
  getActivePlank(e: MouseEvent) {
    const mousePoint = {
      x: e.offsetX,
      y: e.offsetY,
    }
    // 找到当前点击的位置属于哪一个大板, 找到起始坐标小于当前鼠标位置的所有大板, 取最后一个大板
    const arr = this.bigPartList.filter(
      (v) => mousePoint.x >= v.startX && mousePoint.y >= v.startY
    )
    if (arr.length == 0) return null
    // 判断鼠标位置是否在大板所处的位置
    const targetBigpart = arr[arr.length - 1]
    // 找到原数据中的bigpart
    const bigPart = this.bigPartList.find(
      (v) => v.stockKey == targetBigpart.stockKey
    )
    const otherData: any = {}
    if (
      mousePoint.x >
        bigPart.startX +
          2 * this.vueInstance[this.mapFields['deviation']] +
          bigPart.width +
          2 * this.vueInstance[this.mapFields['scalePercent']] &&
      mousePoint.x <
        bigPart.startX +
          2 * this.vueInstance[this.mapFields['deviation']] +
          bigPart.width +
          42 * this.vueInstance[this.mapFields['scalePercent']] &&
      mousePoint.y >
        bigPart.startY +
          this.vueInstance[this.mapFields['padding']] +
          this.vueInstance[this.mapFields['deviation']] &&
      mousePoint.y <
        bigPart.startY +
          this.vueInstance[this.mapFields['padding']] +
          this.vueInstance[this.mapFields['deviation']] +
          40 * this.vueInstance[this.mapFields['scalePercent']]
    ) {
      const extraBtnX =
        bigPart.startX +
        2 * this.vueInstance[this.mapFields['deviation']] +
        bigPart.width +
        2 * this.vueInstance[this.mapFields['scalePercent']] -
        e.offsetX
      const extraBtnY =
        bigPart.startY +
        this.vueInstance[this.mapFields['padding']] +
        this.vueInstance[this.mapFields['deviation']] -
        e.offsetY
      if (this.vueInstance.activebigPart) {
        otherData.left = e.pageX + extraBtnX
        otherData.top =
          e.pageY +
          extraBtnY +
          40 * this.vueInstance[this.mapFields['scalePercent']]
        otherData.data = bigPart
      }
    }
    let activeBigPart: any = null
    if (
      mousePoint.x <=
        bigPart.startX +
          bigPart.width +
          2 * this.vueInstance[this.mapFields['deviation']] &&
      mousePoint.y <=
        bigPart.startY +
          bigPart.height +
          this.vueInstance[this.mapFields['padding']] +
          2 * this.vueInstance[this.mapFields['deviation']]
    ) {
      // 判断点击的是否为大板, 还是顶部信息
      if (
        mousePoint.y >=
          bigPart.startY + this.vueInstance[this.mapFields['padding']] &&
        mousePoint.y <=
          bigPart.startY +
            bigPart.height +
            this.vueInstance[this.mapFields['padding']] +
            2 * this.vueInstance[this.mapFields['deviation']]
      ) {
        activeBigPart = bigPart
      }
    }
    return {
      otherData,
      activeBigPart,
    }
  }
  // 获取当前位置的小板
  getActivePart(e: MouseEvent, bigPlank?: any) {
    bigPlank = bigPlank || this.getActivePlank(e)?.activeBigPart
    if (!bigPlank) return null
    let activePart: any = null
    const mousePoint = {
      x: e.offsetX,
      y: e.offsetY,
    }
    for (let i = 0; i < bigPlank.parts.length; ++i) {
      const part = bigPlank.parts[i]
      const rect = {
        x:
          bigPlank.startX +
          this.vueInstance[this.mapFields['deviation']] +
          this.tranlateSizeFromScale(part.startX),
        y:
          bigPlank.startY +
          this.vueInstance[this.mapFields['deviation']] +
          this.vueInstance[this.mapFields['padding']] +
          this.tranlateSizeFromScale(part.startY),
      }
      const newPos = {
        x: this.restoreScaleSize(mousePoint.x - rect.x),
        y: this.restoreScaleSize(mousePoint.y - rect.y),
      }
      let curvePath = []
      if (part.path) {
        curvePath = part.path[0]
      } else {
        curvePath = [
          { x: 0, y: 0 },
          { x: part.rect.width, y: 0 },
          {
            x: part.rect.width,
            y: part.rect.height,
          },
          { x: 0, y: part.rect.height },
        ]
      }
      const isPointInsidePlank = window.ClipperLib.Clipper.PointInPolygon(
        { X: newPos.x, Y: newPos.y },
        curvePath.map((it: any) => ({ X: it.x, Y: it.y }))
      )
      if (isPointInsidePlank) {
        activePart = part
      }
    }
    return activePart
  }
  // 获取详情的活跃板件位置
  gitDetailActivePart(
    e: MouseEvent,
    bigPart: any,
    other: {
      deviation: number
      topInfoHeight: number
      scalePercent: number
      defaultScale: number
    }
  ) {
    const { deviation, topInfoHeight, scalePercent, defaultScale } = other
    const mousePoint = {
      x: e.offsetX,
      y: e.offsetY,
    }
    let activePart: any = null
    // 如果找到了, 则判断点击的位置
    if (
      mousePoint.x <=
        bigPart.subtleStartX + bigPart.subtleWidth + 2 * deviation &&
      mousePoint.y <=
        bigPart.subtleStartY +
          bigPart.subtleHeight +
          topInfoHeight +
          2 * deviation
    ) {
      // 判断点击的是否为大板, 还是顶部信息
      if (mousePoint.y >= bigPart.subtleStartY + topInfoHeight) {
        for (let i = 0; i < bigPart.parts.length; ++i) {
          const part = bigPart.parts[i]
          const rect = {
            x:
              bigPart.subtleStartX +
              deviation +
              (part.startX / defaultScale) * scalePercent,
            y:
              bigPart.subtleStartY +
              deviation +
              topInfoHeight +
              (part.startY / defaultScale) * scalePercent,
          }
          const newPos = {
            x: ((mousePoint.x - rect.x) / scalePercent) * defaultScale,
            y: ((mousePoint.y - rect.y) / scalePercent) * defaultScale,
          }
          let curvePath = []
          if (part.path) {
            curvePath = part.path[0]
          } else {
            curvePath = [
              { x: 0, y: 0 },
              { x: part.rect.width, y: 0 },
              { x: part.rect.width, y: part.rect.height },
              { x: 0, y: part.rect.height },
            ]
          }
          const isPointInsidePlank = window.ClipperLib.Clipper.PointInPolygon(
            { X: newPos.x, Y: newPos.y },
            curvePath.map((it: any) => ({ X: it.x, Y: it.y }))
          )
          if (isPointInsidePlank) {
            activePart = part
          }
        }
      }
    }
    return activePart
  }
  // 将size通过scale进行转换
  tranlateSizeFromScale(size: number) {
    return (
      (size / this.defaultScale) *
      this.vueInstance[this.mapFields['scalePercent']]
    )
  }
  // 把size恢复成没有进行缩放前的值
  restoreScaleSize(scaleSize: number) {
    return (
      (scaleSize / this.vueInstance[this.mapFields['scalePercent']]) *
      this.defaultScale
    )
  }
}

export function partIsConflict(part: PartType) {
  if (!part) return null
  const flag = [...(part?.holes ?? []), ...(part?.slots ?? [])].find(
    (it) => it.conflict
  )
  return flag
}

export function getArrDifference(arr1: any[], arr2: any[]) {
  return arr1.concat(arr2).filter(function (v, i, arr) {
    return arr.indexOf(v) === arr.lastIndexOf(v)
  })
}

// 处理excel表格数据
export async function dealExcelData(data: any, type: any, instance?: any) {
  const sampleHeadArr = [
    '项目地址',
    '客户资料',
    '工厂单号',
    '房间名称',
    '订单备注',
  ]
  //判断所以导入表格不可缺少的表头
  const indispensableHeaderDataList = [
    '材质',
    '颜色',
    '厚度',
    '成品长度',
    '成品宽度',
    '数量',
    '纹理',
  ]
  let sData = {}
  if (type == 'csv') {
    // 将纯文本数据通过回车换行符进行拆分

    let tempArr = []
    if (data.target.result.includes('\r\n')) {
      tempArr = data.target.result.split(/\r\n/)
    } else if (data.target.result.includes('\n')) {
      tempArr = data.target.result.split(/\n/)
    }
    // 表头数据有额外数据需要处理
    let isSpecialHead =
      tempArr[0] && tempArr[0].split(',').filter((v: any) => v)
    isSpecialHead.color = '#E7E7E7'
    isSpecialHead = isSpecialHead.length
      ? isSpecialHead.every((v: any) => sampleHeadArr.includes(v))
      : false
    let orderNo = ''
    let orderAddress = ''
    let orderRoomName = ''
    let customer = ''
    let remarks = ''
    const originalData = {
      address: '',
      clientData: '',
      factoryOrderNum: '',
      orderRemark: '',
      roomName: '',
    }
    if (isSpecialHead) {
      const tempArrData = tempArr[1]
      if (!tempArrData) return
      const sample = tempArr[1]
        .split(',')
        .map((it: any) => it.replace(/&/g, ','))
      const template = JSON.parse(
        JSON.stringify({ ...sData, ...originalData } ?? {})
      )
      Object.keys(template).forEach((key, idx) => {
        template[key] = sample[idx] ?? ''
      })
      sData = template
      tempArr = tempArr.slice(2)
      orderNo = sample[2]
      orderAddress = sample[0]
      orderRoomName = sample[3]
      customer = sample[1]
      remarks = sample[4]
    }
    // 需要返回
    // this.headerItemAddress = sData
    // 筛选分隔之后的空白数据
    const allDataArr = tempArr
      .filter((v: any) => v)
      .map((data: any) => {
        // 去掉数据中的引号和制表符
        return data.replace(/"/g, '').replace(/\t/g, '')
      })
    const arr = []
    // 去除回车符号(直接用console打印单条数据没有这个回车符号, 但是打印整体就有, 没有找到原因, 但是为了保险, 就还是去掉了一下这个回车符号)
    // 获取csv表格的表头
    const keys = allDataArr[0].split(',')
    keys.forEach((item: any, index: number) => {
      if (item === '名称') {
        keys[index] = '板件名称'
      } else if (item === '长度') {
        keys[index] = '成品长度'
        keys.push('厚度')
      } else if (item === '宽度') {
        keys[index] = '成品宽度'
      } else if (item === '封边信息') {
        keys.push('前封边')
        keys.push('后封边')
        keys.push('左封边')
        keys.push('右封边')
      }
    })
    if (indispensableHeaderDataList.includes('纹路')) {
      indispensableHeaderDataList.pop()
    }
    const existTableHeaderList = keys.filter((item: any) => {
      return indispensableHeaderDataList.includes(item)
    })
    // TODO wc 需要导出
    const lackTableHeaderList = getArrDifference(
      existTableHeaderList,
      indispensableHeaderDataList
    )

    if (lackTableHeaderList.length !== 0) {
      return {
        lackTableHeaderList,
      }
    }

    let resList: any[] = []
    keys.forEach((item: any) => {
      const dataList = allTableHeaderList.filter((value) => {
        return value.label === item
      })

      resList = resList.concat(dataList)
    })
    // 需要返回
    // resList.unshift(this.choose)

    // 删除表头
    allDataArr.splice(0, 1)
    // 处理csv表格内容, 将表头和内容一一对应
    for (let i = 0; i < allDataArr.length; i++) {
      const plankInfo = allDataArr[i].split(',')
      const obj = {
        orderNo,
        orderAddress,
        orderRoomName,
        customer,
        remarks,
      } as any
      for (let j = 0; j < plankInfo.length; j++) {
        obj[keys[j]] = plankInfo[j]
      }
      arr.push(obj)
    }
    data = arr
  } else if (type === 'xls') {
    indispensableHeaderDataList.push('纹路')
  }
  const necessaryTableLackStringObj = {} as any
  const necessaryTableIncorrectFormat = {} as any
  //检测导入数据每一行的必要表头的数据是否缺少
  data.forEach((item: any, index: number) => {
    const excessiveList: any[] = []
    const incorrectFormatList: any[] = []
    indispensableHeaderDataList.forEach((val) => {
      if (item[val] && String(item[val]).trim() === '') {
        if (instance) {
          instance.showTableDialog = true
          instance.isShowLackNeedTableHeaderData = true
          instance.isShowLackNeedTableHeader = false
        }
        excessiveList.push(val)
      } else if (
        val === '厚度' ||
        val === '成品长度' ||
        val === '成品宽度' ||
        val === '数量'
      ) {
        if (!instance?.isNumericPositive(item[val]) && item[val]) {
          if (instance) instance.showMIncorrectFormat = true
          incorrectFormatList.push(val)
        }
      } else if ((val === '纹路' || val === '纹理') && item[val]) {
        if (!['横纹', '竖纹', '无纹理'].includes(String(item[val]).trim())) {
          if (instance) instance.showMIncorrectFormat = true
          incorrectFormatList.push(val)
        }
      }
    })
    if (excessiveList.length !== 0) {
      necessaryTableLackStringObj[index] = excessiveList
    }
    if (incorrectFormatList.length !== 0) {
      necessaryTableIncorrectFormat[index] = incorrectFormatList
    }
  })

  if (instance?.showTableDialog || instance?.showMIncorrectFormat) {
    return
  } else {
    if (instance) instance.samPleFormData = instance?.headerItemAddress
  }

  if (data.length == 0) {
    return
  }
  const plankArr = []
  for (let i = 0; i < data.length; ++i) {
    const plank = data[i]
    // 封边keys 封边 -> 左右为标准 -> 前：下 后：上 -> 结果取左下右上的顺序
    const edgeSort = new Map([
      ['左封边', 1],
      ['后封边', 2],
      ['下封边', 2],
      ['右封边', 3],
      ['前封边', 4],
      ['上封边', 4],
    ])

    const edgeKeyArr = Object.keys(plank).filter((e) => e.includes('封边')) as [
      '左封边',
      '右封边',
      '下封边',
      '上封边',
      '前封边',
      '后封边'
    ]
    const edgeKeys = edgeKeyArr.sort((a, b) => {
      const f = edgeSort.get(a)
      const s = edgeSort.get(b)
      if (f && s) {
        return f - s
      } else {
        return 0
      }
    })

    if (plank['纹理'] === '横纹') {
      ;[plank['长度'], plank['宽度']] = [plank['宽度'], plank['长度']]
    }
    const newPlank = {} as any
    const material = plank['材质'] ?? plank['材料']
    // const index = material?.indexOf('_')
    // // 去除首个下划线
    // if (index !== -1) {
    //   material = material?.substring(0, index) + material?.substring(index + 1)
    // }
    const thick = material ? material.match(/^\d+(\.\d+)?/) ?? [] : []

    newPlank.thick = thick[0] ?? 0
    newPlank.matCode = material ? material.replace(newPlank.thick, '') : ''
    let edgeArr = []
    if (plank['封边信息']) {
      if (plank['封边信息'].includes(':')) {
        edgeArr = plank['封边信息']
          .split(':')[1]
          .split(/前|后|左|右|上|下/)
          .filter((v: any) => v && v !== ' ')
      } else if (plank['封边信息'].includes(': ')) {
        edgeArr = plank['封边信息']
          .split(': ')[1]
          .split(/前|后|左|右|上|下/)
          .filter((v: any) => v && v !== ' ')
      }
    } else if (edgeKeys.length) {
      edgeArr = edgeKeys.map((e) => plank[e])
    }
    if (edgeArr.length < 4) {
      const n = 4 - edgeArr.length
      edgeArr.push(...new Array(n).fill('0'))
    }
    edgeArr = edgeArr.map((item: any) => {
      if (!item) {
        item = '0'
      }
      return item
    })
    newPlank.edgeInfo =
      edgeArr.length > 0
        ? `←${edgeArr[0]}↓${edgeArr[1]}→${edgeArr[2]}↑${edgeArr[3]}`
        : ''

    newPlank.plankID = plank['板号']
    newPlank.partName = plank['名称']
    newPlank.loc = plank['柜体']
    newPlank.customer_name = plank['客户'] ?? plank['customer']
    newPlank.frontEdge = plank['前封边']
    newPlank.backEdge = plank['后封边']
    newPlank.leftEdge = plank['左封边']
    newPlank.rightEdge = plank['右封边']
    newPlank.roomName = plank['房间'] ?? plank['orderRoomName']
    newPlank.remarks = plank['remarks']
    newPlank.partName = plank['板件名称'] ?? plank['工件名称']
    newPlank.orderNo = plank['订单号'] ?? plank['orderNo']
    newPlank.plankNum = plank['板件条码']
    newPlank.type =
      plank['门板'] === '非门板' || !plank['门板'] ? 'Plank' : 'SingleDoor'
    newPlank.address = plank['项目地址'] ?? plank['orderAddress']
    newPlank.specialShape = plank['异形']
    newPlank.specHeight = plank['成品长度'] ?? plank['长度'] ?? plank['长']
    newPlank.specWidth = plank['成品宽度'] ?? plank['宽度'] ?? plank['宽']
    newPlank.thick =
      plank['厚度'] ?? plank['厚'] ?? plank['开料厚'] ?? thick[0] ?? 0
    newPlank.oRect = {
      x: 0,
      y: 0,
      width: newPlank.specWidth ? Number(newPlank.specWidth) : 0,
      height: newPlank.specHeight ? Number(newPlank.specHeight) : 0,
    }
    newPlank.fullSize = {}
    newPlank.rect = {
      x: 0,
      y: 0,
      width: toDecimal(
        Number(newPlank.oRect.width) - Number(edgeArr[0]) - Number(edgeArr[2]),
        2
      ),
      height: toDecimal(
        Number(newPlank.oRect.height) - Number(edgeArr[1]) - Number(edgeArr[3]),
        2
      ),
    }
    newPlank.realRect = JSON.parse(JSON.stringify(newPlank.rect))
    newPlank.amount = plank['数量']
    switch (
      (plank['纹理'] && String(plank['纹理']).trim()) ||
      (plank['纹路'] && String(plank['纹路']).trim())
    ) {
      case '竖纹':
        newPlank.texDir = 'normal'
        break
      case '横纹':
        newPlank.texDir = 'reverse'
        break
      case '无纹理':
        newPlank.texDir = 'notcare'
        break
      default:
        newPlank.texDir = 'notcare'
        break
    }

    ;(newPlank.hsInfo = plank['孔槽数量']), (newPlank.texture = plank['颜色'])
    newPlank.holes = []
    newPlank.slots = []
    newPlank.sholes = []
    newPlank.sslots = []
    newPlank.realCurve = [
      { x: 0, y: 0 },
      { x: newPlank.oRect.width, y: 0 },
      { x: newPlank.oRect.width, y: newPlank.oRect.height },
      { x: 0, y: newPlank.oRect.height },
    ]
    newPlank.name = '导入的板件'
    newPlank.matRotatable = newPlank.texDir == 'notcare' ? true : false
    plankArr.push(newPlank)
  }
  return {
    headerItemAddress: sData,
    plankArr,
  }
}
/**
 *
 * @param part 需要判断的板件
 * @param ncSetting nc设置
 * @param startX 板件横轴起点，部分情况startX会处理所以传入
 * @param startY 板件竖轴起点，同startX
 * @param plankWidth 大板宽度
 * @param plankHeight 大板高度
 * @returns 是否需要旋转
 */
export function judgeSlotIsRotate(
  part: PartType,
  ncSetting: any,
  startX: number,
  startY: number,
  plankWidth: number,
  plankHeight: number,
  otherPlate: any
) {
  OTHERPLATE = otherPlate
  const { slots, rect, path: partPath } = part
  const { sideBoardPlacing } = ncSetting
  const needRotate = false
  if (!slots.length || partPath || sideBoardPlacing === 'default')
    return needRotate
  // 距边距离
  const safeDis = 100
  // 槽摆放位置
  const nearTop = startY - safeDis < 0
  const nearBottom = startY + rect.height + safeDis > plankHeight
  const nearLeft = startX - safeDis < 0
  const nearRight = startX + rect.width + safeDis > plankWidth
  const isXNearSide = nearLeft || nearRight
  const isYNearSide = nearTop || nearBottom
  // 非靠边板直接返回
  if (!isXNearSide && !isYNearSide) return needRotate
  let sortSlotList = [...slots]
  // 计算出真正的槽长
  sortSlotList = sortSlotList.map((slot) => genRealLong(slot))
  sortSlotList = sortAsNearSideDis(sortSlotList, part, startX, startY)
  // 先按照 realLong 从大到小排序,如果 realLong 相同，再按照 nearSideDis 从小到大排序
  sortSlotList.sort(function (plankFront: any, plankNext: any) {
    const realLong = plankNext.realLong - plankFront.realLong
    const nearSideDis = plankFront.nearSideDis - plankNext.nearSideDis
    return realLong || nearSideDis
  })
  if (!(isXNearSide && isYNearSide) && isXNearSide) {
    sortSlotList = sortSlotList.filter((slot) => slot.direction == 'v')
    // 只看竖槽
  } else if (!(isXNearSide && isYNearSide) && isYNearSide) {
    sortSlotList = sortSlotList.filter((slot) => slot.direction == 'h')
  }
  return judgeNearSideDis(
    part,
    ncSetting,
    startX,
    startY,
    plankWidth,
    plankHeight,
    sortSlotList.flat(Infinity)
  )
}

// 计算出真正的槽长
export function genRealLong(slot: any) {
  const optWidth =
    Math.abs(slot.opt1.x - slot.opt2.x) + Math.abs(slot.opt1.y - slot.opt2.y)
  return {
    ...slot,
    realLong: Math.max(optWidth, slot.width).toFixed(3),
    realWidth: Math.min(optWidth, slot.width).toFixed(3),
  }
}
// 判断板件是否距边小于100
function judgeNearSideDis(
  part: PartType,
  ncSetting: any,
  startX: number,
  startY: number,
  plankWidth: number,
  plankHeight: number,
  slots: any
) {
  const { oRect, rect } = part
  const { sideBoardPlacing } = ncSetting
  let needRotate = false
  // 距边距离
  const safeDis = 100
  // 槽摆放位置
  const oRectHeightHalf = oRect.height / 2
  const oRectWidthHalf = oRect.width / 2
  const nearTop = startY - safeDis < 0
  const nearBottom = startY + rect.height / 2 > plankHeight / 2
  const nearLeft = startX - safeDis < 0
  const nearRight = startX + rect.width / 2 > plankWidth / 2
  for (const slot of slots) {
    if (!(slot.nearSideDis <= 100)) continue
    const { path } = getSlotBasicInfo(slot)
    const [minX, minY, maxX, maxY] = getPathMinMaxPoint(path)
    const xDis = maxX - minX
    const yDis = maxY - minY
    // 判断槽是横向还是竖向(部分槽不能通过pt来判断)
    const displayDirection = yDis > xDis ? 'v' : 'h'
    // 横向且当前板件是靠着大板边缘的
    if (displayDirection === 'h') {
      // 板件在上
      if (nearTop) {
        if (
          (maxY < oRectHeightHalf && sideBoardPlacing === 'inside') ||
          (minY > oRectHeightHalf && sideBoardPlacing === 'outside')
        ) {
          needRotate = true
        }
      }
      // 板件在下
      if (nearBottom) {
        if (
          (maxY < oRectHeightHalf && sideBoardPlacing === 'outside') ||
          (minY > oRectHeightHalf && sideBoardPlacing === 'inside')
        ) {
          needRotate = true
        }
      }
    }
    // 竖向且当前板件是靠着大板边缘的
    if (displayDirection === 'v') {
      // 板件在左
      if (nearLeft) {
        if (
          (maxX < oRectWidthHalf && sideBoardPlacing === 'inside') ||
          (minX > oRectWidthHalf && sideBoardPlacing === 'outside')
        ) {
          needRotate = true
        }
      }
      // 板件在右
      if (nearRight) {
        if (
          (maxX < oRectWidthHalf && sideBoardPlacing === 'outside') ||
          (minX > oRectWidthHalf && sideBoardPlacing === 'inside')
        ) {
          needRotate = true
        }
      }
    }
    if (needRotate || slot == slots[0] || slot.nearSideDis <= 100) break
  }
  return needRotate
}

// 判断距边最小
function sortAsNearSideDis(
  slots: any,
  part: any,
  startX: number,
  startY: number
) {
  const { oRect, plankHeight, plankWidth } = part
  const rectWidthHalf = oRect.width / 2
  const rectHeightHalf = oRect.height / 2
  const ncSetting = store.state.ncSetting
  const stockKey = `${part.texture}:${part.matCode}:${part.thick}`
  const d = Number(getPlateKnifeDiameter(stockKey, ncSetting)) / 2
  const realMargin =
    OTHERPLATE && Object.keys(OTHERPLATE).length
      ? OTHERPLATE.trim_edge
      : store.state.historyPlankEdgeOff - d
  // 计算出真正的startX、startY
  const realStartX = startX - realMargin
  const realStartY = startY - realMargin

  // 判断槽在上下左右哪个方位
  slots = slots.map((slot: any) => {
    const minX = Math.min(slot.opt1.x, slot.opt2.x)
    const maxX = Math.max(slot.opt1.x, slot.opt2.x)
    const minY = Math.min(slot.opt1.y, slot.opt2.y)
    const maxY = Math.max(slot.opt1.y, slot.opt2.y)
    const { direction, slotCenter } = judgeVHSlot(slot)
    // 竖槽
    if (direction == 'v') {
      const halfWidth = minX == maxX ? slot.realWidth / 2 : 0
      // 槽据距小板的距离
      let nearSideDis =
        rectWidthHalf > slotCenter.x
          ? minX - oRect.x - halfWidth
          : oRect.width - maxX - halfWidth
      // 槽距大板的距离
      if (plankWidth / 2 > realStartX + oRect.width / 2) {
        // 小板在大板左边
        nearSideDis += realStartX
      } else {
        // 右边
        nearSideDis = plankWidth - (realStartX + oRect.width) + nearSideDis
      }
      return {
        ...slot,
        nearSideDis,
        direction: direction,
      }
    } else {
      const halfWidth = minY == maxY ? slot.realWidth / 2 : 0
      let nearSideDis =
        rectHeightHalf > slotCenter.y
          ? minY - oRect.y - halfWidth
          : oRect.height - maxY - halfWidth
      if (plankHeight / 2 > realStartY + oRect.height / 2) {
        // 上边
        nearSideDis += realStartY
      } else {
        // 下边
        nearSideDis = plankHeight - (realStartY + oRect.height) + nearSideDis
      }
      return {
        ...slot,
        nearSideDis,
        direction: direction,
      }
    }
  })
  return slots
}
// 判断一个槽是横槽还是竖槽并计算槽的中点
export function judgeVHSlot(slot: any) {
  const { opt1, opt2, width } = slot
  const slotInfo = { direction: '', slotCenter: { x: 0, y: 0 } }
  if (opt1.y == opt2.y) {
    slotInfo.slotCenter.x =
      Math.abs(opt2.x - opt1.x) / 2 + Math.min(opt2.x, opt1.x)
    slotInfo.slotCenter.y = opt1.y
    slotInfo.direction = width >= Math.abs(opt2.x - opt1.x) ? 'v' : 'h'
  } else {
    slotInfo.slotCenter.y =
      Math.abs(opt2.y - opt1.y) / 2 + Math.min(opt2.y, opt1.y)
    slotInfo.slotCenter.x = opt1.x
    slotInfo.direction = width <= Math.abs(opt2.y - opt1.y) ? 'v' : 'h'
  }
  return slotInfo
}
export function genHandleSlopesStr(part: any) {
  let str = ''
  part.handleSlopes?.map((elem: any) => {
    const { direction, slotCenter } = judgeVHSlot(elem)
    let position = ''
    if (direction == 'v') {
      if (slotCenter.x < part.oRect.width / 2) {
        position = '左'
      } else {
        position = '右'
      }
    } else {
      if (slotCenter.y < part.oRect.height / 2) {
        position = '上'
      } else {
        position = '下'
      }
    }
    if (elem.symbol == 'haitangCorner') {
      str += `\n${elem.settingName}-${position}/${elem.side == 1 ? '正' : '反'}`
    }
  })
  part.slots?.map((elem: any) => {
    const { direction, slotCenter } = judgeVHSlot(elem)
    let position = ''
    if (direction == 'v') {
      if (slotCenter.x < part.oRect.width / 2) {
        position = '左'
      } else {
        position = '右'
      }
    } else {
      if (slotCenter.y < part.oRect.height / 2) {
        position = '上'
      } else {
        position = '下'
      }
    }
    if (elem.symbol == 'haitangSlot') {
      str += `\n${elem.others.settingName}-${position}/${
        elem.side == 1 ? '正' : '反'
      }`
    }
  })
  part.sslots?.map((elem: any) => {
    const { direction, slotCenter } = judgeVHSlot(elem)
    let position = ''
    if (direction == 'v') {
      if (slotCenter.x < part.oRect.width / 2) {
        position = '左'
      } else {
        position = '右'
      }
    } else {
      if (slotCenter.y < part.oRect.height / 2) {
        position = '上'
      } else {
        position = '下'
      }
    }
    if (elem.symbol == 'haitangSideSlot') {
      str += `\n${elem.others.settingName}-${position}/侧`
    }
  })
  return str
}

// 处理导出Excel表格的数据
export function exportExcel(data: any[]) {
  const tableHeadMap = new Map([
    ['订单号', 'orderNo'],
    ['客户名称', 'customer_name'],
    ['板件名称', 'name'],
    ['板件ID', 'plankNum'],
    ['长度', 'height'],
    ['宽度', 'width'],
    ['厚度', 'thick'],
    ['封边1', 'edgeLeft'],
    ['封边2', 'edgeBottom'],
    ['封边3', 'edgeRight'],
    ['封边4', 'edgeTop'],
    ['异形', 'path'],
    ['小条', 'smallBar'],
    ['长边先封', 'longEdge'],
    ['基材', 'matCode'],
    ['包重', 'packageWeight'],
    ['包装长度', 'packageLength'],
    ['包装宽度', 'packageWidth'],
    ['包装高度', 'packageHeight'],
    ['包装层数', 'packageLayer'],
    ['总包数', 'packages'],
    ['包号', 'packageIndex'],
    ['板件层号', 'plankLayer'],
    ['板件X坐标', 'plankX'],
    ['板件Y坐标', 'plankY'],
    ['包装码', 'packageNum'],
  ])

  const tableHeadNameArr = Array.from(tableHeadMap.keys())
  const headDataArr = [tableHeadNameArr]
  const tableDataArr = data.map((item) => {
    return tableHeadNameArr.map((name) => {
      const key = tableHeadMap.get(name)
      if (key) {
        return item[key]
      } else {
        return ''
      }
    })
  })

  // 创建一个工作簿
  const workBook = XLSX.utils.book_new()
  const sheetData = headDataArr.concat(tableDataArr)

  // 将数据转换为工作表
  const workSheet = XLSX.utils.aoa_to_sheet(sheetData)
  // 将工作表添加到工作簿
  XLSX.utils.book_append_sheet(workBook, workSheet, 'Sheet1')
  // 生成Excel文件并导出
  const excelBuffer = XLSX.write(workBook, { bookType: 'xlsx', type: 'array' })
  const blobData = new Blob([excelBuffer], {
    type: 'application/xlsx;charset=utf-8',
  })

  return blobData
  // FileSaver.saveAs(blobData, '生产进度跟踪批次导入表格.xlsx')
}

/** 判断板件1的内轮廓数据是否容纳板件2外轮廓 */
interface IContainIem {
  path: PointUpperCase[]
  index: number
  key: string
  boundBoxKey: OutlineKeys
}
;[]
export function judgePart1ContainPart2(plank1: PartType, plank2: PartType) {
  // 检查板件1内轮廓是否能容纳板件2
  const filterPolyArr: IContainIem[] = []
  // false表示两个板件出现了冲突
  let isMerge = false
  const { polyArr, curveHoles } = plank1
  const { _plankBoundBox } = plank2
  const area2 = calcArea(_plankBoundBox[0])
  // 过滤出内轮廓边界框面积大于板件2外轮廓的数据
  if (polyArr?.length > 1) {
    polyArr.forEach((path, idx) => {
      if (idx === 0) return
      const area = calcArea(plank1._plankBoundBox[idx])
      if (area > area2) {
        filterPolyArr.push({
          path,
          index: idx,
          key: 'polyArr',
          boundBoxKey: '_plankBoundBox',
        })
      }
    })
  }
  // 过滤出长圆孔轮廓边界框面积大于板件2外轮廓的数据
  if (curveHoles?.length) {
    curveHoles.forEach((_, idx) => {
      const path = plank1._curveHolesBoundBox[idx]
      if (!path) return
      const area = calcArea(path)
      if (area > area2) {
        filterPolyArr.push({
          path: plank1._curveHolesPoly[idx],
          index: idx,
          key: '_curveHolesPoly',
          boundBoxKey: '_curveHolesBoundBox',
        })
      }
    })
  }
  /**
   * 如存在数据说明板件1的内轮廓数据能够完全容纳板件2
   * 那么再次在判断可以容纳的内轮廓是否和板件2的外轮廓产生重叠
   * 产生重叠再使用clipper来判断是否真的可容纳
   */
  const finalArr: IContainIem[] = []
  if (filterPolyArr.length) {
    filterPolyArr.forEach((item) => {
      const { index, boundBoxKey } = item
      const outline = plank1[boundBoxKey][index]
      const isBound = judgeRectIntersect(outline, _plankBoundBox[0])
      if (isBound) {
        // 内轮廓和外轮廓有接触
        finalArr.push(item)
      }
    })
  }
  if (!finalArr.length) return isMerge
  // 寻找和板件2相互接触且板件1的内轮廓完全容纳板件2轮廓的数据, 存为真的结果表明存在轮廓完全包含的情况，则不冲突
  isMerge = finalArr.some((item) =>
    polyContain([item.path], [plank2.polyArr[0]])
  )
  return isMerge
}

/** 板件轮廓相关的字段 */
const outlineKeys = [
  '_plankBoundBox',
  '_curveHolesPoly',
  '_curveHolesBoundBox',
  'polyArr',
]
/** 检查板件内是否缺少轮廓字段 */
export function checkPlankOutlineKey(plank: PartType) {
  return outlineKeys.every((key) => Reflect.has(plank, key))
}

/** 获取和活跃板件的指定大小边界框相冲突的板件 */
export function getCrashPlankByActivePlank(
  activePlank: PartType,
  planks: PartType[],
  options?: { activePlankOffset: number }
) {
  // 检查是否存在关键轮廓数据，不存在则生成
  if (!checkPlankOutlineKey(activePlank)) {
    dealPlankPoly(activePlank)
  }
  const { _plankBoundBox, partUniqueId } = activePlank
  const { activePlankOffset = 0 } = options ?? {}
  const activeOutline = _plankBoundBox[0]
  // 为活跃板件做扩张可以触碰到更多的板件，为需要做更大检测做的处理
  const outline = {
    x1: activeOutline.x1 - activePlankOffset,
    y1: activeOutline.y1 - activePlankOffset,
    x2: activeOutline.x2 + activePlankOffset,
    y2: activeOutline.y2 + activePlankOffset,
  }
  // 过滤出非活跃板件且处于活跃板件的边界框内的其他板件
  return planks.filter((plank) => {
    if (partUniqueId !== plank.partUniqueId) {
      if (!checkPlankOutlineKey(plank)) {
        dealPlankPoly(plank)
      }
      return judgeRectIntersect(outline, plank._plankBoundBox[0])
    }
  })
}

/** 判断大板是否是余料大板 */
export function isSurplusBigPlank(bigPlank: any) {
  return !!(
    bigPlank.surplusInfo && Object.keys(bigPlank.surplusInfo).length > 0
  )
}
/** 判断大板是否是L型余料大板 */
export function isLShapeSurplusBigPlank(bigPlank: any) {
  return isSurplusBigPlank(bigPlank) && bigPlank.surplusInfo.shape === 'lshape'
}

/**
 * todo 后面再做这个
 * @description 键盘移动板件
 * @param e 键盘事件
 * @param targetPlank 目标板件
 * @param bigPlank 目标所在大板
 * @param ncSetting nc设置
 */
// export function plankMoveByKeyboard(
//   e: KeyboardEvent,
//   targetPlank: PartType,
//   bigPlank: any,
//   ncSetting: any
// ) {
//   // 记录移动之前的板件位置
//   const {
//     startX: beforeStartX,
//     startY: beforeStartY,
//     _plankBoundBox,
//   } = targetPlank
//   // 板件间隙
//   const gap = 10
//   const moveDis = 20
//   let newStartX = beforeStartX
//   let newStartY = beforeStartY
//   switch (e.key) {
//     case 'ArrowUp':
//       // targetPlank.y -= ncSetting.ncMoveStep
//       break
//     case 'ArrowDown':
//       // targetPlank.y += ncSetting.ncMoveStep
//       break
//     case 'ArrowLeft':
//       // targetPlank.x -= ncSetting.ncMoveStep
//       break
//     case 'ArrowRight':
//     // targetPlank.x += ncSetting.ncMoveStep
//   }
// }

/** 根据图片URL和期望的最大尺寸，计算并返回适合的展示尺寸 */
export async function calculateImageDisplaySize(
  imgUrl: string,
  maxWidth = AwaitStoreImageSize.width,
  maxHeight = AwaitStoreImageSize.height
) {
  return new Promise<{ width?: string; height?: string } | null>((resolve) => {
    const img = new Image()
    img.src = imgUrl
    img.onload = function (this: any) {
      const scaleW = Number(this.width) / maxWidth
      const scaleH = Number(this.height) / maxHeight
      let newWidth, newHeight
      if (this.width <= maxWidth && this.height <= maxHeight) {
        if (scaleW >= scaleH) {
          newWidth = this.width * (maxWidth / this.width) + 'px'
          newHeight = this.height * (maxWidth / this.width) + 'px'
        } else {
          newWidth = this.width * (maxHeight / this.height) + 'px'
          newHeight = this.height * (maxHeight / this.height) + 'px'
        }
      } else if (this.width > maxWidth && this.height > maxHeight) {
        if (scaleW >= scaleH) {
          newWidth = Number(this.width) / scaleW + 'px'
          newHeight = Number(this.height) / scaleW + 'px'
        } else {
          newWidth = Number(this.width) / scaleH + 'px'
          newHeight = Number(this.height) / scaleH + 'px'
        }
      } else if (this.width <= maxWidth && this.height > maxHeight) {
        newWidth = Number(this.width) / scaleH + 'px'
        newHeight = Number(this.height) / scaleH + 'px'
      } else if (this.width > maxWidth && this.height <= maxHeight) {
        newWidth = Number(this.width) / scaleW + 'px'
        newHeight = Number(this.height) / scaleW + 'px'
      }
      resolve({ width: newWidth, height: newHeight })
    }
    img.onerror = function () {
      console.error('Failed to load image')
      resolve(null) // 图片加载失败时返回null
    }
  })
}

/** 判断当前鼠标位置是否在元素内 */
export function mousePointISInElement(
  e: MouseEvent,
  elementName: string,
  type: 'class' | 'id' = 'class'
) {
  const target = type === 'class' ? `.${elementName}` : `#${elementName}`
  const areaContentDom = document.querySelector(target)
  if (!areaContentDom) return false
  const areaContentMsg = areaContentDom.getBoundingClientRect()
  const xmin = areaContentMsg.left
  const xmax = areaContentMsg.left + areaContentMsg.width
  const ymin = areaContentMsg.top
  const ymax = areaContentMsg.top + areaContentMsg.height
  const { x, y } = e
  if (xmin <= x && x <= xmax && ymin <= y && y <= ymax) {
    return true
  }
  return false
}

/** 在指定大板上清除指定小板 */
export function clearPlankToBigPlank(
  targetPlank: PartType,
  bigPlank: { parts: PartType[] }
) {
  const findIndex = bigPlank.parts.findIndex((plank) =>
    isSamePart(plank, targetPlank)
  )
  if (findIndex === -1) return
  // 在当前大板中清除目标小板
  bigPlank.parts.splice(findIndex, 1)
  return {
    index: findIndex,
  }
}

/**
 * @description 判断当前板件指定的点位是否可以添加到大板内
 * @param point 目标起始点位
 * @param plank 需要添加的板件
 * @param bigPlank 目标大板
 */
export function judgePlankPushBigPlank(
  point: PointType,
  plank: PartType,
  bigPlank: any
) {
  const { x, y } = point
  plank.startX = x
  plank.startY = y
  // 处理轮廓
  dealPlankPoly(plank)
  return checkOnePlank(plank, bigPlank)
}

/** 是否存在下刀点和顺序 */
export function isExistPriority(plank: PartType) {
  return Reflect.has(plank, 'priority') && Reflect.has(plank, 'cutOrigin')
}

/**
 * 对store中的finalDrawData最终的绘制数据进行一个循环，对每一个的板件操作
 * @param func 需要执行的循环函数
 */
export function loopDrawData(func: (plank: PartType) => void) {
  const { finalDrawData } = store.state
  finalDrawData.forEach((drawData) => {
    drawData.data.forEach((bigPlank: any) => {
      bigPlank.parts.forEach((plank: PartType) => {
        func(plank)
      })
    })
  })
}

/** 获取板件的下一个顺序 */
export function getPlankNextIndex() {
  let maxPriority = 0
  const dealFunc = (plank: PartType) => {
    maxPriority = Math.max(maxPriority, +(plank.priority ?? 0))
  }
  loopDrawData(dealFunc)
  return maxPriority + 1
}

/** 生成唯一id,不存在id才生成 */
export function genPartUniqueId(plank: PartType) {
  if (!plank.partUniqueId) {
    plank.partUniqueId = nanoid()
  }
  return plank
}

/** 获取板件材质 */
export function getPlankMatCode(plank: PartType) {
  const matCode = plank.is_high_gloss_plank
    ? plank.matCode.includes('高光')
      ? plank.matCode
      : '高光_' + plank.matCode
    : plank.matCode
  return matCode
}

/**
 * @description 生成一个新的大板数据，需注意传入的参数，此方法只是为了规范大板创建时所需要的参数，并无其他用途
 * @param bigPlank 需要的参数
 */
export function addNewBigPlank(bigPlank: IBigPlank) {
  // 为大板添加轮廓信息
  dealBigPlank(bigPlank)
  return bigPlank
}

// 根据高光属性值，返回值小板的材质
export function getMatCodeByHighPlankProp(
  isHighPlank: boolean,
  matCode: string
) {
  // 1. 如果不是高光，则将材质开头的高光_删掉
  // if (!isHighPlank) return matCode.replace('高光_', '')
  // 2. 如果是高光，则将材质开头的高光_保留
  // else
  //   return matCode.startsWith('高光_') ? matCode : matCode.replace('高光_', '')
  return isHighPlank ? matCode : matCode.replace('高光_', '')
}

/**
 * @description 根据提供的id在料单原始数据(preLayoutData)中查找是否存在此id，如果存在则返回寻找到的板件
 * @param oriPartUniqueId 当前板件的oriPartUniqueId
 */
export function getOriPartByLayout(oriPartUniqueId: string) {
  return store.state.preLayoutData.find(
    (item: PartType) => item.partUniqueId === oriPartUniqueId
  )
}

/** 检查是否有槽影响扩板 */
export function checkHasExtendSlot(plank: any) {
  const symbol = `${plank.texture}:${plank.matCode}:${plank.thick}`
  const diameter = Number(getPlateKnifeDiameter(symbol, store.state.ncSetting)) //获取刀直径
  const hasExtendSlot = plank.parts.some((part: any) => {
    const expandObj = dealExpandSlot(part, store.state.ncSetting, diameter / 2)
    return expandObj && Object.values(expandObj).some((p) => Boolean(p))
  })
  return hasExtendSlot
}

/**
 * 判断传入的两个对象的 颜色材质厚度是否相同
 * @param part1 板件1 任意拥有颜色材质厚度的数据
 * @param part2 板件2 任意拥有颜色材质厚度的数据
 */
export function isPlankTypeSame(part1: PlankTexture, part2: PlankTexture) {
  return (
    part1.matCode === part2.matCode &&
    part1.thick == part2.thick &&
    part1.texture === part2.texture
  )
}
