// Qt.include("GGBomExport.js")
// Qt.include("../../Project/Funcs.js")
// Qt.include("LayoutToolNew.js")
// Qt.include("../MaterialBill/MaterialFuncs.js")
// Qt.include("../../JavaScriptHttpRequest.js")
import { getAllKnifes } from '@/apis/paiban'
import { translateLang } from '@/data/i18n/translateLang'
import { judgeRectangle } from '@/util/commonFun'
import {
  uniquePlankNum as createUniquePlankNum,
  dealNumber,
  isOtherSideMachineHole,
  isOtherSideMachineSlot,
} from '@/util/commonFuncs'
// import { rolloverPlank } from '@/util/LayoutFuncs.js'
import { dealPlankPoly, getPlateKnifeDiameter } from '@/util/dealPaibanData.js'
import { expandPath } from '@/util/dealPlankDataFuncs'
import {
  dealEdgeInfoChange,
  dealFormerEdgeChange,
  getKnifeByName,
  getPathMinMaxPoint,
} from '@/util/plankCommonFuncs'
import axios from 'axios'
import { nanoid } from 'nanoid'

import store from '../store'
import libCurve from './Curve.js'
import {
  adjustHoleDirection,
  dealExpandSlot,
  rotate180Part,
  rotatePart,
} from './LayoutTool.js'
import ClipperLib from './clipper_unminified.js'
import { dealPlankHoleSlotCSide } from './dealPaibanData.js'
import { ggidEncodeList } from './ggid'
import { compareNumbers } from './graphCalc'

var ncSettings
var dataForAll
var yunLayoutFirstData
var layoutCount
var roomInfos
var preLayoutData
var layoutPatchData
var surplusRect
var cutDirection
var throughTowSideToCut
var xyReverse
var startPosition
var initialCutDirection
var initialThroughTowSideToCut
var initialXyReverse
var initialStartPosition
var cutKnifeRadius
var plankWidth
var plankHeight
var plankEdgeOff
var layoutGap
var layoutData
var plankSizeList
var layoutMode
var ncSettings = {}

const strentchHingSetList = new Set([
  'STRENTCH',
  'STRENTCHTWO',
  'MIDSTRENTCH',
  'HINGE',
]) // 拉直槽和铰链孔
let specialRuleMap = new Map()
const specialSymbol = ['STRENTCHTWO', 'MORTISE', 'CCBTSlot']

export {
  layoutGap,
  plankEdgeOff,
  cutKnifeRadius,
  startPosition,
  ncSettings,
  specialSymbol,
}

function initProducionLine() {
  var config = ['默认生产线']
  //目前是只有生产线条数line_count大于0，produceLinePermits才有值
  var produceLinePermits = libGG.getGlobalVariable('bought_line_count')
  if (produceLinePermits !== 1) {
    exNcConfig = curTech.techParams.ExNCConfig
    for (var i in exNcConfig) {
      var name = exNcConfig[i].name
      config.push(name)
    }
  }
  productionLineCB.model = config
  productionLineCB.enabled =
    restrictedPopup.boughtLineEnabled() && produceLinePermits !== 1
}

// 拆分封边 ←A↓B→C↑D => left:A bottom:B right:C top:D
function splitEdgeInfo(edgeInfo) {
  var left = Number(
    edgeInfo.substring(edgeInfo.indexOf('←') + 1, edgeInfo.indexOf('↓'))
  )
  var bottom = Number(
    edgeInfo.substring(edgeInfo.indexOf('↓') + 1, edgeInfo.indexOf('→'))
  )
  var right = Number(
    edgeInfo.substring(edgeInfo.indexOf('→') + 1, edgeInfo.indexOf('↑'))
  )
  var top = Number(edgeInfo.substring(edgeInfo.indexOf('↑') + 1))
  return {
    left: left,
    right: right,
    top: top,
    bottom: bottom,
  }
}

//保留一定位数小数字
export function keepDecimalNumBySetting(ncConfig, num, isExpand, n) {
  if (!n || n !== 0) {
    n = Number(ncConfig.decimal)
    if (typeof n != 'number') {
      n = 2
    }
  }

  var result = parseFloat(num)
  if (typeof num != 'number') {
    return 0
  }

  var a = Math.pow(10, n)
  if (!isExpand) {
    result = Math.round(num * a) / a
  } else {
    result = Math.ceil(num * a) / a
  }

  return result
}

/**
 * @param dataForAll 排版数据
 * @param
 */
//初始化
export async function init(val, data) {
  //收集roomInfo
  fetchLayoutRoomInfos(val)

  layoutCount = dataForAll.length
  // preLayoutData = await startLayoutUseJson(roomInfos);
  preLayoutData = data
  uniquePlankNum(preLayoutData)

  return preLayoutData
}

function getLayoutQueue() {
  if (getCurrentTaskName() !== '批量输出') {
    return
  }
  if (!layoutToolChoose.visible) {
    return
  }

  libGG.layoutIndex = getCurrentTaskIndex()
  curPageIndex = getCurrentTaskIndex()
  if (libGG.dataForAll) {
    dataForAll = dataForAll.concat(libGG.dataForAll)
    libGG.dataForAll = []
  }
}

function initLayoutQueue() {
  if (
    libGG.layoutIndex &&
    libGG.layoutIndex >= 0 &&
    libGG.layoutIndex === curPageIndex
  ) {
    libGG.layoutIndex = -1
    libGG.dataForAll = []
  }
}

function fetchLayoutRoomInfos(val) {
  roomInfos = []
  var layoutQueue = []
  dataForAll = val
  for (var i in dataForAll) {
    var oneProject = dataForAll[i]
    var project = oneProject['projectInfo']
    var roomNames = []
    if (oneProject.hasOwnProperty('roomIds')) {
      var rIds = oneProject['roomIds']
      for (var j in rIds) {
        var rInfo = findRoomInfo(project, rIds[j])
        if (rInfo) {
          rInfo['remark'] = project['remark']
          rInfo['order_code'] = project['order_code']
          rInfo['buyer_address'] = project['buyer_address']
          rInfo['pname'] = project['name']
          rInfo['create_time'] = project['create_time']
          rInfo['customer_name'] = project['customer_name']
          rInfo['oId'] = project['id']

          roomNames.push(rInfo['name'])
          roomInfos.push(rInfo)
        }
      }
    } else {
      for (j in project['room']) {
        rInfo = project['room'][j]
        rInfo['remark'] = project['remark']
        rInfo['order_code'] = project['order_code']
        rInfo['buyer_address'] = project['buyer_address']
        rInfo['pname'] = project['name']
        rInfo['create_time'] = project['create_time']
        rInfo['customer_name'] = project['customer_name']
        rInfo['oId'] = project['id']
        roomNames.push(rInfo['name'])
        roomInfos.push(rInfo)
      }
    }
    layoutQueue.push({
      index: i,
      buyerAddress: project['buyer_address'],
      roomNames: roomNames,
    })
  }
  // taskRep.model = layoutQueue;
  layoutCount = layoutQueue.length
}

function findRoomInfo(pi, rid) {
  var rooms = pi['room']
  for (var i in rooms) {
    if (rid == rooms[i]['id']) {
      return rooms[i]
    }
  }
  return null
}
// complementList为补件信息, 可以从后端获取
function fetchComplementPlankKeys() {
  for (var i in complementList) {
    var tmpMap = complementList[i]
    var texture = tmpMap['texture'].slice(0, 6)
    var matCode = tmpMap['matCode']
    var thick = tmpMap['thick']
    var curKey = texture + ':' + matCode + ':' + thick
    if (plankKeysList.indexOf(curKey) == -1) {
      plankKeysList.push(curKey)
    }
  }
  for (var i in complementOrders) {
    var order = complementOrders[i]
    var plates = order['plates']

    for (var k in plates) {
      var plateInfo = plates[k]['plate_info']
      var size = plateInfo['size']
      var thick = Number(size.split('*')[2])

      var mat = plateInfo['color'] + ':' + plateInfo['matCode'] + ':' + thick
      if (plankKeysList.indexOf(mat) == -1) {
        plankKeysList.push(mat)
      }
    }
  }
}

//设置cutKnifeRadius
function setCutKnifeRadius() {
  var cut = ncSettings['cut']
  var knives = ncSettings['knives']
  var cutKnife = knives[cut['cutKnife']]
  if (cutKnife) {
    if (cutKnife['diameter'] !== '') {
      cutKnifeRadius = parseFloat(cutKnife['diameter']) / 2
    }
  }
}

//获取对穿孔检测
function getIgnoreThroughHole() {
  var ignoreThroughHole = false
  var settingInfo = settingService.getSettingByName('throughHole')
  if (settingInfo !== null && settingInfo !== undefined) {
    var tips = settingInfo['tips']
    if (tips !== null && tips !== undefined) {
      ignoreThroughHole = tips
    }
  }
  return ignoreThroughHole
}

//排版函数调用发送的信号 连接
function signalConnect() {
  timeRecord = getActualTime()
  gg.sLayoutPartial.connect(handleLayoutPartial)
  gg.sLayoutFinished.connect(handleLayoutFinish)
  gg.sLayoutError.connect(handlLayoutError)
  gg.sUpdateProcess.connect(handleUpdateProcess)
  gg.sAddOtherLayoutResult.connect(handleAddOtherLayoutResult)
}

//排版函数调用发送的信号 断开连接
function signalDisconnect() {
  gg.sLayoutPartial.disconnect(handleLayoutPartial)
  gg.sLayoutFinished.disconnect(handleLayoutFinish)
  gg.sLayoutError.disconnect(handlLayoutError)
  gg.sUpdateProcess.disconnect(handleUpdateProcess)
  gg.sAddOtherLayoutResult.disconnect(handleAddOtherLayoutResult)
  progressInfo.close(1)
}

//******************************************* 排版完成 各信号对应函数 **************************************************************
function handleLayoutPartial(oKey, oPriority, oResultMap, mLeftLayout) {
  if (!root || (offlineLayoutError && !isYunLayout)) {
    return
  }
  if (!layoutPlans[oPriority - 1]) {
    layoutPlans[oPriority - 1] = {}
  }
  var afterMap = T.partialProcess(oKey, oResultMap, layoutData)

  if (isYunLayout) {
    T.getYunLayoutPercent(oKey, afterMap, yunResultData)
  }

  layoutPlans[oPriority - 1][oKey] = afterMap

  partialLayoutResultHandle(afterMap, 4)
  progressInfo.updateProgress(1)
  layoutView.repaint()
}

function handleLayoutFinish() {
  if (offlineLayoutError && !isYunLayout) {
    hasFinished = true
    return
  }
  var layoutUseTime = getActualTime()
  var timeCoust = layoutUseTime - timeRecord
  setLayoutUseTime(timeCoust / 1000)

  var l = layoutResult.length
  for (var k in blockedBigPlates) {
    var stock = blockedBigPlates[k]
    k *= 1
    l *= 1
    stock['stockNum'] = k + l
    stock['block'] = true
    var parts = stock['parts']
    for (var j in parts) {
      var part = parts[j]
      part['stockNum'] = k + l
      var realRect = part['realRect']
      var realW = realRect.width
      var realH = realRect.height
      smallPlateArea += realH * realW
    }
    layoutResult.push(stock)
  }

  blockedStockIdx = []
  flushView()

  progressInfo.close(1)

  if (!root) {
    return
  }

  if (layoutMode !== 'complement') {
    ggData = generator(layoutData, 1)
  }

  ifHoleandSlotError(layoutResult)

  for (var i in layoutResult) {
    var stock = layoutResult[i]
    var parts = stock['parts']
    var area = 0
    for (var p in parts) {
      var part = parts[p]
      var pWidth = part['plankWidth']
      var pHeight = part['plankHeight']
      T.rotatePlankWhenSlotAtTheEdge(part, pWidth, pHeight)
    }
  }
  rollCount = T.getRollCount(layoutResult)
  layoutResult = T.sortTheStock(layoutResult)
  for (var i in layoutResult) {
    stock = layoutResult[i]
    if (stock['block']) {
      blockedStockIdx.push(i * 1)
    }
  }

  layoutView.repaint()

  hasFinished = true
  initLayoutQueue()

  //电子开料锯排版排完了 打开对应文件夹
  if (onlyNormalPlate) {
    gg.openLocalPath(chooseElecType.path)
  }
  yunLayoutFirstData = libGG.parseJson(JSON.stringify(layoutView.layoutList))

  //自动保存到本地
  //        (function(){
  //            var  file = gg.getTempRoot() + "layoutLocalSave/layoutPlan/" + getNowDate(true) + ".json";
  //            curlocalLayoutPath = file;

  //            if(!gg.isExistFile(file)) {

  //                var obj = {
  //                    smallPlateArea:smallPlateArea,
  //                    bigPlateArea:bigPlateArea,
  //                    total:total,
  //                    rollCount:rollCount,
  //                    statisticObj:JSON.stringify(statisticObj),
  //                    layoutResult:JSON.stringify(layoutResult),
  //                    timeCoust:s,
  //                    recordDate:getNowDate(false),
  //                    isUploaded:false
  //                }

  //                gg.saveStringToFile(JSON.stringify(obj), file);
  //            }
  //        })()
  loadIndicator1.close()
}

function handlLayoutError(oErrorInfo, oPriority) {
  if (!root) {
    return
  }
  offlineLayoutError = true
  layoutResult = []
  bigPlateArea = 0
  smallPlateArea = 0
  rollCount = 0
  if (!layoutPlans[oPriority - 1]) {
    layoutPlans[oPriority - 1] = {}
  }
  flushView()
  main_msgTips.open(oErrorInfo, 'fail', true)
}

function handleUpdateProcess(oValue, oTotal) {
  if (!root) {
    return
  }
  loadIndicator.updateProgress(oValue / oTotal)
}

function handleAddOtherLayoutResult(oPriority, oResultLst) {
  if (!root) {
    return
  }
  if (!layoutPlans[oPriority - 1]) {
    layoutPlans[oPriority - 1] = {}
  }
  layoutPlans[oPriority - 1] = T.afterProcess(oResultLst, layoutData)

  //        if(oPriority !== 1) {
  //            gg.generateOtherLayoutResult(layoutRectMap, layoutHoleMap,
  //                                         layoutConfig, 1);
  //        }
}

function partialLayoutResultHandle(resultMap, index) {
  var tmp = T.handlePartialResult(resultMap, index % 2)
  var testPath = gg.getTempRoot() + 'yuntest_cut.ini'
  if (isYunLayout) {
    layoutResult = layoutResult.concat(tmp)
  } else {
    for (var i in tmp) {
      var part = tmp[i]
      part['plankWidth'] += (plankEdgeOff - cutKnifeRadius) * 2 - layoutGap
      part['plankHeight'] += (plankEdgeOff - cutKnifeRadius) * 2 - layoutGap
    }

    layoutResult = layoutResult.concat(setCutPriority(tmp))
  }

  blockedStockIdx = []
  flushView()
}

//******************************************* 排版完成 各信号对应函数 以上 **************************************************************

//使用layoutResult生产GGBom
function genGGDataUseLayoutResult(layoutResult) {
  var planks = []

  //搜集小板
  for (var i in layoutResult) {
    //大板
    var bigPlate = layoutResult[i]
    var bParts = bigPlate['parts']
    if (!bParts) {
      continue
    }
    planks = planks.concat(bParts)
  }

  ggData = generator(planks, 1)
}

//设置下刀点
function setCutPriority(data) {
  if (isYunLayout == true) {
    return PYUN.setCutPriority(data)
  }

  if (isTestLayoutPlank == true) {
    return PV3.setCutPriority(data)
  }

  if (priorityVer == '1.0') {
    return P.setCutPriority(data)
  } else if (priorityVer == '2.0') {
    return PV2.setCutPriority(data)
  }
}

//获取标签 (上传标签数据到服务器)
function getSurplusTags() {
  var uid = userService.uid
  var surplus = []

  var layoutResult = layoutView.layoutList

  for (var i in layoutResult) {
    var stock = layoutResult[i]
    var type = stock['matCode']
    var color = stock['texture']
    var parts = stock['parts']
    var plankWidth = stock['plankWidth']
    var plankHeight = stock['plankHeight']

    var array = []
    for (var j in parts) {
      var part = parts[j]
      var rect = part['rect']
      var path = part['path']

      var startX = part['startX']
      var startY = part['startY']
      var address = part['address']
      var roomName = part['roomName']
      var thick = part['thick']

      for (var k in path) {
        for (var m in path[k]) {
          var x = path[k][m]['x']
          var y = path[k][m]['y']
          x = jjFixed2(x)
          y = jjFixed2(y)
          path[k][m]['x'] = x
          path[k][m]['y'] = y
        }
      }

      var x = rect.x
      var y = rect.y
      x = jjFixed2(x)
      y = jjFixed2(y)
      rect = { x: x, y: y, width: rect.width, height: rect.height }
      array.push({
        plankWidth: plankWidth,
        plankHeight: plankHeight,
        rect: rect,
        path: path,
        startX: startX,
        startY: startY,
        roomName: roomName,
        address: address,
      })
    }

    surplus.push({
      type: type,
      uid: uid,
      color: color,
      parts: JSON.stringify(array),
      spec: thick + type,
    })
  }

  var result = dbProjectObj.uploadSurplus({ surplus: surplus })
  var resData = result.data
  for (var k in resData) {
    var ggid = resData[k].ggid
    resData[k].ggid = 'http://eggi.cn/' + ggid
    resData[k].parts = libGG.parseJson(resData[k].parts)
  }

  layoutSurplus = result.data
}

//排版调用函数
function loadLayout() {
  // 重置板件的宽高
  plankWidth = store.state.selectStandardPlank.plankWidth
  plankHeight = store.state.selectStandardPlank.plankHeight
  plankEdgeOff = store.state.selectStandardPlank.plankEdgeOff
  layoutGap = store.state.ncSetting.panelSize.layoutGap

  return {
    layout: layout(),
    userNcPlankInfo: ncSettings,
  }
}

function complementTool() {
  loadIndicator.start(complement, {}, 1)
}

function doYunLayout(type) {
  layoutPlans = []
  layoutResult = []
  bigPlateArea = 0
  smallPlateArea = 0
  rollCount = 0

  var data = layoutWorker(true)
  if (!data) {
    loadIndicator.hide()
    loadIndicator1.close()
    return
  }

  if (!gg.checkSizeLimit(data)) {
    main_msgTips.open('存在过大的板!', 'fail')
    loadIndicator.hide()
    loadIndicator1.close()
    return
  }
  judgeUseSurplus(data['layoutRectMap'], data['layoutConfig'])
  //    startYunLayout(data)
}

function startYunLayout(data) {
  signalDisconnect()
  signalConnect()

  var type = yunLayoutType
  var surplus = surplusList

  //班鲁NC暂不调整下刀点
  // if (initialIsBluenOutput && !initialXyReverse) {
  if (true && !initialXyReverse) {
    data['layoutConfig']['changeKnifePosition'] = false
  }

  //获取工厂单号
  var order_code = []
  var oids = []

  if (layoutMode !== 'complement') {
    for (var i in dataForAll) {
      var tmpId = dataForAll[i]['projectInfo']['id']
      var tmpCode = dataForAll[i]['projectInfo']['order_code']
      if (order_code.indexOf(tmpCode) < 0) {
        order_code.push(tmpCode)
      }
      if (oids.indexOf(tmpId) < 0) {
        oids.push(tmpId)
      }
    }
  } else {
    for (i in complementOrders) {
      tmpId = complementOrders[i]['oid']
      tmpCode = complementOrders[i]['order_code']
      if (order_code.indexOf(tmpCode) < 0) {
        order_code.push(tmpCode)
      }
      if (oids.indexOf(tmpId) < 0) {
        oids.push(tmpId)
      }
    }
    for (i in complementList) {
      tmpCode = complementList[i]['order_code']
      if (order_code.indexOf(tmpCode) < 0) {
        order_code.push(tmpCode)
      }
    }
  }

  //顺带补件的工厂单号
  for (i in layoutPatchData) {
    tmpId = layoutPatchData[i]['oid']
    tmpCode = layoutPatchData[i]['orderNo']
    if (order_code.indexOf(tmpCode) < 0) {
      order_code.push(tmpCode)
    }
    if (tmpId && oids.indexOf(tmpId) < 0) {
      oids.push(tmpId)
    }
  }

  var load = dbProjectObj.yunLayoutNew({
    oids: oids.join(','),
    id: order_code.join(','),
    uid: userService.uid,
    layout_data: data,
    type: type,
    surplus: surplus,
    cutDirection: cutDirection,
  })
  loadIndicator1.start(load, null, 1, plankKeysList.length * 200 + 100)
}

//获取累计排版数量
function statisticsPaiban(day) {
  day = day ? day : 1
  var param = {
    uid: userService.uid,
    recent_day: day,
  }
  globalJSTool.statisticsPaiban(param, function (oData) {
    if (oData.status === 1) {
      secondMenuTab.yunRankCount = oData.data.paiban_count
    }
  })
}

function layoutFromYunToLocal() {
  isYunLayout = false
}

//单个房间排版
function layout() {
  getLayoutData()

  var layoutPlans = []
  var layoutResult = []
  var bigPlateArea = 0
  var smallPlateArea = 0
  var rollCount = 0
  return layoutWorker(true)
  // visible = true;
}

//获取排版使用的数据
function getLayoutData() {
  // 聚类
  const dataGroup = preLayoutData.reduce((pre, cur) => {
    const curPartUniqueId = cur.partUniqueId
    if (pre[curPartUniqueId]) {
      pre[curPartUniqueId].amount = pre[curPartUniqueId].amount + 1
    } else {
      pre[curPartUniqueId] = cur
    }
    return pre
  }, {})

  const val = Object.values(dataGroup)

  layoutData = deepCopy(val)
  layoutData = layoutData.concat(layoutPatchData)
  for (let i = layoutData.length - 1; i >= 0; --i) {
    // 根据数量添加相同参数
    let amount = Number(layoutData[i].amount)
    if (Number(amount) > 1) {
      for (let k = 0; k < amount - 1; ++k) {
        let plank = deepCopy(layoutData[i])
        layoutData.push({
          ...plank,
          labelId: dealNumber(plank['labelIndexRangeArr'][0] + k + 1),
          oriPartUniqueId: plank.partUniqueId,
          partUniqueId: nanoid(),
        })
      }
    }
  }
  // 多个不同材质刀，需要逐步添加
  if (store.state.isSinglePushPrePaibanData) {
    store.commit('setPrePaibanData', [
      ...store.state.prePaibanData,
      ...layoutData,
    ])
  } else {
    store.commit('setPrePaibanData', layoutData)
  }

  for (var j in layoutData) {
    autoRollOver(layoutData[j], true)
  }
}

function addedLayout(container) {
  layoutData = container

  layoutPlans = []
  layoutResult = []
  bigPlateArea = 0
  smallPlateArea = 0
  rollCount = 0
  layoutWorker()
  visible = true
}

// plankNum 去重
function uniquePlankNum(parts) {
  const { uniqueBarCode, uniqueBarCodeForPack } = store.state.ncSetting
  const hash = {}
  for (let i = 0; i < parts.length; ++i) {
    const part = parts[i]
    if (!part.oriPlankNum) part.oriPlankNum = ''
    let { oriPlankNum, guid, roomName } = part
    oriPlankNum += ''
    if (!Reflect.has(hash, oriPlankNum)) {
      hash[oriPlankNum] = { guid, roomName, count: 1 } // 记录出现次数
      continue
    } else if (
      hash[oriPlankNum].guid === guid &&
      hash[oriPlankNum].roomName === roomName &&
      !uniqueBarCode &&
      !uniqueBarCodeForPack
    ) {
      // guid roomName相同的板件保证PlankNum相同
      continue
    }
    if (uniqueBarCodeForPack) {
      part.plankNum += `${hash[oriPlankNum].count}`
      part.oriPlankNum += `${hash[oriPlankNum].count}`
      hash[oriPlankNum].count += 1 // 增加计数
    } else {
      const plankNum = createUniquePlankNum.createUniquePlankNum()
      part.plankNum = plankNum
      part.oriPlankNum = plankNum
    }
    // hash[oriPlankNum] = {}
    hash[oriPlankNum] = { guid, roomName, count: hash[oriPlankNum].count } // 更新hash
  }
  createUniquePlankNum.plankNumCollect.clear()
}

export function noRepeatPlankNum(paibanData) {
  const keysArr = Object.keys(paibanData)

  const paibanDataLengthArr = keysArr.map(
    (key) => paibanData[key].data[0].length
  )
  const partsArr = keysArr.map((key) => paibanData[key].data[0]).flat(1)
  uniquePlankNum(partsArr)

  keysArr.forEach((key, index) => {
    const countLength = !index
      ? 0
      : arrItemSum(paibanDataLengthArr.slice(0, index))
    paibanData[key].data[0] = partsArr.slice(
      countLength,
      countLength + paibanDataLengthArr[index]
    )
  })
}

// 数组元素求和
export function arrItemSum(arr) {
  return arr.reduce((a, b) => a + b)
}

//补件清单：开始排版
function complement() {
  layoutData = deepCopy(complementList)
  var tmpComData = fetchcomplementData(complementOrders)
  if (!tmpComData) {
    layoutData = []
    loadIndicator.hide()
    main_msgTips.open(gg.getTrans('补件数据有误，请重新保存房间补件!'), 'fail')
    return
  }

  layoutData = layoutData.concat(tmpComData)
  for (var j in layoutData) {
    autoRollOver(layoutData[j], isAutoRoll)
  }

  ggData = GGBom.generator(layoutData)

  layoutPlans = []
  layoutResult = []
  bigPlateArea = 0
  smallPlateArea = 0
  rollCount = 0

  signalDisconnect()
  signalConnect()

  if (isYunLayout) {
    doYunLayout()
  } else {
    layoutWorker()
  }

  visible = true
}

//重新排版
function reLayout() {
  //        reLayoutBtn.visible = false;
  offlineLayoutError = false
  layoutView.rotateImgHide()
  resetPlank()
  surplusRect = []
  plankSizeList = []
  //gg.sAddOtherLayoutResult.disconnect(handleAddOtherLayoutResult);
  //gg.sAddOtherLayoutResult.connect(handleAddOtherLayoutResult);

  var blockedStocks = []
  var unBlockedStocks = []
  var layoutlist = ncGenerator.viewDataToNCData(layoutView.layoutList)
  for (var i in layoutlist) {
    var parts = layoutlist[i]['parts']
    for (var j in parts) {
      autoRollOver(parts[j], isAutoRoll)
    }
  }
  updateNCConfig()
  layoutView.selectPart = null

  for (var i = 0; i < layoutlist.length; i++) {
    var stock = layoutlist[i]
    if (blockedStockIdx.indexOf(i) != -1) {
      blockedStocks.push(stock)
    } else {
      unBlockedStocks.push(stock)
    }
  }

  layoutData = []
  layoutPlans = []
  layoutResult = []
  bigPlateArea = 0
  smallPlateArea = 0
  rollCount = 0
  for (var j = 0; j < unBlockedStocks.length; j++) {
    var stock1 = unBlockedStocks[j]
    var parts = stock1['parts']
    layoutData = layoutData.concat(parts)
  }

  ignorePlankList = []
  for (var k = 0; k < blockedStocks.length; k++) {
    var stock2 = blockedStocks[k]
    ignorePlankList = ignorePlankList.concat(stock2['parts'])
  }

  layoutData.forEach(function (part) {
    var pName = part['partName']
    //对拉直器样式2的板子进行扩张
    var slots = part['slots']
    var extendHDis = 0,
      extendVDis = 0
    for (var index in slots) {
      var slot = slots[index]
      if (slot['symbol'] === 'STRENTCHTWO') {
        if (slot['width'] > layoutGap + cutKnifeRadius * 2) {
          if (slot['pt1']['x'] === slot['pt2']['x']) {
            extendVDis = slot['width'] - (layoutGap + cutKnifeRadius * 2)
          }
          if (slot['pt1']['y'] === slot['pt2']['y']) {
            extendHDis = slot['width'] - (layoutGap + cutKnifeRadius * 2)
          }
        }
        break
      }
    }
    if (!extendVDis && !extendHDis) {
      return
    }

    var rectWid = part.rect.width
    var rectHei = part.rect.height

    if (part.rect) {
      part.rect = Qt.rect(
        part.rect.x,
        part.rect.y,
        rectWid + 2 * extendHDis,
        rectHei + 2 * extendVDis
      )
    }
  })

  var rectMap = {}

  for (var m in layoutData) {
    var rect = layoutData[m].rect
    var plk = layoutData[m]

    if (!rect) {
      continue
    }

    var thick = layoutData[m].thick
    var texture = layoutData[m].texture

    var matCode = layoutData[m].matCode

    var key = texture + ':' + matCode + ':' + thick

    if (!rectMap.hasOwnProperty(key)) {
      rectMap[key] = []
    }

    var needRoll = layoutData[m].needRoll
    var rotate = layoutData[m].matRotatable
    rect = Qt.rect(rect.x, rect.y, rect.width, rect.height)

    var rectInfo = {}
    rectInfo['rect'] = rect
    rectInfo['needRoll'] = needRoll
    rectInfo['rotate'] = rotate
    if (layoutData[m]['changedTexDir'] != undefined) {
      //变成了无纹理状态
      rectInfo['rotate'] = layoutData[m]['changedTexDir']
    }
    rectMap[key].push(rectInfo)
    layoutData[m].index = m + 1
    layoutData[m].rectInd = rectMap[key].length
  }

  setLayoutData(layoutData)

  var decimal = Number(ncSettings.decimal)
  if (typeof decimal != 'number') {
    decimal = 2
  }

  // 提取配置信息
  var config = {
    outWidth: plankWidth,
    outHeight: plankHeight,
    margin: plankEdgeOff,
    gap: layoutGap,
    knifeR: cutKnifeRadius,
    startPosition: startPosition,
    changeKnifePosition: true,
    xyReverse: initialXyReverse,
    decimal: decimal,
  }
  blockedBigPlates = blockedStocks

  var holeMap = {}
  layoutlist.forEach(function (part, i) {
    if (blockedStockIdx.indexOf(i) != -1) {
      return
    }

    if (part.hasOwnProperty('holes')) {
      var stockKey = part['stockKey']
      var spec = stockKey.substring(0, stockKey.lastIndexOf(':'))
      var stockNum = stockKey.substring(stockKey.lastIndexOf(':') + 1)

      if (!holeMap.hasOwnProperty(spec)) {
        holeMap[spec] = {}
      }

      var holes = part['holes']

      holes.forEach(function (hole) {
        var x = hole['x']
        var y = hole['y']
        var width = hole['width']
        var height = hole['height']

        // 调整排版原点
        if (startPosition === '左上角') {
        } else if (startPosition === '左下角') {
          hole['y'] = plankHeight - (y + height)
        } else if (startPosition === '右下角') {
          hole['x'] = plankWidth - (x + width)
          hole['y'] = plankHeight - (y + height)
        } else if (startPosition === '右上角') {
          hole['x'] = plankWidth - (x + width)
        }
      })
      holeMap[spec][stockNum] = holes
    }
  })

  hasFinished = false
  layoutView.needCheck = true

  judgeUseSurplus(rectMap, config)

  // progressInfo.open(Object.keys(rectMap).length, "排版中1:", 1);
}

//排版保存到本地
function saveLayoutDataToLocal(layoutName) {
  var file =
    gg.getTempRoot() +
    'layoutLocalSave/layoutPlan/' +
    getNowDate(true) +
    '.json'
  curlocalLayoutPath = file

  if (!gg.isExistFile(file)) {
    var precent =
      bigPlateArea == 0
        ? (yunResultData['total_used_rate'] * 100).toFixed(2)
        : (smallPlateArea * 100) / bigPlateArea //计算综合出材率 云排版可直接取排版率

    var obj = {
      smallPlateArea: smallPlateArea,
      bigPlateArea: bigPlateArea,
      total: total,
      rollCount: rollCount,
      statisticObj: JSON.stringify(statisticObj),
      layoutResult: JSON.stringify(
        ncGenerator.viewDataToNCData(layoutView.layoutList)
      ),
      timeCoust: layoutUseTime,
      recordDate: getNowDate(false),
      isUploaded: false,
      layoutName: layoutName,
      precent: precent,
    }

    gg.saveStringToFile(JSON.stringify(obj), file)
    if (gg.isExistFile(file)) {
      main_msgTips.open(gg.getTrans('保存成功！'), 'success')
    } else {
      main_msgTips.open(gg.getTrans('保存失败！'), 'fail')
    }
  }
}

//排版保存到本地
function saveLayoutDataToLocalWithNc() {
  var result = getNameList(projectInfo, roomInfos, layoutType)

  var layoutName
  if (result) {
    var projectNameList = result['projectNameList']
    var roomNameList = result['roomNameList']
    var room_id = result['room_id']

    if (room_id) {
      layoutName =
        projectNameList.join('+') +
        '-' +
        roomNameList.join('   ') +
        '-' +
        getNowDate(false)
    } else {
      layoutName =
        projectNameList.join('+') + '-' + '批量生产' + '-' + getNowDate(false)
    }
  }

  var file = gg.getTempRoot() + 'layout/layout.json'
  var obj = {
    smallPlateArea: smallPlateArea,
    bigPlateArea: bigPlateArea,
    total: total,
    rollCount: rollCount,
    statisticObj: JSON.stringify(statisticObj),
    layoutResult: JSON.stringify(layoutResult),
    timeCoust: layoutUseTime,
    recordDate: getNowDate(false),
    isUploaded: false,
    layoutName: layoutName,
    version: gg.getLocalConfig('version'),
    psd: gg.getGlobalProperty('pwdtoWeb'),
    phone: gg.getGlobalProperty('nametoWeb'),
  }

  gg.saveStringToFile(JSON.stringify(obj), file)
}

//自动保存
function autoSave() {
  var file = '' //文件存储路径
  var packsFile = '' //文件存储路径
  var projectIDList = [] //订单id数组
  var backName = ''
  var room_id //房间Id

  var result = getNameList(projectInfo, roomInfos, layoutType)
  file = ''
  packsFile = ''

  if (result) {
    var projectNameList = result['projectNameList']
    var roomNameList = result['roomNameList']
    projectIDList = result['projectIDList']
    room_id = result['room_id']

    if (room_id) {
      backName =
        projectNameList.join('+') +
        '-' +
        roomNameList.join('   ') +
        '-' +
        getNowDate(false)
    } else {
      backName = projectNameList.join('+') + '-' + getNowDate(false)
    }

    file = gg.getTempRoot() + 'layout/layoutPlan/' + getNowDate(true) + '.json'
    packsFile = gg.getTempRoot() + 'layout/packs/' + getNowDate(true) + '.json'
  }
  var saveName = backName + ' 排版方案'
  if (!gg.isExistFile(file) || !gg.isExistFile(packsFile)) {
    gg.saveStringToFile(JSON.stringify(layoutResult), file)

    var str = jjSaveUnit(packs)

    gg.saveStringToFile(str, packsFile)

    if (gg.isExistFile(file) && gg.isExistFile(packsFile)) {
      //保存到本地.ggp排版文件
      var day = Qt.formatDateTime(new Date(), 'yyyy-MM-dd')
      var localSavePath = gg.getAppAbsolutePath() + 'nc' + '/' + day + '/'

      gg.mkdir(localSavePath)

      for (var i in projectNameList) {
        projectNameList[i] = projectNameList[i].replace(':', '-')
        projectNameList[i] = projectNameList[i].replace(':', '-')
      }

      if (projectNameList.length > 1) {
        localSavePath += projectNameList.join('_') + '/批量订单-排版文件.ggp'
      } else {
        var rooms = roomNameList[0].split(',')
        if (rooms.length > 1) {
          localSavePath +=
            projectNameList[0] + '/' + projectNameList[0] + '-排版文件.ggp'
        } else {
          localSavePath +=
            projectNameList[0] +
            '_' +
            rooms[0] +
            '/' +
            rooms[0] +
            '-排版文件.ggp'
        }
      }
      localSavePath = localSavePath.replace(' ', '-')

      var localSaveData = { layout: layoutResult, packs: libGG.parseJson(str) }

      //localSavePath = localSavePath + customerAddress+"-排版文件.ggp"
      gg.saveStringToFile(JSON.stringify(localSaveData), localSavePath)

      var layoutUrl = dbProductObj.uploadOneJinToOss(file)
      var packsUrl = dbProductObj.uploadOneJinToOss(packsFile)
      var address = 'https://eggrj.oss-cn-hangzhou.aliyuncs.com/' + layoutUrl
      var address_packs =
        'https://eggrj.oss-cn-hangzhou.aliyuncs.com/' + packsUrl

      var param = {
        oid: projectIDList,
        name: saveName,
        layout_url: address,
        uid: userService.uid,
        parts: address_packs,
        room_id: room_id,
        //info: address_info
      }

      dbProjectObj.saveLayout(param)
    }
  } else {
    //main_msgTips.open("该方案已经保存", "fail");
  }
}

function getEdgesInfo(da, db, dc, dd, qEdgeInfo) {
  if (!qEdgeInfo) {
    return {
      bda: 0,
      bdb: 0,
      bdc: 0,
      bdd: 0,
    }
  }
  var str1 = qEdgeInfo.split(da)[1]
  var fbound
  if (str1) {
    fbound = str1.split(db)[0]
  } else {
    fbound = 0
  }
  var str2 = str1.split(db)[1]
  var rbound
  if (str2) {
    rbound = str2.split(dc)[0]
  } else {
    rbound = 0
  }
  var str3 = str2.split(dc)[1]
  var bbound, lbound
  if (str3) {
    bbound = str3.split(dd)[0]
    lbound = str3.split(dd)[1]
  } else {
    bbound = 0
    lbound = 0
  }
  return {
    bda: fbound * 1,
    bdb: rbound * 1,
    bdc: bbound * 1,
    bdd: lbound * 1,
  }
}

//过滤掉非异形的板件
function filtrationNomalPlank(data) {
  var result = []
  for (var i in data) {
    var td = data[i]
    if (td.path) {
      result.push(td)
    }
  }
  return result
}
function judgeNeedRoll(layoutData) {
  if (!store.state.isSurplusNoRoll) return layoutData.needRoll
  let {
    apertureSlotConcentrated,
    holeConcentrated,
    xyReverse,
    highgloss_direction,
  } = store.state.ncSetting
  let isThroughHole = layoutData.holes.some(
    (hole) => hole.deep >= layoutData.thick
  )
  let isThroughSlot = layoutData.slots.some(
    (slot) => slot.deep >= layoutData.thick
  )
  if (layoutData.curveHoles == undefined) layoutData.curveHoles = []
  if (layoutData.millInfo == undefined) layoutData.millInfo = []
  let isThroughcurveHole = layoutData.curveHoles.some(
    (curve) => curve.deep >= layoutData.thick
  )
  let isThroughMill = layoutData.millInfo.some(
    (mill) => (mill.deep ?? mill.depth) >= layoutData.thick
  )
  let slots = layoutData.slots
  let holes = layoutData.holes
  let curveHoles = layoutData.curveHoles ?? []
  let millInfo = layoutData.millInfo ?? []
  let handleSlopes = layoutData.handleSlopes ?? []
  let isOnlyFront =
    slots.every((slot) => slot.side == 1) &&
    holes.every((hole) => hole.side == 1) &&
    curveHoles.every((curve) => curve.side == 1) &&
    millInfo.every((mill) => mill.side == 1)
  // handleSlopes.every((mill) => mill.side == 1)
  let isOnlyback =
    holes.every((hole) => hole.side == -1) &&
    slots.every((slot) => slot.side == -1) &&
    curveHoles.every((curve) => curve.side == -1) &&
    millInfo.every((mill) => mill.side == -1)
  // handleSlopes.every((mill) => mill.side == 1)

  let hasHoleSlot =
    holes.length || slots.length || curveHoles.length || millInfo.length
  // handleSlopes.length
  let ishighGloss = layoutData.is_high_gloss_plank
  let isOnlyThroughSlot =
    !layoutData.holes.length &&
    slots.every((slot) => slot.deep >= layoutData.thick) &&
    millInfo.every((info) => info.deep >= layoutData.thick)
  // handleSlopes.every((slope) => slope.depth >= layoutData.thick) &&
  // 按槽的孔集中面,否则用孔的集中面
  let concentrated = layoutData.slots.length
    ? apertureSlotConcentrated
    : holeConcentrated
  //  打穿孔槽不可用
  if (
    isThroughHole ||
    isThroughSlot ||
    (ishighGloss && isThroughcurveHole && isThroughMill)
  )
    return true
  // 无孔无槽可用
  if (!hasHoleSlot) return false
  if (!xyReverse) {
    if (ishighGloss) {
      if (isOnlyThroughSlot) return false
      if (highgloss_direction == 'front') return isOnlyFront ? false : true
      return isOnlyFront ? false : true
    }
    if (concentrated == 'back' && !isOnlyFront) return true
    if (!isOnlyFront) return true
  } else {
    if (ishighGloss) {
      if (isOnlyThroughSlot) return false
      if (highgloss_direction == 'front') return isOnlyback ? false : true
      return isOnlyback ? false : true
    }
    if (store.state.isSurplusNoRoll) {
      let {
        apertureSlotConcentrated,
        holeConcentrated,
        xyReverse,
        highgloss_direction,
      } = store.state.ncSetting
      let isThroughHole = layoutData.holes.some(
        (hole) => hole.deep >= layoutData.thick
      )
      let isThroughSlot =
        slots.some((slot) => slot.deep >= layoutData.thick) ||
        millInfo.some((info) => info.deep >= layoutData.thick)
      // handleSlopes.some((slope) => slope.deep >= layoutData.thick) ||
      let isOnlyFront =
        slots.every((slot) => slot.side == 1) &&
        holes.every((hole) => hole.side == 1) &&
        curveHoles.every((curve) => curve.side == 1) &&
        millInfo.every((mill) => mill.side == 1)
      // handleSlopes.every((slope) => slope.side == 1)
      let isOnlyback =
        holes.every((hole) => hole.side == -1) &&
        slots.every((slot) => slot.side == -1) &&
        curveHoles.every((curve) => curve.side == -1) &&
        millInfo.every((mill) => mill.side == -1)
      // handleSlopes.every((slope) => slope.side == -1)
      let hasHoleSlot =
        holes.length || slots.length || curveHoles.length || millInfo.length
      // handleSlopes.length
      let ishighGloss = layoutData.is_high_gloss_plank
      let isOnlyThroughSlot =
        !holes.length &&
        slots.every((slot) => slot.deep >= layoutData.thick) &&
        millInfo.every((mill) => mill.depth >= layoutData.thick)
      // handleSlopes.every((slope) => slope.depth >= layoutData.thick) &&
      // 按槽的孔集中面,否则用孔的集中面
      let concentrated = layoutData.slots.length
        ? apertureSlotConcentrated
        : holeConcentrated
      //  打穿孔槽不可用
      if (isThroughHole || (ishighGloss && isThroughSlot)) return true
      // 无孔无槽可用
      if (!hasHoleSlot) return false
      if (!xyReverse) {
        if (ishighGloss) {
          if (isOnlyThroughSlot) return false
          if (highgloss_direction == 'front') return isOnlyFront ? false : true
          return isOnlyFront ? false : true
        }
        if (concentrated == 'back' && !isOnlyFront) return true
        if (!isOnlyFront) return true
      } else {
        if (ishighGloss) {
          if (isOnlyThroughSlot) return false
          if (highgloss_direction == 'front') return isOnlyback ? false : true
          return isOnlyback ? false : true
        }
        if (concentrated == 'front' && isOnlyFront) return true
        if (!isOnlyback) return true
      }
      if (concentrated == 'front' && isOnlyFront) return true
      if (!isOnlyback) return true
    }
    return false
  }
}
/** 扩张一张板件的函数，抽离出来的，如各位有时间务必在理清楚扩板所有逻辑的前提下，将所有逻辑解耦 */
export function dealPlankExpandKnife(part, ncSetting, noPath) {
  const prePaibanData = store.state.prePaibanData
  ncSetting = ncSetting ?? store.state.ncSetting
  // 刀半径 是否需要在此处使用多材质下料刀 需要排查全部逻辑后更改
  // let cutKnifeRadius = 0
  const layoutGap = ncSetting.panelSize.layoutGap ?? 0.1
  if (ncSetting.knife.diameter && ncSetting.knife.diameter != '') {
    cutKnifeRadius = Number(ncSetting.knife.diameter) / 2
    if (ncSetting.cuttingEquipment === 'glassEquip') {
      cutKnifeRadius = 0
    }
  }
  // 使用锯切刀逻辑
  /**
   * 1、启用锯切雕刻机
   * 2、启用锯片厚度排版
   * 3、锯片刀库至少存在一条不为空的横切刀
   * 4、下料刀半 <= 横切刀直径
   * 5、所有小板中没有异形板
   */
  const sawKnives = ncSetting.sawSetting?.mentaoji_saws
  let yCutKnifeRadius = cutKnifeRadius
  // 添加一个字段代表是否使用锯切刀
  part.isUseSaw = false
  if (
    ncSetting.sawSetting.mentaoji_enable &&
    ncSetting.sawSetting.sawToLayout &&
    ((sawKnives &&
      sawKnives.some((item) => item.saw_direction == 'H' && item.saw_width)) ||
      ncSetting.sawSetting?.horizontalWidth)
  ) {
    // 多个横切刀 取最小刀径
    if (sawKnives) {
      const hSawKnives = sawKnives
        .filter((item) => item.saw_direction == 'H')
        .map((item) => item.saw_width)
      yCutKnifeRadius = Number(Math.min(...hSawKnives))
    } else {
      // 预排版里面的 横切刀直径
      yCutKnifeRadius = Number(ncSetting.sawSetting?.horizontalWidth)
    }
    if (cutKnifeRadius <= yCutKnifeRadius && noPath) {
      cutKnifeRadius = yCutKnifeRadius / 2
      part.isUseSaw = true
    }
  }
  // 处理超长门板扩刀逻辑
  let {
    longPlankMinLength: longSide,
    longPlankShortMinLength: shortSide,
    longPlankExpandedSize: lpes,
    longPlankShortExpandedSize: lpses,
    longPlankCuttingEnable,
    cutSingleDoor,
    cutGeneralPlank,
    generalPlankThick,
  } = ncSetting.longPlankCuttingSetting
  let longPlankExpandedSize = Number(lpes)
  let longPlankShortExpandedSize = Number(lpses)
  const rectWH = [part.rect.width, part.rect.height]
  const partLongSide = Math.max(...Object.values(rectWH))
  const partShortSide = Math.min(...Object.values(rectWH))
  if (
    !(
      longPlankCuttingEnable &&
      partLongSide > longSide &&
      partShortSide > shortSide
    )
  ) {
    longPlankExpandedSize = 0
    longPlankShortExpandedSize = 0
  }
  if (cutSingleDoor && part.type === 'SingleDoor') {
    666 // 门板板件扩张
  } else if (
    cutGeneralPlank &&
    part.type !== 'SingleDoor' &&
    part.thick >= generalPlankThick
  ) {
    666 // 常规板板件扩张
  } else {
    longPlankExpandedSize = 0
    longPlankShortExpandedSize = 0
  }
  let expandedX, expandedY
  // 判断赋予x和y长边放量还是短边放量
  if (rectWH[0] > rectWH[1]) {
    expandedX = longPlankExpandedSize
    expandedY = longPlankShortExpandedSize
  } else if (rectWH[0] < rectWH[1]) {
    expandedX = longPlankShortExpandedSize
    expandedY = longPlankExpandedSize
  } else {
    // 宽高相等进行判断
    if (rectWH[0] > longSide) {
      expandedX = longPlankExpandedSize
      expandedY = longPlankExpandedSize
    } else if (rectWH[0] > shortSide) {
      expandedX = longPlankShortExpandedSize
      expandedY = longPlankShortExpandedSize
    } else {
      expandedX = 0
      expandedY = 0
    }
  }
  if (!part.plankNum) {
    if (part.oriPlankNum) {
      part.plankNum = part.oriPlankNum
    } else {
      // 生成oriPlankNum
      const date = new Date()
      const time = date.getTime()
      const plankNum = ('' + time).slice(-12)
      const strArr = plankNum.split('')
      let num1 = 0
      let num2 = 0
      for (let i = 0; i < strArr.length; ++i) {
        if (i == 0) {
          if (strArr[i] == '0') {
            strArr[i] = Math.floor(Math.random() * 9 + 1)
          }
        }
        if ((i + 1) % 2) {
          num2 += Number(strArr[i])
        } else {
          num1 += Number(strArr[i])
        }
      }
      let finalNum = (10 - ((num1 * 3 + num2) % 10)) % 10
      strArr.push(finalNum)
      part.plankNum = strArr.join('')
      part.oriPlankNum = strArr.join('')
    }
  }
  // 记录原始路径尺寸，需要和扩张后的路径进行对比得到孔槽的偏移量，只考虑异型只有异型会存在问题（默认是矩形板件）,且只考虑扩展后minx，miny
  let oriPathSize = [0, 0]
  // 记录当前路径尺寸，也就是扩张后的尺寸
  let cutPathSize = []
  if (part.rect) {
    var minX = 0,
      minY = 0,
      maxX = part.rect.width,
      maxY = part.rect.height
    function expandPolygon(iPoly, offset) {
      var out = []
      var dpList = [],
        ndpList = []
      var count = iPoly.length

      if (
        Math.abs(iPoly[0]['x'] - iPoly[count - 1]['x']) < 0.0001 &&
        Math.abs(iPoly[0]['y'] === iPoly[count - 1]['y'])
      ) {
        --count
        if (iPoly[0]['b']) iPoly.pop()
        else iPoly.splice(0, 1)
      }

      function dot(fPoint, sPoint) {
        return fPoint.x * sPoint.x + fPoint.y * sPoint.y
      }
      function cross(fPoint, sPoint) {
        return fPoint.x * sPoint.y - fPoint.y * sPoint.x
      }
      function subtraction(fPoint, sPoint) {
        // return Qt.point(fPoint.x-sPoint.x, fPoint.y-sPoint.y)
        return {
          x: fPoint.x - sPoint.x,
          y: fPoint.y - sPoint.y,
        }
      }
      function addition(fPoint, sPoint) {
        // return Qt.point(fPoint.x+sPoint.x, fPoint.y+sPoint.y)
        return {
          x: fPoint.x + sPoint.x,
          y: fPoint.y + sPoint.y,
        }
      }
      function multiplication(point, num) {
        // return Qt.point(point.x*num, point.y*num)
        return {
          x: point.x * num,
          y: point.y * num,
        }
      }
      function segmentsIntr(a, b, c, d) {
        var nx1 = b.y - a.y,
          ny1 = a.x - b.x
        var nx2 = d.y - c.y,
          ny2 = c.x - d.x
        var denominator = nx1 * ny2 - ny1 * nx2
        if (denominator == 0) {
          return false
        }

        var distC_N2 = nx2 * c.x + ny2 * c.y
        var distA_N2 = nx2 * a.x + ny2 * a.y - distC_N2
        var distB_N2 = nx2 * b.x + ny2 * b.y - distC_N2

        if (distA_N2 * distB_N2 >= 0) {
          return false
        }
        var distA_N1 = nx1 * a.x + ny1 * a.y
        var distC_N1 = nx1 * c.x + ny1 * c.y - distA_N1
        var distD_N1 = nx1 * d.x + ny1 * d.y - distA_N1
        if (distC_N1 * distD_N1 >= 0) {
          return false
        }

        //计算交点坐标
        var fraction = distA_N2 / denominator
        var dx = fraction * ny1,
          dy = -fraction * nx1
        var res = { x: a.x + dx, y: a.y + dy }

        return res
      }

      for (var i = 0; i < count; i++) {
        var next = i === count - 1 ? 0 : i + 1
        // var curPoint = Qt.point(iPoly[i]["x"], iPoly[i]["y"]);
        var curPoint = {
          x: iPoly[i]['x'],
          y: iPoly[i]['y'],
        }
        // var nextPoint =  Qt.point(iPoly[next]["x"], iPoly[next]["y"]);
        var nextPoint = {
          x: iPoly[next]['x'],
          y: iPoly[next]['y'],
        }
        dpList.push(subtraction(nextPoint, curPoint))
        var unitLen = 1.0 / Math.sqrt(dot(dpList[i], dpList[i]))
        unitLen = isNaN(0 * unitLen) ? 0 : unitLen
        ndpList.push(multiplication(dpList[i], unitLen))
      }

      var lastXOffset = 0
      var lastYOffset = 0
      for (i = 0; i < count; i++) {
        var startIndex = i === 0 ? count - 1 : i - 1
        var endIndex = i
        var sinTheta = cross(ndpList[startIndex], ndpList[endIndex])
        var orientVector = subtraction(ndpList[endIndex], ndpList[startIndex]) //i.e. PV2-V1P=PV2+PV1
        var temp_out = {}

        // sinTheta = jjFixed2(sinTheta)
        sinTheta = Number(sinTheta.toFixed(2))
        if (sinTheta !== 0) {
          lastXOffset = (offset / sinTheta) * orientVector.x
          lastYOffset = (offset / sinTheta) * orientVector.y
          if (Math.abs(lastYOffset) > Math.abs(offset)) {
            lastYOffset =
              Math.abs(offset) * (lastYOffset / Math.abs(lastYOffset))
          }
          if (Math.abs(lastXOffset) > Math.abs(offset)) {
            lastXOffset =
              Math.abs(offset) * (lastXOffset / Math.abs(lastXOffset))
          }

          iPoly[i]['x'] = iPoly[i]['x'] + lastXOffset
          iPoly[i]['y'] = iPoly[i]['y'] + lastYOffset
        } else {
          iPoly[i]['x'] = iPoly[i]['x'] + lastXOffset
          iPoly[i]['y'] = iPoly[i]['y'] + lastYOffset
        }
      }

      for (i = 0; i < count && count >= 4; i++) {
        var startIndexA = i === 0 ? count - 1 : i - 1
        var endIndexA = i

        var startIndexB = i === count - 1 ? 0 : i + 1
        var endIndexB = i === count - 1 ? 1 : i === count - 2 ? 0 : i + 2

        var res = segmentsIntr(
          iPoly[startIndexA],
          iPoly[endIndexA],
          iPoly[startIndexB],
          iPoly[endIndexB]
        )
        if (res) {
          iPoly[endIndexA]['x'] = res.x
          iPoly[endIndexA]['y'] = res.y
          iPoly.splice(startIndexB, 1)
          --i
          --count
        }
      }
      var minX = 999999
      var minY = 999999

      for (i in iPoly) {
        var py = iPoly[i]
        minX = Math.min(minX, py['x'])
        minY = Math.min(minY, py['y'])
      }
      return [minX, minY]
    }
    if (part.hasOwnProperty('cutCurve') && true) {
      var cutCurve = part['cutCurve']
      var cutCurveEx = part['cutCurveEx']
      var minValue = expandPolygon(cutCurve, cutKnifeRadius)
      for (var ci in cutCurve) {
        cutCurve[ci]['x'] -= minValue[0]
        cutCurve[ci]['y'] -= minValue[1]
      }
      for (var ei in cutCurveEx) {
        cutCurve = cutCurveEx[ei]
        expandPolygon(cutCurve, -cutKnifeRadius)
        for (ci in cutCurve) {
          cutCurve[ci]['x'] -= minValue[0]
          cutCurve[ci]['y'] -= minValue[1]
        }
      }
    }
    // 处理玻璃切割机补偿设置
    const { cuttingEquipment, glass_setting } = ncSetting
    let contour_size_recoup = glass_setting && glass_setting.contour_size_recoup
    if (cuttingEquipment !== 'glassEquip' || !contour_size_recoup) {
      contour_size_recoup = 0
    }
    if (!part.hasOwnProperty('path')) {
      maxX = part.rect.width + expandedX * 2 + contour_size_recoup * 2
      maxY = part.rect.height + expandedY * 2 + contour_size_recoup * 2
      // for(let i = 0; i < part.holes.length; ++i){
      //   let hole = part.holes[i]
      //   hole.center.x += longPlankExpandedSize
      //   hole.center.y += longPlankExpandedSize
      // }
      // for(let i = 0; i < part.slots.length; ++i){
      //   let slot = part.slots[i]
      //   slot.pt1.x += longPlankExpandedSize
      //   slot.pt1.y += longPlankExpandedSize
      //   slot.pt2.x += longPlankExpandedSize
      //   slot.pt2.y += longPlankExpandedSize
      // }
    } else {
      // 异形统一使用长边放量
      expandedX = longPlankExpandedSize
      expandedY = longPlankExpandedSize
      let paths = JSON.parse(JSON.stringify(part.path))
      let len = paths.length
      // 如果存在异形孔则将孔位随着其他异形一起处理,后单独取出来
      if (part['curveHoles']) {
        part['curveHoles'].forEach((k) => {
          // 牛角槽，不算作一个内部异形且在柜门数据传输过来就已扩刀，不需要在进行内缩扩张
          if (k.knifeName) return
          paths.push(k['path'])
        })
      }
      for (let i in paths) {
        for (let j in paths[i]) {
          paths[i][j].X = paths[i][j].x
          paths[i][j].Y = paths[i][j].y
        }
      }
      let resArr = []
      // 特殊扩张距离 刀半径 + 长板放量 + 玻璃切割机放量
      const specialExpandDistance =
        Number(cutKnifeRadius) +
        Number(longPlankExpandedSize) +
        Number(contour_size_recoup)
      paths.forEach((val, index) => {
        // 外轮廓(小板轮廓)为扩张，正的刀半径 / 内轮廓(内部异形)为内缩,负的刀半径
        const offset = !index ? specialExpandDistance : -cutKnifeRadius
        // 扩张路径
        const result = expandPath(offset, val)
        // 取最后结果中最大的一个数组作为板件的path
        const resultLengths = result.map((arr) => arr.length)
        const maxIndex = resultLengths.indexOf(Math.max(...resultLengths))
        resArr.push(result[maxIndex])
      })
      // 记录小板轮廓最小的x和y
      ;[minX, minY, maxX, maxY] = getPathMinMaxPoint(resArr[0])
      // 扩展后的大小
      cutPathSize = getPathMinMaxPoint(resArr[0])
      resArr.forEach((path) => {
        afterExpendPathOffset(path, minX, minY, part)
      })
      // 记录异型路径的原始大小
      oriPathSize = getPathMinMaxPoint(part.path[0])
      // 将异形长圆孔和板件异形分离
      part.path = resArr.slice(0, len)

      if (part['curveHoles']) {
        let curArr = resArr.slice(len)
        const specialCurveHoles = []
        const curveHoles = []
        // 区分特殊长圆孔(牛角槽)
        part['curveHoles'].forEach((item) => {
          if (item.knifeName) {
            specialCurveHoles.push(item)
          } else {
            curveHoles.push(item)
          }
        })
        curveHoles.forEach((item, idx) => {
          if (item.path) {
            let o = curArr[idx]
            item['path'] = o
          }
        })
        // 特殊长圆孔(牛角槽)由于板件扩张Y轴需要位移两个扩张距离
        specialCurveHoles.forEach((item) => {
          // 如牛角槽在板件右侧则需要将X轴也进行偏移
          const isOffsetX = item.path.some((it) => it.x >= part.oRect.width)
          // 如牛角槽在板件下侧则需要将Y轴也进行偏移
          const isOffsetY = item.path.some((it) => it.y >= part.oRect.height)
          item.path = item.path.map((it) => {
            it.y += longPlankExpandedSize
            it.x += longPlankExpandedSize
            if (isOffsetX) {
              it.x += Math.abs(cutKnifeRadius) * 2
            }
            if (isOffsetY) {
              it.y += Math.abs(cutKnifeRadius) * 2
            }
            return it
          })
          return
        })
      }
    }
  }
  var rectWid = maxX - minX
  var rectHei = maxY - minY

  if (part.hasOwnProperty('path')) {
    var lx = 0,
      ly = 0,
      sx = 10000,
      sy = 10000
    var nPath = []
    var p = {}
    if (part.path.length > 0) {
      p = part.path[0]
    }

    for (var k in p) {
      var pt = JSON.parse(JSON.stringify(p[k]))
      var x = pt.x,
        y = pt.y
      if (x > lx) {
        lx = x
      }
      if (y > ly) {
        ly = y
      }
      if (x < sx) {
        sx = x
      }
      if (y < sy) {
        sy = y
      }
    }
    for (var m in p) {
      var t = JSON.parse(JSON.stringify(p[m]))

      var o = {
        x: t.x - sx,
        y: t.y - sy,
      }

      var pointm = JSON.parse(JSON.stringify(o))
      nPath.push(pointm)
    }
    part.path[0] = nPath
  }
  //对拉直器样式2的板子进行扩张

  /** 是否生产线是预排版 */
  const isPreLayout = ncSetting.isPreLayout
  // 所有刀
  const allKnifes = isPreLayout
    ? null
    : store.state.ncSetting && store.state.ncSetting.knives
  // 指定刀直径
  let selectKnifeDiameter = isPreLayout ? cutKnifeRadius * 2 : 0
  // 拉直器
  const symbol = [
    'STRENTCHTWO',
    'MORTISE',
    'CCBTSlot',
    'noSymbol',
    'lightSlot',
    'normalSlot',
    'gratingSlot',
    'BP',
    'CCSlot',
    'exSlot',
  ]

  const finalExtendDisMap = dealExpandSlot(part, ncSetting, cutKnifeRadius)
  const extendW = finalExtendDisMap
    ? Number(finalExtendDisMap.left) + Number(finalExtendDisMap.right)
    : 0
  const extendH = finalExtendDisMap
    ? Number(finalExtendDisMap.top) + Number(finalExtendDisMap.bottom)
    : 0
  if (part.rect) {
    if (part.hasOwnProperty('path')) {
      part.rect = {
        x: part.rect.x,
        y: part.rect.y,
        width: rectWid,
        height: rectHei,
      }
      // 用于排版的扩张板件
      part.expandRect = {
        x: part.rect.x,
        y: part.rect.y,
        width: rectWid + extendW,
        height: rectHei + extendH,
      }
      //                part.realRect = Qt.rect(0, 0, rectWid-2*cutKnifeRadius, rectHei-2*cutKnifeRadius)
    } else {
      const offsetX = 2 * cutKnifeRadius + extendW
      const offsetY = 2 * cutKnifeRadius + extendH
      // 获取到矩形最终扩张的大小，用二次切割和其他条件扩大后的大小减去当前大小，最后加上刀径等扩张大小的值减半则能得到minx miny
      cutPathSize = [
        (rectWid - part.rect.width + 2 * cutKnifeRadius) / 2,
        (rectHei - part.rect.height + 2 * cutKnifeRadius) / 2,
      ]
      part.rect = {
        x: part.rect.x,
        y: part.rect.y,
        width: rectWid + 2 * cutKnifeRadius,
        height: rectHei + 2 * cutKnifeRadius,
      }
      part.expandRect = {
        x: part.rect.x,
        y: part.rect.y,
        width: rectWid + offsetX,
        height: rectHei + offsetY,
      }
      prePaibanData.forEach((p) => {
        if (p.partUniqueId == part.partUniqueId) {
          p.expandRect = part.expandRect
        }
      })
    }
  }
  store.commit('setPrePaibanData', prePaibanData)

  function test(arr) {
    var maxx = -10000,
      maxy = -10000,
      minx = 10000,
      miny = 10000
    for (var k in arr[0]) {
      // debugger
      var pt = arr[0][k]

      if (pt.x > maxx) {
        maxx = pt.x
      }
      if (pt.y > maxy) {
        maxy = pt.y
      }
      if (pt.x < minx) {
        minx = pt.x
      }
      if (pt.y < miny) {
        miny = pt.y
      }
    }
    for (var k in arr) {
      for (var j in arr[k]) {
        arr[k][j].x -= minx
        arr[k][j].y -= miny
      }
    }
    return {
      maxx: maxx,
      minx: minx,
      maxy: maxy,
      miny: miny,
    }
  }
  var dx = 0
  var dy = 0
  var dx2 = part.oRect.width
  var dy2 = part.oRect.height
  if (part.hasOwnProperty('path')) {
    var res = test(part.oPath)
    dx = -res.minx
    dy = -res.miny
    dx2 = res.maxx
    dy2 = res.maxy
    // debugger
  } else {
    if (part['oRect']) {
      part['fullSize']['width'] = part['oRect'].width
      part['fullSize']['height'] = part['oRect'].height
    }
  }
  for (let i = 0; i < part.sslots.length; i++) {
    const sslot = part.sslots[i]
    if (['TENON', 'CCTENON', 'CCDTENON'].includes(sslot.symbol)) {
      dx = 0
      dy = 0
      break
    }
  }
  // 最终使用的孔槽偏移X
  const finalOffsetX = Math.abs(cutPathSize[0]) - oriPathSize[0]
  // 最终使用的孔槽偏移Y
  const finalOffsetY = Math.abs(cutPathSize[1]) - oriPathSize[1]
  part.holes.forEach(function (hole) {
    if (hole.holeType && hole.holeType === 'exHole') {
      //该孔与异形路径绑定，不需要再根据异形路径做平移
      hole.center = {
        x: hole.center.x + finalOffsetX,
        y: hole.center.y + finalOffsetY,
      }
      hole.ocenter = {
        x: hole.ocenter.x + dx,
        y: hole.ocenter.y + dy,
      }
    } else {
      hole.center = {
        x: hole.center.x + finalOffsetX,
        y: hole.center.y + finalOffsetY,
      }
    }
  })
  part.slots.forEach(function (slot) {
    if (slot.slotType && slot.slotType === 'exSlot') {
      slot.pt1 = {
        x: slot.pt1.x + finalOffsetX,
        y: slot.pt1.y + finalOffsetY,
      }
      slot.pt2 = {
        x: slot.pt2.x + finalOffsetX,
        y: slot.pt2.y + finalOffsetY,
      }
    } else {
      slot.pt1 = {
        x: slot.pt1.x + finalOffsetX,
        y: slot.pt1.y + finalOffsetY,
      }
      slot.pt2 = {
        x: slot.pt2.x + finalOffsetX,
        y: slot.pt2.y + finalOffsetY,
      }
    }

    slot.opt1 = {
      x: slot.opt1.x + dx,
      y: slot.opt1.y + dy,
    }
    slot.opt2 = {
      x: slot.opt2.x + dx,
      y: slot.opt2.y + dy,
    }

    part.rect.left = part.rect.x
    part.rect.right = part.rect.x + part.rect.width
    part.rect.top = part.rect.y
    part.rect.bottom = part.rect.height

    // 避免拉槽伤到其他板
    if (slot.pt1.x < part.rect.left) slot.pt1.x = part.rect.left
    if (slot.pt2.x < part.rect.left) slot.pt2.x = part.rect.left

    if (slot.pt1.y < part.rect.top) slot.pt1.y = part.rect.top
    if (slot.pt2.y < part.rect.top) slot.pt2.y = part.rect.top

    if (slot.pt1.x > part.rect.right) slot.pt1.x = part.rect.right
    if (slot.pt2.x > part.rect.right) slot.pt2.x = part.rect.right

    if (slot.pt1.y > part.rect.bottom) slot.pt1.y = part.rect.bottom
    if (slot.pt2.y > part.rect.bottom) slot.pt2.y = part.rect.bottom
  })
  // 斜封边拉手
  part.handleSlopes &&
    part.handleSlopes.forEach(function (slot) {
      if (slot.slotType && slot.slotType === 'exSlot') {
        slot.pt1 = {
          x: slot.pt1.x + cutKnifeRadius + expandedX,
          y: slot.pt1.y + cutKnifeRadius + expandedY,
        }
        slot.pt2 = {
          x: slot.pt2.x + cutKnifeRadius + expandedX,
          y: slot.pt2.y + cutKnifeRadius + expandedY,
        }
      } else {
        slot.pt1 = {
          x: slot.pt1.x + finalOffsetX,
          y: slot.pt1.y + finalOffsetY,
        }
        slot.pt2 = {
          x: slot.pt2.x + finalOffsetX,
          y: slot.pt2.y + finalOffsetY,
        }
      }

      slot.opt1 = {
        x: slot.opt1.x + dx,
        y: slot.opt1.y + dy,
      }
      slot.opt2 = {
        x: slot.opt2.x + dx,
        y: slot.opt2.y + dy,
      }

      part.rect.left = part.rect.x
      part.rect.right = part.rect.x + part.rect.width
      part.rect.top = part.rect.y
      part.rect.bottom = part.rect.height

      // 避免拉槽伤到其他板
      if (slot.pt1.x < part.rect.left) slot.pt1.x = part.rect.left
      if (slot.pt2.x < part.rect.left) slot.pt2.x = part.rect.left

      if (slot.pt1.y < part.rect.top) slot.pt1.y = part.rect.top
      if (slot.pt2.y < part.rect.top) slot.pt2.y = part.rect.top

      if (slot.pt1.x > part.rect.right) slot.pt1.x = part.rect.right
      if (slot.pt2.x > part.rect.right) slot.pt2.x = part.rect.right

      if (slot.pt1.y > part.rect.bottom) slot.pt1.y = part.rect.bottom
      if (slot.pt2.y > part.rect.bottom) slot.pt2.y = part.rect.bottom
    })

  var oldSize = part.oRect
  if (part.path) {
    oldSize = part.oldSize
  }

  if (part.sholes) {
    part.sholes.forEach(function (shole) {
      if (shole.side * 1 === 2) {
        var tmpValue = -(oldSize.width - dx2)
        shole.center = {
          x: shole.center.x + tmpValue,
          y: shole.center.y,
        }
      } else if (shole.side * 1 === 1) {
        tmpValue = -(oldSize.height - dy2)
        shole.center = {
          x: shole.center.x + tmpValue,
          y: shole.center.y,
        }
      } else if (shole.side * 1 === 3) {
        shole.center = {
          x: shole.center.x + dy,
          y: shole.center.y,
        }
      } else {
        shole.center = {
          x: shole.center.x + dx,
          y: shole.center.y,
        }
      }
      shole.ocenter = {
        x: shole.ocenter.x + dx,
        y: shole.ocenter.y + dy,
      }
    })
  }

  if (part.sslots) {
    part.sslots.forEach(function (sslot) {
      if (sslot.side * 1 === 2) {
        var tmpValue = -(oldSize.width - dx2)
        sslot.pt1 = {
          x: sslot.pt1.x + tmpValue,
          y: sslot.pt1.y,
        }
        sslot.pt2 = {
          x: sslot.pt2.x + tmpValue,
          y: sslot.pt2.y,
        }
      } else if (sslot.side * 1 === 1) {
        tmpValue = -(oldSize.height - dy2)
        sslot.pt1 = {
          x: sslot.pt1.x + tmpValue,
          y: sslot.pt1.y,
        }
        sslot.pt2 = {
          x: sslot.pt2.x + tmpValue,
          y: sslot.pt2.y,
        }
      } else if (sslot.side * 1 === 3) {
        sslot.pt1 = {
          x: sslot.pt1.x + dy,
          y: sslot.pt1.y,
        }
        sslot.pt2 = {
          x: sslot.pt2.x + dy,
          y: sslot.pt2.y,
        }
      } else {
        sslot.pt1 = {
          x: sslot.pt1.x + dx,
          y: sslot.pt1.y,
        }
        sslot.pt2 = {
          x: sslot.pt2.x + dx,
          y: sslot.pt2.y,
        }
      }

      sslot.opt1 = {
        x: sslot.opt1.x + dx,
        y: sslot.opt1.y + dy,
      }
      sslot.opt2 = {
        x: sslot.opt2.x + dx,
        y: sslot.opt2.y + dy,
      }
    })
  }
}

/**
 * 旋转前封边
 * 1、启用锯切
 * 2、启用锯片厚度排版
 * 3、勾选已封好不再切的大板边 要把前封边旋转到所选不再切的大板边
 * @param {*} layoutData
 */
function rotateFrontEdge(layoutData) {
  // 映射封边信息和位置关系
  const dirDataMap = {
    '←': 'left',
    '↓': 'bottom',
    '→': 'right',
    '↑': 'top',
  }
  const dirArr = ['top', 'right', 'bottom', 'left']
  if (
    store.state.ncSetting.sawSetting.mentaoji_enable &&
    store.state.ncSetting.sawSetting.sawToLayout &&
    store.state.ncSetting.sawSetting.edgeNoSaw
  ) {
    layoutData.forEach((part) => {
      if (part.model_front_edge) {
        const frontEdge = dirArr.indexOf(dirDataMap[part.model_front_edge])
        const rotateDir = dirArr.indexOf(
          store.state.ncSetting.sawSetting.edgeNoSawSide
        )
        part.isRotateFrontEdge = true
        let deg
        if (rotateDir - frontEdge == 1 || rotateDir - frontEdge == -3) {
          deg = 90
        } else if (Math.abs(rotateDir - frontEdge) == 2) {
          deg = 180
        } else if (rotateDir - frontEdge == 3 || rotateDir - frontEdge == -1) {
          deg = 270
        }
        // 无纹理板才进行90,270旋转，其它纹理只进行180旋转
        switch (deg) {
          case 180:
            part = rotate180Part(part)
            break
          case 270:
            if (part.texDir == 'notcare') {
              part = rotatePart(rotate180Part(part))
            }
            break
          case 90:
            if (part.texDir == 'notcare') {
              part = rotatePart(part)
            }
            break
          default:
            break
        }
      }
    })
  }
}

function layoutWorker(isYunLayout, passLayout) {
  var offlineLayoutError = false
  uniquePlankNum(layoutData)
  var result = checkBackPlank(layoutData)
  if (result.length > 0) {
    // main_msgTips.open(gg.getTrans("房间数据错误，请重新保存房间，避免生产损失" + "\n" + JSON.stringify(result)), "fail");
    return null
  }

  plankSizeList = []

  //过滤掉非异形的板件
  // if (onlyNormalPlate) {
  // layoutData = filtrationNomalPlank(layoutData);
  // }

  if (!store.state.ncSetting.isPreLayout) {
    const isNoKnifeToProcessList = layoutData.map((part) => {
      return part.slots.map((slot) => {
        const knife = slot.knifeName
        if (knife) {
          const k = ncSettings.knives[knife]
          if (!k || !k.diameter || k.diameter < 0) {
            return knife
          } else return false
        } else {
          return false
        }
      })
    })

    const knifeList = Array.from(
      new Set(isNoKnifeToProcessList.flat(Infinity).filter((k) => k))
    )

    if (knifeList.length && false) {
      return {
        isErr: true,
        errMsg: translateLang(
          '缺失槽指定加工刀具，请检查生产线设置！【{msg}】',
          {
            msg: knifeList.join('、'),
          }
        ),
      }
    }
  }

  // 扩张
  store.commit('clearErrorPathPlankID')
  let samePartArr = []
  const noPath = layoutData.every((part) => !part.hasOwnProperty('path'))
  /** 是否有错误的内部路径 */
  let hasErrPathList = []
  layoutData.forEach(async function (part) {
    dealPlankExpandKnife(part, ncSettings ?? store.state.ncSetting, noPath)
    if (part.curveHoles) {
      const hasErrPath = part.curveHoles.some(
        (hole) => !window.ClipperLib.JS.AreaOfPolygon(hole.path)
      )

      if (hasErrPath) {
        hasErrPathList.push(part.plankID)
      }
    }
    if (part.ggid && part.amount > 1) {
      samePartArr.push(part.ggid)
    }
  })
  if (hasErrPathList.length)
    return {
      isErr: true,
      errMsg: '内部异形数据错误,请检查是否符合刀具规格!',
      hasErrPathList,
    }
  // 旋转前封边
  rotateFrontEdge(layoutData)
  samePartArr = Array.from(new Set(samePartArr))
  // 板件多套（开料清单数量大于1）的情况下，ggid添加两位序号
  for (let i in samePartArr) {
    let count = 0
    for (let j in layoutData) {
      if (samePartArr[i] === layoutData[j].ggid) {
        if (layoutData[j].ggid) {
          layoutData[j].ggid += `0${ggidEncodeList()[count % 92]}`
          count += 1
        }
      }
    }
  }

  // 提取矩形数据
  var rectMap = {}
  for (var i in layoutData) {
    var rect = layoutData[i].expandRect ?? layoutData[i].rect
    if (!rect) {
      continue
    }

    var thick = layoutData[i].thick
    var texture = layoutData[i].texture
    var matCode = layoutData[i].matCode
    const isHighPlank = layoutData[i].is_high_gloss_plank

    matCode = isHighPlank
      ? !matCode.includes('高光_')
        ? '高光_' + matCode
        : matCode
      : matCode
    //处理色卡名长度不一导致找不到余料问题
    //因色卡名前六位相同后面字符不同，排版排一块暂注释
    //if (texture && texture.length > 6) {
    //texture = texture.slice(0, 6);
    //layoutData[i].texture = texture
    //}

    var key = texture + ':' + matCode + ':' + thick

    if (!rectMap.hasOwnProperty(key)) {
      rectMap[key] = []
    }
    if (store.state.ncSetting.highgloss_direction == 'front') {
      layoutData[i].is_high_gloss_plank && rolloverPlank(layoutData[i])
    }
    layoutData[i].needRoll = judgeNeedRoll(layoutData[i])
    var needRoll = layoutData[i].needRoll
    var rotate = layoutData[i].matRotatable

    var rectInfo = {}
    const { usedArea } = caclPartArea(layoutData[i])
    rectInfo['usedArea'] = usedArea
    rectInfo['rect'] = {
      x1: rect.x,
      y1: rect.y,
      x2: rect.width,
      y2: rect.height,
    }
    rectInfo['needRoll'] = needRoll
    rectInfo['rotate'] =
      layoutData[i].texDir == 'notcare' && !layoutData[i].isRotateFrontEdge
        ? true
        : false
    rectInfo['ggid'] = layoutData[i].ggid
    rectInfo['partUniqueId'] =
      layoutData[i].oriPartUniqueId ?? layoutData[i].partUniqueId
    if (
      store.state.paibanInfo.isSpecialShape &&
      layoutData[i].path &&
      !!layoutData[i].path.length
    ) {
      rectInfo['cutCurve'] = [...layoutData[i].path]
      let curvePathList = layoutData[i].curveHoles
        ?.filter((it) => compareNumbers(it.deep, layoutData[i].thick))
        .map((curve) => curve.path)
      if (curvePathList && curvePathList.length && !!curvePathList[0]) {
        rectInfo['cutCurve'].push(...curvePathList)
      }
    }
    rectMap[key].push(rectInfo)
    layoutData[i].index = i + 1
    layoutData[i].rectInd = rectMap[key].length
  }
  if (passLayout) {
    return
  }

  var decimal = Number(ncSettings.decimal)
  if (typeof decimal != 'number') {
    decimal = 2
  }
  // 提取配置信息
  var config = {
    outWidth: plankWidth,
    outHeight: plankHeight,
    margin: plankEdgeOff,
    gap: layoutGap,
    knifeR: cutKnifeRadius,
    startPosition: startPosition,
    changeKnifePosition: initialXyReverse,
    xyReverse: initialXyReverse,
    decimal: decimal,
    surplus_no_roll: store.state.isSurplusNoRoll,
  }
  if (isYunLayout) {
    var data = {}
    data['layoutRectMap'] = rectMap
    data['layoutHoleMap'] = {}
    data['layoutConfig'] = config
    // yunlayoutData = data;
    return {
      data: data,
      layout_data: layoutData,
    }
  } else {
    judgeUseSurplus(rectMap, config)
  }
}

// 计算每个小板的面积
export function caclPartArea(part) {
  let area = 0
  let areaAll = 0
  let plankArea = 0
  if (!part.surplusPath) {
    if (part.path) {
      const arr = part.path[0].map((it) => ({
        x: Number(it.x.toFixed(2)),
        y: Number(it.y.toFixed(2)),
      }))
      plankArea += Math.abs(window.ClipperLib.Clipper.Area(arr))
    } else {
      plankArea +=
        Number(part.realRect.height.toFixed(2)) *
        Number(part.realRect.width.toFixed(2))
    }
  }
  let insideArea = 0
  part.path?.map((poly, index) => {
    if (index) {
      insideArea += Math.abs(window.ClipperLib.Clipper.Area(poly))
    }
  })
  if (part.curveHoles) {
    const curveHolesArr = part.curveHoles.map((it) => it.path)
    curveHolesArr?.map((poly) => {
      insideArea += Math.abs(window.ClipperLib.Clipper.Area(poly))
    })
  }
  if (!part.surplusPath) {
    if (part.path) {
      const arr = part.path[0].map((it) => ({
        x: Number(it.x.toFixed(2)),
        y: Number(it.y.toFixed(2)),
      }))
      areaAll += Math.abs(window.ClipperLib.Clipper.Area(arr)) - insideArea
    } else {
      areaAll +=
        Number(part.rect.width.toFixed(2)) *
          Number(part.rect.height.toFixed(2)) -
        insideArea
    }
  }
  return { usedArea: areaAll }
}

// 处理扩张后的偏移
function afterExpendPathOffset(path, minX, minY, part) {
  try {
    path.forEach((point) => {
      if (minY < 0) {
        point.y += Math.abs(minY)
      }
      if (minX < 0) {
        point.x += Math.abs(minX)
      }
    })
    path.push({ ...path[0] })
  } catch (error) {
    store.commit('setErrorPathPlankID', part.plankID)
  }
}

function patchLayoutStart() {
  resetPlank()
  layoutToolChoose.visible = false
  loadIndicator.start()
  loadPatchInfo()
  //loadSurplusList({uid: userService.uid})
  complementTool()
  LF.setCutKnifeRadius()
  importPop.load()
}

export function layoutStart(ncSetting, noSurplus, data) {
  ncSettings = ncSetting
  layoutPatchData = []
  return doLayout(noSurplus)
}

function doLayout(noSurplus) {
  cutDirection = ncSettings.cutDirection
  throughTowSideToCut = ncSettings.throughTowSideToCut
  xyReverse = ncSettings.xyReverse
  startPosition = ncSettings.startPosition

  initialCutDirection = ncSettings.cutDirection
  initialThroughTowSideToCut = ncSettings.throughTowSideToCut
  initialXyReverse = ncSettings.xyReverse
  initialStartPosition = ncSettings.startPosition
  if (ncSettings.knife.diameter && ncSettings.knife.diameter != '') {
    cutKnifeRadius = Number(ncSettings.knife.diameter) / 2
    if (ncSettings.cuttingEquipment === 'glassEquip') {
      cutKnifeRadius = 0
    }
    return loadLayout()
  } else {
    return false
  }
}

function directYunLayout() {
  var modelLst = buttonRepeater.model
  for (var index = 0; index < modelLst.length; ++index) {
    var tmpData = modelLst[index]
    if (tmpData['name'] === gg.getTrans('云排版(优化率最高)')) {
      tmpData['checked'] = true
    } else {
      tmpData['checked'] = false
    }
  }
  buttonRepeater.model = modelLst
  isYunLayout = true

  root.layoutStart()
}

//**************************************************** 余料利用相关 **************************************************************

//加载余料清单
function loadSurplusList(p) {
  dbProjectObj.loadSurplusListPostData(p) //POST加载数据
}

//余料使用，数据
function getUseSurplusData(list) {
  isDataWrong = false
  var useData = {}
  for (var i in list) {
    var item = list[i]
    var texture = item['color'],
      matCode = item['type'],
      thick = item['thick']
    var key = texture + ':' + matCode + ':' + thick

    var dataItem = {}
    dataItem['width'] = item['width']
    dataItem['height'] = item['long']
    dataItem['area'] = item['area']
    dataItem['id'] = item['id']

    if (item['shape'] === 0) {
      //0是矩形余料
      if (dataItem['width'] && dataItem['height']) {
        if (!useData.hasOwnProperty(key)) {
          useData[key] = []
        }
        useData[key].push(dataItem)
      } else {
        continue
      }
    } else {
      //1是L形余料
      dataItem['area'] = item['area']
      dataItem['id'] = item['id']
      dataItem['shape'] = 'lshape'
      dataItem['x3'] = item['min_width'] * 1
      dataItem['y3'] = item['long'] * 1
      dataItem['x4'] = item['min_width'] * 1
      dataItem['y4'] = item['min_long'] * 1
      dataItem['x5'] = item['width'] * 1
      dataItem['y5'] = item['min_long'] * 1
      if (item['width'] && item['long']) {
        if (!useData.hasOwnProperty(key)) {
          useData[key] = []
        }
        useData[key].push(dataItem)
      } else {
        continue
      }
    }
  }
  return useData
}

//余料使用：进行排版
function conductLayout(rectMap, config, surplus) {
  config.surplus_no_roll = store.state.isSurplusNoRoll
  var surplusMargin = curTech.techParams.plankInfo.panelSize.surplusMargin
  //        var surplusTrim = curTech.techParams.plankInfo.surplusTrim

  dbProjectObj.sLoadSurplusPostData.disconnect(loadSurplusData)
  mode = gg.getTrans('排版图')

  //    progressInfo.open(Object.keys(rectMap).length, "排版中2:", 1);
  hasFinished = false

  if (isYunLayout) {
    var yunData = {}
    yunData['layoutRectMap'] = rectMap
    yunData['layoutHoleMap'] = {}
    yunData['layoutConfig'] = config
    startYunLayout(yunData)
    return
  }
  if (isTestLayoutPlank == true) {
    gg.generateLayoutInformation(
      rectMap,
      {},
      config,
      surplus,
      false,
      true,
      surplusMargin
    )
  } else {
    gg.generateLayoutInformation(rectMap, {}, config, surplus)
  }
}

//加载余料仓信息
function loadSurplusData(oData) {
  var list = []
  var count = 0
  var canUseCount = 0
  for (var i in oData) {
    var item = oData[i]
    var user_num = item['user_num']
    count += item['amount'] * 1

    if (user_num > 0) {
      if (user_num > 1) {
        for (var aInd = 0; aInd < user_num; aInd++) {
          list.push(item)
        }
      } else {
        list.push(item)
      }
    }
  }

  surplusUseData = getUseSurplusData(list)

  for (var i in surplusUseData) {
    var item = surplusUseData[i]
    if (plankKeysList && plankKeysList.indexOf(i) != -1) {
      canUseCount += item.length
    }
  }
  surplusDataLoad = true
  surplusRow.surplusCount = count
  surplusRow.canSurplus = canUseCount
}

//判断是否有能使用的余料
function judgeUseSurplus(rectMap, config) {
  var worker = function () {
    if (fromPurchase) {
      conductLayout(rectMap, config, surplusList)
    }
    //进入排版页面
    else if (Object.keys(surplusUseData).length > 0) {
      var data = {}
      for (var skey in surplusUseData) {
        if (rectMap.hasOwnProperty(skey)) {
          data[skey] = surplusUseData[skey]
        }
      }

      surplusList = data

      if (surplusList && Object.keys(surplusList).length > 0) {
        confirmUsePop.rectMap = rectMap
        confirmUsePop.config = config
        if (surplusCheckBtn.checked || useSurps == true) {
          confirmUsePop.useSurplus()
        } else {
          confirmUsePop.open()
        }
      } else {
        conductLayout(rectMap, config, surplusList)
      }
    } else {
      conductLayout(rectMap, config, surplusList)
    }
  }

  // 板件数量有差异
  // if (preverify) {
  if (false) {
    function fixed(num, n) {
      return Math.round(num * Math.pow(10, n)) / Math.pow(10, n)
    }

    var eList = []
    var cList = []

    var params = {
      filter_data: [],
    }

    var itemObj = {}
    itemObj['order_id'] = projectInfo['id']
    itemObj['room_id'] = []

    for (var rInd in roomInfos) {
      var roomItem = roomInfos[rInd]
      var curRoomId = roomItem['id']
      itemObj['room_id'].push(curRoomId)
    }

    params['filter_data'].push(itemObj)

    globalJSTool.postJSON(
      'http://ggtools.thinkerx.com/get_material_bill',
      params,
      function success(result) {
        result = JSON.parse(result)

        if (result['status'] === 1) {
          var data = result['data']
          if (data.hasOwnProperty('wardrobe')) {
            var planks = data['wardrobe']['plank_obj']
            for (var i = 0; i < planks.length; ++i) {
              var plank = planks[i]
              var spec = plank['spec']
              var amount = plank['amount']
              var plankID = plank['plkId']
              var roomName = plank['roomName']
              var plankName = plank['plankName']
              var partMaskName = plank['partMaskName']

              if (!roomName) {
                roomName = '-'
              }

              if (!partMaskName) {
                partMaskName = roomName + '_' + plankName
              }

              for (var j = 0; j < amount; ++j) {
                if (plank['matCode'] === gg.getTrans('占位板')) {
                  continue
                }

                eList.push({
                  desc:
                    '#' +
                    plankID +
                    ' ' +
                    roomName +
                    ' ' +
                    partMaskName +
                    ' ' +
                    spec,
                  checked: false,
                })
              }
            }
          }
          if (data.hasOwnProperty('panel')) {
            var panels = data['panel']['panel_obj']
            for (var i = 0; i < panels.length; ++i) {
              var panel = panels[i]
              var spec = panel['spec']
              var name = panel['name']
              var style = panel['style']
              var amount = panel['amount']
              var plankID = panel['plkId']
              var roomName = panel['roomName']
              var parentName = panel['parentName']
              var wardrobeName = panel['wardrobeName']
              var partMaskName = panel['partMaskName']

              if (!roomName) {
                roomName = '-'
              }

              if (!partMaskName) {
                partMaskName =
                  roomName + '_' + wardrobeName + '_' + parentName + '_' + name
              }

              if (style === '板式门') {
                for (var j = 0; j < amount; ++j) {
                  if (panel['matCode'] === gg.getTrans('占位板')) {
                    continue
                  }

                  eList.push({
                    desc:
                      '#' +
                      plankID +
                      ' ' +
                      roomName +
                      ' ' +
                      partMaskName +
                      ' ' +
                      spec,
                    checked: false,
                  })
                }
              }
            }
          }
        }
      }
    )

    // 添加补件板件
    layoutPatchData.forEach(function (part) {
      var plankID = part['plankID']
      var roomName = part['roomName']
      var partName = part['partName']
      var fullSize = part['fullSize']
      var thick = part['thick']

      var rect = part['rect']

      if (!rect) {
        return
      }

      eList.push({
        desc:
          '#' +
          plankID +
          ' ' +
          roomName +
          ' ' +
          partName +
          ' ' +
          (fixed(fullSize.width, 1) +
            '*' +
            fixed(fullSize.height, 1) +
            '*' +
            thick),
        checked: false,
      })
    })

    layoutData.forEach(function (part) {
      var plankID = part['plankID']
      var roomName = part['roomName']
      var partName = part['partName']
      var fullSize = part['fullSize']
      var thick = part['thick']

      var rect = part['rect']

      if (!rect) {
        return
      }

      cList.push({
        desc:
          '#' +
          plankID +
          ' ' +
          roomName +
          ' ' +
          partName +
          ' ' +
          (fixed(fullSize.width, 1) +
            '*' +
            fixed(fullSize.height, 1) +
            '*' +
            thick),
        checked: false,
      })
    })

    // 添加锁定排版板件
    ignorePlankList.forEach(function (part) {
      var plankID = part['plankID']
      var roomName = part['roomName']
      var partName = part['partName']
      var fullSize = part['fullSize']
      var thick = part['thick']

      var rect = part['rect']

      if (!rect) {
        return
      }

      cList.push({
        desc:
          '#' +
          plankID +
          ' ' +
          roomName +
          ' ' +
          partName +
          ' ' +
          (fixed(fullSize.width, 1) +
            '*' +
            fixed(fullSize.height, 1) +
            '*' +
            thick),
        checked: false,
      })
    })

    if (eList.length <= 0 && cList.length > 0) {
      main_msgTips.open(gg.getTrans('多板漏板检测失败!'), 'fail')
      return
    }

    if (eList.length !== cList.length) {
      differentPlankList = []

      var fList = []
      var sList = []

      if (eList.length > cList.length) {
        fList = eList
        sList = cList
      } else {
        fList = cList
        sList = eList
      }

      fList.forEach(function (fPart) {
        var fDesc = fPart['desc']
        var fPartName = fDesc.split(' ')[2]

        for (var k in sList) {
          var sPart = sList[k]
          var sChecked = sPart['checked']
          if (sChecked) {
            continue
          }

          var sDesc = sPart['desc']
          var sPartName = sDesc.split(' ')[2]
          if (
            sPartName.indexOf(fPartName) >= 0 ||
            fPartName.indexOf(sPartName) >= 0
          ) {
            sPart['checked'] = true
            return
          }
        }

        differentPlankList.push(fDesc)
      })

      warningDialog.actual = cList.length
      warningDialog.estimated = eList.length
      warningDialog.callback = worker
      warningDialog.open()
      return
    }
  }

  // worker();
}

//余料使用数据保存——NC输出，余料数据扣减
function useSurplusSave() {
  var useSurplusCount = 0
  var usedSurpCount = {}
  for (var count in layoutResult) {
    if (layoutResult[count]['surplusInfo'] !== null) {
      var curInfo = layoutResult[count]['surplusInfo']
      var res = []
      var needBreak = false
      for (var index in Object.keys(usedSurpCount)) {
        var currentKey = Object.keys(usedSurpCount)[index]
        if (parseFloat(currentKey) == parseFloat(curInfo['id'])) {
          res = usedSurpCount[currentKey]
          res.push(curInfo)
          usedSurpCount[currentKey] = res
          needBreak = true
          continue
        }
      }
      if (needBreak) {
        continue
      }
      res.push(curInfo)
      usedSurpCount[curInfo['id']] = res
      useSurplusCount++
    }
  }
  var result = []
  for (var i in surplusRect) {
    var surplusItem = surplusRect[i]
    var currentID = surplusItem['id']

    var data = {}
    data['id'] = currentID
    data['num'] =
      usedSurpCount && usedSurpCount.hasOwnProperty(currentID)
        ? usedSurpCount[currentID].length
        : useSurplusCount
    var isBreak = false

    for (var rInd in result) {
      var rID = result[rInd]['id']
      if (parseFloat(rID) === parseFloat(currentID)) {
        result[rInd]['num']++
        isBreak = true
        continue
      }
    }

    if (isBreak) {
      continue
    }
    result.push(data)
  }

  var params = {}
  params['surplus'] = result
  dbProjectObj.useSurplusList(params)
}

//**************************************************** 余料利用相关 以上 **************************************************************

//检测孔槽是否在小板内
function checkHoleSlotsInPlank(checkData) {
  var cutR = cutKnifeRadius
  var hasError = false

  for (var i in checkData) {
    var row = deepCopy(checkData[i])
    var holes = row['holes']
    var slots = row['slots']
    var oRect = row['oRect']
    var rect = row['rect']
    var path = row['path']

    var edgeInfo = row['edgeInfo']
    var splitResut = splitEdgeInfo(edgeInfo)
    var left = splitResut.left
    var right = splitResut.right
    var top = splitResut.top
    var bottom = splitResut.bottom

    var qtPoints = []
    if (path) {
      for (var pi in path[0]) {
        qtPoints.push({ x: path[0][pi].x, y: path[0][pi].y })
      }
    } else {
      qtPoints = [
        { x: 0, y: 0 },
        { x: rect.width, y: 0 },
        { x: rect.width, y: rect.height },
        { x: 0, y: rect.height },
        { x: 0, y: 0 },
      ]
    }

    for (var hInd in holes) {
      var hole = holes[hInd]
      var center = { x: hole['center'].x, y: hole['center'].y }
      var inPlank = gg.isPointInPolygin(qtPoints, center)
      if (!inPlank) {
        checkData[i]['holeError'] = true
        hasError = true
        break
      }
    }

    if (checkData[i]['holeError']) {
      continue
    }

    for (var sInd in slots) {
      var slot = slots[sInd]
      var swidth = slot['width']
      var pt1 = { x: slot['pt1'].x, y: slot['pt1'].y }
      var pt2 = { x: slot['pt2'].x, y: slot['pt2'].y }

      var slotRect = []
      if (pt1.x === pt2.x) {
        slotRect = [
          { x: pt1.x - swidth / 2, y: pt1.y },
          { x: pt1.x - swidth / 2, y: pt2.y },
          { x: pt1.x + swidth / 2, y: pt2.y },
          { x: pt1.x + swidth / 2, y: pt1.y },
        ]
      }

      var intersected = gg.polyIntersected(qtPoints, slotRect)
      var error = false
      for (var ind in intersected) {
        var pt = intersected[ind]
        if (slotRect.indexOf(pt) === -1) {
          error = true
        }
      }
      if (error) {
        checkData[i]['holeError'] = true
        hasError = true
        break
      }
    }
  }
  return hasError
}

//wsl-将原来判断孔槽问题的函数提出来
function ifHoleandSlotError(layoutresult) {
  var checkData = []
  for (var i in layoutresult) {
    var layout = layoutresult[i]['parts']
    for (var jj in layout) {
      var row = layout[jj]
      var map = row
      delete map['item']
      checkData.push(map)
    }
  }

  var hasError = checkHoleSlotsInPlank(checkData)

  var result = gg.checkHoleSlots(checkData, cutKnifeRadius, null)
  var holeError = result['networkHoleErrors']
  for (i in layoutresult) {
    layout = layoutresult[i]['parts']
    for (jj in layout) {
      row = layout[jj]
      var ggid = row['ggid_short']
      if (ggid === undefined) ggid = row['ggid']
      if (holeError[ggid]) {
        row['holeError'] = true
      } else {
        ggid = row['oriPlankNum']
        if (holeError[ggid]) {
          row['holeError'] = true
        }
      }
      if (row['holeError']) {
        hasError = true
      }
    }
  }

  if (hasError) {
    layoutView.repaint()
    main_msgTips.open(gg.getTrans('检测到板件孔槽异常，请仔细核对后再生产！'))
    return true
  }
  return false
}

function findErrorHole(layoutResult) {
  for (var i in layoutResult) {
    var parts = layoutResult[i]['parts']
    for (var j in parts) {
      var part = parts[j]
      var holes = part['holes']

      for (var k in holes) {
        var hole = holes[k]
        var p = hole['center']

        if (p.x < 0 || p.y < 0) {
          return true
        }
      }
    }
    return false
  }
}

function changedLayout() {
  layoutPlans = []
  layoutResult = []
  bigPlateArea = 0
  smallPlateArea = 0
  rollCount = 0

  if (layoutMode === 'complement') {
    patchLayoutStart()
  } else {
    loadLayout()
  }
}

//点击触发事件： 排版方案选择
function changeLayoutResult(index) {
  resetPlank()
  bigPlateArea = 0
  smallPlateArea = 0
  rollCount = 0
  if (index !== -1) {
    if (isTestLayoutPlank) {
      isTestLayoutPlank = false
      changedLayout()
      return
    }
    var tmp = T.handleLayoutPlans(index)
    layoutResult = setCutPriority(tmp)
  } else {
    isTestLayoutPlank = true
    changedLayout()
    return
  }

  var l = layoutResult.length
  for (var k in blockedBigPlates) {
    var stock = blockedBigPlates[k]
    k *= 1
    l *= 1
    stock['stockNum'] = k + l
    var parts = stock['parts']
    for (var j in parts) {
      var part = parts[j]
      part['stockNum'] = k + l
      var realRect = part['realRect']
      if (realRect) {
        var realW = realRect.width
        var realH = realRect.height
        smallPlateArea += realH * realW
      }
    }
    bigPlateArea += plankWidth * plankHeight
    layoutResult.push(stock)
  }
  ifHoleandSlotError(layoutResult)
  rollCount = T.getRollCount(layoutResult)
  layoutResult = layoutResult

  hasFinished = true
  blockedStockIdx = []
  flushView()
}

//页面导出路径
function exportFile() {
  if (root.path === '') {
    userFlies.open()
  }
}

//导出云熙DXF
function exportDxf() {
  exportFile()

  if (root.path !== '') {
    loadLayoutTianGong()

    var pth = gg.getStandardPath(root.path)
    var orderCode = ''
    if (libGG.order_code) {
      orderCode = libGG.order_code.replace(new RegExp('[:<>/|:"*?]', 'g'), '')
    }

    var buyerAddress = ''
    if (libGG.buyer_address) {
      buyerAddress = libGG.buyer_address.replace(
        new RegExp('[:<>/|:"*?]', 'g'),
        ''
      )
    }
    buyerAddress = buyerAddress.replace(/:|\*|\?|\/|\\|\||\<|\>|\"/g, '')
    pth +=
      '/云熙-' +
      buyerAddress +
      '-' +
      Qt.formatDateTime(new Date(), 'yyyyMMddhhmmss') +
      '/'
    pth = pth.replace(/\s+/g, ' ')

    var count = 1
    //            var roomInfo = roomInfos[0];
    //            if (roomInfo && roomInfo["parts"] && roomInfo["parts"][gg.getTrans("生产数量")]) {
    //                count = roomInfo["parts"][gg.getTrans("生产数量")];
    //            }

    ggData = GGBom.generator(layoutData, count)
    //为防止重名组件导致覆盖，做如下操作
    for (var k in ggData) {
      var obj = ggData[k]
      obj['partName'] = obj['partName'] + k
    }

    gg.saveYunXiToDxf2(ggData, pth)

    gg.openLocalPath(pth)
  }
}

//*********************************************** 电子开料锯 **************************************************************

//新版料单数据加载
function initSplitNew() {
  loadIndicator.start()
  var param = {
    filter_data: [],
  }
  if (dataForAll) {
    for (var i in dataForAll) {
      var dItem = dataForAll[i]
      var pInfo = dItem['projectInfo']
      var curRoomIds = dItem['roomIds']
      var allRooms = pInfo.room
      var itemObj = {}
      projectInfo = pInfo

      //单个房间的输出
      if (curRoomIds) {
        layoutType = 'layout'
        param['filter_data'] = {}
        param['filter_data']['room_id'] = [curRoomIds[0]]

        break
      } else {
        layoutType = 'fromOneOrder'

        //多订单批量生产
        if (i * 1 > 1) {
          layoutType = 'fromOrders'
        }

        itemObj['order_id'] = pInfo['id']
        itemObj['room_id'] = []

        for (var rInd in allRooms) {
          var roomItem = allRooms[rInd]
          var curRoomId = roomItem['id']
          itemObj['room_id'].push(curRoomId)
        }

        param['filter_data'].push(itemObj)
      }
    }
  } else if (complementOrders) {
    var itemObj = { room_id: [] }
    for (var i in complementOrders) {
      var rooms = complementOrders[i]['plates']
      for (var j in rooms) {
        var rid = rooms[j]['rid']
        if (itemObj['room_id'].indexOf(rid) === -1) {
          itemObj['room_id'].push(rid)
        }
      }
    }
    param['filter_data'].push(itemObj)
  }

  loadSplitData(param)
  loadIndicator.hide()
}

//料单数据加载：批量生产
function loadSplitData(url) {
  if (Object.keys(url).length > 0) {
    var params = url

    globalJSTool.postJSON(
      'http://ggtools.thinkerx.com/get_material_bill',
      params,
      function success(result) {
        result = libGG.parseJson(result)

        if (result['status'] === 1) {
          var data = result['data']
          if (data.hasOwnProperty('wardrobe')) {
            materailBillData = getMaterialBiilData(data)
            return
          } else {
            main_msgTips.open(gg.getTrans(data), 'fail')
            return
          }
        }
        main_msgTips.open(gg.getTrans(result['msg']), 'fail')
      }
    )
  }
}

//*************************************************** 电子开料锯 以上 **********************************************************************

//新版料单——获取数据
function loadSplitViewNew() {
  if (!materailBillData || !materailBillData.length) {
    loadIndicator.start()
    initSplitNew()
    loadIndicator.hide()
  }

  wardrobeBillData = materailBillData
}

//获取开料清单的数据
function getMaterialBiilData(data) {
  var res = []

  //板件信息
  var wardrobeData = handleSplitInterfaceRet('wardrobe', data)
  for (var i in wardrobeData) {
    res.push(wardrobeData[i])
  }

  //板式门
  var doorData = handleSplitInterfaceRet('panel', data)
  for (var j in doorData) {
    res.push(doorData[j])
  }

  return res
}

//处理料单接口返回的数据
function handleSplitInterfaceRet(type, info) {
  var curData = info[type]
  if (type === 'wardrobe') {
    curData = curData['plank_obj']
  } else if (type === 'panel') {
    curData = curData['panel_obj']
  } else if (type === 'slidedoor') {
    curData = curData['slidedoor_obj']
  }

  var result = []

  for (var i in curData) {
    var item = curData[i]

    if (type === 'panel') {
      if (item['style'] === gg.getTrans('板式门')) {
        var row = getTypeRowData(item, 'panelSplit')
        result.push(row)
      }
    } else {
      var row = getTypeRowData(item, type)
      result.push(row)
    }
  }

  return result
}

//开料清单数据加载——补件
function loadMaterialBillData() {
  wardrobeBillData = deepCopy(complementList)
  var tmpComData = fetchcomplementData(complementOrders)
  if (!tmpComData) {
    wardrobeBillData = []
    main_msgTips.open(gg.getTrans('补件数据有误，请重新保存房间补件!'), 'fail')
    return
  }
  wardrobeBillData = wardrobeBillData.concat(tmpComData)
}

function exportUnmanned(filters) {
  var savePath = ''

  if (path !== '') {
    savePath = gg.getStandardPath(path + '/无人线/')
  } else {
    userFlies.open()
    savePath = gg.getStandardPath(userFlieUrl.text + '/无人线/')
  }

  loadLayoutTianGong()

  var count = 1
  //        var roomInfo = roomInfos[0];
  //        if (roomInfo && roomInfo["parts"] && roomInfo["parts"][gg.getTrans("生产数量")]) {
  //            count = roomInfo["parts"][gg.getTrans("生产数量")];
  //        }

  ggData = GGBom.generator(layoutData, count)

  var address = projectInfo['buyer_address']
  if (address) {
    address = address
      .replace(/ /g, '-')
      .replace(/:/g, '-')
      .replace(new RegExp('[:<>/|:"*?]', 'g'), '')
  } else {
    address = '多订单打包'
  }

  savePath = UMD.generator(savePath, address, ggData, filters)

  if (UMD.errorLog.length > 0) {
    // 数据异常
    main_msgTips.open(UMD.errorLog.join('\r\n'), 'fail')
  } else {
    gg.openLocalPath(savePath)
  }

  // 单独启动排版
  if (UMD.addedLayoutContainer.length > 0) {
    var container = []

    for (var i in UMD.addedLayoutContainer) {
      var remain = UMD.addedLayoutContainer[i]

      for (var j in layoutData) {
        var part = layoutData[j]

        if (String(remain['gid']) === String(part['gid'])) {
          container.push(part)
          break
        }
      }
    }

    addedLayout(container)
  }

  // 上传到补件仓
  if (UMD.complementContainer.length > 0) {
    var container = {}

    for (var i in UMD.complementContainer) {
      var remain = UMD.complementContainer[i]

      for (var j in layoutData) {
        var part = layoutData[j]
        if (String(remain['gid']) === String(part['gid'])) {
          var roomID = part['roomID']
          var plankID = part['plankID']

          if (!container.hasOwnProperty(roomID)) {
            container[roomID] = []
          }

          container[roomID].push(plankID)
        }
      }
    }

    for (var key in container) {
      var value = container[key]
      if (value) {
        dbProjectObj.addPatch({ room_id: key, plank_id: value.join(',') })
      }
    }
  }
}

//**************************************************** 排版 NC 备份相关 **************************************************************

//加载备份信息
function loadBackupInfo() {
  var projectIDList = [],
    room_id = []
  var param = {}

  if (layoutType === 'fromOrders') {
    for (var i in multiList) {
      var projectInfo_orders = multiList[i]['projectInfo']
      projectIDList.push(getParam(projectInfo_orders, 'id', ''))
    }

    param = { oid: projectIDList }
  } else if (layoutType === 'fromOneOrder') {
    projectIDList.push(getParam(projectInfo, 'id', ''))
    param = { oid: projectIDList }
  } else {
    room_id = getParam(roomInfos[0], 'id', '')
    param = { room_id: room_id }
  }
  if (param['room_id'] !== '') dbProjectObj.loadNC(param)
}

//nc备份 保存排版 获取名称
function getNameList() {
  var roomNameList = [],
    projectNameList = []
  var projectIDList = []

  for (var i in dataForAll) {
    var oneProject = dataForAll[i]
    var project = oneProject['projectInfo']
    projectNameList.push(project['buyer_address'])
    projectIDList.push(project['id'])

    if (oneProject.hasOwnProperty('roomIds')) {
      var rIds = oneProject['roomIds']
      for (var j in rIds) {
        var rInfo = findRoomInfo(project, rIds[j])
        if (rInfo) {
          roomNameList.push(rInfo['name'])
        }
      }
    } else {
      for (j in project['room']) {
        rInfo = project['room'][j]
        roomNameList.push(rInfo['name'])
      }
    }
  }

  var result = {
    projectNameList: projectNameList,
    projectIDList: projectIDList,
    roomNameList: roomNameList,
  }

  return result
}

export function changeLayoutData(data) {
  preLayoutData = dealPlankHoleSlotCSide(data)
  uniquePlankNum(preLayoutData)
  layoutData = preLayoutData
}

//nc备份 保存排版 获取名称中的日期
function getNowDate(isSave) {
  var date = new Date()
  var year = date.getFullYear()
  var month = date.getMonth() + 1
  var day = date.getDate()
  var hour = date.getHours()
  var minutes = date.getMinutes()

  if (minutes < 10) {
    minutes = '0' + minutes
  }

  var str = ''
  if (isSave) {
    str = Date.parse(new Date())
  } else {
    str = year + '.' + month + '.' + day + ' ' + hour + ':' + minutes
  }

  return str
}

//加载历史排版信息
function loadSavedLayout(data) {
  rectColumn.width = 0
  rectColumn.visible = false
  backLayoutBtn.visible = true

  var urlList = data.split('/')
  var fileName = urlList[urlList.length - 1]
  fileName = gg.getTempRoot() + 'layout/layoutPlan/' + fileName

  if (!gg.isExistFile(fileName)) {
    fileSvc.downloadFile(data, fileName, 'none')
  }
  var newLayoutReault = libGG.parseJson(gg.loadFileToString(fileName))
  layoutResult = newLayoutReault

  surplusRect = []
  layoutView.repaint()
}

//导入排版文件
function importLayoutFile() {
  var result = getNameList(projectInfo, roomInfos, layoutType)
  var projectNameList = result['projectNameList']
  var roomNameList = result['roomNameList']
  var day = Qt.formatDateTime(new Date(), 'yyyy-MM-dd')
  var path = gg.getAppAbsolutePath() + 'nc' + '/' + day + '/'
  for (var i in projectNameList) {
    projectNameList[i] = projectNameList[i].replace(':', '-')
    projectNameList[i] = projectNameList[i].replace(':', '-')
  }
  if (projectNameList.length > 1) {
    path += projectNameList.join('_') + '/批量订单-排版文件.ggp'
  } else {
    var rooms = roomNameList[0].split(',')
    if (rooms.length > 1) {
      path += projectNameList[0] + '/' + projectNameList[0] + '-排版文件.ggp'
    } else {
      path +=
        projectNameList[0] + '_' + rooms[0] + '/' + rooms[0] + '-排版文件.ggp'
    }
  }
  path = path.replace(' ', '-')
  if (!gg.isExistFile(path)) {
    main_msgTips.open(gg.getTrans('暂无对应排版文件'), 'fail')
    return
  }

  var fileData = libGG.parseJson(gg.loadFileToString(path))
  var newLayoutReault = fileData['layout']
  layoutResult = newLayoutReault
  layoutView.repaint()
  main_msgTips.open(gg.getTrans('导入成功'), 'success')
}

//将表数据转换为excel格式
function getExcelData(dataList, roleList) {
  var list = []
  for (var m in dataList) {
    var item = []
    var listItem = dataList[m]

    for (var n in roleList) {
      var role = roleList[n]

      var keys = Object.keys(listItem)
      if (keys.indexOf(role) != -1) {
        item.push(listItem[role])
      } else {
        item.push('')
      }
    }
    list.push(item)
  }

  var result = ''
  for (var itm in list) {
    var istr = list[itm].join(',')
    istr = istr + '\n'
    result = result + istr
  }

  return result
}

//将数组转换为key:value对象
function convertListToJson(list, keyList) {
  var obj = {}
  for (var i in list) {
    for (var j in keyList) {
      if (i * 1 === j * 1) {
        if (list[i] === 'true' || list[i] === 'TRUE') {
          obj[keyList[j]] = true
        } else if (list[i] === 'false' || list[i] === 'FALSE') {
          obj[keyList[j]] = false
        } else {
          obj[keyList[j]] = list[i]
        }
      }
    }
  }
  return obj
}

//加载数据转化为mode
function changeDataToMode(data, headerRole) {
  var list = []
  for (var i in data) {
    if (i > 0) {
      var result = convertListToJson(data[i], headerRole)
      list.push(result)
    }
  }

  importPop.refresh(list)
  importData = list
}

//返回当前排版按钮visible
function changeReturnLayout(value) {
  backLayoutBtn.visible = value
}

//返回当前排版功能
function returnLayout() {
  resetPlank()
  rectColumn.width = outerRectangle.currentWidth
  rectColumn.visible = true
  backLayoutBtn.visible = false
  layoutToolChoose.visible = true

  returnBeforeLayout()
  layoutResult = beforeLayoutResult
  surplusRect = []
  layoutView.repaint()
}

//**************************************************** 排版 NC 备份相关 以上 **************************************************************

//设置板件ID
function setPlankNum() {
  var plankNum = ''
  var oriPlankNum = ''
  if (plankNum === '' || oriPlankNum === '') {
    oriPlankNum = gg.genEAN13CodeByTimestamp()
    plankNum = gg.genEAN13CheckCode(oriPlankNum)
  }
  return [plankNum, oriPlankNum]
}

//加载补件信息
function loadPatchInfo() {
  dbProjectObj.loadAppliesList({ uid: userService.uid })
  globalJSTool.getPatch(
    { uid: userService.uid },
    getPatchSucceed,
    getPatchFailed
  )
}

function getPatchSucceed(s) {
  if (s['status']) {
    var data = s['data']
    var orders = data['orders']
    var count = 0
    var patchMat = {}
    //能够补件板件id;
    var canId = []
    for (var i in orders) {
      var plates = orders[i]['plates']

      for (var k in plates) {
        var plateInfo = plates[k]['plate_info']
        var size = plateInfo['size']
        var thick = Number(size.split('*')[2])

        plates[k]['size'] = size
        plates[k]['thick'] = thick
        plates[k]['plankId'] = plateInfo['plKId']
        plates[k]['color'] = plateInfo['color']
        plates[k]['type'] = plateInfo['matCode']

        var mat = plateInfo['color'] + ':' + plateInfo['matCode'] + ':' + thick
        if (!patchMat.hasOwnProperty(mat)) {
          var matList = [plates[k]['id']]
          patchMat[mat] = matList
        } else {
          matList = patchMat[mat]
          if (matList.indexOf(plates[k]['id']) === -1) {
            matList.push(plates[k]['id'])
          }
          patchMat[mat] = matList
        }
      }
      count += plates.length
    }

    //计算可以顺便补件的板件
    //补件清单里有的旧数据(patchMat)会出现如{"T01.jpg:多层实木:17":[2279,2280,2640]}这样的。需要做处理才能匹配上
    var nObj = {}
    for (var key in patchMat) {
      var v = patchMat[key]
      //key --> T01.jpg:多层实木:17
      var arr = key.split(':')
      var colorCode = arr[0]
      var relColorCode = colorCode.split('.')[0]
      var relKey = [relColorCode, arr[1], arr[2]]
      relKey = relKey.join(':')
      if (!nObj.hasOwnProperty(relKey)) {
        nObj[relKey] = v
      } else {
        nObj[relKey] = nObj[relKey].concat(v)
      }
    }
    for (var i in plankKeysList) {
      //plankKeysList[i]是个类似这样的东西 --> T01:多层实木:17
      if (nObj.hasOwnProperty(plankKeysList[i])) {
        matList = nObj[plankKeysList[i]]
        canId = canId.concat(matList)
      }
    }

    complementOrders = orders
    patchRow.canPatchIdsByRenderJson = canId
  }
  patchDataLoadNew = true
}
function getPatchFailed(e) {
  //        main_msgTips.open(gg.getTrans("获取失败"), "fail")
}

//获取补件基础信息
function loadPatchSimpleInfo() {
  patchRow.patchCount = 0
  patchRow.canPatch = 0
  globalJSTool.getPatchSimpleInfo(
    { uid: userService.uid, new_data: 1 },
    getPatchInfoSucceed
  )
}

function getPatchInfoSucceed(s) {
  if (s['status']) {
    var data = s['data']
    var plates = data['plank_list']
    var count = data['num']
    var canPatchCount = 0
    var patchMat = {}

    for (var k in plates) {
      var plateInfo = plates[k]

      var mat =
        plateInfo['color'] + ':' + plateInfo['type'] + ':' + plateInfo['depth']
      if (!patchMat.hasOwnProperty(mat)) {
        patchMat[mat] = 1
      } else {
        var matCount = patchMat[mat]
        patchMat[mat] = matCount + 1
      }
    }

    for (var i in plankKeysList) {
      //plankKeysList[i]是个类似这样的东西 --> T01:多层实木:17
      if (patchMat.hasOwnProperty(plankKeysList[i])) {
        canPatchCount += patchMat[plankKeysList[i]]
      }
    }

    patchRow.patchCount = count
    patchRow.canPatch = canPatchCount
  }
}

function dealWithPatchInfo(mjson) {
  var num = 0
  var patchMat = {}
  var canId = []
  for (var k in mjson) {
    var plks = mjson[k]['plate']
    num += plks.length

    for (var i in plks) {
      var plateInfo = libGG.parseJson(plks[i]['plate_other'])
      var color = plks[i]['color']
      var type = plks[i]['type']
      var thick = plateInfo['thick']
      var mat = color + ':' + type + ':' + thick
      if (!patchMat.hasOwnProperty(mat)) {
        var matList = [plks[i]['id']]
        patchMat[mat] = matList
      } else {
        matList = patchMat[mat]
        matList.push(plks[i]['id'])
        patchMat[mat] = matList
      }
    }
  }
  for (var i in plankKeysList) {
    if (patchMat.hasOwnProperty(plankKeysList[i])) {
      matList = patchMat[plankKeysList[i]]
      canId = canId.concat(matList)
    }
  }

  patchRow.canPatchIds = canId
  patchDataLoadOld = true
}

//添加顺带补件数据
function addPatchPack() {
  layoutPatchData = []
  var patchIds = patchRow.canPatchIds
  var arr = []
  for (var k in patchData) {
    var plks = patchData[k]['plate']
    for (var i in plks) {
      var tmpId = plks[i]['id']
      if (patchIds.indexOf(tmpId) !== -1) {
        arr.push(plks[i])
      }
    }
  }

  for (var j in arr) {
    var part = arr[j]
    var str = part['size']
    var texture = part['color']
    var roomName = part['room_name']
    var matCode = part['type']
    if (!matCode) {
      matCode = gg.getTrans('多层实木')
    }

    var address = part['buyer_address']
    var name = part['part_name']
    var ggid = part['ggid']
    var orderCode = part['order_code']
    var plankID = part['plankId']
    var sizes = str.split('*')
    var width = sizes[0]
    var height = sizes[1]
    var thick = sizes[2]
    var realRect = Qt.rect(0, 0, width, height)
    var rect = Qt.rect(0, 0, width, height)
    var fullSize = { width: width, height: height }

    var plate = {}
    var gid = ''
    var loc = ''
    var partName = ''
    var plankNum = ''
    var oriPlankNum = ''
    var sholeInfo = ''
    var edgeInfo = ''
    var holes = []
    var sholes = []
    var slots = []
    var path = null
    var oRect = null
    var oPath = null
    var matRotatable = false

    if (part['plate_other']) {
      var pLen = part['plate_other'].length
      if (part['plate_other'][pLen - 1] !== '}') {
        isLocked = false
        return main_msgTips.open(gg.getTrans('数据有误'), 'fail')
      }

      plate = libGG.parseJson(part['plate_other'])
      gid = plate['gid']
      loc = plate['loc']
      partName = plate['partName']
      plankNum = plate['plankNum']
      oriPlankNum = plate['oriPlankNum']
      sholeInfo = plate['sholeInfo']
      holes = plate['holes']
      sholes = plate['sholes']
      slots = plate['slots']
      rect = plate['rect']
      realRect = plate['realRect']
      edgeInfo = plate['edgeInfo']
      path = plate['path']
      oRect = plate['oRect']
      oPath = plate['oPath']
      matRotatable = plate['matRotatable']
      thick = plate['thick']
    }
    var tmpMap = {
      gid: gid,
      loc: loc,
      name: name,
      plankID: plankID,
      partName: partName,
      ggid: ggid,
      remark: '',
      orderNo: orderCode,
      address: address,
      rect: rect,
      realRect: realRect,
      fullSize: fullSize,
      thick: thick,
      texture: texture,
      roomName: roomName,
      plankNum: plankNum,
      oriPlankNum: oriPlankNum,
      matCode: matCode,
      edgeInfo: edgeInfo,
      sholeInfo: sholeInfo,
      holes: holes,
      sholes: sholes,
      slots: slots,
      oRect: oRect,
      oPath: oPath,
      matRotatable: matRotatable,
      item: null,
    }
    if (path) {
      tmpMap['path'] = path
    }

    layoutPatchData.push(tmpMap)
  }

  //新补件
  patchIds = patchRow.canPatchIdsByRenderJson
  var comData = fetchcomplementData(complementOrders, patchIds)
  if (!comData) {
    main_msgTips.open(gg.getTrans('补件数据有误，请重新保存房间补件!'), 'fail')
  } else {
    layoutPatchData = layoutPatchData.concat(comData)
  }
}

function getBtnInfo() {
  var path = gg.getTempRoot() + userService.uid + '/btnInfo.json'
  if (gg.isExistFile(path)) {
    var msg = libGG.parseJson(gg.loadFileToString(path))
    rectColumnFlick.setYunLayoutBtnValue(msg[0]['text'], msg[0]['bId'])
    rectColumnFlick.setLayoutBtnValue(msg[1]['text'], msg[1]['bId'])
    yunLayoutType = msg[0]['bId']
  } else {
    rectColumnFlick.setYunLayoutBtnValue(gg.getTrans('云排版(优化率最高)'), 0)
    rectColumnFlick.setLayoutBtnValue(gg.getTrans('离线排版(方案1)'), -1)
    yunLayoutType = 1
  }
}
function saveBtnInfo() {
  var saveData = [
    rectColumnFlick.getYunLayoutBtnValue(),
    rectColumnFlick.getLayoutBtnValue(),
  ]

  var path = gg.getTempRoot() + userService.uid + '/btnInfo.json'
  gg.saveStringToFile(JSON.stringify(saveData, null, '\t'), path)
}

//刷新界面
function flushView() {
  layoutView.visible = false
  outerRectangle.visible = false

  ncGenerator.visible = false
  labelGenerator.visible = false

  if (mode === gg.getTrans('排版图')) {
    //reLayoutBtn.visible = true
    layoutView.visible = true
    outerRectangle.visible = true
  } else if (mode === '标签') {
    labelGenerator.visible = true
  } else if (mode === '设备NC代码' || mode === '设备班鲁NC代码') {
    ncGenerator.loading = false
    ncGenerator.visible = true
    libGG.setGlobalVariable('updateNCConfigInExporting', false)
    //reLayoutBtn.visible = false;
    //if(blockLay.visible === true) {
    //  blockLay.visible = false;
    //}

    //是否显示备份次数
    loadBackupInfo()

    saveLayoutDataToLocalWithNc()
  }

  loadIndicator.hide()
}

function getSmallLayoutData(list) {
  var resultList = []
  for (var i in list) {
    var row = list[i]
    var tmpRow = {}
    tmpRow['stockKey'] = row['stockKey']
    tmpRow['plankWidth'] = row['plankWidth']
    tmpRow['plankHeight'] = row['plankHeight']
    var tmpParts = []
    var parts = row['parts']
    for (var j in parts) {
      var part = parts[j]
      var tmpPart = {}
      tmpPart['rect'] = part['rect']
      tmpPart['startX'] = part['startX']
      tmpPart['startY'] = part['startY']
      tmpPart['cutOrigin'] = part['cutOrigin']
      tmpPart['priority'] = part['priority']
      if (layoutView.userChangedList.indexOf(part['plankNum']) > -1)
        tmpPart['changed'] = 1
      else tmpPart['changed'] = 0
      tmpParts.push(tmpPart)
    }
    tmpRow['parts'] = tmpParts
    resultList.push(tmpRow)
  }
  return resultList
}

//深度拷贝
export function deepCopy(obj) {
  var result = Array.isArray(obj) ? [] : {}
  if (obj == null) {
    result = null
    return result
  } else if (obj == undefined) {
    result = undefined
    return result
  }

  for (var key in obj) {
    if (obj.hasOwnProperty(key)) {
      if (typeof obj[key] === 'object') {
        result[key] = deepCopy(obj[key]) //递归复制
      } else {
        result[key] = obj[key]
      }
    }
  }
  return result
}

//开料清单
function externLoadDataToLayout(iData) {
  var result = []
  for (var i in iData) {
    var plk = iData[i]
    var num = plk['num'] * 1

    if (plk['layoutData']) {
      var tData = {}
      var pData = plk['layoutData']
      tData['plankID'] = pData['plankID']
      tData['role'] = pData['role']
      tData['partName'] = pData['partName']
      tData['name'] = pData['name']
      tData['plkName'] = pData['plkName']
      tData['edgeInfo'] = pData['edgeInfo']
      tData['matCode'] = pData['matCode']
      tData['texture'] = pData['texture']
      tData['texDir'] = pData['texDir']
      tData['thick'] = pData['thick']
      tData['fullSize'] = pData['fullSize']
      tData['rect'] = pData['rect']
      tData['realRect'] = pData['realRect']
      tData['oRect'] = pData['oRect']
      tData['holes'] = pData['holes']
      tData['slots'] = pData['slots']
      tData['sholes'] = pData['sholes']
      tData['sslots'] = pData['sslots']
      tData['sholeInfo'] = pData['sholeInfo']
      tData['sslotInfo'] = pData['sslotInfo']
      tData['wardrobeName'] = pData['wardrobeName']
      tData['roomName'] = pData['roomName']
      tData['address'] = pData['address']
      tData['plankNum'] = pData['plankNum']
      tData['oriPlankNum'] = pData['oriPlankNum']
      tData['ggid'] = pData['ggid']
      tData['needRoll'] = pData['needRoll']
      tData['matRotatable'] = pData['matRotatable']

      for (var cind = 0; cind < num; ++cind) {
        result.push(tData)
      }
      continue
    }

    var texture = plk['texture']
    var spec = plk['spec']
    var size = plk['size']
    var texDir = plk['texDir']
    var plankNum = plk['number']
    var name = plk['name']
    var edges = plk['edgeInfo']
    var hsInfo = plk['hsInfo']

    var thick = 0
    var matCode = '多层实木'

    var width = 0,
      height = 0
    if (size && size.indexOf('=') !== -1) {
      var brr = size.split('=')
      var str = brr[0]
      if (str.indexOf('x') !== -1) {
        var crr = str.split('x')
        if (crr.length !== 3) {
          main_msgTips.open('错误的尺寸规则(WxHxT)', 'fail')
          return
        }
        height = crr[0]
        height *= 1
        width = crr[1]
        width *= 1
        var boolW = globalJSTool.isNumber(height) && height > 0
        var boolH = globalJSTool.isNumber(width) && width > 0
        if (!boolW) {
          main_msgTips.open('错误的尺寸规则(宽度)', 'fail')
          return
        }
        if (!boolH) {
          main_msgTips.open('错误的尺寸规则(高度)', 'fail')
          return
        }
      } else {
        main_msgTips.open('错误的尺寸规则(x)', 'fail')
        return
      }
    } else {
      main_msgTips.open('错误的尺寸规则(=)', 'fail')
      return
    }

    if (texDir === gg.getTrans('横纹')) {
      var temp = width
      width = height
      height = temp
    }

    if (spec && spec.indexOf('_') !== -1) {
      var xrr = spec.split('_')
      str = xrr[1]
      matCode = xrr[0]
      thick = str.split(matCode)[0]
      thick *= 1
      var boolT = globalJSTool.isNumber(thick) && thick > 0
      if (!boolT) {
        main_msgTips.open('错误的尺寸规则(厚度)', 'fail')
        return
      }
    } else {
      main_msgTips.open('错误的材质', 'fail')
    }

    var rwidth = width
    var rheight = height
    var edgeInfo = ''
    if (edges) {
      var edgeSplit = edges.split(':')
      if (edgeSplit.length !== 2) {
        main_msgTips.open('封边信息错误', 'fail')
        edgeInfo = ''
      } else if (edgeSplit.length === 2) {
        edges = edgeSplit[1].trim()
        edges = edges.split(/[^0-9.]/gi)
        var dirs = ['', '←', '↓', '→', '↑']
        for (var j = 1; j < edges.length; ++j) {
          edgeInfo += dirs[j] + edges[j]
          if (j % 2) {
            rwidth -= edges[j]
          } else {
            rheight -= edges[j]
          }
        }
      }
    }

    tData = {}
    tData['plankID'] = plankNum
    tData['role'] = 'Plank'
    tData['partName'] = name
    tData['name'] = name
    tData['plkName'] = name
    tData['edgeInfo'] = edgeInfo
    tData['matCode'] = matCode
    tData['texture'] = texture
    tData['texDir'] = texDir
    tData['thick'] = thick

    tData['fullSize'] = {
      width: width,
      height: height,
    }
    tData['rect'] = Qt.rect(0, 0, rwidth, rheight)
    tData['realRect'] = Qt.rect(0, 0, rwidth, rheight)
    tData['oRect'] = Qt.rect(0, 0, width, height)
    tData['holes'] = []
    tData['slots'] = []
    tData['sholes'] = []
    tData['sslots'] = []
    tData['sholeInfo'] = ''
    tData['sslotInfo'] = ''
    tData['wardrobeName'] = ''
    tData['roomName'] = ''
    tData['address'] = ''
    tData['oriPlankNum'] = gg.genEAN13CodeByTimestamp()
    tData['plankNum'] = gg.genEAN13CheckCode(tData['oriPlankNum'])
    tData['matRotatable'] = texDir === gg.getTrans('无纹理')

    for (var cind = 0; cind < num; ++cind) {
      result.push(tData)
    }
  }

  preLayoutData = result
  layoutStart()
}

function checkBackPlank(layoutData) {
  var result = []

  for (var i in layoutData) {
    var data = layoutData[i]
    var edgeInfo = data['edgeInfo']
    var rect = data['rect']
    var oRect = data['oRect']
    var partName = data['partName']
    var roomName = data['roomName']
    var splitResut = splitEdgeInfo(edgeInfo)
    var left = splitResut.left
    var right = splitResut.right
    var top = splitResut.top
    var bottom = splitResut.bottom

    var width = Number(rect['width'])
    var height = Number(rect['height'])
    var owidth = Number(oRect['width'])
    var oheight = Number(oRect['height'])
    // 排除异型的条件，由于clipper的扩张问题可能会导致出现大小的误差(在后端就已经出现了)
    if (
      (Math.abs(width + left + right - owidth) > 0.1 ||
        Math.abs(height + top + bottom - oheight) > 0.1) &&
      !data.path
    ) {
      if (result.indexOf(roomName) === -1) {
        result.push(roomName)
      }
    }
  }
  return result
}

var plankKeysList

function getParam(obj, key, def) {
  if (obj !== undefined && obj !== null) {
    if (obj.hasOwnProperty(key)) {
      return obj[key]
    }
  }
  if (def !== undefined) {
    return def
  }
  return ''
}

async function startLayoutUseJson(rooms) {
  plankKeysList = []

  var preLayoutDatas = []
  for (var i in rooms) {
    var room = rooms[i]
    var rParts = room['parts']
    var n = 1
    if (typeof rParts === 'string' && rParts) {
      n = parseInt(rParts.split(':')[1])
      if (typeof n != 'number') {
        n = 1
      }
    } else {
      n = rParts['生产数量']
    }

    if (n == 0) {
    }
    for (var j = 0; j < n; j++) {
      preLayoutDatas.push(room)
    }
  }
  var data = []
  await loadLayoutDataNew(preLayoutDatas).then((res) => {
    data = res
  })
  return data
}

//通过URL下载并加载数据
async function downloadRenderData(url) {
  var loadData = {}
  await axios.get(url).then((res) => {
    loadData = res.data
  })
  return loadData
}

async function loadLayoutDataNew(data) {
  var resultData = []

  for (var i in data) {
    var room = data[i]
    var path = room['render_url']
    var loadData = await downloadRenderData(path)
    //        var version = loadData["version"];
    //        if(!version || version<"2.9.0.4-2") {
    //            main_msgTips.open(gg.getTrans("该房间需要重新保存")+"("+room["name"]+")", "fail");
    //            break;
    //        }
    var tmpData = getLayoutDataNew(loadData, room)
    if (!tmpData) {
      return null
    }

    resultData = resultData.concat(tmpData)
  }
  // var path = "http://eggrj.oss-cn-hangzhou.aliyuncs.com/classifiedJins/1588926699454.json"//正常柜子
  // var path = "http://eggrj.oss-cn-hangzhou.aliyuncs.com/classifiedJins/1589164100872.json";//异形柜子
  return resultData
}

function getLayoutDataNew(loadData, room, plkId, plateInfo) {
  var planks = []
  var roomName = room['name']
  var models = loadData['models']

  for (var i in models) {
    var axis = models[i]['axis']
    collectPlanks(models[i], planks, room, {}, axis)
  }
  if (plkId !== undefined) {
    //补件
    var patchPlank = []
    for (var i in planks) {
      var xplateInfo = {
        color: planks[i].texImg,
        matCode: planks[i].matCode,
        size: planks[i].spec,
      }
      var boolX = true
      for (var x in xplateInfo) {
        if (xplateInfo[x] != plateInfo[x]) {
          boolX = false
          break
        }
      }
      var plkid = planks[i]['plkId']
      if (plkid === plkId && boolX) {
        patchPlank.push(planks[i])
      }
    }
    planks = patchPlank
  }
  let layoutdatas = []
  for (var i in planks) {
    var data = planks[i]
    var tmpData = dealWithLayoutData(data, room)
    if (!tmpData) {
      return null
    }
    layoutdatas.push(tmpData)
  }
  return layoutdatas
}

function dealWithLayoutData(data, roomInfo) {
  var result = {}
  var plkID = data['plkId']
  var guid = data['guid']
  var name = data['name']
  var partName = data['partName']
  var wardrobeName = data['wardrobeName']
  var partMaskName = data['partMaskName']
  var ggid = data['ggid']
  var roomName = data['roomName']
  var plkName = roomName + '_' + wardrobeName + '_' + name
  var role = data['roleName']
  var plankNum = data['plankNum']

  var edgeInfo = data['edgeInfo'] ? data['edgeInfo'] : ''
  var edgeInfo2 = data['edgeInfo2'] ? data['edgeInfo2'] : ''
  var matCode = data['matCode']
  var texture = data['texImg']
  var texDir = data['texDir']
  var loc = data['wardrobeName']

  var lastCurve = data['lastCurve']
  var lastCurveCut = data['lastCurveCut']
  var lastCurveTG = data['lastCurveTG']
  var coord = data['coord']
  var cutSize = data['cutSize']
  var spec = data['spec']
  var sizes = spec.split('*')
  var owidth = sizes[0]
  var oheight = sizes[1]
  var width = Number(cutSize.split('*')[0]).toFixed(3)
  var height = Number(cutSize.split('*')[1]).toFixed(3)
  var thick = data['plankThick']
  if (!thick) {
    thick = sizes[2]
  }

  var drawerStyle = data['drawerStyle']
  if (drawerStyle) {
    result['drawerStyle'] = drawerStyle
  }

  if (ggid && ggid.indexOf('http://eggi.cn/') === -1) {
    var str = 'http://eggi.cn/÷' + ggid
    ggid = str
  }

  var fullSize = {
    width: owidth,
    height: oheight,
  }

  var objSize = data['objSize']
  if (objSize) {
    var objs = objSize.split('*')
    var oldSize = {
      width: objs[0],
      height: objs[1],
    }
  } else {
    oldSize = {
      width: owidth,
      height: oheight,
    }
  }

  var hdvDir = data['hdvDir']

  if (plankKeysList.indexOf(texture + ':' + matCode + ':' + thick) === -1) {
    //余料使用
    plankKeysList.push(texture + ':' + matCode + ':' + thick)
  }

  if (texDir === 'reverse') {
    var tmp = width
    width = height
    height = tmp

    tmp = owidth
    owidth = oheight
    oheight = tmp
  }

  var sz = {
    width: width,
    height: height,
    owidth: owidth,
    oheight: oheight,
  }

  //不带封边
  var rect = {
    x: 0,
    y: 0,
    width: width,
    height: height,
  }
  var realRect = {
    x: 0,
    y: 0,
    width: width,
    height: height,
  }
  //带封边
  var oRect = {
    x: 0,
    y: 0,
    width: owidth,
    height: oheight,
  }

  var holes = data['holes'] ? data['holes'] : []
  var slots = data['slots'] ? data['slots'] : []
  var sholes = data['sholes'] ? data['sholes'] : []
  var sslots = data['sslots'] ? data['sslots'] : []

  result['realCurve'] = [
    {
      x: 0,
      y: 0,
    },
    {
      x: owidth,
      y: 0,
    },
    {
      x: owidth,
      y: oheight,
    },
    {
      x: 0,
      y: oheight,
    },
  ]
  result['realCurveEx'] = []
  // TODO: 异形路径处理
  if (lastCurve && lastCurve !== 'none') {
    if (!coord) {
      return null
    }
    //请别删这个注释
    if (!lastCurveCut) {
      var lpath = dealWithLastCurve(
        lastCurve,
        coord,
        texDir,
        sz,
        edgeInfo2,
        hdvDir,
        thick
      )
    } else {
      lpath = dealWithLastCurveCut(
        lastCurve,
        lastCurveCut,
        lastCurveTG,
        coord,
        texDir,
        sz,
        edgeInfo2,
        hdvDir,
        thick
      )
    }

    result['realCurve'] = lpath['realCurve']
    result['realCurveEx'] = lpath['realCurveEx']
    result['cutCurve'] = lpath['cutCurve']
    result['cutCurveEx'] = lpath['cutCurveEx']
    result['path'] = lpath['path']
    result['oPath'] = lpath['oPath']
    result['depth'] = lpath['depth']

    holes = holes.concat(lpath['exHoles'])
    slots = slots.concat(lpath['exSlots'])
  }

  const sh = holes.filter((hole) => isOtherSideMachineHole(hole))
  const sl = slots.filter((slot) => isOtherSideMachineSlot(slot))
  let sholeInfo = generatorSideHoleSlot([
    ...sholes,
    ...sh.filter((h) => h.side == '1'),
  ])
  let sholeInfoF = generatorSideHoleSlot(
    sh.filter((h) => h.side == '-1'),
    'upDownFlip'
  )
  let sslotInfo = generatorSideHoleSlot([
    ...sslots,
    ...sl.filter((l) => l.side == '1'),
  ])
  let sslotInfoF = generatorSideHoleSlot(
    sl.filter((l) => l.side == '-1'),
    'upDownFlip'
  )

  var matRotatable = false
  if (texDir === 'notcare') {
    matRotatable = true
  }

  //酒格板  // 忽略
  // if (data.hasOwnProperty("parentType") && data["parentType"] === "WineRack") {
  //   var cellInfo = data["cellInfo"];
  //   if (cellInfo) {
  //     var winResult = getWineRackPlankPath(width, height, thick, cellInfo);

  //     result["path"] = winResult["path"];
  //     result["oPath"] = winResult["oPath"];
  //     sholeInfo = winResult["sholeInfo"];
  //     sslotInfo = winResult["sslotInfo"];
  //     fullSize = winResult["fullSize"];
  //     oldSize = winResult["fullSize"];
  //   }
  // }

  //板式门处理
  if (partName === 'SingleDoor') {
    var openway = data['openway']
    var openDir = ''
    if (openway === 'left') {
      openDir = '左'
    } else if (openway === 'right') {
      openDir = '右'
    } else if (openway === 'top') {
      openDir = '上'
    } else if (openway === 'bottom') {
      openDir = '下'
    }
    result['openDir'] = openDir
  }

  var len = plankNum.length

  result['plankNumType2'] =
    plankNum.slice(0, len - 6) +
    '-' +
    plankNum.slice(len - 6, -4) +
    '-' +
    plankNum.slice(-4)
  result['type'] = partName
  result['guid'] = guid
  result['ggid'] = ggid
  result['role'] = role
  result['partName'] = partMaskName ? partMaskName : plkName
  result['plankID'] = plkID
  result['plankNum'] = plankNum
  result['oriPlankNum'] = plankNum
  result['name'] = name
  result['wardrobeName'] = wardrobeName
  result['roomName'] = roomName
  result['plkName'] = plkName
  result['edgeInfo'] = edgeInfo2
  result['matCode'] = matCode
  result['texture'] = texture
  result['texDir'] = texDir
  result['thick'] = thick
  result['fullSize'] = fullSize
  result['oldSize'] = oldSize
  result['rect'] = rect
  result['realRect'] = realRect
  result['oRect'] = oRect
  result['holes'] = holes
  result['slots'] = slots
  result['sholes'] = sholes
  result['sslots'] = sslots
  result['sholeInfo'] = sholeInfo
  result['sholeInfoF'] = sholeInfoF
  result['sslotInfo'] = sslotInfo
  result['sslotInfoF'] = sslotInfoF
  result['matRotatable'] = matRotatable
  result['loc'] = loc

  plankOtherInfo(result, guid, roomInfo)

  var hasBack = false
  for (var si in slots) {
    var slot = slots[si]
    var side = slot['side']
    if (side === -1) {
      hasBack = true
    }
  }
  for (var hi in holes) {
    var hole = holes[hi]
    side = hole['side']
    if (side === -1) {
      hasBack = true
    }
  }

  if (hasBack) {
    result['oriPlankNumF'] = plankNum + 'K'
  }

  //防止C++中数据类型不统一
  for (var i in result['holes']) {
    var hole = result['holes'][i]
    hole['center'] = {
      x: hole['center']['x'],
      y: hole['center']['y'],
    }
    hole['ocenter'] = {
      x: hole['ocenter']['x'],
      y: hole['ocenter']['y'],
    }
  }
  return result
}

// // 构造侧孔顺序
// function genSHoleInfo(sholes, isFront = true) {
//   var holeHash = {}
//   for (var i in sholes) {
//     var shole = sholes[i]
//     var side = shole['cSide'] ? shole['cSide'] : shole['side']

//     if (!holeHash.hasOwnProperty(side)) {
//       holeHash[side] = []
//     }

//     holeHash[side].push(shole)
//   }
//   let sholeInfo = isFront ? '' : '翻面'

//   if (isFront) {
//     Object.keys(holeHash).forEach(function (side, i) {
//       switch (side) {
//         case '1':
//           sholeInfo += i + 1 + '←'
//           break
//         case '2':
//           sholeInfo += i + 1 + '↓'
//           break
//         case '3':
//           sholeInfo += i + 1 + '→'
//           break
//         case '4':
//           sholeInfo += i + 1 + '↑'
//           break
//         default:
//           break
//       }
//     })
//   } else {
//     Object.keys(holeHash).forEach(function (side, i) {
//       switch (side) {
//         case '1':
//           sholeInfo += i + 1 + '←'
//           break
//         case '4':
//           sholeInfo += i + 1 + '↓'
//           break
//         case '3':
//           sholeInfo += i + 1 + '→'
//           break
//         case '2':
//           sholeInfo += i + 1 + '↑'
//           break
//         default:
//           break
//       }
//     })
//   }

//   return sholeInfo
// }
// // 构造侧槽顺序
// function genSSlotInfo(sslots, isFront = true) {
//   var slotHash = {}

//   for (var i in sslots) {
//     var sslot = sslots[i]
//     var side = sslot['cSide'] ? sslot['cSide'] : sslot['side']

//     if (!slotHash.hasOwnProperty(side)) {
//       slotHash[side] = []
//     }

//     slotHash[side].push(sslot)
//   }
//   let sslotInfo = isFront ? '' : '翻面'

//   if (isFront) {
//     Object.keys(slotHash).forEach(function (side, i) {
//       switch (side) {
//         case '1':
//           sslotInfo += i + 1 + '←'
//           break
//         case '2':
//           sslotInfo += i + 1 + '↓'
//           break
//         case '3':
//           sslotInfo += i + 1 + '→'
//           break
//         case '4':
//           sslotInfo += i + 1 + '↑'
//           break
//         default:
//           break
//       }
//     })
//   } else {
//     Object.keys(slotHash).forEach(function (side, i) {
//       switch (side) {
//         case '1':
//           sslotInfo += i + 1 + '←'
//           break
//         case '4':
//           sslotInfo += i + 1 + '↓'
//           break
//         case '3':
//           sslotInfo += i + 1 + '→'
//           break
//         case '2':
//           sslotInfo += i + 1 + '↑'
//           break
//         default:
//           break
//       }
//     })
//   }

//   return sslotInfo
// }
export function generatorSideHoleSlot(targets, type) {
  const targetHash = {}
  for (let i in targets) {
    const target = targets[i]
    const side = target['cSide'] ? target['cSide'] : target['side']
    if (!targetHash.hasOwnProperty(side)) {
      targetHash[side] = []
    }
    targetHash[side].push(target)
  }
  let targetInfo = type ? '翻面' : ''

  if (type === 'upDownFlip') {
    Object.keys(targetHash).forEach(function (side, i) {
      switch (side) {
        case '1':
          targetInfo += i + 1 + '←'
          break
        case '4':
          targetInfo += i + 1 + '↓'
          break
        case '3':
          targetInfo += i + 1 + '→'
          break
        case '2':
          targetInfo += i + 1 + '↑'
          break
        default:
          break
      }
    })
  } else if (type === 'leftRightFlip') {
    Object.keys(targetHash).forEach(function (side, i) {
      switch (side) {
        case '3':
          targetInfo += i + 1 + '←'
          break
        case '2':
          targetInfo += i + 1 + '↓'
          break
        case '1':
          targetInfo += i + 1 + '→'
          break
        case '4':
          targetInfo += i + 1 + '↑'
          break
        default:
          break
      }
    })
  } else {
    Object.keys(targetHash).forEach(function (side, i) {
      switch (side) {
        case '1':
          targetInfo += i + 1 + '←'
          break
        case '2':
          targetInfo += i + 1 + '↓'
          break
        case '3':
          targetInfo += i + 1 + '→'
          break
        case '4':
          targetInfo += i + 1 + '↑'
          break
        default:
          break
      }
    })
  }
  return targetInfo
}
// 值判断
function judgeUndefined(data) {
  if (!data) {
    return []
  }
  return data
}
export function autoRollOver(plank, isAutoRoll) {
  if (store.state.ncSetting.custom_special_hole_slot_side.is_open) {
    // 自定义特殊孔槽规则数组
    const special_hole_slot_side =
      store.state.ncSetting.custom_special_hole_slot_side.special_hole_slot_side
    //特殊孔槽自定义规则
    specialRuleMap = new Map()
    // 按优先级(索引由低到高)判断小板是否带有规则定义的特殊孔槽
    for (const obj of special_hole_slot_side) {
      const key = obj.key
      const value = obj.value === 'front' ? 1 : -1
      specialRuleMap.set(key, value)
    }
  }
  var isNeed = iNeedRollOver(plank)
  // 当板件是高光板并且 high_gloss_side 不为0的情况才可进入自动翻面
  const isHighlightF =
    plank['is_high_gloss_plank'] && plank['high_gloss_side'] != 0
  if (isNeed && isAutoRoll && !isHighlightF) {
    // 翻面板件
    var rect = plank['rect']
    var holes = plank['holes']
    var sholes = plank['sholes']
    var slots = plank['slots']
    let handleSlopes = plank['handleSlopes'] ?? []
    var sslots = plank['sslots']
    var edgeInfo = plank['edgeInfo']
    var thick = plank['thick']
    var oRect = plank['oRect']
    var oheight = oRect['height']
    var owidth = oRect['width']
    var curveHoles = plank['curveHoles']
    let millInfo = plank['millInfo'] ?? []
    var oriCurveHoles = plank['oriCurveHoles']
    let model_front_edge = plank['model_front_edge']
    let lightEdgeInfo = plank['lightEdgeInfo']
    let doorOpenSide = plank['doorOpenSide']

    // 全部未打穿的孔槽
    const allNoThroughSlotHoles = [
      ...judgeUndefined(holes),
      ...judgeUndefined(slots),
      ...judgeUndefined(curveHoles),
      ...judgeUndefined(millInfo),
    ].filter((e) => e.deep * 1 + 0.001 < thick)
    // 是否全在同一面
    const isAllSameSide =
      allNoThroughSlotHoles.every((e) => e.side == 1) ||
      allNoThroughSlotHoles.every((e) => e.side == -1)

    // holes
    holes.forEach(function (it) {
      it.center = { x: it.center.x, y: rect.height - it.center.y }
      it.ocenter = { x: it.ocenter.x, y: oheight - it.ocenter.y }
      // 如果存在正反面都存在未打穿孔槽
      if (isAllSameSide) {
        it.side = -it.side
      } else {
        if (
          it.deep * 1 + 0.001 < thick ||
          !store.state.ncSetting.throughHoleSlotSameSide
        ) {
          it.side = -it.side
        }
      }

      // 上下翻面，不修改上下两边孔的方向。
      const newHole = adjustHoleDirection(it, 0, 'flipX')
      Object.assign(it, newHole)
    })
    if (curveHoles) {
      curveHoles.forEach((item) => {
        item['path'].forEach((p) => {
          p.y = rect.height - p.y
        })
        if (item.deep + 0.001 < thick) item.side = -item.side
      })
    }
    // 牛角槽
    if (millInfo) {
      const noThroughMill = millInfo.filter(
        (mill) => Number(mill.depth) + 0.001 < thick
      )
      let sameSide = noThroughMill[0] ? -noThroughMill[0].side : 0
      millInfo.forEach((item) => {
        item['shape'].forEach((p) => {
          p.y = oRect.height - p.y
        })
        if (sameSide) item.side = sameSide
      })
    }

    if (oriCurveHoles) {
      oriCurveHoles.forEach((item) => {
        item['path'].forEach((p) => {
          p.y = oRect.height - p.y
        })
        item.side = -item.side
      })
    }

    sholes.forEach(function (shole) {
      var side = shole['side']
      var center = shole['center']
      var ocenter = shole['ocenter']

      switch (String(side)) {
        case '2':
          side = 4
          break
        case '4':
          side = 2
          break
      }
      center = {
        x: center.z - center.x,
        y: thick - center.y,
        z: center.z,
      }
      ocenter = { x: ocenter.x, y: oheight - ocenter.y }

      shole['side'] = side
      shole['center'] = center
      shole['ocenter'] = ocenter
    })

    // slots
    slots.forEach(function (it) {
      it.pt1 = { x: it.pt1.x, y: rect.height - it.pt1.y }
      it.opt1 = { x: it.opt1.x, y: oheight - it.opt1.y }
      it.pt2 = { x: it.pt2.x, y: rect.height - it.pt2.y }
      it.opt2 = { x: it.opt2.x, y: oheight - it.opt2.y }
      it.side = -it.side
    })

    // handleSlopes
    // wc handleSlopes暂不加入孔槽集中面判断
    handleSlopes &&
      handleSlopes.forEach(function (it) {
        it.pt1 = { x: it.pt1.x, y: rect.height - it.pt1.y }
        it.opt1 = { x: it.opt1.x, y: oheight - it.opt1.y }
        it.pt2 = { x: it.pt2.x, y: rect.height - it.pt2.y }
        it.opt2 = { x: it.opt2.x, y: oheight - it.opt2.y }
        it.side = -it.side
      })

    // sslots
    sslots.forEach(function (sslot) {
      var side = sslot['side']
      var pt1 = sslot['pt1']
      var opt1 = sslot['opt1']
      var pt2 = sslot['pt2']
      var opt2 = sslot['opt2']

      switch (String(side)) {
        case '2':
          side = 4
          break
        case '4':
          side = 2
          break
      }
      // 在侧槽side不同面，z代表着板宽和板长
      // 侧槽的y代表在侧边的距上和距下的位置
      pt1 = {
        x: pt1.z - pt1.x,
        y: thick - pt1.y,
        z: pt1.z,
      }
      opt1 = { x: opt1.x, y: oheight - opt1.y }

      pt2 = {
        x: pt2.z - pt2.x,
        y: thick - pt2.y,
        z: pt2.z,
      }
      opt2 = { x: opt2.x, y: oheight - opt2.y }

      sslot['side'] = side
      sslot['pt1'] = pt1
      sslot['opt1'] = opt1
      sslot['pt2'] = pt2
      sslot['opt2'] = opt2
    })

    // edgeInfo
    var left = edgeInfo.substring(
      edgeInfo.indexOf('←') + 1,
      edgeInfo.indexOf('↓')
    )
    var bottom = edgeInfo.substring(
      edgeInfo.indexOf('↓') + 1,
      edgeInfo.indexOf('→')
    )
    var right = edgeInfo.substring(
      edgeInfo.indexOf('→') + 1,
      edgeInfo.indexOf('↑')
    )
    var top = edgeInfo.substring(edgeInfo.indexOf('↑') + 1)
    edgeInfo = '←' + left + '↓' + top + '→' + right + '↑' + bottom
    lightEdgeInfo = dealEdgeInfoChange(lightEdgeInfo, 'upDownFlip')
    model_front_edge = dealFormerEdgeChange(
      model_front_edge,
      'upDownFlip',
      'former'
    )
    doorOpenSide = dealFormerEdgeChange(
      doorOpenSide,
      'upDownFlip',
      'doorOpenSide'
    )

    const sh = holes.filter((hole) => isOtherSideMachineHole(hole))
    const sl = slots.filter((slot) => isOtherSideMachineSlot(slot))
    let sholeInfo = generatorSideHoleSlot([
      ...sholes,
      ...sh.filter((h) => h.side == '1'),
    ])
    let sholeInfoF = generatorSideHoleSlot(
      sh.filter((h) => h.side == '-1'),
      'upDownFlip'
    )
    let sslotInfo = generatorSideHoleSlot([
      ...sslots,
      ...sl.filter((l) => l.side == '1'),
    ])
    let sslotInfoF = generatorSideHoleSlot(
      sl.filter((l) => l.side == '-1'),
      'upDownFlip'
    )

    plank['holes'] = holes
    plank['slots'] = slots
    plank['sholes'] = sholes
    plank['sslots'] = sslots
    plank['edgeInfo'] = edgeInfo
    plank['sholeInfo'] = sholeInfo
    plank['sholeInfoF'] = sholeInfoF
    plank['sslotInfo'] = sslotInfo
    plank['sslotInfoF'] = sslotInfoF
    plank['curveHoles'] = curveHoles
    plank['millInfo'] = millInfo
    plank['oriCurveHoles'] = oriCurveHoles
    plank['lightEdgeInfo'] = lightEdgeInfo
    plank['model_front_edge'] = model_front_edge
    plank['doorOpenSide'] = doorOpenSide

    // path
    if (plank.hasOwnProperty('path')) {
      var o = { x: rect.width / 2, y: rect.height / 2 }

      var paths = plank['path']
      for (var i in paths) {
        var path = paths[i]
        for (var j in path) {
          var p = path[j]

          var x = p['x'] - o.x
          var y = p['y'] - o.y

          p['x'] = x + o.x
          p['y'] = -y + o.y
        }
      }
      plank['path'] = paths
    }

    // oPath
    if (plank.hasOwnProperty('oPath')) {
      var o = { x: owidth / 2, y: oheight / 2 }

      var paths = plank['oPath']
      for (var i in paths) {
        var path = paths[i]
        for (var j in path) {
          var p = path[j]

          var x = p['x'] - o.x
          var y = p['y'] - o.y

          p['x'] = x * 1 + o.x
          p['y'] = -y + o.y
        }
      }
      plank['oPath'] = paths
    }

    // cutCurve
    if (plank['cutCurve'] && plank['cutCurve'].length) {
      var o = { x: rect.width / 2, y: rect.height / 2 }

      var lastB = -1000
      var cutCurve = plank['cutCurve']
      cutCurve.reverse()
      for (var j in cutCurve) {
        p = cutCurve[j]

        x = p['x'] - o.x
        y = p['y'] - o.y

        p['x'] = x + o.x
        p['y'] = -y + o.y

        var tmpB = lastB
        if (p.hasOwnProperty('b')) {
          lastB = p['b']
        } else {
          lastB = -1000
        }
        if (tmpB !== -1000) {
          p['b'] = tmpB
        } else {
          delete p['b']
        }
      }
      if (lastB !== -1000) {
        cutCurve[0]['b'] = lastB
      }

      var cutCurveEx = plank['cutCurveEx']
      for (var i in cutCurveEx) {
        var curveEx = cutCurveEx[i]
        lastB = -1000
        curveEx.reverse()
        for (var j in curveEx) {
          p = curveEx[j]

          x = p['x'] - o.x
          y = p['y'] - o.y

          p['x'] = x + o.x
          p['y'] = -y + o.y

          tmpB = lastB
          if (p.hasOwnProperty('b')) {
            lastB = p['b']
          } else {
            lastB = -1000
          }
          if (tmpB !== -1000) {
            p['b'] = tmpB
          } else {
            delete p['b']
          }
        }
        if (lastB !== -1000) {
          curveEx[0]['b'] = lastB
        }
      }
    }

    // realCurve
    if (plank['realCurve'] && plank['realCurve'].length) {
      var o = { x: owidth / 2, y: oheight / 2 }
      var lastB = -1000
      var path = plank['realCurve']
      path.reverse()
      for (var i in path) {
        var p = path[i]
        var x = p['x'] - o.x
        var y = p['y'] - o.y
        p['x'] = x * 1 + o.x
        p['y'] = -y + o.y

        var tmpB = lastB
        if (p.hasOwnProperty('b')) {
          lastB = p['b']
        } else {
          lastB = -1000
        }
        if (tmpB !== -1000) {
          p['b'] = tmpB
        } else {
          delete p['b']
        }
      }
      if (lastB !== -1000) {
        path[0]['b'] = lastB
      }
      plank['realCurve'] = path

      var realCurveEx = plank['realCurveEx']
      for (var i in realCurveEx) {
        var curveEx = realCurveEx[i]
        lastB = -1000
        curveEx.reverse()
        for (var j in curveEx) {
          p = curveEx[j]

          x = p['x'] - o.x
          y = p['y'] - o.y

          p['x'] = x + o.x
          p['y'] = -y + o.y

          tmpB = lastB
          if (p.hasOwnProperty('b')) {
            lastB = p['b']
          } else {
            lastB = -1000
          }
          if (tmpB !== -1000) {
            p['b'] = tmpB
          } else {
            delete p['b']
          }
        }
        if (lastB !== -1000) {
          curveEx[0]['b'] = lastB
        }
      }
    }
  }
  /**
   * 高光板进行翻转
   */
  if (store.state.ncSetting.xyReverse) {
    if (plank['is_high_gloss_plank'] && plank['high_gloss_side'] > 0) {
      reversalPlank(plank)
    }
  } else {
    if (plank['is_high_gloss_plank'] && plank['high_gloss_side'] < 0) {
      reversalPlank(plank)
    }
  }
  return plank
}
/**
 * @desc 判断自定义特殊孔槽的规则（未打穿孔槽） 优先级高于高光板外的情况
 * @param {Array} allNoThroughSlotHoles 全部未打穿的孔槽
 * @param {Array} special_hole_slot_side 特殊孔槽自定义规则
 * @author zhouchi 2024-08-27
 * @return {null || Boolean} 小板是否需要翻面
 * */
function judgeCustSpecialHoleAndSlot(
  allNoThroughSlotHoles,
  special_hole_slot_side
) {
  const strentchHingSetList = new Set([
    'STRENTCH',
    'STRENTCHTWO',
    'MIDSTRENTCH',
    'HINGE',
  ]) // 拉直槽和铰链孔
  // 将当前小板的孔槽插存储symbol和side的map中
  const holeSlotMap = new Map()
  // 存储重复槽/孔
  const repSet = new Set()
  // // 存储通孔通槽
  // const throughSlotHoleSet = new Set()
  // // 是否存在打穿孔槽的情况
  // let isExistThrough = false
  // 存储孔槽至holeSlotMap和repSet
  saveHoleSlotInfo(allNoThroughSlotHoles)
  // 按优先级(索引由低到高)判断小板是否带有规则定义的特殊孔槽
  for (const obj of special_hole_slot_side) {
    const key = obj.key
    const value = obj.value === 'front' ? 1 : -1
    // 若规则命中槽/孔为重复且不在同一面的情况 规则不生效
    if (repSet.has(key)) {
      continue
    }
    if (holeSlotMap.has(key)) {
      return holeSlotMap.get(key) !== value
    }
  }
  // // 若有铰链孔和拉直器槽的字段 返回NULL 继续后续逻辑
  // if (key === 'straightener_slots_and_hinged_cup_holes') {
  //   return null
  // }
  // function througHandler(side, isRoll, holeSlots) {
  //   holeSlots.forEach((it) => {
  //     if (it.deep >= plank.thick) {
  //       it.side = isRoll ? -side : side
  //     }
  //   })
  // }
  function saveHoleSlotInfo(allNoThroughSlotHoles) {
    for (const it of allNoThroughSlotHoles) {
      // 若当前小板有同类型的孔且不在同一面,则进行标记 后续若命中规则其规则不生效
      let holeSlotName = it.symbol
      //   三合一大孔也是偏心孔
      if (it.holeType === 'bigHole' && it.symbol === '3in1Lock') {
        holeSlotName = 'CCPXHole'
      }
      // 遇到拉直器和铰链杯孔的时候全部重命名为straightener_slots_and_hinged_cup_holes
      if (strentchHingSetList.has(holeSlotName)) {
        holeSlotName = 'straightener_slots_and_hinged_cup_holes'
      }
      // 若当前小板有同类型的槽且不在同一面,则进行标记 后续若命中规则其规则不生效
      if (
        holeSlotMap.has(holeSlotName) &&
        holeSlotMap.get(holeSlotName) !== it.side
      ) {
        repSet.add(holeSlotName)
      }
      // 打穿的情况下不进行记录
      // if (it.deep >= plank.thick) {
      //   isExistThrough = true
      //   throughSlotHoleSet.add(holeSloTtName)
      // } else {
      //   holeSlotMap.set(holeSlotName, it.side)
      // }
      holeSlotMap.set(holeSlotName, it.side)
    }
  }
  // handleThrough({}, allThroughSlotHoles, holeSlotMap)
  return null
}

/**
 * @desc 处理特殊孔槽规则下打穿孔槽的情况
 * @param allThroughSlotHoles 所有打穿孔槽
 */
function handleThrough(allThroughSlotHoles) {
  // 是否匹配到了特殊孔槽规则
  // 如果side为null 标表示未匹配到打穿孔槽的特殊规则 此时要按照优先级判断打穿的孔槽是否匹配到特殊规则
  // if (side === null || isRollOver === null) {
  //   for (const it of allThroughSlotHoles) {
  //     let holeSlotName = it.symbol
  //     //   三合一大孔也是偏心孔
  //     if (it.holeType === 'bigHole' && it.symbol === '3in1Lock') {
  //       holeSlotName = 'CCPXHole'
  //     }
  //     // 遇到拉直器和铰链杯孔的时候全部重命名为straightener_slots_and_hinged_cup_holes
  //     if (strentchHingSetList.has(holeSlotName)) {
  //       holeSlotName = 'straightener_slots_and_hinged_cup_holes'
  //     }
  //     if (holeSlotMap.has(holeSlotName)) {
  //       side = holeSlotMap.get(holeSlotName)
  //       break
  //     }
  //   }
  // }
  // if (side === null) return
  // 遍历所有的打穿孔槽并确定所在面
  for (const it of allThroughSlotHoles) {
    // 若当前小板有同类型的孔且不在同一面,则进行标记 后续若命中规则其规则不生效
    let holeSlotName = it.symbol
    //   三合一大孔也是偏心孔
    if (it.holeType === 'bigHole' && it.symbol === '3in1Lock') {
      holeSlotName = 'CCPXHole'
    }
    // 遇到拉直器和铰链杯孔的时候全部重命名为straightener_slots_and_hinged_cup_holes
    if (strentchHingSetList.has(holeSlotName)) {
      holeSlotName = 'straightener_slots_and_hinged_cup_holes'
    }
    // 如果是规则中的则按照规则来否则在特殊孔槽规则控制的面
    if (specialRuleMap.has(holeSlotName)) {
      it.side = specialRuleMap.get(holeSlotName)
      if (xyReverse) {
        it.side = -it.side
      }
      // 有此标志的孔槽不受（只存在通孔通槽默认在大阪正面）规则的影响
      it.specialRuleFlag = true
    }
  }
}

function iNeedRollOver(plank) {
  var sp = 0 // 正面拉槽数量
  var sn = 0 // 反面拉槽数量
  var hp = 0 // 正面孔数量
  var hn = 0 // 反面孔数量
  let hingeNum = 0 // 铰链孔数量
  let strentchNum = 0 // 拉直器数量
  const strentchList = ['STRENTCH', 'STRENTCHTWO', 'MIDSTRENTCH'] // 拉直槽
  // hn + hp == 0 && sn + sp > 0

  var holes = plank['holes']
  var slots = plank['slots']

  var thick = plank['thick']
  let curveHoles = plank['curveHoles'] ?? []
  let handleSlopes = plank['handleSlopes'] ?? []
  let millInfo = plank['millInfo']
    ? plank['millInfo'].filter((mill) => Number(mill.depth) + 0.001 < thick)
    : []
  var changedValue = 0
  var throughTowSideToCut = ncSettings.throughTowSideToCut
  var xyReverse = ncSettings.xyReverse
  const {
    holeConcentrated,
    apertureSlotConcentrated,
    prorate_holes_slots_of_both_side,
  } = ncSettings
  var dock5FDrill = ncSettings.dock5FDrill
  var dock6FDrill = ncSettings.dock6FDrill
  // 老代码留着备用
  // xyReverse = xyReverse & !(dock5FDrill || dock6FDrill)
  xyReverse = xyReverse
  //是否全是穿孔穿槽
  const holeAllPenetrate = holes.every((it) => it.deep * 1 + 0.001 >= thick)
  const curveHolesAllPenetrate = curveHoles.every(
    (it) => it.deep * 1 + 0.001 >= thick
  )
  const slotAllPenetrate = [...slots, ...millInfo].every(
    (it) => (it.deep ?? it.depth) * 1 + 0.001 >= thick
  )
  //xy互换时的数据统计 穿孔穿槽XY互换时反面孔/槽数量 + n 没有开启XY互换是正面 孔/槽数量 + n 为什么XY互换后穿孔穿槽在反面呢 标记
  const holeSlotSideStatisticRe = (hsData, type = 'holes') => {
    hsData.forEach((it) => {
      if (xyReverse) {
        it.side = -1
        type == 'holes' ? hn++ : sn++
      } else {
        it.side = 1
        type == 'holes' ? hp++ : sp++
      }
    })
  }

  // if (holeAllPenetrate && slotAllPenetrate && curveHolesAllPenetrate) {
  //   holeSlotSideStatisticRe(curveHoles, 'holes')
  //   // holeSlotSideStatisticRe(handleSlopes, 'slots')
  //   holeSlotSideStatisticRe(millInfo, 'slots')
  //   holeSlotSideStatisticRe(slots, 'slots')
  //   holeSlotSideStatisticRe(holes)
  //   return
  // }
  // 如果孔槽集中面在正面，此时大部分孔都在正面，但是XY互换导致翻面，此时孔槽集中面其实就在反面了，但是我们得保证孔槽集中面在正面！所以得在翻面前将孔槽统计对立面去！翻面后就仍孔槽集中面就仍然在正面了
  const holeSlotSideStatistic = (data, type = 'holes') => {
    data.forEach((it) => {
      if (Number(it.deep ?? it.depth) * 1 + 0.001 >= thick) return
      // 若孔为铰链孔，则铰链孔+1
      if (it.symbol && it.symbol == 'HINGE') hingeNum++
      if (it.symbol && strentchList.includes(it.symbol)) strentchNum++
      if (!xyReverse) {
        if (it.side > 0) type == 'holes' ? hp++ : sp++
        if (it.side < 0) type == 'holes' ? hn++ : sn++
      } else {
        if (it.side > 0) type == 'holes' ? hn++ : sn++
        if (it.side < 0) type == 'holes' ? hp++ : sp++
      }
    })
  }

  holeSlotSideStatistic(holes, 'holes')
  holeSlotSideStatistic(slots, 'slots')
  // holeSlotSideStatistic(handleSlopes, 'slots')
  holeSlotSideStatistic(millInfo, 'slots')
  holeSlotSideStatistic(curveHoles, 'holes')
  // 所有未打穿孔槽
  let allNoThroughSlotHoles = []
  // 所用打穿孔槽
  let allThroughSlotHoles = []
  const allSlotHole = [...holes, ...slots, ...curveHoles, ...millInfo]
  allSlotHole.forEach((e) => {
    if ((e.deep ?? e.depth) * 1 + 0.001 < thick) {
      allNoThroughSlotHoles.push(e)
    } else {
      allThroughSlotHoles.push(e)
    }
  })
  // 全部未打穿的孔槽
  // const allNoThroughSlotHoles = [
  //   ...holes,
  //   ...slots,
  //   ...curveHoles,
  //   ...millInfo,
  // ].filter((e) => (e.deep ?? e.depth) * 1 + 0.001 < thick)
  // // 全部打穿的孔槽
  // const allThroughSlotHoles = [
  //   ...holes,
  //   ...slots,
  //   ...curveHoles,
  //   ...millInfo,
  // ].filter((e) => (e.deep ?? e.depth) * 1 + 0.001 >= thick)

  // 是否有未打穿的孔槽
  const hasNoThroughSlotHole = Boolean(allNoThroughSlotHoles.length)
  // 是否全在同一面
  const isAllSameSide =
    allNoThroughSlotHoles.every((e) => e.side == 1) ||
    allNoThroughSlotHoles.every((e) => e.side == -1)
  // 1 都在正面 -1 都在反面 0 都有
  let sameSideValue = allNoThroughSlotHoles.every((e) => e.side == 1)
    ? 1
    : allNoThroughSlotHoles.every((e) => e.side == -1)
    ? -1
    : 0

  const dealSameValue = (data, side) => {
    data.forEach(function (it) {
      if (Number(it.deep ?? it.depth) * 1 + 0.001 >= thick) {
        it.side = side
        // 若孔槽都在同一个面且勾选打穿孔槽所在面跟随该小板上未穿孔槽，则所有打穿的孔槽都在某一面
        if (store.state.ncSetting.throughHoleSlotSameSide && isAllSameSide) {
          it.side = sameSideValue
          if (!hasNoThroughSlotHole) {
            it.side = side
          }
        }
      }
    })
  }

  function dealHoleSlotsSide(side = 1) {
    dealSameValue(holes, side)
    dealSameValue(slots, side)
    dealSameValue(curveHoles, side)
    // dealSameValue(handleSlopes, side)
    dealSameValue(millInfo, side)
  }
  dealHoleSlotsSide()
  plank['needRoll'] = Boolean(sp + hp && sn + hn)
  // 打开自定义特殊孔槽的情况并且不是高光板
  if (
    store.state.ncSetting.custom_special_hole_slot_side.is_open &&
    !(plank['is_high_gloss_plank'] && plank['high_gloss_side'] != 0)
  ) {
    // 最后处理未打穿孔槽未匹配到特殊孔槽规则 但是 通孔通槽匹配到特殊看规则的情况
    Promise.resolve().then(() => {
      handleThrough(allThroughSlotHoles)
    })
    // 自定义特殊孔槽规则数组
    const special_hole_slot_side =
      store.state.ncSetting.custom_special_hole_slot_side.special_hole_slot_side
    // 如果xy互换,此时已经需要把孔槽全部翻转到对应面,所以我们这的翻转逻辑需要逆思考
    const isRollOver = judgeCustSpecialHoleAndSlot(
      allNoThroughSlotHoles,
      special_hole_slot_side
    )
    if (isRollOver !== null) {
      return xyReverse ? !isRollOver : isRollOver
    }
    // 最后处理未打穿孔槽未匹配到特殊孔槽规则 但是 通孔通槽匹配到特殊看规则的情况
    // Promise.resolve().then(() => {
    //   handleThrough(allThroughSlotHoles)
    // })
  }
  //processChainAndHoleOnBigPlaneBack在特殊孔槽规则上线后要被取待
  // if (
  //   (hingeNum || strentchNum) &&
  //   store.state.ncSetting.processChainAndHoleOnBigPlaneBack
  // ) {
  //   // 找到一个铰链孔或者拉直器，拉直器和铰链孔只会在同一面所以找一个即可
  //   const d = [...slots, ...holes].find(
  //     (it) => it.symbol == 'HINGE' || strentchList.includes(it.symbol)
  //   )
  //   // xy轴互换，如果本身铰链孔和拉直器就在反面就需要先翻到正面去，xy互换后才能是反面
  //   if (!xyReverse) {
  //     if (d.side == 1) {
  //       if (!store.state.ncSetting.throughHoleSlotSameSide) {
  //         dealHoleSlotsSide(-1)
  //       }
  //       return true
  //     }
  //   } else {
  //     if (d.side == -1) {
  //       if (!store.state.ncSetting.throughHoleSlotSameSide) {
  //         dealHoleSlotsSide(-1)
  //       }
  //       return true
  //     }
  //   }
  // } else
  if (prorate_holes_slots_of_both_side) {
    // 开启按比例分配孔槽，孔槽集中面不再生效
    return false
  } else if (sp == sn) {
    // 如果槽正反面数量一致, 则只需要考虑孔的分布情况
    // 孔槽集中面在反面
    if (store.state.ncSetting.holeConcentrated == 'back') {
      if (hp > hn) return true
    } else {
      // 孔槽集中面是正面 正面孔数量小于反面孔的数量
      if (hp < hn) return true
    }
  } else {
    // 如果槽正反面数量不一致, 则只需要考虑槽的分布情况
    if (store.state.ncSetting.apertureSlotConcentrated == 'back') {
      if (sp > sn) return true
    } else {
      if (sp < sn) return true
    }
  }
  return false
}

// 提取上面的方法  翻转单个板件
export function reversalPlank(plank) {
  var rect = plank['rect']
  var holes = plank['holes']
  var sholes = plank['sholes']
  var slots = plank['slots']
  let handleSlopes = plank['handleSlopes'] ?? []
  var sslots = plank['sslots']
  var edgeInfo = plank['edgeInfo']
  var thick = plank['thick']
  var oRect = plank['oRect']
  var oheight = oRect['height']
  var owidth = oRect['width']
  var curveHoles = plank['curveHoles']
  let millInfo = plank['millInfo']
  var oriCurveHoles = plank['oriCurveHoles']
  let model_front_edge = plank['model_front_edge']
  let lightEdgeInfo = plank['lightEdgeInfo']
  let doorOpenSide = plank['doorOpenSide']

  // holes
  holes.forEach(function (it) {
    it.center = { x: it.center.x, y: rect.height - it.center.y }
    it.ocenter = { x: it.ocenter.x, y: oheight - it.ocenter.y }
    it.side = -it.side
    // 上下翻面
    const newHole = adjustHoleDirection(it, 0, 'flipX')
    Object.assign(it, newHole)
  })

  if (curveHoles) {
    curveHoles.forEach((item) => {
      item['path'].forEach((p) => {
        p.y = rect.height - p.y
      })
      item.side = -item.side
    })
  }
  if (millInfo) {
    millInfo.forEach((item) => {
      const path = item['shape']
      path.forEach((p) => {
        p.y = oRect.height - p.y
      })
      item.side = -item.side
    })
  }

  if (oriCurveHoles) {
    oriCurveHoles.forEach((item) => {
      item['path'].forEach((p) => {
        p.y = oRect.height - p.y
      })
      item.side = -item.side
    })
  }

  sholes.forEach(function (shole) {
    var side = shole['side']
    var center = shole['center']
    var ocenter = shole['ocenter']

    switch (String(side)) {
      case '2':
        side = 4
        break
      case '4':
        side = 2
        break
    }
    center = {
      x: (center.z ? center.z : 0) - center.x,
      y: thick - center.y,
      z: center.z ? center.z : 0,
    }
    ocenter = { x: ocenter.x, y: oheight - ocenter.y }

    shole['side'] = side
    shole['center'] = center
    shole['ocenter'] = ocenter
  })

  // slots
  slots.forEach(function (it) {
    it.pt1 = { x: it.pt1.x, y: rect.height - it.pt1.y }
    it.opt1 = { x: it.opt1.x, y: oheight - it.opt1.y }
    it.pt2 = { x: it.pt2.x, y: rect.height - it.pt2.y }
    it.opt2 = { x: it.opt2.x, y: oheight - it.opt2.y }
    it.side = -it.side
  })

  // handleSlopes
  handleSlopes.forEach(function (it) {
    it.pt1 = { x: it.pt1.x, y: rect.height - it.pt1.y }
    it.opt1 = { x: it.opt1.x, y: oheight - it.opt1.y }
    it.pt2 = { x: it.pt2.x, y: rect.height - it.pt2.y }
    it.opt2 = { x: it.opt2.x, y: oheight - it.opt2.y }
    it.side = -it.side
  })

  // sslots
  sslots.forEach(function (sslot) {
    var side = sslot['side']
    var pt1 = sslot['pt1']
    var opt1 = sslot['opt1']
    var pt2 = sslot['pt2']
    var opt2 = sslot['opt2']

    switch (String(side)) {
      case '2':
        side = 4
        break
      case '4':
        side = 2
        break
    }

    pt1 = {
      x: pt1.z - pt1.x,
      y: pt1.y,
      z: pt1.z,
    }
    opt1 = { x: opt1.x, y: oheight - opt1.y }

    pt2 = {
      x: pt2.z - pt2.x,
      y: pt2.y,
      z: pt2.z,
    }
    opt2 = { x: opt2.x, y: oheight - opt2.y }

    sslot['side'] = side
    sslot['pt1'] = pt1
    sslot['opt1'] = opt1
    sslot['pt2'] = pt2
    sslot['opt2'] = opt2
  })

  // edgeInfo
  var left = edgeInfo.substring(
    edgeInfo.indexOf('←') + 1,
    edgeInfo.indexOf('↓')
  )
  var bottom = edgeInfo.substring(
    edgeInfo.indexOf('↓') + 1,
    edgeInfo.indexOf('→')
  )
  var right = edgeInfo.substring(
    edgeInfo.indexOf('→') + 1,
    edgeInfo.indexOf('↑')
  )
  var top = edgeInfo.substring(edgeInfo.indexOf('↑') + 1)
  edgeInfo = '←' + left + '↓' + top + '→' + right + '↑' + bottom
  lightEdgeInfo = dealEdgeInfoChange(lightEdgeInfo, 'upDownFlip')
  model_front_edge = dealFormerEdgeChange(
    model_front_edge,
    'upDownFlip',
    'former'
  )
  doorOpenSide = dealFormerEdgeChange(
    doorOpenSide,
    'upDownFlip',
    'doorOpenSide'
  )

  const sh = holes.filter((hole) => isOtherSideMachineHole(hole))
  const sl = slots.filter((slot) => isOtherSideMachineSlot(slot))
  let sholeInfo = generatorSideHoleSlot([
    ...sholes,
    ...sh.filter((h) => h.side == '1'),
  ])
  let sholeInfoF = generatorSideHoleSlot(
    sh.filter((h) => h.side == '-1'),
    'upDownFlip'
  )
  let sslotInfo = generatorSideHoleSlot([
    ...sslots,
    ...sl.filter((l) => l.side == '1'),
  ])
  let sslotInfoF = generatorSideHoleSlot(
    sl.filter((l) => l.side == '-1'),
    'upDownFlip'
  )

  plank['holes'] = holes
  plank['slots'] = slots
  plank['sholes'] = sholes
  plank['sslots'] = sslots
  plank['edgeInfo'] = edgeInfo
  plank['sholeInfo'] = sholeInfo
  plank['sholeInfoF'] = sholeInfoF
  plank['sslotInfo'] = sslotInfo
  plank['sslotInfoF'] = sslotInfoF
  plank['lightEdgeInfo'] = lightEdgeInfo
  plank['model_front_edge'] = model_front_edge
  plank['doorOpenSide'] = doorOpenSide
  plank['oriCurveHoles'] = oriCurveHoles
  plank['curveHoles'] = curveHoles
  plank['millInfo'] = millInfo
  holes.forEach((hole) => {
    if (Number(hole.deep) + 0.001 > thick) {
      if (!hole?.specialRuleFlag) {
        hole.side = 1
      } else {
        let holeSlotName = hole.symbol
        if (hole.holeType === 'bigHole' && hole.symbol === '3in1Lock') {
          holeSlotName = 'CCPXHole'
        }
        hole.side = specialRuleMap.get(holeSlotName) ?? -hole.side
      }
    }
  })
  slots.forEach((slot) => {
    if (Number(slot.deep) + 0.001 > thick) {
      if (!slot?.specialRuleFlag) {
        slot.side = 1
      } else {
        slot.side = specialRuleMap.get(slot.symbol) ?? -slot.side
      }
    }
  })
  if (curveHoles) {
    curveHoles.forEach((cHole) => {
      if (Number(cHole.deep) + 0.001 > thick) {
        cHole.side = 1
      }
    })
  }
  // path
  if (plank.hasOwnProperty('path') && plank['path']) {
    var o = { x: rect.width / 2, y: rect.height / 2 }

    var paths = plank['path']
    for (var i in paths) {
      var path = paths[i]
      for (var j in path) {
        var p = path[j]

        var x = p['x'] - o.x
        var y = p['y'] - o.y

        p['x'] = x + o.x
        p['y'] = -y + o.y
      }
    }
    plank['path'] = paths
  }

  // oPath
  if (plank.hasOwnProperty('oPath') && plank['oPath']) {
    var o = { x: owidth / 2, y: oheight / 2 }

    var paths = plank['oPath']
    for (var i in paths) {
      var path = paths[i]
      for (var j in path) {
        var p = path[j]

        var x = p['x'] - o.x
        var y = p['y'] - o.y

        p['x'] = x * 1 + o.x
        p['y'] = -y + o.y
      }
    }
    plank['oPath'] = paths
  }

  // cutCurve
  if (plank.hasOwnProperty('cutCurve') && plank['cutCurve']) {
    var o = { x: rect.width / 2, y: rect.height / 2 }

    var lastB = -1000
    var cutCurve = plank['cutCurve']
    cutCurve.reverse()
    for (var j in cutCurve) {
      p = cutCurve[j]

      x = p['x'] - o.x
      y = p['y'] - o.y

      p['x'] = x + o.x
      p['y'] = -y + o.y

      var tmpB = lastB
      if (p.hasOwnProperty('b')) {
        lastB = p['b']
      } else {
        lastB = -1000
      }
      if (tmpB !== -1000) {
        p['b'] = tmpB
      } else {
        delete p['b']
      }
    }
    if (lastB !== -1000) {
      cutCurve[0]['b'] = lastB
    }

    var cutCurveEx = plank['cutCurveEx']
    for (var i in cutCurveEx) {
      var curveEx = cutCurveEx[i]
      lastB = -1000
      curveEx.reverse()
      for (var j in curveEx) {
        p = curveEx[j]

        x = p['x'] - o.x
        y = p['y'] - o.y

        p['x'] = x + o.x
        p['y'] = -y + o.y

        tmpB = lastB
        if (p.hasOwnProperty('b')) {
          lastB = p['b']
        } else {
          lastB = -1000
        }
        if (tmpB !== -1000) {
          p['b'] = tmpB
        } else {
          delete p['b']
        }
      }
      if (lastB !== -1000) {
        curveEx[0]['b'] = lastB
      }
    }
  }

  // realCurve
  if (plank.hasOwnProperty('realCurve') && plank['realCurve']) {
    var o = { x: owidth / 2, y: oheight / 2 }
    var lastB = -1000
    var path = plank['realCurve']
    path.reverse()
    for (var i in path) {
      var p = path[i]
      var x = p['x'] - o.x
      var y = p['y'] - o.y
      p['x'] = x * 1 + o.x
      p['y'] = -y + o.y

      var tmpB = lastB
      if (p.hasOwnProperty('b')) {
        lastB = p['b']
      } else {
        lastB = -1000
      }
      if (tmpB !== -1000) {
        p['b'] = tmpB
      } else {
        delete p['b']
      }
    }
    if (lastB !== -1000) {
      path[0]['b'] = lastB
    }
    plank['realCurve'] = path

    var realCurveEx = plank['realCurveEx']
    for (var i in realCurveEx) {
      var curveEx = realCurveEx[i]
      lastB = -1000
      curveEx.reverse()
      for (var j in curveEx) {
        p = curveEx[j]

        x = p['x'] - o.x
        y = p['y'] - o.y

        p['x'] = x + o.x
        p['y'] = -y + o.y

        tmpB = lastB
        if (p.hasOwnProperty('b')) {
          lastB = p['b']
        } else {
          lastB = -1000
        }
        if (tmpB !== -1000) {
          p['b'] = tmpB
        } else {
          delete p['b']
        }
      }
      if (lastB !== -1000) {
        curveEx[0]['b'] = lastB
      }
    }
  }
}

// 左右翻板
const dealPoint = (pointObj, field, width) => {
  pointObj[field] = { x: width - pointObj[field].x, y: pointObj[field].y }
}
const dealPath = (shape, width) => {
  let lastB = -1000
  shape.reverse()
  for (const curPoint of shape) {
    const tmpB = lastB
    curPoint.x = width - curPoint.x
    if (curPoint.hasOwnProperty('b')) {
      lastB = curPoint['b']
    } else {
      lastB = -1000
    }
    if (tmpB !== -1000) {
      curPoint['b'] = tmpB
    } else {
      delete curPoint['b']
    }
  }

  if (lastB !== -1000) {
    shape[0]['b'] = lastB
  }
}
const dirMap = {
  1: 3,
  2: 2,
  3: 1,
  4: 4,
}

// 用于获取侧槽侧空需要那个值来减去当前x轴
function getLongKey(side) {
  return side == 1 || side == 3 ? 'height' : 'width'
}
export function rolloverPlank(plank, isReverePlank) {
  let {
    rect: { width: width, height: height },
    holes,
    sholes,
    slots,
    handleSlopes,
    sslots,
    thick,
    oRect: { width: oWidth, height: oHeight },
    curveHoles,
    millInfo,
    oriCurveHoles,
    edgeInfo,
    model_front_edge,
    lightEdgeInfo,
    doorOpenSide,
    oPath,
    path,
    cutCurve,
    cutCurveEx,
    realCurve,
    realCurveEx,
  } = plank
  holes.forEach((it) => {
    dealPoint(it, 'center', width)
    dealPoint(it, 'ocenter', oWidth)
    it.side = -it.side
    // 左右翻面对z扣孔的方向做处理
    const newHole = adjustHoleDirection(it, 0, 'flipY')
    Object.assign(it, newHole)
  })

  sholes.forEach((it) => {
    it.center.y = thick - it.center.y

    const k = getLongKey(it.side)
    const long = plank.oRect[k]
    it.center.x = long - it.center.x

    it.ocenter.x = oWidth - it.ocenter.x
    it.side = dirMap[it.side]
  })

  slots.forEach((it) => {
    dealPoint(it, 'pt1', width)
    dealPoint(it, 'opt1', oWidth)
    dealPoint(it, 'pt2', width)
    dealPoint(it, 'opt2', oWidth)
    it.side = -it.side
  })

  handleSlopes &&
    handleSlopes.forEach((it) => {
      dealPoint(it, 'pt1', width)
      dealPoint(it, 'opt1', oWidth)
      dealPoint(it, 'pt2', width)
      dealPoint(it, 'opt2', oWidth)
      it.side = -it.side
    })

  sslots.forEach((it) => {
    it.pt1.y = thick - it.pt1.y
    it.pt2.y = thick - it.pt2.y
    const k = getLongKey(it.side)
    const long = plank.oRect[k]
    it.pt1.x = long - it.pt1.x
    it.pt2.x = long - it.pt2.x

    it.opt1.x = oWidth - it.opt1.x
    it.opt2.x = oWidth - it.opt2.x
    it.side = dirMap[it.side]
  })

  if (path) {
    path.forEach((it) => {
      dealPath(it, width)
    })
  }

  if (oPath) {
    oPath.forEach((it) => {
      dealPath(it, oWidth)
    })
  }

  if (cutCurve) {
    dealPath(cutCurve, width)
  }

  if (cutCurveEx) {
    cutCurveEx.forEach((it) => {
      dealPath(it, width)
    })
  }

  if (realCurve) {
    dealPath(realCurve, oWidth)
  }

  if (realCurveEx) {
    realCurveEx.forEach((it) => {
      dealPath(it, oWidth)
    })
  }

  if (curveHoles) {
    curveHoles.forEach((it) => {
      dealPath(it.path, width)
      if (it.oPath) {
        dealPath(it.oPath, oWidth)
      }
      it.side = -it.side
    })
  }
  if (millInfo) {
    millInfo.forEach((it) => {
      dealPath(it.shape, oWidth)
      if (it.oPath) {
        dealPath(it.oPaplankth, oWidth)
      }
      it.side = -it.side
    })
  }

  if (oriCurveHoles) {
    oriCurveHoles.forEach((it) => {
      dealPath(it.path, oWidth)
      if (it.oPath) {
        dealPath(it.oPath, oWidth)
      }
      it.side = -it.side
    })
  }
  const sh = holes.filter((hole) => isOtherSideMachineHole(hole))
  const sl = slots.filter((slot) => isOtherSideMachineSlot(slot))
  plank.sholeInfo = generatorSideHoleSlot([
    ...sholes,
    ...sh.filter((h) => h.side == '1'),
  ])
  plank.sholeInfoF = generatorSideHoleSlot(
    sh.filter((h) => h.side == '-1'),
    'leftRightFlip'
  )
  plank.sslotInfo = generatorSideHoleSlot([
    ...sslots,
    ...sl.filter((l) => l.side == '1'),
  ])
  plank.sslotInfoF = generatorSideHoleSlot(
    sl.filter((l) => l.side == '-1'),
    'leftRightFlip'
  )
  plank.edgeInfo = dealEdgeInfoChange(edgeInfo, 'leftRightFlip')
  plank.lightEdgeInfo = dealEdgeInfoChange(lightEdgeInfo, 'leftRightFlip')
  plank.model_front_edge = dealFormerEdgeChange(
    model_front_edge,
    'leftRightFlip',
    'former'
  )
  plank.doorOpenSide = dealFormerEdgeChange(
    doorOpenSide,
    'leftRightFlip',
    'doorOpenSide'
  )
  if (isReverePlank) {
    plank.reverePlank = true
  }
}
/**
 * 此方法用于按比例分配孔槽
 * 此文件不调用，调用文件为layoutTool.js
 * @param {*} plank 大板
 * @returns
 */
export function allocationHoleSlot(plank) {
  if (!plank.parts) return
  // 高光板板件不能进行按比例分配
  if (plank.parts[0] && plank.parts[0].is_high_gloss_plank) return
  const {
    processChainAndHoleOnBigPlaneBack,
    prorate_holes_slots_of_both_side,
  } = store.state.ncSetting
  if (!prorate_holes_slots_of_both_side) return
  const strentchSlot = ['STRENTCH', 'STRENTCHTWO', 'MIDSTRENTCH'] // 拉直槽
  const hingeHole = ['HINGE'] // 铰链孔
  // 记录总的孔槽数量
  let holeSlotTotalNum = 0
  // 目前还不会用到这个，先留着
  // eslint-disable-next-line
  let frontAio = 0
  let oppositeAio = 0
  const result = []
  const special_hole_slot_side =
    store.state.ncSetting.custom_special_hole_slot_side.special_hole_slot_side
  const specialSet = new Set()
  store.state.ncSetting.custom_special_hole_slot_side.is_open &&
    special_hole_slot_side?.forEach((it) => {
      specialSet.add(it.key)
    })
  plank.parts.forEach((part) => {
    if (!part.slots && !part.holes) return
    const obj = {}
    const { holes, slots } = part

    const isExistStraightener = specialSet.has(
      'straightener_slots_and_hinged_cup_holes'
    )
    // 开启铰链孔/拉直器反面加工则排除有铰链孔和拉直器的板 或 特殊孔槽有拉直器的也不做处理 特殊
    if (processChainAndHoleOnBigPlaneBack || isExistStraightener) {
      const hinge = holes.filter((it) => hingeHole.includes(it.symbol))
      const strentch = slots.filter((it) => strentchSlot.includes(it.symbol))
      if (hinge.length || strentch.length) return
    }
    holeSlotTotalNum += holes?.length ?? 0
    holeSlotTotalNum += slots?.length ?? 0
    const holeSlot = [...holes, ...slots]
    obj.part = part
    obj.holeSlot = holeSlot
    result.push(obj)
  })
  if (!result.length) return
  result.forEach((it) => {
    // 板件正面孔槽占比
    it.partHSZ =
      (it.holeSlot.filter((it) => {
        // 特殊孔槽规则的通孔通槽不计算在内

        if (it?.specialRuleFlag) {
          holeSlotTotalNum--
        }

        return it.side === 1 && !it?.specialRuleFlag
      }).length /
        holeSlotTotalNum) *
      100
    // 板件反面孔槽占比 特殊孔槽规则中的通孔通槽不计算在内
    it.partHSF =
      (it.holeSlot.filter((it) => {
        return it.side === -1 && !it?.specialRuleFlag
      }).length /
        holeSlotTotalNum) *
      100
    // 反面与正面差值 得到翻转可以增加/减少多少翻面比例
    it.oppositeDifference = (it.partHSF - it.partHSZ) * -1
    // 总的正面孔槽比例
    frontAio += it.partHSZ
    // 总的反面孔槽比例
    oppositeAio += it.partHSF
  })
  // 执行分配
  executeAllocation(oppositeAio, result, specialSet, plank.thick)
}
/**
 * 执行分配
 * @param {*} oppositeAio 反面孔槽占比
 * @param {*} result 处理的孔槽数据集合
 * @param {*} specialSet 特殊孔槽规则集合
 * @returns
 */
function executeAllocation(oppositeAio, result, specialSet, thick) {
  let { percentage_of_back_hole_slot: pbs } = store.state.ncSetting
  pbs = +pbs
  // 需要进行翻转的板件
  const needFlipPart = []
  const positive = result.filter((it) => it.oppositeDifference > 0)
  const negative = result.filter((it) => it.oppositeDifference < 0)
  let idx = 0
  let count = 0
  let type
  while (count < result.length) {
    if (pbs > oppositeAio) {
      type = 'add'
    } else if (pbs < oppositeAio) {
      type = 'sub'
    } else {
      break
    }
    // 防止出现无限循环
    count++
    const collect = type === 'add' ? positive : negative
    const { oppositeDifference, part, holeSlot } = collect[idx] ?? {}
    if (!part) break
    const f =
      Math.abs(pbs - (oppositeAio + oppositeDifference)) >
      Math.abs(pbs - oppositeAio)
    if (f) break
    oppositeAio += oppositeDifference
    let isPush = true
    // 判断是否将此小板加入翻版列表中 小板存在符合特殊孔槽规则的 未打穿孔槽 不翻版
    for (const it of holeSlot) {
      if ((it.deep ?? it.depth) * 1 + 0.001 >= thick) continue
      let holeSlotName = it.symbol
      if (it.holeType === 'bigHole' && it.symbol === '3in1Lock') {
        holeSlotName = 'CCPXHole'
      }
      if (specialSet.has(holeSlotName)) {
        isPush = false
        break
      }
    }

    // 有特殊孔槽规则并且孔槽未打穿的板子不翻转
    isPush && needFlipPart.push(part)
    idx++
    const isBreak = type === 'add' ? pbs <= oppositeAio : pbs >= oppositeAio
    if (isBreak) {
      break
    }
  }
  // 翻转板件
  needFlipPart.forEach((part) => {
    reversalPlank(part)
  })
}

function findPlateData(datas, plate) {
  for (var i in datas) {
    var data = datas[i]
    if (data['plkId'] === plate['plate_info']['plKId']) {
      return data
    }
  }
}

//搜集补件的板子
function collectPatchPlanks(plates, planks) {
  var tmpPlanks = []

  for (var i in plates) {
    var plate = plates[i]
    var fData = findPlateData(planks, plate)
    if (fData) {
      tmpPlanks.push(fData)
    }
  }
  return tmpPlanks
}

function collectPlanks(data, planks, roomInfo, otherData, axis) {
  var children = data['children']
  if (data.hasOwnProperty('partName')) {
    if (data['partName'] === 'Plank') {
      data['roomName'] = roomInfo['name']
      if (data['matCode'].includes('占位板')) {
        //占位板不生产
        return
      }

      for (var j in otherData) {
        data[j] = otherData[j]
      }
      planks.push(data)
    } else if (
      data['partName'] === 'SingleDoor' &&
      data['style'].includes('占位板')
    ) {
      data['children'] = []
      data['roomName'] = roomInfo['name']

      if (data['matCode'].includes('占位板')) {
        //占位板不生产
        return
      }
      planks.push(data)
    }
  }
  if (children) {
    for (var i in children) {
      var otData = {}
      if (data['partName'] === 'WineRack') {
        otData['cellInfo'] = data['cellInfo']
      }
      if (data['drawerStyle']) {
        otData['drawerStyle'] = data['drawerStyle']
      }

      collectPlanks(children[i], planks, roomInfo, otData, axis)
    }
  }
}

//删除重复点
function deletRepetitivePoint(points) {
  var result = []
  for (var i = 0; i < points.length; ++i) {
    var curPt = points[i]
    if (i > 0) {
      var lastPt = points[i - 1]
      if (lastPt.x === curPt.x && lastPt.y === curPt.y) {
        continue
      }
    }
    result.push(curPt)
  }

  return result
}

function getBound(points) {
  var minx = Number.MAX_VALUE
  var miny = Number.MAX_VALUE
  var maxx = Number.MIN_VALUE
  var maxy = Number.MIN_VALUE

  if (!points || points.length < 1) {
    return null
  }

  points.forEach(function (pt) {
    minx = Math.min(minx, pt.x)
    miny = Math.min(miny, pt.y)
    maxx = Math.max(maxx, pt.x)
    maxy = Math.max(maxy, pt.y)
  })

  return {
    minx: minx,
    miny: miny,
    maxx: maxx,
    maxy: maxy,
  }
}

function dealWithLastCurve(
  lastCurve,
  iCoord,
  texDir,
  sizes,
  edgeInfo,
  dir,
  pThick
) {
  var hvd = dir.split('_')
  var coord = iCoord.split('_')

  var coordH = coord[0]
  var coordV = coord[1]
  var coordD = coord[2]

  var signH = coordH[0] >= 3 ? -1 : 1
  var signV = coordV[0] >= 3 ? -1 : 1

  var preVertex = JSON.parse(JSON.stringify(lastCurve['vertex']))
  preVertex = deletRepetitivePoint(preVertex)
  var vertex = JSON.parse(JSON.stringify(preVertex))
  var overtex = JSON.parse(JSON.stringify(preVertex))
  var extras = JSON.parse(JSON.stringify(lastCurve['extras']))
  var oExtras = JSON.parse(JSON.stringify(lastCurve['extras']))
  var lastRect = lastCurve['lastRect']
  if (lastRect) {
    var w = Number(lastRect['w']).toFixed(3)
    var h = Number(lastRect['h']).toFixed(3)
  } else {
    var bound = getBound(preVertex)
    w = Number(bound.maxx - bound.minx).toFixed(3)
    h = Number(bound.maxy - bound.miny).toFixed(3)
  }

  //处理结果
  var result = {}
  var oexHoles = []
  var oexSlots = []
  var exHoles = []
  var exSlots = []

  var realCurve = JSON.parse(JSON.stringify(overtex))
  var exCurve = []
  // return
  var curve = libCurve.newCurve()
  curve.points = overtex
  var points = curve.getPolygon(true) // 处理圆弧数据
  rotatePath(points, coord, hvd)

  var cw = w
  var ch = h
  if (texDir === 'reverse') {
    cw = h
    ch = w
  }

  for (var i in points) {
    points[i].x += cw / 2
    points[i].y += ch / 2
  }

  result['oPath'] = [points]

  // 异形编辑挖洞处理
  for (i in oExtras) {
    var oex = oExtras[i]

    var tmpWd = w
    var tmpHg = h
    var type = ''
    var isCurve = false
    var isThrough = ''
    var xdepth = 0
    for (var j in oex) {
      var exPt = oex[j]
      exPt['x'] += (tmpWd / 2) * signH
      exPt['y'] += (tmpHg / 2) * signV

      isThrough = exPt.through

      //孔位的深度大于板厚的时候，孔位深度值=板厚
      if (
        pThick &&
        Math.abs(exPt.depth * 1 - pThick) > 0 &&
        isThrough === '是'
      ) {
        xdepth = pThick
      } else {
        xdepth = exPt.depth * 1
      }

      if (exPt.disType === 'hole') {
        //圆孔为通孔时且圆孔直径大于30，当做异形处理
        if (xdepth >= pThick && Math.abs(exPt['r']) > 15) {
          type = 'curve'
        } else {
          type = 'hole'
        }
      } else if (exPt.disType === 'rect') {
        //方孔为通孔时，当做异形处理
        if (xdepth >= pThick) {
          type = 'curve'
        } else {
          type = 'slot'
        }
      }
    }

    if (xdepth === 0) {
      continue
    }

    if (type === 'hole') {
      rotatePath(oex, coord, hvd)
      var hole = changePathToHoleOrSlot(oex, 'hole', pThick, xdepth, isThrough)
      if (hole) {
        oexHoles.push(hole)
      }
    } else if (type === 'slot') {
      rotatePath(oex, coord, hvd)
      var slot = changePathToHoleOrSlot(oex, 'slot', pThick, xdepth, isThrough)
      if (slot) {
        oexSlots.push(slot)
      }
    } else {
      curve.points = oex
      points = curve.getPolygon(true)
      rotatePath(points, coord, hvd)
      result['oPath'].push(points)
    }
  }

  //---------------------以上 oPath 带封边---------------------------------------------
  var wd = sizes['width']
  var hg = sizes['height']
  var owd = sizes['owidth']
  var ohg = sizes['oheight']
  vertex.forEach((v) => {})
  // return
  // texDir如果是横纹x,y交换竖纹就直接求
  // edgeInfo, 第一个和第三个为x值, 第二个和第四个为竖纹
  // vertex中, 最大x坐标和最小x坐标, 取两个x坐标的长度减去封边信息中x的值再除以长度, 得到x的比例
  // y坐标同理, 最大y和最小y, 取长度减去封边信息组中y的值再除以长度, 得到y的比例
  var sc = getLastCurveScaleFactor(vertex, edgeInfo, texDir)
  var sx = sc.sx
  var sy = sc.sy

  for (var i in vertex) {
    var pt = vertex[i]
    pt.x = pt.x * sx
    pt.y = pt.y * sy
    vertex[i] = pt
  }
  var cutCurve = deepCopy(vertex)
  curve.points = vertex
  points = curve.getPolygon(true)
  rotatePath(points, coord, hvd)

  bound = getBound(points)
  var minx = bound.minx
  var miny = bound.miny

  for (i in points) {
    points[i].x -= minx
    points[i].y -= miny
  }

  result['path'] = [points]
  result['exHoles'] = []
  result['exSlots'] = []
  result['depth'] = []

  //处理异形挖孔
  for (var k in extras) {
    var ex = extras[k]
    if (!ex) continue

    type = ''
    tmpWd = sc.wd
    tmpHg = sc.hg
    xdepth = 0
    isThrough = ''
    for (i in ex) {
      pt = ex[i]
      isThrough = pt.through

      //孔位的深度大于板厚的时候，孔位深度值=板厚
      if (pThick && Math.abs(pt.depth * 1 - pThick) > 0 && isThrough === '是') {
        xdepth = pThick
      } else {
        xdepth = pt.depth * 1
      }

      if (pt.disType === 'hole') {
        //圆孔为通孔时且圆孔直径大于30，当做异形处理
        if (xdepth >= pThick && Math.abs(pt['r']) > 15) {
          type = 'curve'
        } else {
          type = 'hole'
        }
        break
      } else if (pt.disType === 'rect') {
        //方孔为通孔时，当做异形处理
        if (xdepth >= pThick) {
          type = 'curve'
        } else {
          type = 'slot'
        }
        break
      }
    }

    if (xdepth === 0) {
      continue
    }
    if (type === 'hole') {
      moveExPath(ex, coord, hvd, edgeInfo, minx, miny)
      hole = changePathToHoleOrSlot(ex, 'hole', pThick, xdepth, isThrough)
      if (hole) {
        exHoles.push(hole)
      }
    } else if (type === 'slot') {
      moveExPath(ex, coord, hvd, edgeInfo, minx, miny)
      slot = changePathToHoleOrSlot(ex, 'slot', pThick, xdepth, isThrough)
      if (slot) {
        exSlots.push(slot)
      }
    } else {
      //收集没有转换的异形孔槽
      var tmpEx = []
      for (var ei in ex) {
        var tmpPt = {
          x: ex[ei].x,
          y: ex[ei].y,
        }
        if (ex[ei].b) {
          tmpPt['b'] = mFixed(ex[ei].b)
        }
        tmpEx.push(tmpPt)
      }
      dealWithCurve(tmpEx, coord, hvd, true)
      exCurve.push(tmpEx)

      curve.points = ex
      var expath = curve.getPolygon(true)
      rotatePath(expath, coord, hvd)
      for (var t in expath) {
        expath[t]['x'] -= minx
        expath[t]['y'] -= miny
      }
      result['path'].push(expath)
      if (!ex[0].depth) {
        result['depth'].push(pThick)
      } else {
        if (
          pThick &&
          Math.abs(ex[0].depth * 1 - pThick) > 0 &&
          ex[0]['through'] === '是'
        ) {
          result['depth'].push(pThick)
        } else {
          result['depth'].push(ex[0].depth)
        }
      }
    }
  }

  mergeHoles(exHoles, oexHoles)
  mergeSlots(exSlots, oexSlots)

  result['exHoles'] = exHoles
  result['exSlots'] = exSlots

  //---------------------以上 path 不带封边---------------------------------------------

  var cutBound = dealWithCurve(cutCurve, coord, hvd)
  var minValue = dealWithCurve(realCurve, coord, hvd)
  var cutExCurve = deepCopy(exCurve)
  var realExCurve = deepCopy(exCurve)

  for (i in cutExCurve) {
    ex = cutExCurve[i]
    for (ei in ex) {
      pt = ex[ei]
      pt['x'] = mFixed(pt['x'] - cutBound[0], 3)
      pt['y'] = mFixed(pt['y'] - cutBound[1], 3)
    }
  }

  for (i in realExCurve) {
    ex = realExCurve[i]
    for (ei in ex) {
      pt = ex[ei]
      pt['x'] = mFixed(pt['x'] - minValue[0], 3)
      pt['y'] = mFixed(pt['y'] - minValue[1], 3)
    }
  }

  result['cutCurve'] = cutCurve
  result['cutCurveEx'] = cutExCurve
  result['realCurve'] = realCurve
  result['realCurveEx'] = realExCurve

  return result
}

function mFixed(num, n) {
  if (typeof n != 'number') {
    n = 2
  }
  var result = num
  if (typeof result != 'number') {
    return 0
  }

  var a = Math.pow(10, n)
  result = Math.round(num * a) / a

  return result
}

function dealWithCurve(curve, coord, hvd, flag) {
  dealWithRealCurve(curve, coord, hvd)
  if (flag) {
    return [0, 0]
  }
  var bound = getBound(curve)
  var minx = bound.minx
  var miny = bound.miny

  for (var i in curve) {
    curve[i].x -= minx
    curve[i].y -= miny
  }

  if (!checkSequence(curve)) {
    var lastB = -1000
    curve.reverse()
    for (var i in curve) {
      var p = curve[i]
      var tmpB = lastB
      if (p.hasOwnProperty('b')) {
        lastB = p['b']
      } else {
        lastB = -1000
      }
      if (tmpB !== -1000) {
        p['b'] = -tmpB
      } else {
        delete p['b']
      }
    }
    if (lastB !== -1000) {
      curve[0]['b'] = -lastB
    }
  }
  return [minx, miny]
}

function dealWithLastCurveCut(
  lastCurve,
  lastCurveCut,
  lastCurveTG,
  iCoord,
  texDir,
  sizes,
  edgeInfo,
  dir,
  pThick
) {
  //处理结果
  var result = {}

  var hvd = dir.split('_')
  var coord = iCoord.split('_')

  var coordH = coord[0]
  var coordV = coord[1]
  var coordD = coord[2]

  var signH = coordH[0] >= 3 ? -1 : 1
  var signV = coordV[0] >= 3 ? -1 : 1

  function curveToPoints(lastCurve) {
    var lastRect = JSON.parse(JSON.stringify(lastCurve['lastRect']))
    var preVertex = JSON.parse(JSON.stringify(lastCurve['vertex']))
    preVertex = deletRepetitivePoint(preVertex)

    var curve = libCurve.newCurve()
    curve.points = preVertex
    var points = curve.getPolygon(true)
    rotatePath(points, coord, hvd)

    var cw = lastRect.w
    var ch = lastRect.h
    if (texDir === 'reverse') {
      cw = lastRect.h
      ch = lastRect.w
    }

    for (i in points) {
      points[i].x += cw / 2
      points[i].y += ch / 2
    }

    if (!checkSequence(points)) {
      var lastB = -1000
      points.reverse()
      for (var i in points) {
        var p = points[i]
        var tmpB = lastB
        if (p.hasOwnProperty('b')) {
          lastB = p['b']
        } else {
          lastB = -1000
        }
        if (tmpB !== -1000) {
          p['b'] = -tmpB
        } else {
          delete p['b']
        }
      }
      if (lastB !== -1000) {
        points[0]['b'] = -lastB
      }
    }

    return points
  }

  if (lastCurveTG) {
    var points = curveToPoints(lastCurveTG)
    var realCurve = JSON.parse(JSON.stringify(lastCurveTG['vertex']))
  } else {
    points = curveToPoints(lastCurve)
    realCurve = JSON.parse(JSON.stringify(lastCurve['vertex']))
  }

  var cutpoints = curveToPoints(lastCurveCut)

  dealWithRealCurve(realCurve, coord, hvd)

  var bound = getBound(realCurve)
  var minx = bound.minx
  var miny = bound.miny
  for (var i in realCurve) {
    realCurve[i].x -= minx
    realCurve[i].y -= miny
  }
  result['oPath'] = [points]
  result['path'] = [cutpoints]
  result['cutCurve'] = cutpoints
  result['cutCurveEx'] = []
  result['realCurve'] = realCurve
  result['realCurveEx'] = []
  result['exHoles'] = []
  result['exSlots'] = []

  return result
}

function moveExPath(path, coord, hvd, edgeInfo, minx, miny) {
  var left = Number(
    edgeInfo.substring(edgeInfo.indexOf('←') + 1, edgeInfo.indexOf('↓'))
  )
  var bottom = Number(
    edgeInfo.substring(edgeInfo.indexOf('↓') + 1, edgeInfo.indexOf('→'))
  )
  var right = Number(
    edgeInfo.substring(edgeInfo.indexOf('→') + 1, edgeInfo.indexOf('↑'))
  )
  var top = Number(edgeInfo.substring(edgeInfo.indexOf('↑') + 1))

  rotatePath(path, coord, hvd)
  pathOffSet(path, -(left - right) / 2, (bottom - top) / 2)
  for (var t in path) {
    path[t]['x'] -= minx
    path[t]['y'] -= miny
  }
}

function pathOffSet(path, ox, oy) {
  for (var i in path) {
    var pt = path[i]
    pt.x += ox
    pt.y += oy
  }
}

//合并孔
function mergeHoles(exHoles, oexHoles) {
  if (exHoles.length !== oexHoles.length) {
    return
  }

  for (var i = 0; i < exHoles.length; ++i) {
    var hole = exHoles[i]
    var oholes = oexHoles[i]

    hole['ocenter'] = oholes['center']
  }
}

//合并槽
function mergeSlots(exSlots, oexSlots) {
  if (exSlots.length !== oexSlots.length) {
    return
  }
  for (var i = 0; i < exSlots.length; ++i) {
    var slot = exSlots[i]
    var oslot = oexSlots[i]

    slot['opt1'] = oslot['pt1']
    slot['opt2'] = oslot['pt2']
  }
}

//把圆形，方形路径转换成孔槽
function changePathToHoleOrSlot(path, type, thick, xdepth, isThrough) {
  var result = {}

  var side = 1
  xdepth *= 1
  if (xdepth && isThrough === '否') {
    if (xdepth >= thick || xdepth <= -thick) {
      xdepth = thick
    }
    thick = Math.abs(xdepth)
    if (xdepth >= 0) {
      side = 1
    } else {
      side = -1
    }
  }

  var bound = getBound(path)
  var minX = bound.minx
  var minY = bound.miny
  var maxX = bound.maxx
  var maxY = bound.maxy

  if (type === 'hole') {
    var xDis = maxX - minX
    var yDis = maxY - minY
    var center = {
      x: (minX + maxX) / 2,
      y: (minY + maxY) / 2,
    }
    var diameter = (xDis + yDis) / 2

    if (diameter <= 0.001) {
      return null
    }

    result = {
      type: 'hole',
      deep: thick * 1,
      side: side,
      center: center,
      diameter: diameter * 1,
      holeType: 'exHole',
    }
  } else if (type === 'slot') {
    if (maxX - minX < maxY - minY) {
      var width = maxX - minX
      var length = maxY - minY
      var pt1 = {
        x: (maxX + minX) / 2,
        y: minY,
      }
      var pt2 = {
        x: (maxX + minX) / 2,
        y: maxY,
      }
    } else {
      width = maxY - minY
      length = maxX - minX
      pt1 = {
        x: minX,
        y: (maxY + minY) / 2,
      }
      pt2 = {
        x: maxX,
        y: (maxY + minY) / 2,
      }
    }

    if (width <= 0.001 || length <= 0.001) {
      return null
    }

    result = {
      type: 'slot',
      pt1: pt1,
      pt2: pt2,
      deep: thick * 1,
      side: side,
      width: width * 1,
      length: length * 1,
      slotType: 'exSlot',
    }
  }

  return result
}

function dealWithRealCurve(path, coord, hvd) {
  var hDir = hvd[0]
  var vDir = hvd[1]
  var dDir = hvd[2]

  var coordH = coord[0]
  var coordV = coord[1]
  var coordD = coord[2]

  var signH = coordH[0] >= 3 ? -1 : 1
  var signV = coordV[0] >= 3 ? -1 : 1

  for (var i in path) {
    var pt = path[i]

    var tmpPt = {}
    tmpPt[coordH % 3] = pt.x * signH
    tmpPt[coordV % 3] = pt.y * signV

    var retPt = {
      x: tmpPt[hDir % 3],
      y: tmpPt[vDir % 3],
    }
    if (pt.b) {
      if ((signH === 1 && signV === 1) || (signH === -1 && signV === -1)) {
        retPt['b'] = -pt.b
      } else {
        retPt['b'] = pt.b
      }
    }

    path[i] = retPt
  }

  if (!checkSequence(path)) {
    var lastB = -1000
    path.reverse()
    for (var i in path) {
      var p = path[i]
      var tmpB = lastB
      if (p.hasOwnProperty('b')) {
        lastB = p['b']
      } else {
        lastB = -1000
      }
      if (tmpB !== -1000) {
        p['b'] = -tmpB
      } else {
        delete p['b']
      }
    }
    if (lastB !== -1000) {
      path[0]['b'] = -lastB
    }
  }
}

function rotatePath(path, coord, hvd) {
  var hDir = hvd[0]
  var vDir = hvd[1]
  var dDir = hvd[2]

  var coordH = coord[0]
  var coordV = coord[1]
  var coordD = coord[2]

  var signH = coordH[0] >= 3 ? -1 : 1
  var signV = coordV[0] >= 3 ? -1 : 1

  for (var i in path) {
    var pt = path[i]

    var tmpPt = {}
    tmpPt[coordH % 3] = pt.x * signH
    tmpPt[coordV % 3] = pt.y * signV

    var retPt = {
      x: tmpPt[hDir % 3],
      y: tmpPt[vDir % 3],
    }
    path[i] = retPt
  }
}

//验证两个数是否近似相等
function isEqual(a, b) {
  a *= 1
  b *= 1
  var res = a - b
  res = Math.abs(res)
  if (res <= 0.01) {
    return true
  } else return false
}

//计算酒格的path // 忽略
function getWineRackPlankPath(width, height, depth, cellInfo) {
  var polygon
  var pthick, pspacing, widthOffset, deepOffset
  var wineRackOffset = cellInfo.tbOffset

  var ox = cellInfo.ox
  var oy = cellInfo.oy
  var hNum = cellInfo.hNum
  var vNum = cellInfo.vNum

  if (cellInfo.hasOwnProperty('hNum')) {
    var fHeight = Number(height)
    if (cellInfo.name === '方酒格') {
      fHeight += 2 * wineRackOffset
    }

    if (isEqual(fHeight, cellInfo.w)) {
      //传来的是横板,使用hspacing,hnum;
      pthick = cellInfo.thick
      pspacing = cellInfo.hSpacing
      widthOffset = cellInfo.widthOffset
      deepOffset = cellInfo.deepOffset

      polygon = TBFZ.calCubeWinplanks(
        width,
        height,
        depth,
        hNum,
        vNum,
        'v',
        widthOffset,
        deepOffset,
        wineRackOffset,
        ox,
        oy
      )
    } else if (isEqual(fHeight, cellInfo.h)) {
      //传来的是竖板,使用vSpacing,vNum
      pthick = cellInfo.thick
      pspacing = cellInfo.vSpacing
      widthOffset = cellInfo.widthOffset
      deepOffset = cellInfo.deepOffset
      polygon = TBFZ.calCubeWinplanks(
        width,
        height,
        depth,
        hNum,
        vNum,
        'h',
        widthOffset,
        deepOffset,
        wineRackOffset,
        ox,
        oy
      )
    }
  } else if (cellInfo.hasOwnProperty('nh')) {
    pthick = cellInfo.thick
    pspacing = cellInfo.spacing
    widthOffset = cellInfo.widthOffset
    deepOffset = cellInfo.deepOffset

    height = Number(height)
    height += 2 * wineRackOffset
    polygon = TBFZ.calPlanks(
      pthick,
      pspacing,
      width,
      height,
      deepOffset,
      widthOffset,
      wineRackOffset
    )
  } else if (cellInfo.hasOwnProperty('name') && cellInfo.name === 'X酒架') {
    pthick = cellInfo.thick
    widthOffset = cellInfo.widthOffset
    deepOffset = cellInfo.deepOffset
    var path = [
      { x: 0, y: 0 },
      { x: width, y: 0 },

      { x: width, y: height / 2 - pthick / 2 - widthOffset },
      {
        x: width - (width / 2 + deepOffset),
        y: height / 2 - pthick / 2 - widthOffset,
      },
      {
        x: width - (width / 2 + deepOffset),
        y: height / 2 + pthick / 2 + widthOffset,
      },
      { x: width, y: height / 2 + pthick / 2 + widthOffset },

      { x: width, y: height },

      { x: 0, y: height },
    ]

    var edge = wineRackOffset

    var pl = path.length

    for (var k in path) {
      var pt = path[k]
      if (k == 0) {
        pt.x += edge
        pt.y += edge
      } else if (k == 1) {
        pt.x -= edge
        pt.y += edge
      } else if (k == pl - 2) {
        pt.x -= edge
        pt.y -= edge
      } else if (k == pl - 1) {
        pt.x += edge
        pt.y -= edge
      } else {
        if (pt.x == path[1].x + edge) pt.x -= edge
      }
    }

    path[0].x += edge
    path[0].y += edge

    var pathTg = [
      { x: 0, y: 0 },
      { x: width, y: 0 },

      { x: width, y: height / 2 - pthick / 2 - widthOffset + edge },
      {
        x: width - (width / 2 + deepOffset) + edge,
        y: height / 2 - pthick / 2 - widthOffset + edge,
      },
      {
        x: width - (width / 2 + deepOffset) + edge,
        y: height / 2 + pthick / 2 + widthOffset - edge,
      },
      { x: width, y: height / 2 + pthick / 2 + widthOffset - edge },

      { x: width, y: height },

      { x: 0, y: height },
    ]

    polygon = {
      poly: path,
      fullPoly: pathTg,
    }
  } else {
    height = height + 4 * wineRackOffset
    if (cellInfo.h == height) {
      pthick = cellInfo.thick
      pspacing = cellInfo.vSpacing
      widthOffset = cellInfo.widthOffset
      deepOffset = cellInfo.deepOffset
      polygon = TBFZ.calPlanks(
        pthick,
        pspacing,
        width,
        height + 4 * wineRackOffset,
        deepOffset,
        widthOffset,
        wineRackOffset
      )
    }
  }

  var array = [],
    arr2 = []
  if (polygon) {
    array.push(polygon.poly)
    arr2.push(polygon.fullPoly)
  }

  var minX = 100000,
    minY = 100000
  for (var i in array[0]) {
    minX = Math.min(minX, array[0][i]['x'])
    minY = Math.min(minY, array[0][i]['y'])
  }
  for (var i in array[0]) {
    array[0][i]['x'] -= minX
    array[0][i]['y'] -= minY
  }

  var result = {}
  result['path'] = array
  result['oPath'] = arr2
  result['edgeInfo'] = '←0↓0→0↑0'
  result['sholeInfo'] = ''
  result['sslotInfo'] = ''
  result['fullSize'] = Qt.size(width, height)

  return result
}

function plankOtherInfo(result, unqId, roomInfo) {
  var remark = roomInfo['remark']
  var orderNo = roomInfo['order_code']
  var address = roomInfo['buyer_address']
  var userName = roomInfo['pname']
  var createTime = roomInfo['create_time']
  var customerName = roomInfo['customer_name']
  var oId = roomInfo['oId']

  result['oId'] = oId
  result['remark'] = remark
  result['address'] = address
  result['orderNo'] = orderNo
  result['userName'] = userName
  result['createTime'] = createTime
  result['customer_name'] = customerName
}

function getPlankGGid(plates, unqId) {
  for (var i in plates) {
    var plate = plates[i]
    var plateOther = plate['plate_other']
    var po = libGG.parseJson(plateOther)
    if (po['gid'] === unqId) {
      return plate['ggid']
    }
  }
  return ''
}

function getPlankGGidshort(plates, unqId) {
  for (var i in plates) {
    var plate = plates[i]
    var plateOther = plate['plate_other']
    var po = libGG.parseJson(plateOther)
    if (po['gid'] === unqId) {
      return plate['ggid_short']
    }
  }
  return ''
}

//搜集排版信息 complementOrders补件的订单   patchIds顺带补件的id
async function fetchcomplementData(complementOrders, patchIds) {
  var datas = []
  for (var i in complementOrders) {
    var orderInfo = complementOrders[i]
    var roomInfo = {}
    var plates = orderInfo['plates']
    roomInfo['remark'] = orderInfo['remark']
    roomInfo['order_code'] = orderInfo['order_code']
    roomInfo['buyer_address'] = orderInfo['buyer_address']
    roomInfo['pname'] = orderInfo['name'] ? orderInfo['name'] : ''
    roomInfo['create_time'] = orderInfo['create_time']
    roomInfo['customer_name'] = orderInfo['customer_name']
    roomInfo['oId'] = orderInfo['id']

    for (var j in plates) {
      var plate = plates[j]

      var plkId = plate['plankId']
      var plkggId = plate['ggid']
      var id = plate['id']
      if (patchIds && patchIds.indexOf(id) === -1) {
        //顺带补件
        continue
      }

      var rJson = plate['render_url']
      var loadData = await downloadRenderData(rJson)
      roomInfo['name'] = plate['room_name']
      var plateInfo = plate['plate_info'] //{"color":"02天使白","matCode":"多层实木","plKId":82,"size":"386.33*174*18"}
      delete plateInfo.plKId
      var tmpComplementData = getLayoutDataNew(
        loadData,
        roomInfo,
        plkId,
        plateInfo
      )

      if (!tmpComplementData) {
        return null
      }
      datas = datas.concat(tmpComplementData)
    }
  }
  return datas
}

//用于服务器上输出NC的数据转换
function changeData(data) {
  for (var i in data) {
    var tData = data[i]
    var plankWidth = tData.plankWidth
    var plankHeight = tData.plankHeight

    var parts = tData['parts']

    for (var j in parts) {
      var part = parts[j]
      // 左下角 转换
      part['startY'] = plankHeight - part['rect'].height - part['startY']
    }
  }
}

//判断顺时针还是逆时针
function checkSequence(iPath) {
  var path = deepCopy(iPath)
  var n = path.length
  var s = path[0].y * (path[n - 1].x - path[1].x)
  path[n] = path[0]
  for (var i = 1; i < n; ++i) {
    s += path[i].y * (path[i - 1].x - path[i + 1].x)
  }
  return s > 0
}

function getLastCurveScaleFactor(vertex, edgeInfo, texDir) {
  var sy, sx

  var curve = libCurve.newCurve()
  curve.points = vertex
  var points = curve.getPolygon(true)
  var minx, miny, maxx, maxy
  for (var i in points) {
    var pt = points[i]
    if (Number(i) === 0) {
      minx = maxx = pt.x
      miny = maxy = pt.y
    } else {
      minx = Math.min(minx, pt.x)
      miny = Math.min(miny, pt.y)
      maxx = Math.max(maxx, pt.x)
      maxy = Math.max(maxy, pt.y)
    }
  }

  var owd = maxx - minx
  var ohg = maxy - miny

  var left = Number(
    edgeInfo.substring(edgeInfo.indexOf('←') + 1, edgeInfo.indexOf('↓'))
  )
  var bottom = Number(
    edgeInfo.substring(edgeInfo.indexOf('↓') + 1, edgeInfo.indexOf('→'))
  )
  var right = Number(
    edgeInfo.substring(edgeInfo.indexOf('→') + 1, edgeInfo.indexOf('↑'))
  )
  var top = Number(edgeInfo.substring(edgeInfo.indexOf('↑') + 1))

  if (texDir === 'reverse') {
    var wd = owd - top - bottom
    var hg = ohg - left - right
  } else {
    wd = owd - left - right
    hg = ohg - top - bottom
  }
  sx = wd / owd
  sy = hg / ohg

  return { sx: sx, sy: sy, wd: wd, hg: hg, owd: owd, ohg: ohg }
}
