import { getLayoutData } from '@/apis/baseMaterial'
import { GetProductionNcSetting } from '@/apis/common/productionNcSetting'
import { asyncPaiban, fetchAsyncPaiban, getPaiban } from '@/apis/paiban'
import { getPrelayoutSetting } from '@/apis/preLayoutSetting'
import router from '@/router'
import store from '@/store'
import { cutKnifeRadius, generatorSideHoleSlot } from '@/util/LayoutFuncs.js'
import { genColorRes, judgeSourceData, randomInt } from '@/util/commonFun'
import {
  isOtherSideMachineHole,
  isOtherSideMachineSlot,
} from '@/util/commonFuncs'
import {
  compareNumbers,
  isRectangleContained,
  judgeRectIntersect,
  rectToPath,
} from '@/util/graphCalc'
import { dealPreLayoutSetting } from '@/views/materialsList/util/preLayoutDeal'
import { Message, Notification } from 'element-ui'
import _ from 'lodash'
import Vue from 'vue'

import { afterProcess } from './LayoutTool.js'
import { rotate180Part } from './LayoutTool.js'
import { viewDataToNCData } from './NCGenerator.js'
import { translate } from './commonFun.js'
import { compareTowNum, dealNumber, toDecimal } from './commonFuncs.js'
import {
  checkPlankOutlineKey,
  getPathMinMaxPoint,
  isLShapeSurplusBigPlank,
  judgePart1ContainPart2,
  judgeSlotIsRotate,
} from './plankCommonFuncs'
import {
  holeIsIntersect,
  polyContain,
  polyIntersect,
} from './polygonRelation.js'

let NOTROTATELGAP = false

//通过颜色来判断当前坐标是否被绘制
export function isCanvasPixelDrawn(ctx, x, y) {
  const pixelData = ctx.getImageData(x, y, 1, 1).data
  if (
    (pixelData[0] == 238 &&
      pixelData[1] == 238 &&
      pixelData[2] == 238 &&
      pixelData[3] == 255) ||
    (pixelData[0] == 0 &&
      pixelData[1] == 0 &&
      pixelData[2] == 0 &&
      pixelData[3] == 0)
  ) {
    return false // 浅灰色或白色 大板空白处或大板外颜色
  } else {
    // return pixelData[3] > 0 // 判断像素数据的透明度是否大于 0
    return true // 判断像素数据的透明度是否大于 0
  }
}
/**
 * 计算最大矩形
 * @param { Object } e 鼠标事件源
 * @param { Object } bigpart 大版信息
 * @param { Object } ctx canvas上下文
 * @returns
 */
export function computeMostRect(
  e,
  bigpart,
  ctx,
  topInfoHeight,
  deviation,
  gap,
  scalePercent,
  defaultScale
) {
  const scaleDeviation = (deviation / defaultScale) * scalePercent
  const scaleGap = (gap / defaultScale) * scalePercent
  let copyParams = {
    x: e.offsetX,
    y: e.offsetY,
  }
  const minX =
    (Math.min(...bigpart.bigPlank[0].map((item) => item.X)) / defaultScale) *
    scalePercent
  const minY =
    (Math.min(...bigpart.bigPlank[0].map((item) => item.Y)) / defaultScale) *
    scalePercent
  let data = {
    canvasData: {
      height:
        bigpart.subtleHeight + bigpart.subtleStartY + topInfoHeight + deviation,
      width: bigpart.subtleWidth + bigpart.subtleStartX + deviation,
      left: bigpart.subtleStartX,
      top: bigpart.subtleStartY,
    },
    limit: {
      up: bigpart.subtleStartY + topInfoHeight + deviation - 1,
      down:
        bigpart.subtleHeight +
        bigpart.subtleStartY +
        topInfoHeight +
        deviation +
        -1,
      left: bigpart.subtleStartX + deviation - 1,
      right: bigpart.subtleWidth + bigpart.subtleStartX + deviation - 1,
    },
    surplusmaterial: {
      startX: '',
      startY: '',
      width: '',
      height: '',
    },
  }
  //以y为轴向=================================================
  let rightDownXList = [] //右下所有的x坐标
  let leftDownXList = [] //左下所有的x坐标
  let rightUpXList = [] //右上所有的x坐标
  let leftUpXList = [] //右上所有的x坐标
  let starty = ''
  let startx = ''
  let widthY = ''
  let heightY
  let heightUp = ''
  let heightdwon = ''
  //右下
  for (let i = copyParams.y; i < data.canvasData.height; i++) {
    for (let k = copyParams.x; k < data.canvasData.width; k += 1) {
      let isIn1 = isCanvasPixelDrawn(ctx, k, i)
      if (k == data.limit.right) {
        rightDownXList.push(k + scaleDeviation + (minX > 0 ? -minX : minX))
        break
      } else if (isIn1) {
        rightDownXList.push(k - scaleGap)
        break
      }
    }
    let isIn = isCanvasPixelDrawn(ctx, copyParams.x, i)
    if (i == data.limit.down) {
      heightdwon = i + scaleDeviation + (minY > 0 ? -minY : minY)
      break
    } else if (isIn) {
      heightdwon = i - scaleGap
      break
    }
  }
  //左下
  for (let i = copyParams.y; i < data.canvasData.height; i++) {
    for (let k = copyParams.x; k > data.canvasData.left; k -= 1) {
      let isIn1 = isCanvasPixelDrawn(ctx, k, i)
      if (isIn1 && k == data.limit.left) {
        leftDownXList.push(k + scaleDeviation + minX)
        break
      } else if (isIn1) {
        leftDownXList.push(k + scaleGap)
        break
      }
    }
    let isIn = isCanvasPixelDrawn(ctx, copyParams.x, i)
    if (isIn || i == data.limit.down) break
  }
  //右上
  for (let i = copyParams.y; i > data.canvasData.top + 1; i--) {
    for (let k = copyParams.x; k <= data.canvasData.width + 1; k += 1) {
      let isIn1 = isCanvasPixelDrawn(ctx, k, i)
      if (k == data.limit.right) {
        rightUpXList.push(k + scaleDeviation + (minX > 0 ? -minX : minX))
        break
      } else if (isIn1) {
        rightUpXList.push(k - scaleGap)
        break
      }
    }
    let isIn = isCanvasPixelDrawn(ctx, copyParams.x, i)
    if (isIn && i == data.limit.up) {
      heightUp = i + scaleDeviation + minY
      break
    } else if (isIn) {
      heightUp = i + scaleGap
      break
    }
  }

  //左上
  for (let i = copyParams.y; i > data.canvasData.top; i--) {
    for (let k = copyParams.x; k > data.canvasData.left; k -= 1) {
      let isIn1 = isCanvasPixelDrawn(ctx, k, i)
      if (isIn1 && k == data.limit.left) {
        leftUpXList.push(k + scaleDeviation + minX)
        break
      } else if (isIn1) {
        leftUpXList.push(k + scaleGap)
        break
      }
    }
    let isIn = isCanvasPixelDrawn(ctx, copyParams.x, i)
    if (isIn && i == data.limit.up) {
      starty = i + scaleDeviation + minY
      break
    } else if (isIn) {
      starty = i + scaleGap
      break
    }
  }
  rightDownXList.pop()
  leftDownXList.pop()
  rightUpXList.pop()
  leftUpXList.pop()

  let leftDownListXSort = leftDownXList.sort((a, b) => b - a)[0]
  let leftUpListXSort = leftUpXList.sort((a, b) => b - a)[0]
  let cc =
    leftDownListXSort > leftUpListXSort ? leftDownListXSort : leftUpListXSort

  let rightDownListXSort = rightDownXList.sort((a, b) => a - b)[0]
  let rightUpListXSort = rightUpXList.sort((a, b) => a - b)[0]
  let dd =
    rightDownListXSort > rightUpListXSort
      ? rightUpListXSort
      : rightDownListXSort

  startx =
    leftDownListXSort > leftUpListXSort ? leftDownListXSort : leftUpListXSort //起始x点
  // starty =  //起始y点
  widthY = dd - cc //以y轴计算出来的矩形宽度
  heightY = heightdwon - heightUp //以y轴计算出来的矩形高度

  //以x为轴向=================================================
  let rightDownYList = [] //右下所有的y坐标
  let leftDownYList = [] //左下所有的y坐标
  let rightUpYList = [] //右上所有的y坐标
  let leftUpYList = [] //右上所有的y坐标

  let endX = '' //y的结束点
  let startX = '' //起始x点
  let startY = '' //起始x点
  let widthX = '' //以x轴计算出来的矩形宽度
  let heightX = '' //以x轴计算出来的矩形高度
  //右下
  for (let i = copyParams.x; i < data.canvasData.width; i++) {
    for (let k = copyParams.y; k < data.canvasData.height; k += 1) {
      let isIn1 = isCanvasPixelDrawn(ctx, i, k)
      if (k == data.limit.down) {
        rightDownYList.push(k + scaleDeviation + (minY > 0 ? -minY : minY))
        break
      } else if (isIn1) {
        rightDownYList.push(k - scaleGap)
        break
      }
    }
    let isIn = isCanvasPixelDrawn(ctx, i, copyParams.y)
    if (i == data.limit.right) {
      endX = i + scaleDeviation + (minX > 0 ? -minX : minX)
      break
    } else if (isIn) {
      endX = i - scaleGap
      break
    }
  }

  //左下
  for (let i = copyParams.x; i > data.canvasData.left; i--) {
    for (let k = copyParams.y; k < data.canvasData.height; k += 1) {
      let isIn1 = isCanvasPixelDrawn(ctx, i, k)
      if (k == data.limit.down) {
        leftDownYList.push(k + scaleDeviation + (minY > 0 ? -minY : minY))
        break
      } else if (isIn1) {
        leftDownYList.push(k + scaleGap)
        break
      }
    }
    let isIn = isCanvasPixelDrawn(ctx, i, copyParams.y)
    if (i == data.limit.left) {
      startX = i + scaleDeviation + minX
      break
    } else if (isIn) {
      startX = i + scaleGap
      break
    }
  }

  //右上
  for (let i = copyParams.x; i < data.canvasData.width; i++) {
    for (let k = copyParams.y; k > data.canvasData.top; k -= 1) {
      let isIn1 = isCanvasPixelDrawn(ctx, i, k)
      if (k == data.limit.up) {
        rightUpYList.push(k + scaleDeviation + minY)
        break
      } else if (isIn1) {
        rightUpYList.push(k - scaleGap)
        break
      }
    }
    let isIn = isCanvasPixelDrawn(ctx, i, copyParams.y)
    if (isIn || i == data.limit.right) break
  }

  //左上
  for (let i = copyParams.x; i > data.canvasData.left; i--) {
    for (let k = copyParams.y; k > data.canvasData.top; k -= 1) {
      let isIn1 = isCanvasPixelDrawn(ctx, i, k)
      if (k == data.limit.up) {
        leftUpYList.push(k + scaleDeviation + minY)
        break
      } else if (isIn1) {
        leftUpYList.push(k + scaleGap)
        break
      }
    }
    let isIn = isCanvasPixelDrawn(ctx, i, copyParams.y)
    if (isIn || i == data.limit.left) break
  }
  rightDownYList.pop()
  leftDownYList.pop()
  rightUpYList.pop()
  leftUpYList.pop()

  let leftUpListYSort = leftUpYList.sort((a, b) => b - a)[0]
  let rightUpListYSort = rightUpYList.sort((a, b) => b - a)[0]
  let aa =
    leftUpListYSort > rightUpListYSort ? leftUpListYSort : rightUpListYSort

  let leftDownListYSort = leftDownYList.sort((a, b) => a - b)[0]
  let rightDownListYSort = rightDownYList.sort((a, b) => a - b)[0]
  let bb =
    leftDownListYSort > rightDownListYSort
      ? rightDownListYSort
      : leftDownListYSort

  // startX = startX
  startY = aa
  widthX = endX - startX
  heightX = bb - aa

  let state =
    startX === startx && startY === starty
      ? widthY * heightY < widthX * heightX
      : widthY * heightY > widthX * heightX //y轴矩形是否大于x轴
  data.surplusmaterial.startX = state ? startx : startX
  data.surplusmaterial.startY = state ? starty : startY
  data.surplusmaterial.width = state ? widthY : widthX
  data.surplusmaterial.height = state ? heightY : heightX

  return data.surplusmaterial
}

/**
 * 以线段方式计算出最大矩形
 * @param {*} bigpart 大板
 * @param {*} mousePoint 鼠标点
 * @returns 裁剪出的余料坐标
 */
export function computeMostRectByLine(bigpart, mousePoint) {
  // 大板点位
  const bigpartXPoint = bigpart.bigPlank[0].map((item) => item.X)
  const bigpartYPoint = bigpart.bigPlank[0].map((item) => item.Y)
  // 鼠标点到大板边横线（默认为矩形板，L形大板下方单独计算处理）
  let hLine = [
    { x: Math.min(...bigpartXPoint), y: mousePoint.y },
    { x: Math.max(...bigpartXPoint), y: mousePoint.y },
  ]
  // 鼠标点到大板边竖线
  let vLine = [
    { x: mousePoint.x, y: Math.min(...bigpartYPoint) },
    {
      x: mousePoint.x,
      y: Math.max(...bigpartYPoint),
    },
  ]
  let surplus = bigpart.surplusInfo
  // L形余料大板
  if (surplus?.shape && surplus?.shape == 'lshape') {
    bigpart.hLine = []
    bigpart.vLine = []
    // 各个方向的交点列表
    const bigpartVTopInterPointList = []
    const bigpartVBottomInterPointList = []
    const bigpartHLeftInterPointList = []
    const bigpartHRightInterPointList = []
    for (let i = 0; i < bigpart.bigPlank[0].length; i++) {
      for (let j = i + 1; j < bigpart.bigPlank[0].length; j++) {
        // L形大板横线
        if (bigpart.bigPlank[0][i].Y == bigpart.bigPlank[0][j].Y) {
          let bigpartHLine = [
            { x: bigpart.bigPlank[0][i].X, y: bigpart.bigPlank[0][i].Y },
            { x: bigpart.bigPlank[0][j].X, y: bigpart.bigPlank[0][i].Y },
          ]
          bigpart.hLine.push(bigpartHLine)
          const bigpartVInterPoint = getIntersectionPoint(
            getLineEquationFromPoints(vLine),
            getLineEquationFromPoints(bigpartHLine)
          )
          if (
            bigpartVInterPoint &&
            vLine[0].x >= Math.min(...bigpartHLine.map((item) => item.x)) &&
            vLine[0].x <= Math.max(...bigpartHLine.map((item) => item.x))
          ) {
            // 竖线与鼠标点上方L形大板横线交点
            if (mousePoint.y >= bigpartHLine[0].y) {
              bigpartVTopInterPointList.push(bigpartVInterPoint)
            } else {
              // 竖线与鼠标点下方L形大板横线交点
              bigpartVBottomInterPointList.push(bigpartVInterPoint)
            }
          }
        }
        // L形大板竖线
        if (bigpart.bigPlank[0][i].X == bigpart.bigPlank[0][j].X) {
          let bigpartVLine = [
            { x: bigpart.bigPlank[0][i].X, y: bigpart.bigPlank[0][i].Y },
            { x: bigpart.bigPlank[0][i].X, y: bigpart.bigPlank[0][j].Y },
          ]
          bigpart.vLine.push(bigpartVLine)
          const bigpartHInterPoint = getIntersectionPoint(
            getLineEquationFromPoints(hLine),
            getLineEquationFromPoints(bigpartVLine)
          )
          if (
            bigpartHInterPoint &&
            hLine[0].y >= Math.min(...bigpartVLine.map((item) => item.y)) &&
            hLine[0].y <= Math.max(...bigpartVLine.map((item) => item.y))
          ) {
            // 横线与鼠标点左侧L形大板竖线交点
            if (mousePoint.x >= bigpartVLine[0].x) {
              bigpartHLeftInterPointList.push(bigpartHInterPoint)
            } else {
              // 横线与鼠标点右侧L形大板竖线交点
              bigpartHRightInterPointList.push(bigpartHInterPoint)
            }
          }
        }
      }
    }
    // 根据与大板各方向的交点 计算出不超出大板范围的横线、竖线
    if (bigpartVTopInterPointList.length) {
      let yArr = bigpartVTopInterPointList.map((item) => item.y)
      vLine[0].y = Math.max(...yArr)
    }
    if (bigpartVBottomInterPointList.length) {
      let yArr = bigpartVBottomInterPointList.map((item) => item.y)
      vLine[1].y = Math.min(...yArr)
    }
    if (bigpartHLeftInterPointList.length) {
      let xArr = bigpartHLeftInterPointList.map((item) => item.x)
      hLine[0].x = Math.max(...xArr)
    }
    if (bigpartHRightInterPointList.length) {
      let xArr = bigpartHRightInterPointList.map((item) => item.x)
      hLine[1].x = Math.min(...xArr)
    }
  }
  // 各个方向的交点列表
  const hTopInterPointList = []
  const hBottomInterPointList = []
  const hLeftInterPointList = []
  const hRightInterPointList = []

  for (let i = 0; i < bigpart.parts.length; i++) {
    const part = bigpart.parts[i]
    // 鼠标点上方或小板长度范围内横线
    if (
      part.startY + part.rect.height <= mousePoint.y ||
      (mousePoint.y > part.startY &&
        mousePoint.y < part.startY + part.rect.height)
    ) {
      if (part.startY + part.rect.height <= mousePoint.y) {
        part.hLine = [
          { x: part.startX, y: part.startY + part.rect.height },
          {
            x: part.startX + part.rect.width,
            y: part.startY + part.rect.height,
          },
        ]
      } else {
        part.hLine = [
          { x: part.startX, y: mousePoint.y },
          { x: part.startX + part.rect.width, y: mousePoint.y },
        ]
      }
      const hTopInterPoint = getIntersectionPoint(
        getLineEquationFromPoints(vLine),
        getLineEquationFromPoints(part.hLine)
      )
      if (
        hTopInterPoint &&
        vLine[0].x >= part.hLine[0].x &&
        vLine[0].x <= part.hLine[1].x
      ) {
        hTopInterPointList.push(hTopInterPoint)
      }
    }

    // 鼠标点下方横线
    if (mousePoint.y <= part.startY) {
      part.hLine = [
        { x: part.startX, y: part.startY },
        { x: part.startX + part.rect.width, y: part.startY },
      ]
      const hBottomInterPoint = getIntersectionPoint(
        getLineEquationFromPoints(vLine),
        getLineEquationFromPoints(part.hLine)
      )
      if (
        hBottomInterPoint &&
        vLine[0].x >= part.hLine[0].x &&
        vLine[0].x <= part.hLine[1].x
      ) {
        hBottomInterPointList.push(hBottomInterPoint)
      }
    }

    // 鼠标点左侧或小板宽度范围内竖线
    if (
      part.startX + part.rect.width <= mousePoint.x ||
      (mousePoint.x > part.startX &&
        mousePoint.x < part.startX + part.rect.width)
    ) {
      if (part.startX + part.rect.width <= mousePoint.x) {
        part.vLine = [
          { x: part.startX + part.rect.width, y: part.startY },
          {
            x: part.startX + part.rect.width,
            y: part.startY + part.rect.height,
          },
        ]
      } else {
        part.vLine = [
          { x: mousePoint.x, y: part.startY },
          { x: mousePoint.x, y: part.startY + part.rect.height },
        ]
      }

      const hLeftInterPoint = getIntersectionPoint(
        getLineEquationFromPoints(hLine),
        getLineEquationFromPoints(part.vLine)
      )
      if (
        hLeftInterPoint &&
        hLine[0].y >= part.vLine[0].y &&
        hLine[0].y <= part.vLine[1].y
      ) {
        hLeftInterPointList.push(hLeftInterPoint)
      }
    }

    // 鼠标点右侧竖线
    if (part.startX >= mousePoint.x) {
      part.vLine = [
        { x: part.startX, y: part.startY },
        { x: part.startX, y: part.startY + part.rect.height },
      ]
      const hRightInterPoint = getIntersectionPoint(
        getLineEquationFromPoints(hLine),
        getLineEquationFromPoints(part.vLine)
      )
      if (
        hRightInterPoint &&
        hLine[0].y >= part.vLine[0].y &&
        hLine[0].y <= part.vLine[1].y
      ) {
        hRightInterPointList.push(hRightInterPoint)
      }
    }

    // 鼠标点在异形部分
    if (
      part.startX <= mousePoint.x &&
      mousePoint.x <= part.startX + part.rect.width &&
      part.startY <= mousePoint.y &&
      mousePoint.y <= part.startY + part.rect.height
    ) {
      // 异形部分不能裁剪矩形
      Vue.prototype.$message.warning(
        translate('arrangedPage.subtlePage.noCutPath')
      )
      return
    }
  }
  if (hTopInterPointList.length) {
    let yArr = hTopInterPointList.map((item) => item.y)
    vLine[0].y = Math.max(...yArr) + store.state.ncSetting.panelSize.layoutGap
  }
  if (hBottomInterPointList.length) {
    let yArr = hBottomInterPointList.map((item) => item.y)
    vLine[1].y = Math.min(...yArr) - store.state.ncSetting.panelSize.layoutGap
  }
  if (hLeftInterPointList.length) {
    let xArr = hLeftInterPointList.map((item) => item.x)
    hLine[0].x = Math.max(...xArr) + store.state.ncSetting.panelSize.layoutGap
  }
  if (hRightInterPointList.length) {
    let xArr = hRightInterPointList.map((item) => item.x)
    hLine[1].x = Math.min(...xArr) - store.state.ncSetting.panelSize.layoutGap
  }

  // 竖线范围内与小板横线有重合的横线
  const hLineYLimit = bigpart.parts
    .filter(
      (item) =>
        item.hLine[0].y >= vLine[0].y &&
        item.hLine[0].y <= vLine[1].y &&
        (item.hLine[1].x > hLine[0].x ||
          item.hLine[0].x < hLine[1].x ||
          (item.hLine[0].x >= hLine[0].x && item.hLine[1].x <= hLine[1].x))
    )
    .map((part) => part.hLine)
  // 竖线范围内与大板横线有重合的横线（主要是L形）
  const bigpartHLineYLimit = bigpart.hLine?.filter((item) => {
    const minY = Math.min(...item.map((point) => point.y))
    const minX = Math.min(...item.map((point) => point.x))
    const maxX = Math.max(...item.map((point) => point.x))
    return (
      minY >= vLine[0].y &&
      minY <= vLine[1].y &&
      (maxX > hLine[0].x ||
        minX < hLine[1].x ||
        (minX >= hLine[0].x && maxX <= hLine[1].x))
    )
  })
  if (bigpartHLineYLimit && bigpartHLineYLimit.length) {
    hLineYLimit.push(...bigpartHLineYLimit)
  }
  const hLineLeftYLimit = hLineYLimit.filter(
    (item) => Math.max(...item.map((point) => point.x)) <= mousePoint.x
  )
  const hLineRightYLimit = hLineYLimit.filter(
    (item) => Math.min(...item.map((point) => point.x)) > mousePoint.x
  )

  // 最小横线
  const hLineMin = JSON.parse(JSON.stringify(hLine))
  if (hLineLeftYLimit.length) {
    const xArr = hLineLeftYLimit.flat(1).map((item) => item.x)
    // 大板边缘不加间隙
    if (Math.max(...xArr) < Math.max(...bigpartXPoint)) {
      hLineMin[0].x =
        Math.max(...xArr) + store.state.ncSetting.panelSize.layoutGap
    }
  }
  if (hLineRightYLimit.length) {
    const xArr = hLineRightYLimit.flat(1).map((item) => item.x)
    // 大板边缘不加间隙
    if (Math.min(...xArr) > Math.min(...bigpartXPoint)) {
      hLineMin[1].x =
        Math.min(...xArr) - store.state.ncSetting.panelSize.layoutGap
    }
  }
  // 最小横线扫描矩形面积
  const minHLineAre =
    (hLineMin[1].x - hLineMin[0].x) * (vLine[1].y - vLine[0].y)

  const vLineXLimit = bigpart.parts
    .filter(
      (item) =>
        item.vLine[0].x >= hLine[0].x &&
        item.vLine[0].x <= hLine[1].x &&
        (item.vLine[1].y > vLine[0].y ||
          item.vLine[0].y < vLine[1].y ||
          (item.vLine[0].y >= vLine[0].y && item.vLine[1].y <= vLine[1].y))
    )
    .map((part) => part.vLine)
  const bigpartVLineYLimit = bigpart.vLine?.filter((item) => {
    const minX = Math.min(...item.map((point) => point.x))
    const minY = Math.min(...item.map((point) => point.y))
    const maxY = Math.max(...item.map((point) => point.y))
    return (
      minX >= hLine[0].x &&
      minX <= hLine[1].x &&
      (maxY > vLine[0].y ||
        minY < vLine[1].y ||
        (minY >= vLine[0].y && maxY <= vLine[1].y))
    )
  })
  if (bigpartVLineYLimit && bigpartVLineYLimit.length) {
    vLineXLimit.push(...bigpartVLineYLimit)
  }
  const vLineTopYLimit = vLineXLimit.filter(
    (item) => Math.max(...item.map((point) => point.y)) <= mousePoint.y
  )
  const vLineBottomYLimit = vLineXLimit.filter(
    (item) => Math.min(...item.map((point) => point.y)) > mousePoint.y
  )
  // 最小竖线
  const vLineMin = JSON.parse(JSON.stringify(vLine))
  if (vLineTopYLimit.length) {
    const yArr = vLineTopYLimit.flat(1).map((item) => item.y)
    // 大板边缘不加间隙
    if (Math.max(...yArr) < Math.max(...bigpartYPoint)) {
      vLineMin[0].y =
        Math.max(...yArr) + store.state.ncSetting.panelSize.layoutGap
    }
  }
  if (vLineBottomYLimit.length) {
    const yArr = vLineBottomYLimit.flat(1).map((item) => item.y)
    // 大板边缘不加间隙
    if (Math.min(...yArr) > Math.min(...bigpartYPoint)) {
      vLineMin[1].y =
        Math.min(...yArr) - store.state.ncSetting.panelSize.layoutGap
    }
  }
  // 最小横线扫描矩形面积
  const minVLineAre =
    (hLine[1].x - hLine[0].x) * (vLineMin[1].y - vLineMin[0].y)

  // 取最大面积
  if (minHLineAre >= minVLineAre) {
    hLine = hLineMin
  } else {
    vLine = vLineMin
  }

  const surplusPoint = [
    // 左上
    { x: hLine[0].x, y: vLine[0].y },
    // 左下
    { x: hLine[0].x, y: vLine[1].y },
    // 右下
    { x: hLine[1].x, y: vLine[1].y },
    // 右上
    { x: hLine[1].x, y: vLine[0].y },
  ]
  return surplusPoint
}

/**
 * 获取直线方程式
 * @param {*} line 直线 两点坐标
 */
function getLineEquationFromPoints(line) {
  const { x: x1, y: y1 } = line[0]
  const { x: x2, y: y2 } = line[1]

  // 计算斜率
  const slope = (y2 - y1) / (x2 - x1)

  // 处理垂直直线的情况
  if (x2 == x1) {
    return { A: 1, B: 0, C: -x1 }
  } else {
    // 使用斜率来计算A，B，C
    const A = -slope
    const B = 1
    const C = -(A * x1 + B * y1)
    return { A, B, C }
  }
}

/**
 * 获取交点坐标
 * 这个方法把线段进行了延伸 所以最终使用时 要判断是否在线段范围内
 * @param {*} line1
 * @param {*} line2
 */
function getIntersectionPoint(line1, line2) {
  const { A: A1, B: B1, C: C1 } = line1
  const { A: A2, B: B2, C: C2 } = line2

  // 计算行列式
  const determinant = A1 * B2 - A2 * B1

  // 如果行列式为0，则直线平行或重合，没有交点
  if (determinant === 0) {
    return null
  }

  // 计算交点坐标
  const x = (B2 * C1 - B1 * C2) / determinant
  const y = (A1 * C2 - A2 * C1) / determinant
  return { x: Math.abs(x), y: Math.abs(y) }
}

/**
 *
 * @param { Object -> Array } param 请求接口的参数 -> 现所有参数改为数组方式
 * @param { Array } preData 预处理数据
 * @param { String } type 选择的排版方式(最高优化率, 紧凑排版, 少翻板)
 * @param { Boolean } needSave 是否需要重新保存数据
 * @param { Boolean } isHistory 是否需要切换历史记录的状态
 * @param { Boolean } needMorePlan 是否需要多方案排版
 * @param { Boolean } isReLayout 是否为重新排版
 * @param { Boolean } scattered_layout 是否为分散排版
 * @returns
 */
export function dealPaibanData(
  paramArr,
  preData,
  type = 'paiban_v2',
  needSave = true,
  isHistory = false,
  needMorePlan = false,
  isReLayout = false,
  scattered_layout = false,
  safe_length = [0, 0]
) {
  return new Promise(async (resolve) => {
    // 重新排版时剔除余料板件
    preData = preData.filter((part) => !part.specialType)
    let isThinkerxMaterial = false
    const customPlankOrder = store.state.customPlankOrder
    const isPreLayout = store.state.ncSetting.isPreLayout
    const knives = isPreLayout
      ? store.state.preLayoutSetting.setting_value.knife.diameter
      : store.state.ncSetting.knife.diameter
    const { scattered_layout = false, safe_length } = isPreLayout
      ? store.state.preLayoutSetting.setting_value.movePlankSetting
      : store.state.ncSetting.movePlankSetting
    let minWidth, minHeight
    ;[minWidth, minHeight] = safe_length
    const isJinxiPaiban = router.currentRoute.query?.is_jinxi_paiban === '1'
    paramArr.forEach((param) => {
      if (sessionStorage.getItem('thinkerx_material')) {
        param.data['from'] = '门窗erp'
        isThinkerxMaterial && (isThinkerxMaterial = true)
      }
      // 都可以拥有多种方案
      if (!customPlankOrder) {
        param.data.is_plural = true
      }
    })
    const types = ['paiban_v2', 'paiban_roll_v2']
    // 离线排版完成次数
    let finishCount = 0
    // 离线排版结果
    let offlineRes = []
    // 在线排版结果
    let onlineRes = []
    // 是否离线排版
    let isOfflinePaiban = false
    // 获取接口请求
    const isWebQT =
      window.webToQt &&
      types.includes(type) &&
      window.webToQt.layout &&
      !store.state.paibanInfo.isSpecialShape &&
      paramArr.length < 2
    // 暂时禁用内嵌离线排版
    if (isWebQT && false) {
      paramArr.forEach((param) => {
        isOfflinePaiban = true
        window.webToQt.layoutFinished.connect(callBack)
        window.webToQt.layout({
          data: param.data,
          type: type,
          roll: type.includes('roll'),
        })
      })
    } else {
      isOfflinePaiban = false
      const promise = paramArr.map(({ data }) => asyncPaiban({ data, type }))
      // const promise = paramArr.map(({ data }) => getPaiban({ data, type }))
      // 发起所有材质的请求
      // Promise.all(promise).then((res) => {
      //   callBack(res)
      // })
      /** 全部任务 */
      let resArr = await Promise.all(promise)
      // callBack(resArr)

      /** 正在 padding 中的task_id */
      let paddingTaskIdList = []

      /** 记录超时的请求接口 */
      let timeOutTaskIdMap = {}

      function checkIsFinishTask(res) {
        const isFinishTask = ![3, 4, 6].includes(Number(res.code))
        /**  超时不超过3次的task */
        const isTimeOutEnd =
          res.code == 6
            ? timeOutTaskIdMap[res.data.task_id] &&
              timeOutTaskIdMap[res.data.task_id] > 3
            : [3, 4].includes(Number(res.code))
            ? false
            : true

        return isFinishTask || isTimeOutEnd
      }

      const awaitPromise = new Promise(async (resolve) => {
        /** 已完成的 task */
        const resultArr = resArr.filter((res) => {
          return checkIsFinishTask(res)
        })
        // 记录超时次数
        function staticTimeOutTask(resList) {
          resList
            .filter((res) => Number(res.code) == 6)
            .forEach((res) => {
              const taskId = res.data.task_id
              if (timeOutTaskIdMap[taskId]) {
                timeOutTaskIdMap[taskId] += 1
              } else {
                timeOutTaskIdMap[taskId] = 1
              }
            })
        }
        staticTimeOutTask(resArr)

        let interval = setInterval(async () => {
          /** 已完成的 task_id 列表 */
          const resultTaskIDArr = resultArr.map(
            (res) => res.data?.task_id || res.task_id
          )
          /** 排队中的 Task */
          const waittingTaskArr = resArr.filter(
            (res) => !resultTaskIDArr.includes(res.data?.task_id || res.task_id)
          )
          /** 是否有排队中任务 */
          let hasWaittingTask = Boolean(waittingTaskArr.length)
          if (hasWaittingTask) {
            /** 不在 Padding 中的 task */
            const notPaddingTaskList = waittingTaskArr.filter(
              (re) =>
                !paddingTaskIdList.includes(re.data?.task_id || re.task_id)
            )
            /** 不在 Padding 中的任务 */
            const promiseArr = notPaddingTaskList.map((res) =>
              fetchAsyncPaiban({
                task_id: res.data?.task_id || res.task_id,
                otherPlateSize: res.otherPlateSize,
              })
            )
            /** 加入 padding 列表记录 task_id  */
            paddingTaskIdList = paddingTaskIdList.concat(
              notPaddingTaskList.map(
                (task) => task.data?.task_id || task.task_id
              )
            )

            /** 本次完成的 task 结果 */
            const newResArr = await Promise.all(promiseArr)
            /** 已完成 task_id */
            const finishTaskIdList = newResArr.map(
              (res) => res.task_id ?? res.data?.task_id
            )
            paddingTaskIdList = paddingTaskIdList.filter(
              (id) => !finishTaskIdList.includes(id)
            )
            /** 再次统计超时次数 */
            staticTimeOutTask(newResArr)
            /** 将成功的结果 push 进最终的结果列表 */
            newResArr.forEach((res) => {
              if (checkIsFinishTask(res)) resultArr.push(res)
            })
            if (resultArr.length == resArr.length) {
              clearInterval(interval)
              resolve(resultArr)
            }
          } else {
            clearInterval(interval)
            resolve(resultArr)
          }
        }, 2000)
      })

      const result = await awaitPromise

      callBack(result.map((r) => ({ data: r, status: 200 })))
    }

    function callBack(result) {
      if (isOfflinePaiban) {
        offlineRes.push(result)
        finishCount++
        if (finishCount < paramArr.length) {
          if (window.webToQt && window.webToQt.layoutFinished)
            window.webToQt.layoutFinished.disconnect(callBack)
          return
        }
      } else {
        onlineRes = result.map((res) => res.data)
      }
      const resArr = isOfflinePaiban ? offlineRes : onlineRes
      // 获取code
      const code = Math.max(...resArr.map((it) => it.code))
      // 获取板件数据
      let resultData = (isOfflinePaiban ? offlineRes : onlineRes).reduce(
        (pre, cut) => ({ ...pre, ...cut.result_data }),
        {}
      )

      if (code == 1) {
        let resultPlans = dealAlignedPlan(resultData)
        resultPlans.forEach((plan) => {
          checkPlanks(plan, minWidth, minHeight, knives)
        })
        sortPlans(resultPlans, type, scattered_layout)
        if (Array.isArray(resultPlans)) {
          resultData = resultPlans[0]
        }
        // 玻璃切割机需要将数据保存，用作方案切换
        let glassResult, allResult
        if (!(customPlankOrder && scattered_layout)) {
          glassResult = allResult = _.cloneDeep(resultPlans).map((it, idx) => {
            checkPlanks(it, minWidth, minHeight, knives)
            const obj = {
              specialId: new Date().getTime() + (idx + 1),
              data: it,
              isGreatPlan: it.isGreatPlan,
            }
            return obj
          })
          if (!isJinxiPaiban) {
            store.commit('paibanStore/setPaibanDataCollect', glassResult)
          }
          store.commit('paibanStore/setSubPaibanDataCollect', allResult)
          // 排版后将预处理数据保存，精细排版的重新排版不需要保存预处理数据
          store.commit(
            'paibanStore/setPrePaibanData',
            JSON.parse(JSON.stringify(preData))
          )
        }
        // 处理排版数据
        paibanDataToView(
          preData,
          resultData,
          needSave,
          isHistory,
          needMorePlan
        ).then((paibanData) => {
          resolve(paibanData)
          // 玻璃切割机保存第一个排版后处理的数据
          let specialId
          if (!(customPlankOrder && scattered_layout)) {
            specialId = glassResult[0].specialId
            if (!isJinxiPaiban) {
              store.commit('paibanStore/setAlreadyDealDrawData', {
                specialId,
                value: JSON.parse(JSON.stringify({ paibanData })),
              })
              store.commit('paibanStore/setCurrentUsingSpecialId', specialId)
            }
            store.commit('paibanStore/setSubAlreadyDealDrawData', {
              specialId,
              value: JSON.parse(JSON.stringify({ paibanData })),
            })
            store.commit('paibanStore/setSubCurrentUsingSpecialId', specialId)

            // 精细排板不进行分散排板检测
            if (
              scattered_layout &&
              !resultData?.isGreatPlan &&
              !isJinxiPaiban
            ) {
              store.commit('setIsShowDisPaiabnConfirm', true)
            }
          }
        })
      } else if (code == 2) {
        const res = resArr.find((it) => it.code == 2)

        const errText = res.msg
          ? res.msg
          : res.message
          ? res.message
          : res.data.err_info
        // Vue.prototype.$message.error(errText)
        if (typeof errText == 'string' && errText.includes('排版错误')) {
          resolve({ isPaibanErr: true })
        } else if (typeof errText == 'object') {
          resolve({
            message: errText,
            text: translate('arrangedPage.overSizeTip'),
            isErr: true,
          })
        } else if (errText.includes('存在过大板件')) {
          resolve({
            text: (isReLayout ? '重新排版未生效，' : '') + errText,
            isOverSize: true,
          })
        } else {
          Vue.prototype.$message.error(errText)
        }
        resolve(false)
      } else {
        let content
        if (preData.length === 0) {
          content = translate('arrangedPage.uselessPart')
        } else {
          content = translate('arrangedPage.splitArranged')
        }
        Vue.prototype.$antdConfirm({
          title: translate('common.tip'),
          content: content,
          okText: translate('common.confirm'),
          cancelText: translate('common.cancel'),
          centered: true,
        })
        resolve(false)
      }

      if (window.webToQt && window.webToQt.layoutFinished)
        window.webToQt.layoutFinished.disconnect(callBack)
    }
  })
}
// 判断一个方案是否是稳妥的
function checkPlanks(plan, minWidth, minHeight, knives) {
  const minSize = Math.min(minWidth, minHeight)
  const maxSize = Math.max(minWidth, minHeight)
  let group = {}
  Object.entries(plan).forEach(([material, item]) => {
    const parts = item.data[0]
    const groupTemp = parts.reduce((acc, part) => {
      const {
        stockNum,
        rect: { x1, x2, y1, y2 },
      } = part
      const width = Math.abs(x2 - x1) - knives
      const height = Math.abs(y2 - y1) - knives
      const max = Math.max(width, height)
      const min = Math.min(width, height)
      if (!acc[`${material}-${stockNum}`]) {
        acc[`${material}-${stockNum}`] = {
          stockNum,
          isGreatPlank: false,
        }
      }
      if (max >= maxSize && min >= minSize) {
        acc[`${material}-${stockNum}`].isGreatPlank = true
      }
      return acc
    }, {})
    group = { ...group, ...groupTemp }
  })
  plan.isGreatPlan = Object.values(group).every((item) => {
    return item.isGreatPlank
  })
  plan.greatPlankNum = Object.values(group).filter(
    (item) => item.isGreatPlank
  ).length
  Object.defineProperty(plan, 'isGreatPlan', {
    enumerable: false,
  })
  Object.defineProperty(plan, 'greatPlankNum', {
    enumerable: false,
  })
}

/**
 *
 * @param {*} resultPlans 排版方案
 * @param {*} type 排版类别，主要判断是不是少翻板
 * @param {*} scattered_layout 分散排版4
 */
// 对排版方案进行排序
function sortPlans(resultPlans, type, scattered_layout) {
  // 提取计算平均使用率的函数
  const calculateAverageRate = (plan) => {
    const rates = Object.values(plan).map((m) => {
      const avgRate =
        m.used_rate.reduce((sum, r) => sum + r.rate, 0) / m.used_rate.length
      return avgRate
    })
    return (
      (rates.reduce((sum, rate) => sum + rate, 0) / rates.length) *
      100
    ).toFixed(2)
  }

  // 获取最大板号的使用率
  const getMaxStockNumRate = (plan) => {
    const usedRates = Object.values(plan)[0].used_rate
    const maxStockNum = Math.max(...usedRates.map((item) => item.stock_num))
    return usedRates.find((item) => item.stock_num === maxStockNum).rate
  }

  // 获取第一块板的数量
  const getRollCount = (plan) => Object.values(plan)[0].rollCount[0]

  // 获取大板数量
  const getBigPlankNum = (plan) => Object.values(plan)[0].stockCount[0]

  resultPlans.sort((a, b) => {
    // 分散排版时优先考虑isGreatPlan
    if (scattered_layout && a.isGreatPlan !== b.isGreatPlan) {
      return b.isGreatPlan - a.isGreatPlan
    }

    // 少翻版模式
    if (type === 'paiban_roll') {
      return getRollCount(a) - getRollCount(b)
    }

    const rateA = calculateAverageRate(a)
    const rateB = calculateAverageRate(b)

    if (rateA !== rateB) {
      return rateB - rateA
    }
    const stockCountA = getBigPlankNum(a)
    const stockCountB = getBigPlankNum(b)
    if (stockCountA === stockCountB) {
      // 第一块板数量相同时，按最大板号的使用率升序
      return getMaxStockNumRate(a) - getMaxStockNumRate(b)
    }
    return b.greatPlankNum - a.greatPlankNum
  })
}
// 判断孔槽是否冲突
function checkAllPlankHoleSlot(paibanArr) {
  for (let i = 0; i < paibanArr.length; ++i) {
    for (let k = 0; k < paibanArr[i].parts.length; ++k) {
      // 记录是否板件孔槽冲突
      paibanArr[i].parts[k].holeSlotMerge = false
      checkPlankDepth(paibanArr[i].parts[k])
      checkPlankHoleSlot(paibanArr[i].parts[k])
    }
  }
}
//  孔槽深度判断
function checkPlankDepth(part) {
  const holes = part.holes ? part.holes : []
  const slots = part.slots ? part.slots : []
  const handleSlopes = part.handleSlopes ? part.handleSlopes : []
  const thick = part.thick
  let holeDepthError, slotDepthError, slopeDepthError
  const { cutZ } = store.state.ncSetting
  const _deep = Number(thick) + Number(cutZ)
  // const _deep = 1 //测试值
  // 孔槽深度 > 板件厚度 + 切透值 即为异常 deep > thick + cutZ
  // 为每个孔槽添加深度异常信息
  holes.forEach((hole) => {
    hole.depthError = hole.deep > _deep
  })
  slots.forEach((slot) => {
    slot.depthError = slot.deep > _deep
  })
  handleSlopes.forEach((slope) => {
    slope.depthError = slope.deep > _deep
  })
  holeDepthError = holes.some((hole) => hole.depthError)
  slotDepthError = slots.some((slot) => slot.depthError)
  slopeDepthError = handleSlopes.some((slope) => slope.depthError)
  // 判断整个小板是否有孔槽深度异常
  part.depthError = holeDepthError || slotDepthError || slopeDepthError
}
function holeSlotsFocus(paibanData, ncSetting) {
  let holeFocus = ncSetting.holeConcentrated
    ? ncSetting.holeConcentrated
    : 'front'
  let slotFocus = ncSetting.apertureSlotConcentrated
    ? ncSetting.apertureSlotConcentrated
    : 'front'
  // 测试，深拷贝，不影响正常数据
  // paibanData = JSON.parse(JSON.stringify(paibanData))
  for (let i = 0; i < paibanData.length; i++) {
    let parts = paibanData[i].parts
    for (let j = 0; j < parts.length; j++) {
      reversalPart(parts[j], holeFocus, slotFocus)
    }
  }
}
function reversalPart(part, holeFocus, slotFocus) {
  holeFocus = holeFocus == 'front' ? true : false
  slotFocus = slotFocus == 'front' ? true : false
  let slots = part.slots ? part.slots : []
  let holes = part.holes ? part.holes : []
  let zSlots = slots.filter((item) => item.side == 1).length
  let fSlots = slots.filter((item) => item.side == -1).length
  let zHoles = holes.filter((item) => item.side == 1).length
  let fHoles = holes.filter((item) => item.side == -1).length
  // 是否翻面
  let flag = false
  // 有槽的时候只根据槽判断是否翻面
  if (slots.length && fSlots != zSlots) {
    if (slotFocus) {
      if (fSlots > zSlots) {
        flag = true
      }
    } else {
      if (zSlots > fSlots) {
        flag = true
      }
    }
  } else {
    // 没有槽根据空做判断
    if (holeFocus) {
      if (fHoles > zHoles) {
        flag = true
      }
    } else {
      if (zHoles > fHoles) {
        flag = true
      }
    }
  }
  if (flag) {
    let plank = part
    let rect = plank['rect']
    let holes = plank['holes']
    let sholes = plank['sholes']
    let slots = plank['slots']
    let handleSlopes = plank['handleSlopes'] ?? []
    let sslots = plank['sslots']
    let edgeInfo = plank['edgeInfo']
    let lightEdgeInfo = plank['lightEdgeInfo']
    let thick = plank['thick']
    let oRect = plank['oRect']
    let oheight = oRect['height']
    let owidth = oRect['width']
    holes.forEach((it) => {
      it.side = -it.side
      it.center = { x: it.center.x, y: rect.height - it.center.y }
      it.ocenter = { x: it.ocenter.x, y: oheight - it.ocenter.y }
    })
    sholes.forEach((item) => {
      let side = item.side
      let center = item.center
      let ocenter = item.ocenter
      switch (String(side)) {
        case '2':
          side = '4'
          break
        case '4':
          side = '2'
          break
      }
      center = {
        x: (center.z ? center.z : 0) - center.x,
        y: thick - center.y,
        z: center.z ? center.z : 0,
      }
      ocenter = { x: ocenter.x, y: oheight - ocenter.y }
      item.side = side
      item.center = center
      item.ocenter = ocenter
    })
    // slots
    slots.forEach((it) => {
      it.pt1 = { x: it.pt1.x, y: rect.height - it.pt1.y }
      it.opt1 = { x: it.opt1.x, y: oheight - it.opt1.y }
      it.pt2 = { x: it.pt2.x, y: rect.height - it.pt2.y }
      it.opt2 = { x: it.opt2.x, y: oheight - it.opt2.y }
      it.side = -it.side
    })
    // handleSlopes
    handleSlopes.forEach((it) => {
      it.pt1 = { x: it.pt1.x, y: rect.height - it.pt1.y }
      it.opt1 = { x: it.opt1.x, y: oheight - it.opt1.y }
      it.pt2 = { x: it.pt2.x, y: rect.height - it.pt2.y }
      it.opt2 = { x: it.opt2.x, y: oheight - it.opt2.y }
      it.side = -it.side
    })
    // sslots
    sslots.forEach((sslot) => {
      let side = sslot['side']
      let pt1 = sslot['pt1']
      let opt1 = sslot['opt1']
      let pt2 = sslot['pt2']
      let opt2 = sslot['opt2']
      switch (String(side)) {
        case '2':
          side = '4'
          break
        case '4':
          side = '2'
          break
      }
      pt1 = { x: pt1.z - pt1.x, y: pt1.y, z: pt1.z }
      opt1 = { x: opt1.x, y: oheight - opt1.y }
      pt2 = { x: pt2.z - pt2.x, y: pt2.y, z: pt2.z }
      opt2 = { x: opt2.x, y: oheight - opt2.y }
      sslot['side'] = side
      sslot['pt1'] = pt1
      sslot['opt1'] = opt1
      sslot['pt2'] = pt2
      sslot['opt2'] = opt2
    })
    // edgeInfo
    let left = edgeInfo.substring(
      edgeInfo.indexOf('←') + 1,
      edgeInfo.indexOf('↓')
    )
    let bottom = edgeInfo.substring(
      edgeInfo.indexOf('↓') + 1,
      edgeInfo.indexOf('→')
    )
    let right = edgeInfo.substring(
      edgeInfo.indexOf('→') + 1,
      edgeInfo.indexOf('↑')
    )
    let top = edgeInfo.substring(edgeInfo.indexOf('↑') + 1)
    edgeInfo = '←' + left + '↓' + top + '→' + right + '↑' + bottom
    if (!lightEdgeInfo) {
      lightEdgeInfo = '←0↓0→0↑0'
    }
    left = lightEdgeInfo.substring(
      lightEdgeInfo.indexOf('←') + 1,
      lightEdgeInfo.indexOf('↓')
    )
    bottom = lightEdgeInfo.substring(
      lightEdgeInfo.indexOf('↓') + 1,
      lightEdgeInfo.indexOf('→')
    )
    right = lightEdgeInfo.substring(
      lightEdgeInfo.indexOf('→') + 1,
      lightEdgeInfo.indexOf('↑')
    )
    top = lightEdgeInfo.substring(lightEdgeInfo.indexOf('↑') + 1)
    lightEdgeInfo = '←' + left + '↓' + top + '→' + right + '↑' + bottom
    const sh = holes.filter((hole) => isOtherSideMachineHole(hole))
    const sl = slots.filter((slot) => isOtherSideMachineSlot(slot))
    let sholeInfo = generatorSideHoleSlot([
      ...sholes,
      ...sh.filter((h) => h.side == '1'),
    ])
    let sholeInfoF = generatorSideHoleSlot(
      sh.filter((h) => h.side == '-1'),
      'upDownFlip'
    )
    let sslotInfo = generatorSideHoleSlot([
      ...sslots,
      ...sl.filter((l) => l.side == '1'),
    ])
    let sslotInfoF = generatorSideHoleSlot(
      sl.filter((l) => l.side == '-1'),
      'upDownFlip'
    )
    plank['holes'] = holes
    plank['slots'] = slots
    plank['sholes'] = sholes
    plank['sslots'] = sslots
    plank['edgeInfo'] = edgeInfo
    plank['lightEdgeInfo'] = lightEdgeInfo
    plank['sholeInfo'] = sholeInfo
    plank['sholeInfoF'] = sholeInfoF
    plank['sslotInfo'] = sslotInfo
    plank['sslotInfoF'] = sslotInfoF
    if (plank.hasOwnProperty('path')) {
      let o = { x: rect.width / 2, y: rect.height / 2 }
      let paths = plank['path']
      for (let i in paths) {
        let path = paths[i]
        for (let j in path) {
          let p = path[j]
          let x = p['x'] - o.x
          let y = p['y'] - o.y
          p['x'] = x + o.x
          p['y'] = -y + o.y
        }
      }
      plank['path'] = paths
    }
    // oPath

    if (plank.hasOwnProperty('oPath')) {
      let o = { x: owidth / 2, y: oheight / 2 }
      let paths = plank['oPath']
      for (let i in paths) {
        let path = paths[i]
        for (let j in path) {
          let p = path[j]
          let x = p['x'] - o.x
          let y = p['y'] - o.y
          p['x'] = x * 1 + o.x
          p['y'] = -y + o.y
        }
      }
      plank['oPath'] = paths
    }
    // cutCurve
    if (plank.hasOwnProperty('cutCurve') && plank['cutCurve']) {
      let o = { x: rect.width / 2, y: rect.height / 2 }
      let lastB = -1000
      let cutCurve = plank['cutCurve']
      cutCurve.reverse()
      for (let j in cutCurve) {
        let p = cutCurve[j]
        let x = p['x'] - o.x
        let y = p['y'] - o.y
        p['x'] = x + o.x
        p['y'] = -y + o.y
        let tmpB = lastB
        if (p.hasOwnProperty('b')) {
          lastB = p['b']
        } else {
          lastB = -1000
        }
        if (tmpB !== -1000) {
          p['b'] = tmpB
        } else {
          delete p['b']
        }
      }
      if (lastB !== -1000) {
        cutCurve[0]['b'] = lastB
      }
      let cutCurveEx = plank['cutCurveEx']
      for (let i in cutCurveEx) {
        let curveEx = cutCurveEx[i]
        lastB = -1000
        curveEx.reverse()
        for (let j in curveEx) {
          p = curveEx[j]
          x = p['x'] - o.x
          y = p['y'] - o.y
          p['x'] = x + o.x
          p['y'] = -y + o.y
          tmpB = lastB
          if (p.hasOwnProperty('b')) {
            lastB = p['b']
          } else {
            lastB = -1000
          }
          if (tmpB !== -1000) {
            p['b'] = tmpB
          } else {
            delete p['b']
          }
        }
        if (lastB !== -1000) {
          curveEx[0]['b'] = lastB
        }
      }
    }
    // realCurve
    if (plank.hasOwnProperty('realCurve') && plank['realCurve']) {
      let o = { x: owidth / 2, y: oheight / 2 }
      let lastB = -1000
      let path = plank['realCurve']
      path.reverse()
      for (let i in path) {
        let p = path[i]
        let x = p['x'] - o.x
        let y = p['y'] - o.y
        p['x'] = x * 1 + o.x
        p['y'] = -y + o.y
        let tmpB = lastB
        if (p.hasOwnProperty('b')) {
          lastB = p['b']
        } else {
          lastB = -1000
        }
        if (tmpB !== -1000) {
          p['b'] = tmpB
        } else {
          delete p['b']
        }
      }
      if (lastB !== -1000) {
        path[0]['b'] = lastB
      }
      plank['realCurve'] = path
      let realCurveEx = plank['realCurveEx']
      for (let i in realCurveEx) {
        let curveEx = realCurveEx[i]
        lastB = -1000
        curveEx.reverse()
        for (let j in curveEx) {
          p = curveEx[j]
          x = p['x'] - o.x
          y = p['y'] - o.y
          p['x'] = x + o.x
          p['y'] = -y + o.y
          tmpB = lastB
          if (p.hasOwnProperty('b')) {
            lastB = p['b']
          } else {
            lastB = -1000
          }
          if (tmpB !== -1000) {
            p['b'] = tmpB
          } else {
            delete p['b']
          }
        }
        if (lastB !== -1000) {
          curveEx[0]['b'] = lastB
        }
      }
    }
  }
  // if(flag){
  //   for(let j = 0; j < part.holes.length; ++j){
  //     let hole = part.holes[j]
  //     hole.center.x = part.rect.width - hole.center.x
  //     hole.ocenter.x = part.oRect.width - hole.ocenter.x
  //     hole.side = -hole.side
  //   }
  //   // 处理拉槽
  //   for(let j = 0; j < part.slots.length; ++j){
  //     let slot = part.slots[j]
  //     slot.pt1.x = part.rect.width - slot.pt1.x
  //     slot.pt2.x = part.rect.width - slot.pt2.x
  //     slot.opt1.x = part.oRect.width - slot.opt1.x
  //     slot.opt2.x = part.oRect.width - slot.opt2.x
  //     slot.side = -slot.side
  //   }
  //   // 处理异形路径
  //   if(part.path){
  //     for(let j = 0; j < part.path.length; j++){
  //       for(let k = 0; k < part.path[j].length; k++){
  //         let point = part.path[j][k]
  //         point.x = part.rect.width - point.x
  //       }
  //     }
  //     for(let j = 0; j < part.oPath.length; j++){
  //       for(let k = 0; k < part.oPath[j].length; k++){
  //         let point = part.oPath[j][k]
  //         point.x = part.oRect.width - point.x
  //       }
  //     }
  //   }
  // }
}
// function genSHoleInfo(sholes, isFront = true) {
//   var holeHash = {}
//   for (let i in sholes) {
//     let shole = sholes[i]
//     let side = shole['cSide'] ? shole['cSide'] : shole['side']
//     if (!holeHash.hasOwnProperty(side)) {
//       holeHash[side] = []
//     }
//     holeHash[side].push(shole)
//   }
//   let sholeInfo = isFront ? '' : '翻面'

//   if (isFront) {
//     Object.keys(holeHash).forEach(function (side, i) {
//       switch (side) {
//         case '1':
//           sholeInfo += i + 1 + '←'
//           break
//         case '2':
//           sholeInfo += i + 1 + '↓'
//           break
//         case '3':
//           sholeInfo += i + 1 + '→'
//           break
//         case '4':
//           sholeInfo += i + 1 + '↑'
//           break
//         default:
//           break
//       }
//     })
//   } else {
//     Object.keys(holeHash).forEach(function (side, i) {
//       switch (side) {
//         case '1':
//           sholeInfo += i + 1 + '←'
//           break
//         case '4':
//           sholeInfo += i + 1 + '↓'
//           break
//         case '3':
//           sholeInfo += i + 1 + '→'
//           break
//         case '2':
//           sholeInfo += i + 1 + '↑'
//           break
//         default:
//           break
//       }
//     })
//   }
//   return sholeInfo
// }

// // 构造侧槽顺序
// function genSSlotInfo(sslots, isFront = true) {
//   let slotHash = {}
//   for (let i in sslots) {
//     let sslot = sslots[i]
//     let side = sslot['cSide'] ? sslot['cSide'] : sslot['side']
//     if (!slotHash.hasOwnProperty(side)) {
//       slotHash[side] = []
//     }
//     slotHash[side].push(sslot)
//   }
//   let sslotInfo = isFront ? '' : '翻面'

//   if (isFront) {
//     Object.keys(slotHash).forEach(function (side, i) {
//       switch (side) {
//         case '1':
//           sslotInfo += i + 1 + '←'
//           break
//         case '2':
//           sslotInfo += i + 1 + '↓'
//           break
//         case '3':
//           sslotInfo += i + 1 + '→'
//           break
//         case '4':
//           sslotInfo += i + 1 + '↑'
//           break
//         default:
//           break
//       }
//     })
//   } else {
//     Object.keys(slotHash).forEach(function (side, i) {
//       switch (side) {
//         case '1':
//           sslotInfo += i + 1 + '←'
//           break
//         case '4':
//           sslotInfo += i + 1 + '↓'
//           break
//         case '3':
//           sslotInfo += i + 1 + '→'
//           break
//         case '2':
//           sslotInfo += i + 1 + '↑'
//           break
//         default:
//           break
//       }
//     })
//   }
//   return sslotInfo
// }
// 判断单个板件是否存在孔槽冲突
// 成功判断出冲突依旧需要继续判断，收集其他冲突孔槽
export function checkPlankHoleSlot(plank) {
  let slotLen = plank.slots.length
  let holeLen = plank.holes.length
  // 记录板件厚度
  let thick = plank.thick
  // 记录起始点
  let { startX, startY } = plank
  // 处理板件边框点位
  let flag = true
  dealPlankPoly(plank)
  // 先判断槽是否在大板外侧, 同时处理槽的轮廓数据
  if (slotLen > 0) {
    for (let i = 0; i < slotLen; ++i) {
      let slot = plank.slots[i]
      dealSlotPoly(slot, startX, startY)
      flag = polyContain([plank.polyArr[0]], slot.polyArr)
      if (!flag) {
        plank.holeSlotMerge = true
        genHoleSlotConflictInfo(slot, 'beyond', null, 'slot')
        // return
      }
    }
  }
  // 判断孔是否冲突
  if (holeLen > 0) {
    // 判断孔是否在大板外面
    for (let i = 0; i < holeLen; ++i) {
      // 孔以圆心为中心点, 描绘出一个长度为直径的正方形点位
      let hole = plank.holes[i]
      let point = hole.center
      let radius = hole.diameter / 2
      let holeUniqueId = hole._conflict_uniqueId
      let holePoly = [
        [
          {
            X: toDecimal(point.x - radius + startX, 2),
            Y: toDecimal(point.y - radius + startY, 2),
          },
          {
            X: toDecimal(point.x + radius + startX, 2),
            Y: toDecimal(point.y - radius + startY, 2),
          },
          {
            X: toDecimal(point.x + radius + startX, 2),
            Y: toDecimal(point.y + radius + startY, 2),
          },
          {
            X: toDecimal(point.x - radius + startX, 2),
            Y: toDecimal(point.y + radius + startY, 2),
          },
        ],
      ]
      // 判断孔是否超出板件
      // flag = polyContain(plank.polyArr, holePoly)
      // 判断孔的中心点是否超出板件
      const pt = { X: point.x + startX, Y: point.y + startY }
      flag = checkPointInPart(pt, plank.polyArr)
      if (!flag) {
        plank.holeSlotMerge = true
        genHoleSlotConflictInfo(hole, 'beyond', null, 'slot')
        // return
      }

      // 判断孔是否和孔冲突
      for (let k = 0; k < holeLen; ++k) {
        if (i == k) continue
        let _hole = plank.holes[k]
        // 如果两个孔是否除方向以外其余属性完全一致, 则判断为对穿孔, 忽略这种情况下的孔冲突
        let a = +hole.deep == +_hole.deep
        let b =
          compareTowNum(
            toDecimal(hole.ocenter.x, 2),
            toDecimal(_hole.ocenter.x, 2),
            '='
          ) &&
          compareTowNum(
            toDecimal(hole.ocenter.y, 2),
            toDecimal(_hole.ocenter.y, 2),
            '='
          )
        let c =
          compareTowNum(
            toDecimal(hole.center.x, 2),
            toDecimal(_hole.center.x, 2),
            '='
          ) &&
          compareTowNum(
            toDecimal(hole.center.y, 2),
            toDecimal(_hole.center.y, 2),
            '='
          )
        let d = hole.diameter == _hole.diameter
        let e = hole.symbol == _hole.symbol
        if (a && b && c && d && e) continue
        // 处理其他点位的孔点位
        let _point = _hole.center
        let _radius = _hole.diameter / 2
        let _holePoly = [
          [
            {
              X: toDecimal(_point.x - _radius + startX, 2),
              Y: toDecimal(_point.y - _radius + startY, 2),
            },
            {
              X: toDecimal(_point.x + _radius + startX, 2),
              Y: toDecimal(_point.y - _radius + startY, 2),
            },
            {
              X: toDecimal(_point.x + _radius + startX, 2),
              Y: toDecimal(_point.y + _radius + startY, 2),
            },
            {
              X: toDecimal(_point.x - _radius + startX, 2),
              Y: toDecimal(_point.y + _radius + startY, 2),
            },
          ],
        ]
        // 如果两个孔一个在正面, 一个在反面则需要先判断厚度, 再判断是否相交
        if (_hole.side != hole.side) {
          let holeThick = Number(hole.deep) + Number(_hole.deep)
          // 如果两个孔的深度加起来大于了板件厚度, 则需要判断是否重叠了
          if (holeThick > thick) {
            // flag = polyIntersect(holePoly, _holePoly)
            const h1 = { ...point, diameter: hole.diameter }
            const h2 = { ..._point, diameter: _hole.diameter }
            flag = holeIsIntersect(h1, h2)
            if (!flag) {
              plank.holeSlotMerge = true
              genHoleSlotConflictInfo(hole, 'differentIntersect', _hole, 'hole')
              genHoleSlotConflictInfo(_hole, 'differentIntersect', hole, 'hole')
              return
            }
          }
          continue
        }
        // 如果两个孔方向一致, 则直接判断是否相交
        if (hole.side == _hole.side) {
          // flag = polyIntersect(holePoly, _holePoly)
          const h1 = { ...point, diameter: hole.diameter }
          const h2 = { ..._point, diameter: _hole.diameter }
          flag = holeIsIntersect(h1, h2)
          if (!flag) {
            plank.holeSlotMerge = true
            genHoleSlotConflictInfo(hole, 'identicalIntersect', _hole, 'hole')
            genHoleSlotConflictInfo(_hole, 'identicalIntersect', hole, 'hole')
            // return
          }
        }
      }
      // 判断孔和槽之间是否冲突
      if (slotLen > 0) {
        for (let k = 0; k < slotLen; ++k) {
          let slot = plank.slots[k]
          let slotUniqueId = slot._conflict_uniqueId

          // 如果孔和槽都为拉直器, 则忽略此情况的孔槽冲突
          if (hole.symbol == 'STRENTCH' && slot.symbol == 'STRENTCH') continue
          // 乐扣槽忽略冲突
          if (
            hole.symbol &&
            hole.symbol.indexOf('WS') > -1 &&
            slot.symbol &&
            slot.symbol.indexOf('WS') > -1 &&
            holeUniqueId == slotUniqueId
          ) {
            continue
          }
          // 如果孔和槽不在同一面, 则先判断厚度是否超出板件厚度, 再进行相交判断
          if (hole.side != slot.side) {
            let holeSlotThick = Number(hole.deep) + Number(slot.deep)
            if (holeSlotThick > thick) {
              flag = polyIntersect(holePoly, slot.polyArr)
              if (!flag) {
                plank.holeSlotMerge = true
                genHoleSlotConflictInfo(
                  hole,
                  'differentIntersect',
                  slot,
                  'slot'
                )
                genHoleSlotConflictInfo(
                  slot,
                  'differentIntersect',
                  hole,
                  'hole'
                )
              }
            }
            continue
          } else {
            flag = polyIntersect(holePoly, slot.polyArr)
            if (!flag) {
              plank.holeSlotMerge = true
              genHoleSlotConflictInfo(hole, 'identicalIntersect', slot, 'slot')
              genHoleSlotConflictInfo(slot, 'identicalIntersect', hole, 'hole')
              // return
            }
          }
        }
      }
    }
  }
}
// 生成孔槽的冲突信息，只用于详细的展示
function genHoleSlotConflictInfo(
  holeSlot,
  conflictType,
  conflictObj,
  conflictObjType
) {
  const newConflictObj = { ...conflictObj }
  // 需要删除原对象上存储的冲突信息，由于页面显示是不需要这个参数的，但是此处拷贝会拷贝到所有的信息那么冲突套冲突的话内存会崩溃
  Reflect.deleteProperty(newConflictObj, 'conflict')
  const obj = JSON.parse(
    JSON.stringify({
      isConflict: true,
      conflictType: conflictType,
      conflictObj: newConflictObj,
      conflictObjType: conflictObjType,
    })
  )
  if (holeSlot.conflict) {
    if (
      !holeSlot.conflict.find(
        (it) =>
          it.conflictObj?._conflict_uniqueId === conflictObj?._conflict_uniqueId
      )
    ) {
      holeSlot.conflict.push(obj)
    }
  } else {
    holeSlot.conflict = [obj]
  }
}
// 处理拉槽轮廓数据
export function dealSlotPoly(slot, startX, startY, isSave = true) {
  let { pt1, pt2 } = slot
  const precision = 0.0001
  let slotPointArr = []
  // 判断是
  if (Math.abs(pt1.x - pt2.x) <= precision) {
    if (pt1.y < pt2.y) {
      slotPointArr = [
        {
          X: toDecimal(startX + pt1.x - slot.width / 2, 2),
          Y: toDecimal(startY + pt1.y, 2),
        },
        {
          X: toDecimal(startX + pt1.x + slot.width / 2, 2),
          Y: toDecimal(startY + pt1.y, 2),
        },
        {
          X: toDecimal(startX + pt1.x + slot.width / 2, 2),
          Y: toDecimal(startY + pt1.y + Math.abs(pt1.y - pt2.y), 2),
        },
        {
          X: toDecimal(startX + pt1.x - slot.width / 2, 2),
          Y: toDecimal(startY + pt1.y + Math.abs(pt1.y - pt2.y), 2),
        },
      ]
    } else {
      slotPointArr = [
        {
          X: toDecimal(startX + pt2.x - slot.width / 2, 2),
          Y: toDecimal(startY + pt2.y, 2),
        },
        {
          X: toDecimal(startX + pt2.x + slot.width / 2, 2),
          Y: toDecimal(startY + pt2.y, 2),
        },
        {
          X: toDecimal(startX + pt2.x + slot.width / 2, 2),
          Y: toDecimal(startY + pt2.y + Math.abs(pt1.y - pt2.y), 2),
        },
        {
          X: toDecimal(startX + pt2.x - slot.width / 2, 2),
          Y: toDecimal(startY + pt2.y + Math.abs(pt1.y - pt2.y), 2),
        },
      ]
    }
  }
  if (Math.abs(pt1.y - pt2.y) <= precision) {
    if (pt1.x < pt2.x) {
      slotPointArr = [
        {
          X: toDecimal(startX + pt1.x, 2),
          Y: toDecimal(startY + pt1.y - slot.width / 2, 2),
        },
        {
          X: toDecimal(startX + pt1.x + Math.abs(pt1.x - pt2.x), 2),
          Y: toDecimal(startY + pt1.y - slot.width / 2, 2),
        },
        {
          X: toDecimal(startX + pt1.x + Math.abs(pt1.x - pt2.x), 2),

          Y: toDecimal(startY + pt1.y + slot.width / 2, 2),
        },
        {
          X: toDecimal(startX + pt1.x, 2),

          Y: toDecimal(startY + pt1.y + slot.width / 2, 2),
        },
      ]
    } else {
      slotPointArr = [
        {
          X: toDecimal(startX + pt2.x, 2),

          Y: toDecimal(startY + pt2.y - slot.width / 2, 2),
        },
        {
          X: toDecimal(startX + pt2.x + Math.abs(pt2.x - pt1.x), 2),

          Y: toDecimal(startY + pt2.y - slot.width / 2, 2),
        },
        {
          X: toDecimal(startX + pt2.x + Math.abs(pt2.x - pt1.x), 2),

          Y: toDecimal(startY + pt2.y + slot.width / 2, 2),
        },
        {
          X: toDecimal(startX + pt2.x, 2),

          Y: toDecimal(startY + pt2.y + slot.width / 2, 2),
        },
      ]
    }
  }
  if (isSave) {
    slot.polyArr = [slotPointArr]
  }
  return slotPointArr
}

// 判断板件冲突
function checkPlank(paibanData) {
  // 计算大板轮廓的点位
  for (let i = 0; i < paibanData.length; ++i) {
    dealBigPlank(paibanData[i])
    // 判断每一个大板的冲突情况, 单独写一个方法是为了后续拖动板件后判断单个大板的情况
    checkOneBigpart(paibanData[i])
  }
}

// 判断单个大板的板件冲突情况, 用于板件旋转
export function checkOneBigpart(bigpart) {
  // 记录是否产生过冲突
  let isConflict = false
  for (let k = 0; k < bigpart.parts.length; ++k) {
    let plank = bigpart.parts[k]
    // 判断一个小板和大板, 其他小板的冲突情况
    let flag = checkOnePlank(plank, bigpart)
    plank.plankMerge = !flag
    // 冲突
    if (!isConflict && !flag) {
      isConflict = true
    } else {
      isConflict = false
    }
  }
  return isConflict
}

// 判断单个板件是否和大板冲突, 用于板件拖动和键盘控制板件
export function checkOnePlank(plank, bigpart) {
  let bigPlank = []
  // if (bigpart.bigPlank) {
  //   bigPlank = bigpart.bigPlank
  // } else {
  dealBigPlank(bigpart)
  bigPlank = bigpart.bigPlank
  // }
  // isMerge为false时, 代表板件出现冲突
  let isMerge = false
  if (!checkPlankOutlineKey(plank)) {
    dealPlankPoly(plank)
  }
  // 大板边框轮廓
  const [x1, y1, x2, y2] = getPathMinMaxPoint(
    bigPlank[0].map((it) => ({ x: it.X, y: it.Y }))
  )
  const { _plankBoundBox: currentPlankBoundBox } = plank
  // 处理处理了板件轮廓这个对象必然有值
  const currentPlankBoundBoxFirst = currentPlankBoundBox[0]
  let bigPlankContainPlank = true
  // 是否是L形的余料
  if (isLShapeSurplusBigPlank(bigpart)) {
    const plankPath = rectToPath(currentPlankBoundBoxFirst).map((it) => ({
      X: it.x,
      Y: it.y,
    }))
    bigPlankContainPlank = polyContain(bigPlank, [plankPath])
    // 如果判断存在冲突则在使用完整的路径进行二次判断
    if (!bigPlankContainPlank && plank.path) {
      bigPlankContainPlank = polyContain(bigPlank, [plank.polyArr[0]])
    }
  } else {
    const { rect1ContainsRect2 } = isRectangleContained(
      { x1, y1, x2, y2 },
      currentPlankBoundBoxFirst
    )
    bigPlankContainPlank = rect1ContainsRect2
  }

  // 如果大板轮廓完全包含小板则不再进行判断小板是不是在大板轮廓内
  if (bigPlankContainPlank) {
    isMerge = true
  } else {
    // 当大板不完全包含小板则判断是否存在碰撞，存在碰撞直接返回冲突状态
    const { x1: px1, y1: py1, x2: px2, y2: py2 } = currentPlankBoundBoxFirst
    const flag = judgeRectIntersect(
      { x1, y1, x2, y2 },
      { x1: px1, y1: py1, x2: px2, y2: py2 }
    )
    if (flag) return isMerge
  }
  if (!isMerge) {
    // 当逻辑走到此处说明小板已在大板外，不做后面的处理
    return isMerge
  }
  // 获取当前板件的轮廓
  const plank1Outline = plank._plankBoundBox[0]
  for (const _plank of bigpart.parts) {
    // 当前循环板件如果和需要判断的板件相同则不比较
    if (_plank.index === plank.index) continue
    if (!checkPlankOutlineKey(_plank)) {
      dealPlankPoly(_plank)
    }
    const plank21Outline = _plank._plankBoundBox[0]
    // 预先判断两个盒子的边界情况，如边界不存在任何相交可能则不再进行下一步操作
    if (!judgeRectIntersect(plank1Outline, plank21Outline)) continue
    // 边界已出现碰撞，进行更加精细的判断板件是否相交
    isMerge = polyIntersect([plank.polyArr[0]], [_plank.polyArr[0]])
    // // 返回值为true说明板件并未出现相交的情况，跳过这次对比
    if (isMerge) continue
    // 判断两个板件是否出现互相容纳
    const { isContain } = isRectangleContained(
      currentPlankBoundBoxFirst,
      _plank._plankBoundBox[0]
    )
    // 如果两个板件只是碰撞并未出现容纳状态直接返回冲突状态
    if (!isContain) return isMerge
    // 执行到此处说明两个小板出现了碰撞且出现了容纳,则继续判断两个板件内轮廓和外轮廓的关系
    isMerge =
      judgePart1ContainPart2(plank, _plank) ||
      judgePart1ContainPart2(_plank, plank)
    // 如出现内轮廓冲突则抛出冲突状态，不在检查剩余板件
    if (!isMerge) return
  }
  return isMerge
}

/**
 * @description 为板件生成外/内部轮廓以及边界框,包含长圆孔(coverHoles)
 * @param {array} path 路径
 * @param {object} ncSetting NC设置
 */
function genPlankOutLineOrBoundBox(path, startX, startY, ncSetting, up = true) {
  const { decimal } = ncSetting
  // 采用什么字段作为key
  const [xField, yField] = up ? ['X', 'Y'] : ['x', 'y']
  // 板件轮廓
  let x1 = Infinity,
    y1 = Infinity,
    x2 = -Infinity,
    y2 = -Infinity

  const newPath = path.map((point) => {
    const x = toDecimal(startX + point.x, decimal)
    const y = toDecimal(startY + point.y, decimal)
    x1 = Math.min(x1, x)
    y1 = Math.min(y1, y)
    x2 = Math.max(x2, x)
    y2 = Math.max(y2, y)

    return { [xField]: x, [yField]: y }
  })
  return {
    path: newPath,
    boundBox: { x1, y1, x2, y2 },
  }
}

// 处理板件轮廓点位
export function dealPlankPoly(plank, setting = null) {
  let storeSetting = setting ? setting : store
  const { decimal } = storeSetting.state.ncSetting
  let { startX, startY, curveHoles } = plank
  let plankPoly = []
  // 板件轮廓边界框信息 信息存储在板件上
  const _plankBoundBox = []
  // 长圆孔轮廓信息 信息存储在板件上
  const _curveHolesPoly = []
  // 长圆孔轮廓边界信息 信息存储在板件上
  const _curveHolesBoundBox = []
  if (curveHoles) {
    curveHoles.forEach((curveHole) => {
      if (!compareNumbers(curveHole.deep, plank.thick)) return
      const { path = [] } = curveHole
      const { path: newPath, boundBox } = genPlankOutLineOrBoundBox(
        path,
        startX,
        startY,
        storeSetting.state.ncSetting
      )
      _curveHolesPoly.push(newPath)
      _curveHolesBoundBox.push(boundBox)
    })
  }
  if (plank.path) {
    let array = []
    let area = ClipperLib.JS.AreaOfPolygon(plank.path[0])
    // 将异形路径中外轮廓点位处理成顺时针点位
    if (area < 0) {
      plank.path[0].reverse()
    }
    for (let i = 0; i < plank.path.length; ++i) {
      const { path, boundBox } = genPlankOutLineOrBoundBox(
        plank.path[i],
        startX,
        startY,
        storeSetting.state.ncSetting
      )
      array.push(path)
      _plankBoundBox.push(boundBox)
    }
    plankPoly = array
  } else {
    plankPoly = [
      [
        { X: toDecimal(startX, decimal), Y: toDecimal(startY, decimal) },
        {
          X: toDecimal(startX + plank.rect.width, decimal),
          Y: toDecimal(startY, decimal),
        },
        {
          X: toDecimal(startX + plank.rect.width, decimal),

          Y: toDecimal(startY + plank.rect.height, decimal),
        },
        {
          X: toDecimal(startX, decimal),

          Y: toDecimal(startY + plank.rect.height, decimal),
        },
      ],
    ]
    _plankBoundBox.push({
      x1: startX,
      y1: startY,
      x2: toDecimal(startX + plank.rect.width, decimal),
      y2: toDecimal(startY + plank.rect.height, decimal),
    })
  }
  plank.polyArr = plankPoly
  plank._plankBoundBox = _plankBoundBox
  plank._curveHolesPoly = _curveHolesPoly
  plank._curveHolesBoundBox = _curveHolesBoundBox
}

// 处理板件轮廓点位  todo
// export function dealPlankPoly(plank, setting = null) {
//   let storeSetting = setting ? setting : store
//   const { decimal } = storeSetting.state.ncSetting
//   let { startX, startY } = plank
//   let plankPoly = []
//   if (plank.path) {
//     let array = []
//     let area = ClipperLib.JS.AreaOfPolygon(plank.path[0])
//     // 将异形路径中外轮廓点位处理成顺时针点位
//     if (area < 0) {
//       plank.path[0].reverse()
//     }

//     for (let i = 0; i < plank.path.length; ++i) {
//       let pointArr = []
//       for (let k = 0; k < plank.path[i].length; ++k) {
//         let point = JSON.parse(JSON.stringify(plank.path[i][k]))
//         let newPoint = {
//           X: toDecimal(startX + point.x, decimal),
//           Y: toDecimal(startY + point.y, decimal),
//         }
//         pointArr.push(newPoint)
//       }
//       array.push(pointArr)
//     }
//     plankPoly = array
//   } else {
//     plankPoly = [
//       [
//         { X: toDecimal(startX, decimal), Y: toDecimal(startY, decimal) },
//         {
//           X: toDecimal(startX + plank.rect.width, decimal),
//           Y: toDecimal(startY, decimal),
//         },
//         {
//           X: toDecimal(startX + plank.rect.width, decimal),

//           Y: toDecimal(startY + plank.rect.height, decimal),
//         },
//         {
//           X: toDecimal(startX, decimal),

//           Y: toDecimal(startY + plank.rect.height, decimal),
//         },
//       ],
//     ]
//   }
//   plank.polyArr = plankPoly
// }

// 处理大板外轮廓点位
export function dealBigPlank(bigpart) {
  let ncSetting = store.state.ncSetting
  // let plankOffset = Math.abs(
  //   ncSetting.knife.diameter / 2 - ncSetting.panelSize.plankEdgeOff
  // )

  const trimSide =
    store.state.isHistoryStatus && !NOTROTATELGAP
      ? window.sessionStorage.getItem('trimSide')
      : ncSetting.trimSide
  const standardPlank = store.state.selectStandardPlank
  // 满足使用锯切，优先使用锯切刀
  const diameter = bigpart.parts.every((part) => part.isUseSaw)
    ? cutKnifeRadius * 2
    : +getPlateKnifeDiameter(bigpart.stockKey, ncSetting)
  const plankEdge =
    bigpart?.otherPlate && Object.keys(bigpart?.otherPlate).length
      ? bigpart.otherPlate.trim_edge
      : // : store.state.historyPlankEdgeOff
      bigpart.surplusInfo
      ? bigpart.surplusInfo.margin ?? standardPlank?.plankEdgeOff ?? 0
      : bigpart.margin ?? standardPlank?.plankEdgeOff ?? 0

  let plankOffset = plankEdge - diameter / 2
  let plankWidth = bigpart.plankWidth
  let plankHeight = bigpart.plankHeight
  let bigPlank = []
  // 存储板件边框减去修边值，用于计算大板剩余距离
  let bigPlankShrinkPolygon = []
  if (bigpart.surplusInfo && Object.keys(bigpart.surplusInfo).length > 0) {
    let surplusInfo = bigpart.surplusInfo
    if (surplusInfo.shape && surplusInfo.shape == 'lshape') {
      let x3 = ncSetting.xyReverse
        ? Number(surplusInfo.y5)
        : Number(surplusInfo.x3)
      let y3 = ncSetting.xyReverse
        ? Number(surplusInfo.x5)
        : Number(surplusInfo.y3)
      let x4 = ncSetting.xyReverse
        ? Number(surplusInfo.y4)
        : Number(surplusInfo.x4)
      let y4 = ncSetting.xyReverse
        ? Number(surplusInfo.x4)
        : Number(surplusInfo.y4)
      let x5 = ncSetting.xyReverse
        ? Number(surplusInfo.y3)
        : Number(surplusInfo.x5)
      let y5 = ncSetting.xyReverse
        ? Number(surplusInfo.x3)
        : Number(surplusInfo.y5)
      let width = surplusInfo.width
      let height = surplusInfo.height
      // L型余料缺口处不需要修边
      if (
        ncSetting.xyReverse &&
        (ncSetting.startPosition == '右下角' ||
          ncSetting.startPosition == '左上角')
      ) {
        x3 = width - x3
        x4 = width - x4
        x5 = width - x5
        bigPlank = [
          [
            { X: plankOffset, Y: plankOffset },
            { X: width - plankOffset, Y: plankOffset },
            { X: width - plankOffset, Y: height - plankOffset },
            { X: x3, Y: y3 - plankOffset },
            { X: x4, Y: y4 },
            { X: plankOffset, Y: y5 },
          ],
        ]
        bigPlankShrinkPolygon = [
          { x: plankEdge, y: plankEdge },
          { x: width - plankEdge, y: plankEdge },
          { x: width - plankEdge, y: height - plankEdge },
          { x: x3, y: y3 - plankEdge },
          { x: x4, y: y4 },
          { x: plankEdge, y: y5 },
        ]
      } else {
        y3 = height - y3
        y4 = height - y4
        y5 = height - y5
        // 根据修边方向（xy互换不处理 高光板只生效右下，左上修边） 设置L形余料缺口方向
        const isHighGPlank =
          bigpart.parts[0]?.is_high_gloss_plank ??
          bigpart.stockKey.includes('高光_')
        if (trimSide === 'topLeft' && !ncSetting.xyReverse && !NOTROTATELGAP) {
          bigPlank = [
            [
              { X: width - plankOffset, Y: height - plankOffset },
              { X: width - x3, Y: height - plankOffset },
              { X: width - x4, Y: height - y4 },
              { X: width - x5 + plankOffset, Y: height - y5 },
              { X: plankOffset, Y: plankOffset },
              { X: width - plankOffset, Y: plankOffset },
            ],
          ]
          bigPlankShrinkPolygon = [
            { x: width - plankEdge, y: height - plankEdge },
            { x: width - x3, y: height - plankEdge },
            { x: width - x4, y: height - y4 },
            { x: width - x5 + plankEdge, y: height - y5 },
            { x: plankEdge, y: plankEdge },
            { x: width - plankEdge, y: plankEdge },
          ]
        } else if (
          trimSide === 'bottomLeft' &&
          !ncSetting.xyReverse &&
          !NOTROTATELGAP &&
          !isHighGPlank
        ) {
          bigPlank = [
            [
              { X: width - plankOffset, Y: plankOffset },
              { X: width - x3, Y: plankOffset },
              { X: width - x4, Y: y4 },
              { X: width - x5 + plankOffset, Y: y5 },
              { X: plankOffset, Y: height - plankOffset },
              { X: width - plankOffset, Y: height - plankOffset },
            ],
          ]
          bigPlankShrinkPolygon = [
            { x: width - plankEdge, y: plankEdge },
            { x: width - x3, y: plankEdge },
            { x: width - x4, y: y4 },
            { x: width - x5 + plankEdge, y: y5 },
            { x: plankEdge, y: height - plankEdge },
            { x: width - plankEdge, y: height - plankEdge },
          ]
        } else if (
          trimSide === 'topRight' &&
          !ncSetting.xyReverse &&
          !NOTROTATELGAP &&
          !isHighGPlank
        ) {
          bigPlank = [
            [
              { X: plankOffset, Y: height - plankOffset },
              { X: x3, Y: height - plankOffset },
              { X: x4, Y: height - y4 },
              { X: x5 - plankOffset, Y: height - y5 },
              { X: width - plankOffset, Y: plankOffset },
              { X: plankOffset, Y: plankOffset },
            ],
          ]
          bigPlankShrinkPolygon = [
            { x: plankEdge, y: height - plankEdge },
            { x: x3, y: height - plankEdge },
            { x: x4, y: height - y4 },
            { x: x5 - plankEdge, y: height - y5 },
            { x: width - plankEdge, y: plankEdge },
            { x: plankEdge, y: plankEdge },
          ]
        } else {
          bigPlank = [
            [
              { X: plankOffset, Y: plankOffset },
              { X: x3, Y: plankOffset },
              { X: x4, Y: y4 },
              { X: x5 - plankOffset, Y: y5 },
              { X: width - plankOffset, Y: height - plankOffset },
              { X: plankOffset, Y: height - plankOffset },
            ],
          ]
          bigPlankShrinkPolygon = [
            { x: plankEdge, y: plankEdge },
            { x: x3, y: plankEdge },
            { x: x4, y: y4 },
            { x: x5 - plankEdge, y: y5 },
            { x: width - plankEdge, y: height - plankEdge },
            { x: plankEdge, y: height - plankEdge },
          ]
        }
      }
    } else {
      let width = surplusInfo.width
      let height = surplusInfo.height
      bigPlank = [
        [
          { X: plankOffset, Y: plankOffset },
          { X: width - plankOffset, Y: plankOffset },
          { X: width - plankOffset, Y: height - plankOffset },
          { X: plankOffset, Y: height - plankOffset },
        ],
      ]
      bigPlankShrinkPolygon = [
        { x: plankEdge, y: plankEdge },
        { x: width - plankEdge, y: plankEdge },
        { x: width - plankEdge, y: height - plankEdge },
        { x: plankEdge, y: height - plankEdge },
      ]
    }
  } else {
    bigPlank = [
      [
        { X: plankOffset, Y: plankOffset },
        { X: plankWidth - plankOffset, Y: plankOffset },
        { X: plankWidth - plankOffset, Y: plankHeight - plankOffset },
        { X: plankOffset, Y: plankHeight - plankOffset },
      ],
    ]
    bigPlankShrinkPolygon = [
      { x: plankEdge, y: plankEdge },
      { x: plankWidth - plankEdge, y: plankEdge },
      { x: plankWidth - plankEdge, y: plankHeight - plankEdge },
      { x: plankEdge, y: plankHeight - plankEdge },
    ]
  }
  bigpart.bigPlank = bigPlank
  bigpart.bigPlankShrinkPolygon = bigPlankShrinkPolygon
}

export function dealHoleSlotCloseSide(paibanData) {
  return paibanData.map((data) => {
    data.parts = dealPlankHoleSlotCSide(data.parts)
    return data
  })
}

export function dealPlankHoleSlotCSide(parts) {
  parts.forEach((part) => {
    part.holes.forEach((hole) => {
      if (
        (hole.isSide && hole.isSide == '1') ||
        (store.state.ncSetting.sideHoleDrill &&
          store.state.ncSetting.side_machine_hole_slot.HINGE &&
          hole.symbol === 'HINGE')
      ) {
        const directionArr = [
          hole.ocenter.x,
          part.rect.height - hole.ocenter.y,
          part.rect.width - hole.ocenter.x,
          hole.ocenter.y,
        ]
        const minD = Math.min(...directionArr)
        hole.cSide = `${directionArr.indexOf(minD) + 1}`
      }
    })
    part.slots.forEach((slot) => {
      if (
        (slot.isSide && slot.isSide == '1') ||
        (store.state.ncSetting.sideHoleDrill &&
          store.state.ncSetting.side_machine_hole_slot.STRENTCH &&
          ['STRENTCH', 'STRENTCHTWO', 'MIDSTRENTCH'].includes(slot.symbol))
      ) {
        const w = Math.abs(slot.pt1.x - slot.pt2.x)
        const h = Math.abs(slot.pt1.y - slot.pt2.y)
        // 横拉槽
        if (w > h) {
          const d1 = slot.pt1['y']
          const d2 = part.rect.height - slot.pt1['y']
          slot.cSide = d1 < d2 ? '4' : '2'
        } else {
          const d1 = slot.pt1['x']
          const d2 = part.rect.width - slot.pt1['x']
          slot.cSide = d1 < d2 ? '1' : '3'
        }
      }
    })
  })

  return parts
}
/**
 * 将排版接口返回数据处理成排版
 * @param { Object } preData // 预处理数据
 * @param { Object } paibanResultData // 排版接口返回数据
 * @param { Boolean } needSave // 是否需要重新保存数据
 * @param { Boolean } isHistory // 是否需要切换历史记录的状态
 * @param { Boolean | object } isPerLoad // 是否是开启预加载数据
 */
export async function paibanDataToView(
  preData,
  paibanResultData,
  needSave = true,
  isHistory = false,
  isPerLoad = false
) {
  const ncSetting = store.state.ncSetting
  const keys = Object.keys(paibanResultData)
  if (!keys.length) return
  for (let i = 0; i < keys.length; ++i) {
    for (let k = 0; k < paibanResultData[keys[i]].data[0].length; ++k) {
      let part = paibanResultData[keys[i]].data[0][k]
      if (part.rect) {
        part.rect = {
          x: part.rect.x1,
          y: part.rect.y1,
          width: part.rect.x2,
          height: part.rect.y2,
        }
      }
    }
  }
  // 彩色标签数据源判断逻辑
  // 订单 房间 柜体 任何一个数据源超过12个，则toast提示彩色标签不生效
  const judgeL = judgeSourceData(preData)
  let judgeLimit = false
  for (let key in judgeL) {
    if (judgeL[key].length > 12) {
      judgeLimit = true
    }
  }
  let colorRes = {}
  if (!judgeLimit) {
    colorRes = genColorRes(judgeL)
  }
  let finalPaibanData = await afterProcess(
    paibanResultData,
    preData,
    ncSetting,
    isPerLoad,
    colorRes
  )
  Object.keys(finalPaibanData).forEach((key) => {
    finalPaibanData[key].data.forEach((data) => {
      Object.keys(data).forEach((dataKey) => {
        data[dataKey].parts = dealPlankHoleSlotCSide(data[dataKey].parts)
      })
    })
  })
  let finalKeys = Object.keys(finalPaibanData)
  let paibanData = []
  // 记录每一块大板的优化率(这个接口的返回值, 整麻了)
  for (let i = 0; i < finalKeys.length; ++i) {
    let plankKeys = Object.keys(finalPaibanData[finalKeys[i]].data[0])
    for (let j = 0; j < plankKeys.length; ++j) {
      let bigpart = finalPaibanData[finalKeys[i]].data[0][plankKeys[j]]

      for (let k = 0; k < finalPaibanData[finalKeys[i]].used_rate.length; ++k) {
        let rate = finalPaibanData[finalKeys[i]].used_rate[k]
        if (rate.stock_num == bigpart.stockNum) {
          // 将优化率为100%的处理一下, 不要显示百分百的优化率
          if (rate.rate == 1) {
            let height = 0
            if (
              bigpart.surplusInfo &&
              Object.keys(bigpart.surplusInfo).length > 0
            ) {
              if (
                bigpart.surplusInfo.shape &&
                bigpart.surplusInfo.shape == 'lshape'
              ) {
                height = bigpart.surplusInfo.y3
              } else {
                height = bigpart.surplusInfo.height
              }
            } else {
              height = store.state.selectStandardPlank.plankHeight
            }
            let minHeight = 9999999
            let maxHeight = 0
            bigpart.parts.forEach((plank) => {
              if (plank.startY < minHeight) {
                minHeight = plank.startY
              }
              if (plank.startY + plank.rect.height > maxHeight) {
                maxHeight = plank.startY + plank.rect.height
              }
            })
            let finalHeight = Math.abs(maxHeight - minHeight)
            // 计算优化率
            let rate = 1
            if (finalHeight > height) {
              rate = 1
            } else {
              rate = finalHeight / height
            }
            if (rate == 1) {
              rate = 0.95
            }
            bigpart.usedRate = rate
          } else {
            bigpart.usedRate = rate.rate
          }
        }
      }
      bigpart.parts.forEach((part) => {
        const sh = part.holes.filter((hole) => isOtherSideMachineHole(hole))
        const sl = part.slots.filter((slot) => isOtherSideMachineSlot(slot))
        part.sholeInfo = generatorSideHoleSlot([
          ...part.sholes,
          ...sh.filter((h) => h.side == '1'),
        ])
        part.sholeInfoF = generatorSideHoleSlot(
          sh.filter((h) => h.side == '-1'),
          'upDownFlip'
        )
        part.sslotInfo = generatorSideHoleSlot([
          ...part.sslots,
          ...sl.filter((l) => l.side == '1'),
        ])
        part.sslotInfoF = generatorSideHoleSlot(
          sl.filter((l) => l.side == '-1'),
          'upDownFlip'
        )
      })
      paibanData.push(bigpart)
    }
  }
  // 记录使用的余料参数
  if (needSave) {
    let surplusObj = setSurplusObj(paibanData)
    store.commit('setSurplusParams', surplusObj)
  }
  // 靠边槽旋转
  Object.keys(finalPaibanData).forEach((key) => {
    finalPaibanData[key].data.forEach((data) => {
      Object.keys(data).forEach((dataKey) => {
        data[dataKey].parts.forEach((part) => {
          // 前封边旋转高于靠边板槽
          if (!ncSetting.xyReverse && !part.isRotateFrontEdge) {
            // 将原点重置为左下角再进行判断
            let { startX, startY, rect, plankHeight, plankWidth } = part
            if (ncSetting.startPosition === '左上角') {
              startY = plankHeight - rect.height - startY
            } else if (ncSetting.startPosition === '右上角') {
              startY = plankHeight - rect.height - startY
              startX = plankWidth - rect.width - startX
            } else if (ncSetting.startPosition === '右下角') {
              startX = plankWidth - rect.width - startX
            }
            if (
              judgeSlotIsRotate(
                part,
                ncSetting,
                startX,
                startY,
                part.plankWidth,
                part.plankHeight,
                data[dataKey].otherPlate
              )
            ) {
              rotate180Part(part)
            }
          }
        })
      })
    })
  })
  paibanData = viewDataToNCData(paibanData, 'toView')
  //  第一次生成大板外轮廓数据时，不进行L形余料缺口的旋转。也就是下方checkPlank会一层层调dealBigPlank(生成大板外轮廓的方法)，为了避免一层层传参，设了这个全局变量
  NOTROTATELGAP = true
  store.state.ncSetting.drawPlankWidth = ncSetting.xyReverse
    ? store.state.selectStandardPlank.plankHeight
    : store.state.selectStandardPlank.plankWidth
  store.state.ncSetting.drawPlankHeight = ncSetting.xyReverse
    ? store.state.selectStandardPlank.plankWidth
    : store.state.selectStandardPlank.plankHeight
  store.state.ncSetting.drawPlankEdgeOff =
    store.state.selectStandardPlank.plankEdgeOff
  // 判断孔槽冲突
  checkAllPlankHoleSlot(paibanData)
  // 判断板件冲突
  checkPlank(paibanData)
  handleLShapePart(paibanData, ncSetting)
  if (needSave) {
    if (ncSetting.sideHoleVerticalEnable) {
      store.commit('setPaibanData', dealHoleSlotCloseSide(paibanData))
    } else {
      store.commit('setPaibanData', paibanData)
    }
  }
  // 如果是历史记录重新进行了排版, 则可以允许二次保存, 取消vuex中记录的"是否为历史记录"的状态
  if (isHistory) {
    window.sessionStorage.removeItem('isHistory')
    store.commit('changeHistoryStatus', false)
    store.commit('setIsLockedRePaiban', false)
  }
  // 处理完排版数据后 排版页点击板件等时 旋转L形余料缺口 具体逻辑见dealBigPlank
  NOTROTATELGAP = false
  return paibanData
}

/**
 * 处理L形余料大板缺口旋转后小板的位置
 * @param {*} paibanData
 * @param {Object} ncSetting
 *
 * 高光板只生效修边为右上，左下的缺口旋转
 */
export function handleLShapePart(paibanData, ncSetting) {
  paibanData.forEach((bigpart) => {
    if (bigpart.surplusInfo && Object.keys(bigpart.surplusInfo).length > 0) {
      let surplus = bigpart.surplusInfo
      if (surplus.shape && surplus.shape == 'lshape') {
        let newWidth = surplus.width
        let newHeight = surplus.height
        bigpart.surplusInfo.gapDir = 'topRight'
        if (bigpart.bigPlank && NOTROTATELGAP) {
          if (
            ncSetting.trimSide === 'topRight' &&
            !ncSetting.xyReverse &&
            !bigpart.parts[0].is_high_gloss_plank
          ) {
            bigpart.bigPlank[0] = bigpart.bigPlank[0].map((point) => {
              point.Y = newHeight - point.Y
              return point
            })
            bigpart.bigPlankShrinkPolygon = bigpart.bigPlankShrinkPolygon.map(
              (point) => {
                point.y = newHeight - point.y
                return point
              }
            )
            bigpart.surplusInfo.gapDir = 'bottomRight'
          } else if (
            ncSetting.trimSide === 'bottomLeft' &&
            !ncSetting.xyReverse &&
            !bigpart.parts[0].is_high_gloss_plank
          ) {
            bigpart.bigPlank[0] = bigpart.bigPlank[0].map((point) => {
              point.X = newWidth - point.X
              return point
            })
            bigpart.bigPlankShrinkPolygon = bigpart.bigPlankShrinkPolygon.map(
              (point) => {
                point.x = newWidth - point.x
                return point
              }
            )
            bigpart.surplusInfo.gapDir = 'topLeft'
          } else if (ncSetting.trimSide === 'topLeft' && !ncSetting.xyReverse) {
            bigpart.bigPlank[0] = bigpart.bigPlank[0].map((point) => {
              point.X = newWidth - point.X
              point.Y = newHeight - point.Y
              return point
            })
            bigpart.bigPlankShrinkPolygon = bigpart.bigPlankShrinkPolygon.map(
              (point) => {
                point.x = newWidth - point.x
                point.y = newHeight - point.y
                return point
              }
            )
            bigpart.surplusInfo.gapDir = 'bottomLeft'
          }
        }
        bigpart.parts.forEach((e) => {
          if (
            ncSetting.trimSide === 'topRight' &&
            !ncSetting.xyReverse &&
            !bigpart.parts[0].is_high_gloss_plank
          ) {
            e.startY = toDecimal(
              newHeight - e.startY - e.rect.height,
              ncSetting.decimal
            )
            e.cutOrigin = e.cutOrigin.includes('Top')
              ? e.cutOrigin.replace('Top', 'Bottom')
              : e.cutOrigin.replace('Bottom', 'Top')
          } else if (
            ncSetting.trimSide === 'bottomLeft' &&
            !ncSetting.xyReverse &&
            !bigpart.parts[0].is_high_gloss_plank
          ) {
            e.startX = toDecimal(
              newWidth - e.startX - e.rect.width,
              ncSetting.decimal
            )
            e.cutOrigin = e.cutOrigin.includes('left')
              ? e.cutOrigin.replace('left', 'right')
              : e.cutOrigin.replace('right', 'left')
          } else if (ncSetting.trimSide === 'topLeft' && !ncSetting.xyReverse) {
            e.startX = toDecimal(
              newWidth - e.startX - e.rect.width,
              ncSetting.decimal
            )
            e.startY = toDecimal(
              newHeight - e.startY - e.rect.height,
              ncSetting.decimal
            )
            e.cutOrigin = e.cutOrigin.includes('left')
              ? e.cutOrigin.replace('left', 'right')
              : e.cutOrigin.replace('right', 'left')
            e.cutOrigin = e.cutOrigin.includes('Top')
              ? e.cutOrigin.replace('Top', 'Bottom')
              : e.cutOrigin.replace('Bottom', 'Top')
          }
          if (
            ncSetting.trimSide === 'topRight' &&
            !ncSetting.xyReverse &&
            !bigpart.parts[0].is_high_gloss_plank
          ) {
            if (e.path) {
              e.path = e.path.map((pathItem) => {
                pathItem = pathItem.map((point) => {
                  point.y = toDecimal(
                    e.rect.height - point.y,
                    ncSetting.decimal
                  )
                  return point
                })
                return pathItem
              })
            }

            if (e.holes && e.holes.length) {
              e.holes = e.holes.map((point) => {
                point.center.y = toDecimal(
                  e.rect.height - point.center.y,
                  ncSetting.decimal
                )
                point.ocenter.y = toDecimal(
                  e.oRect.height - point.ocenter.y,
                  ncSetting.decimal
                )
                return point
              })
            }
            if (e.slots && e.slots.length) {
              e.slots = e.slots.map((point) => {
                if (point.pt1.y !== point.pt2.y) {
                  point.pt1.y = toDecimal(
                    e.rect.height - point.pt1.y,
                    ncSetting.decimal
                  )
                  point.pt2.y = toDecimal(
                    e.rect.height - point.pt2.y,
                    ncSetting.decimal
                  )
                  point.opt1.y = toDecimal(
                    e.oRect.height - point.opt1.y,
                    ncSetting.decimal
                  )
                  point.opt2.y = toDecimal(
                    e.oRect.height - point.opt2.y,
                    ncSetting.decimal
                  )
                } else {
                  point.pt1.x = toDecimal(
                    e.rect.width - point.pt1.x,
                    ncSetting.decimal
                  )
                  point.pt2.x = toDecimal(
                    e.rect.width - point.pt2.x,
                    ncSetting.decimal
                  )
                  point.opt1.x = toDecimal(
                    e.oRect.width - point.opt1.x,
                    ncSetting.decimal
                  )
                  point.opt2.x = toDecimal(
                    e.oRect.width - point.opt2.x,
                    ncSetting.decimal
                  )
                }
                return point
              })
            }
            if (e.millInfo && e.millInfo.length) {
              e.millInfo = e.millInfo.map((millInfoItem) => {
                millInfoItem.shape = millInfoItem.shape.map((point) => {
                  point.y = toDecimal(
                    e.rect.height - point.y,
                    ncSetting.decimal
                  )
                  return point
                })
                return millInfoItem
              })
            }
            if (e.curveHoles && e.curveHoles.length) {
              e.curveHoles = e.curveHoles.map((curveHItem) => {
                curveHItem.path = curveHItem.path.map((point) => {
                  point.y = toDecimal(
                    e.rect.height - point.y,
                    ncSetting.decimal
                  )
                  return point
                })
                return curveHItem
              })
            }
          } else if (
            ncSetting.trimSide === 'bottomLeft' &&
            !ncSetting.xyReverse &&
            !bigpart.parts[0].is_high_gloss_plank
          ) {
            if (e.path) {
              e.path = e.path.map((pathItem) => {
                pathItem = pathItem.map((point) => {
                  point.x = toDecimal(e.rect.width - point.x, ncSetting.decimal)
                  return point
                })
                return pathItem
              })
            }

            if (e.holes && e.holes.length) {
              e.holes = e.holes.map((point) => {
                point.center.x = toDecimal(
                  e.rect.width - point.center.x,
                  ncSetting.decimal
                )
                point.ocenter.x = toDecimal(
                  e.oRect.width - point.ocenter.x,
                  ncSetting.decimal
                )
                return point
              })
            }
            if (e.slots && e.slots.length) {
              e.slots = e.slots.map((point) => {
                point.pt1.x = toDecimal(
                  e.rect.width - point.pt1.x,
                  ncSetting.decimal
                )
                point.pt2.x = toDecimal(
                  e.rect.width - point.pt2.x,
                  ncSetting.decimal
                )
                point.opt1.x = toDecimal(
                  e.oRect.width - point.opt1.x,
                  ncSetting.decimal
                )
                point.opt2.x = toDecimal(
                  e.oRect.width - point.opt2.x,
                  ncSetting.decimal
                )
                return point
              })
            }
            if (e.millInfo && e.millInfo.length) {
              e.millInfo = e.millInfo.map((millInfoItem) => {
                millInfoItem.shape = millInfoItem.shape.map((point) => {
                  point.x = toDecimal(e.rect.width - point.x, ncSetting.decimal)
                  return point
                })
                return millInfoItem
              })
            }
            if (e.curveHoles && e.curveHoles.length) {
              e.curveHoles = e.curveHoles.map((curveHItem) => {
                curveHItem.path = curveHItem.path.map((point) => {
                  point.x = toDecimal(e.rect.width - point.x, ncSetting.decimal)
                  return point
                })
                return curveHItem
              })
            }
          } else if (ncSetting.trimSide === 'topLeft' && !ncSetting.xyReverse) {
            if (e.path) {
              e.path = e.path.map((pathItem) => {
                pathItem = pathItem.map((point) => {
                  point.y = toDecimal(
                    e.rect.height - point.y,
                    ncSetting.decimal
                  )
                  point.x = toDecimal(e.rect.width - point.x, ncSetting.decimal)
                  return point
                })
                return pathItem
              })
            }

            if (e.holes && e.holes.length) {
              e.holes = e.holes.map((point) => {
                point.center.y = toDecimal(
                  e.rect.height - point.center.y,
                  ncSetting.decimal
                )
                point.center.x = toDecimal(
                  e.rect.width - point.center.x,
                  ncSetting.decimal
                )
                point.ocenter.y = toDecimal(
                  e.oRect.height - point.ocenter.y,
                  ncSetting.decimal
                )
                point.ocenter.x = toDecimal(
                  e.oRect.width - point.ocenter.x,
                  ncSetting.decimal
                )
                return point
              })
            }
            if (e.slots && e.slots.length) {
              e.slots = e.slots.map((point) => {
                point.pt1.y = toDecimal(
                  e.rect.height - point.pt1.y,
                  ncSetting.decimal
                )
                point.pt2.y = toDecimal(
                  e.rect.height - point.pt2.y,
                  ncSetting.decimal
                )
                point.pt1.x = toDecimal(
                  e.rect.width - point.pt1.x,
                  ncSetting.decimal
                )
                point.pt2.x = toDecimal(
                  e.rect.width - point.pt2.x,
                  ncSetting.decimal
                )
                point.opt1.y = toDecimal(
                  e.oRect.height - point.opt1.y,
                  ncSetting.decimal
                )
                point.opt2.y = toDecimal(
                  e.oRect.height - point.opt2.y,
                  ncSetting.decimal
                )
                point.opt1.x = toDecimal(
                  e.oRect.width - point.opt1.x,
                  ncSetting.decimal
                )
                point.opt2.x = toDecimal(
                  e.oRect.width - point.opt2.x,
                  ncSetting.decimal
                )
                return point
              })
            }
            if (e.millInfo && e.millInfo.length) {
              e.millInfo = e.millInfo.map((millInfoItem) => {
                millInfoItem.shape = millInfoItem.shape.map((point) => {
                  point.y = toDecimal(
                    e.rect.height - point.y,
                    ncSetting.decimal
                  )
                  point.x = toDecimal(e.rect.width - point.x, ncSetting.decimal)
                  return point
                })
                return millInfoItem
              })
            }
            if (e.curveHoles && e.curveHoles.length) {
              e.curveHoles = e.curveHoles.map((curveHItem) => {
                curveHItem.path = curveHItem.path.map((point) => {
                  point.y = toDecimal(
                    e.rect.height - point.y,
                    ncSetting.decimal
                  )
                  point.x = toDecimal(e.rect.width - point.x, ncSetting.decimal)
                  return point
                })
                return curveHItem
              })
            }
          }
          if (e.polyArr) {
            dealPlankPoly(e)
          }
        })
      }
    }
  })
}

/**
 * 将多个排版方案处理成每一个单独的方案
 * @param {Object} paibanResult 排版接口返回数据
 */
export function dealAlignedPlan(paibanResult) {
  try {
    const { glass_setting } = store.state.ncSetting
    const stockKeys = Object.keys(paibanResult)
    if (!stockKeys.length) throw new Error('paibanResult no data')
    // 获取所有方案中排版结果最多的一种材质
    let maxPlanNum = 0
    Object.values(paibanResult).forEach((it) => {
      if (it.data.length > maxPlanNum) maxPlanNum = it.data.length
    })
    const finalResult = []
    for (let index = 0; index < maxPlanNum; index++) {
      const newPlan = {}
      stockKeys.forEach((key) => {
        const cutItem = paibanResult[key]
        const { data, rollCount, used_rate, stockCount } = cutItem
        let useIdx = index
        // 只需要获取data的长度即可
        const cutMaxLen = data.length - 1
        if (index > cutMaxLen) useIdx = cutMaxLen
        newPlan[key] = {
          data: [data[useIdx]],
          rollCount: [rollCount[useIdx]],
          used_rate: used_rate[useIdx],
        }
        if (stockCount) {
          newPlan[key].stockCount = [stockCount[useIdx]]
        } else {
          newPlan[key].stockCount = used_rate.length
        }
      })
      // dealAlignedPlanArea(stockKeys, newPlan, store.state.ncSetting)
      finalResult.push(newPlan)
    }
    return finalResult
  } catch (error) {
    console.error(error)
  }
}

function dealAlignedPlanArea(stockKeys, paibanData, ncSetting) {
  const { plankHeight, plankWidth } = store.state.selectStandardPlank
  stockKeys.forEach((key) => {
    const cutItem = paibanData[key]
    // 将data数据进行分离
    const separateData = separatePlank(cutItem.data[0])
    separateData.forEach((parts, key) => {
      let specialShape = null
      let area = 0
      // 按照板件进行计算优化率
      parts.forEach((it) => {
        // 特殊板件 余料板件/超大板
        if ((it.surplusInfo || it.otherPlate) && !specialShape) {
          specialShape = it.surplusInfo || it.otherPlate
        }
        // 计算小板面积总和
        area += it.rect.x2 * it.rect.y2
      })
      const plankArea = specialShape
        ? specialShape.width * specialShape.height
        : plankWidth * plankHeight
      // 通过板号找到优化率内对应的板件
      const cutUsedRate = Array.isArray(cutItem.used_rate)
        ? cutItem.used_rate.find((it) => it.stock_num == key)
        : cutItem.used_rate
      cutUsedRate.rate = area / plankArea
    })
  })
}
function separatePlank(partData) {
  if (!partData.length) return
  const partMap = new Map()
  // 将data中的板件数据分离成对应每一个板子的数据
  partData.forEach((part) => {
    if (!partMap.has(part.stockNum)) {
      partMap.set(part.stockNum, [part])
    } else {
      const d = partMap.get(part.stockNum)
      d.push(part)
    }
  })
  return partMap
}

export function checkPointInPart(pt, polygonArr) {
  if (!polygonArr.length) return
  // true表示孔在板件内 false则相反
  let flag = true
  for (let i = 0; i < polygonArr.length; i++) {
    const polygon = polygonArr[i]
    const isOut = window.ClipperLib.Clipper.PointInPolygon(pt, polygon)
    // 判断孔是否在板外
    if (!i && !isOut) {
      flag = false
      break
    }
    // 判断孔是否在异形轮廓内
    if (i && isOut) {
      flag = false
      break
    }
  }
  return flag
}

/**
 * 处理layoutStart函数所返回的数据用于排版
 * @param {object} val layoutStart函数返回数据
 * @param {Array} needBaseMaterials 可使用的原片数据
 * @param {object} state 偏好设置
 * @param {object} ncSetting nc设置
 * @param {object} store vuex
 * @param {boolean} onlyLayoutTooBig 是否开启特殊大板只排超尺小板
 */
export function dealLayoutResultData(
  val,
  needBaseMaterials,
  state,
  ncSetting,
  store,
  isNewLayout = false,
  onlyLayoutTooBig = false
) {
  const isPreLayout = store.state.ncSetting.isPreLayout
  const result = val.layout
  if (!result) return
  const preData = val.layout.layout_data
  const layoutParam = result.data.layoutRectMap
  const keys = Object.keys(layoutParam)
  for (let i = 0; i < keys.length; ++i) {
    for (let k = 0; k < layoutParam[keys[i]].length; ++k) {
      let part = layoutParam[keys[i]][k]
      const plank = preData.find((e) => e.ggid === part.ggid)
      if (state.isSpecialShape && plank.path) {
        part.cutCurve = [...plank.path]
        let curvePathList = plank.curveHoles
          ?.filter((it) => compareNumbers(it.deep, plank.thick))
          .map((curve) => curve.path)
        if (curvePathList && curvePathList.length && !!curvePathList[0]) {
          part.cutCurve.push(...curvePathList)
        }
      } else {
        delete part.cutCurve
      }
    }
  }
  result.data.layoutConfig.customPlankOrder = state.customPlankOrder
  if (!state.customPlankOrder) {
    if (needBaseMaterials.length) {
      store.commit('setSpecialPlankParams', needBaseMaterials)
      let outSizeMap = {}
      needBaseMaterials.forEach((item) => {
        if (item.isPicked) {
          let key = `${item.color}:${item.matCode}:${item.thick}`
          outSizeMap[key] = {
            width: item.width,
            height: item.height,
            trim_edge: item.trim_edge,
          }
        }
      })
      result.data.layoutConfig.outSizeMap = outSizeMap
      result.data.layoutConfig.otherPlateSize = needBaseMaterials
      // 只要开启了特殊大板上只排超尺小板，那么这个outSizeMap字段的值就不需要了
      if (onlyLayoutTooBig) {
        result.data.layoutConfig.outSizeMap = {}
        // 对用到的所有特殊大板进行分组
        const newGroup = needBaseMaterials.reduce((acc, cur) => {
          // 这里不用cur中的symbol的原因就是因为这里的symbol可能是重复的：：：false/true，即使他们的颜色、材质、厚度不一样
          const symbol = `${cur.color}:${cur.matCode}:${cur.thick}`
          if (!acc[symbol]) {
            acc[symbol] = []
          }
          acc[symbol].push(cur)
          return acc
        }, {})
        // 获取到每一类板件中可以排下所有超尺小板的长边最小的特殊大板
        const otherPlateSize = Object.keys(newGroup).map((k) => {
          newGroup[k].sort((a, b) => {
            const aMax = Math.max(a.width, a.height)
            const bMax = Math.max(b.width, b.height)
            return bMax - aMax
          })
          return newGroup[k][0]
        })
        result.data.layoutConfig.otherPlateSize = otherPlateSize
        // 判断使用的特殊大板是不是默认勾选的，如果是，就需要给outSizeMap赋值
        const pickedPlate = otherPlateSize.find((plate) => plate.isPicked)
        if (pickedPlate) {
          result.data.layoutConfig.outSizeMap = outSizeMap
        }
      }
    }
  } else {
    onlyLayoutTooBig = false
    // 自定义大板有标准大板 不处理outSizeMap
    result.data.layoutConfig.customPlankOrder = state.customPlankOrder
    const standardPlank = state.plankOrderList.find(
      (item) => item.plankType == '标准大板'
    )
    if (standardPlank) {
      result.data.layoutConfig.order = standardPlank.plankOrder
      result.data.layoutConfig.amount = Number(standardPlank.amount)
    } else {
      result.data.layoutConfig.amount = 0
      result.data.layoutConfig.order = -1
    }
    const plateList = state.plankOrderList.filter(
      (item) => item.plankType != '标准大板' && item.amount != '0'
    )
    if (plateList.length) {
      result.data.layoutConfig.otherPlateSize = plateList.map((item) => ({
        color: item.texture,
        height: Number(item.plankSize.split('x')[1]),
        matCode: item.isHighPlank.includes('非')
          ? item.matCode
          : `高光_${item.matCode}`,
        symbol: `${item.matCode}:${item.thick}:${item.thick}`,
        thick: item.thick,
        trim_edge: item.plankEdgeOff,
        width: Number(item.plankSize.split('x')[0]),
        order: item.plankOrder,
        amount: Number(item.amount),
      }))
    }
  }
  const safe_length = isPreLayout
    ? store.state.preLayoutSetting.setting_value.movePlankSetting.safe_length
    : ncSetting.movePlankSetting.safe_length ?? [] // 不需要默认值，算法那边给定了默认值
  result.data.layoutConfig.surplus_position = ncSetting.surplusPosition
  result.data.layoutConfig.trim_side = ncSetting.trimSide
  result.data.layoutConfig.xyReverse = ncSetting.xyReverse ? 1 : 0
  result.data.layoutConfig.is_new_layout = isNewLayout
  result.data.layoutConfig.new_cut_sequence = !!ncSetting.movePlankSetting
    ?.strong_suction_zones.length
    ? ncSetting.movePlankSetting.newCutSequence
    : false
  result.data.layoutConfig.strong_suction_zones = ncSetting.movePlankSetting
    ? ncSetting.movePlankSetting.strong_suction_zones
    : [5]
  if (Array.isArray(safe_length) && safe_length.length === 2) {
    result.data.layoutConfig.safe_length = safe_length
  }
  result.data.layoutConfig.scattered_layout =
    (isPreLayout
      ? store.state.preLayoutSetting.setting_value.movePlankSetting
          .scattered_layout
      : ncSetting.movePlankSetting.scattered_layout) ?? false

  result.data.layoutConfig.surplus_no_roll = store.state.isSurplusNoRoll
  result.data.layoutConfig.nesting_version = state.isSpecialShape
    ? state.nesting_version ??
      store.state.preferencesSetting.setting?.nesting_version
    : undefined //异形嵌套方式 普通/高级
  result.data.layoutConfig.onlyLayoutTooBig = onlyLayoutTooBig
  result.data.layoutConfig.margin = store.state.selectStandardPlank.plankEdgeOff
  return {
    result,
    preData,
  }
}

/**
 * 生成排版所需的所有余料数据
 * @param {object} allSurplusList 所有的余料板件数据
 * @returns
 */
export function genSurplusParams(allSurplusList) {
  const surplusObj = {}
  const selectedSurplusArr = []
  for (let i = 0; i < allSurplusList.length; ++i) {
    if (allSurplusList[i].isSelected) {
      selectedSurplusArr.push(allSurplusList[i])
    }
  }

  let surplusLen = selectedSurplusArr.length
  if (surplusLen > 500) {
    selectedSurplusArr.slice(0, 500)
    surplusLen = 500
  }
  let perNum = Math.floor(500 / surplusLen)
  let lastNum = perNum
  for (let i = 0; i < surplusLen; ++i) {
    let surplus = selectedSurplusArr[i]
    let title = `${surplus.color}:${surplus.type}:${surplus.thick}`
    let obj = {
      id: surplus.id,
      width: Number(surplus.width),
      height: Number(surplus.long),
      area: Number(surplus.area),
      ggid: surplus.ggid,
      branch_name: surplus.branch_name,
      branch_no: surplus.branch_no,
    }
    if (surplus.shape == 1) {
      let lObj = {
        x3: Number(surplus.min_width),
        y3: Number(surplus.long),
        x4: Number(surplus.min_width),
        y4: Number(surplus.min_long),
        x5: Number(surplus.width),
        y5: Number(surplus.min_long),
        shape: 'lshape',
      }
      obj = Object.assign(obj, lObj)
    }
    let amount = Number(surplus.amount)
    if (amount < perNum) {
      lastNum = amount
    } else {
      lastNum = perNum
    }
    if (Number(surplus.showAmount) < lastNum) {
      lastNum = Number(surplus.showAmount)
    }
    let surplusArr = Array(lastNum).fill(obj)
    if (Object.keys(surplusObj).length == 0) {
      surplusObj[title] = surplusArr
    } else {
      let flag = true
      for (let key in surplusObj) {
        if (key == title) {
          flag = false
          surplusObj[key] = [...surplusObj[key], ...surplusArr]
        }
      }
      if (flag) {
        surplusObj[title] = surplusArr
      }
    }
  }
  store.commit('setSurplusObj', surplusObj)
  return surplusObj
}

/**
 * 生成排版接口所需参数
 * @param {*} store
 * @param {*} surplusObj 被处理后的余料数据
 * @param {*} result layoutStart函数返回的数据
 * @returns
 */
export function genPaibanRequestParams(store, surplusObj, result) {
  const { orderInfo, ncSetting, userInfo } = store.state
  return {
    data: {
      count_list: [1],
      cutDirection: ncSetting.cutDirection ?? '逆时针',
      id: orderInfo.order_codes,
      layout_data: JSON.stringify(result.data),
      oids: orderInfo.order_ids,
      surplus: JSON.stringify(surplusObj),
      uid: userInfo.id,
    },
    status: 1,
  }
}

/**
 * 根据材质获取当前需要使用的刀径
 * @param {*} stockKey 颜色:材质:厚度
 * @param {*} ncSetting
 */
export function getPlateKnifeDiameter(stockKey, ncSetting) {
  const plate_knife_map = ncSetting.plate_knife_map ?? {}
  let diameter = ncSetting.knife.diameter
  if (!stockKey) {
    Notification.error({
      title: '错误',
      message: '无法获取板件材质厚度,刀具应用错误,请勿使用此次结果',
    })
    if (ncSetting.glass_setting) diameter = 0
    return diameter
  }
  stockKey = stockKey.split(':').filter((it) => it)
  const keys = Object.keys(plate_knife_map)
  for (const k of keys) {
    const current = plate_knife_map[k]
    const [matCode, thick] = k.split(':').filter((it) => it)
    if (matCode === stockKey[1] && thick * 1 === stockKey[2] * 1) {
      diameter = current.diameter
      break
    }
  }
  if (ncSetting.glass_setting) diameter = 0
  return diameter
}

export async function dealLayoutDataToMaterial(isGetLayoutData) {
  let plankList = []
  Vue.prototype.$getByToken(
    '/production/trial_room',
    { is_get_layout_data: isGetLayoutData },
    (res) => {
      if (res.status === 1) {
        if (isGetLayoutData) {
          plankList = res.data.result_data.flat(2)
          store.commit('clearPaibanData')
          store.commit('setPreLayoutData', dealPreLayoutDataLabelID(plankList))
          store.commit('setIsTrialProduct', true)
          store.commit('setFilterMaterialList', null)
          store.commit('setFilterObject', null)
          store.commit('setIsShowSample', false)
          router.push('/materialList')
        }
        store.commit('setTrailProductState', res.data.state)
        return true
      } else {
        Message.error({
          center: true,
          message: res.msg,
        })
        return false
      }
    }
  )
}

export function dealPreLayoutDataLabelID(preLayoutData) {
  // TODO  preLayoutData的labelID的生成  王超
  return preLayoutData
}

export function dealPaibanDataLabelID(paibanData) {
  paibanData.forEach((data) => {
    data.parts.forEach((part, index) => {
      const preData = store.state.prePaibanData.find(
        (p) => p.partUniqueId == part.partUniqueId
      )
      part.labelId = part.labelId
        ? part.labelId
        : preData
        ? dealNumber(index + preData.labelIndexRangeArr[0])
        : ''
    })
  })
  return paibanData
}

export function dealPlankListLabelID(plankList) {
  let maxIndex = 1
  if (plankList) {
    return plankList.map((plank) => {
      return {
        ...plank,
        parts: plank.parts.map((part) => {
          let minIndex = maxIndex
          maxIndex += Number(part.amount) ? Number(part.amount) : 1
          // store.commit('setPlankLableId', minIndex)
          return {
            ...part,
            labelId: dealNumber(minIndex),
            labelIndexRangeArr: [minIndex, maxIndex],
          }
        }),
      }
    })
  } else return []
}
// 需要发送的余料参数
export function setSurplusObj(paibanData) {
  const surplusObj = {}
  for (let i = 0; i < paibanData.length; ++i) {
    if (
      paibanData[i].surplusInfo &&
      Object.keys(paibanData[i].surplusInfo).length > 0 &&
      !paibanData[i].surplusInfo.isNotSurplus
    ) {
      let title = `${paibanData[i].texture}:${paibanData[i].matCode}:${paibanData[i].thick}`
      if (surplusObj[title]) {
        surplusObj[title].push(paibanData[i].surplusInfo)
      } else {
        surplusObj[title] = [paibanData[i].surplusInfo]
      }
    }
  }
  return surplusObj
}

// 获取新的NC设置
export async function fetchNewNCSetting() {
  if (store.state.ncSetting) {
    let ncParams = {
      setting_id: store.state.ncSetting.process_setting_id,
    }
    if (window.sessionStorage.getItem('thinkerx_material')) {
      ncParams.production_from = 'guimen'
    }
    const nc = ncParams.setting_id
      ? await GetProductionNcSetting(ncParams)
      : {
          data: dealPreLayoutSetting(
            (await getPrelayoutSetting()).data.setting_value
          ),
        }
    let standardPlank =
      JSON.stringify(store.state.selectStandardPlank) != '{}'
        ? store.state.selectStandardPlank
        : nc.data.panelSize
    nc.data.drawPlankWidth = nc.data.xyReverse
      ? standardPlank.plankHeight
      : standardPlank.plankWidth
    nc.data.drawPlankHeight = nc.data.xyReverse
      ? standardPlank.plankWidth
      : standardPlank.plankHeight
    nc.data.drawPlankEdgeOff = standardPlank.plankEdgeOff
    let ncSetting = Object.assign(nc.data, {
      process_setting_id: store.state.ncSetting.process_setting_id,
      process_setting_name: store.state.ncSetting.process_setting_name,
    })
    store.commit('setNcSetting', ncSetting)
    store.commit('setMergeFolderSetting', null)
    return ncSetting
  }
}
// 检查大板修边是否改变
export function checkPlankEdgeHasChanged(baseMaterialData) {
  return new Promise(async (resolve) => {
    if (
      store.state.isHistoryStatus &&
      !store.state.historyPlankEdgeOff &&
      store.state.historyPlankEdgeOff != 0
    ) {
      resolve(true)
      return
    }
    const ncSetting =
      store.state.ncSetting.process_setting_id > 0
        ? await fetchNewNCSetting()
        : store.state.ncSetting
    let hasChangedPlankEdgeOff = false
    let defaultPlankList
    if (Array.isArray(baseMaterialData.defaultPlankInfo)) {
      defaultPlankList = baseMaterialData.defaultPlankInfo
    } else {
      defaultPlankList = [baseMaterialData.defaultPlankInfo]
    }
    let standardPlank = store.state.paibanData.find((item) => !item.otherPlate)
    let specialPlank = store.state.paibanData.filter(
      (item) => item.otherPlate && Object.keys(item.otherPlate).length
    )
    if (
      store.state.isHistoryStatus &&
      (store.state.historyPlankEdgeOff ||
        store.state.historyPlankEdgeOff == 0) &&
      ((standardPlank &&
        !standardPlank.standardPlankId &&
        standardPlank.standardPlankId != 0) ||
        (specialPlank.length &&
          specialPlank.every((item) => !item.otherPlate?.id)))
    ) {
      if (store.state.historyPlankEdgeOff != ncSetting.panelSize.plankEdgeOff) {
        hasChangedPlankEdgeOff = true
      }
    } else {
      if (
        standardPlank &&
        Object.hasOwnProperty.call(standardPlank, 'standardPlankId')
      ) {
        defaultPlankList.forEach((item) => {
          if (
            item?.standard_plank_id === standardPlank?.standardPlankId &&
            item?.plankEdgeOff != standardPlank?.margin
          ) {
            hasChangedPlankEdgeOff = true
          }
        })
      }
      if (
        specialPlank &&
        specialPlank.length &&
        baseMaterialData?.specialPlankInfo?.length
      ) {
        specialPlank.forEach((item) => {
          baseMaterialData.specialPlankInfo.forEach((specialItem) => {
            if (
              item.otherPlate?.id == specialItem?.id &&
              item.otherPlate?.trim_edge != specialItem?.trim_edge
            ) {
              hasChangedPlankEdgeOff = true
            }
          })
        })
      }
    }
    if (hasChangedPlankEdgeOff) {
      store.state.isDownloadingNc = false
      const dom = '系统检测到存在大板的修边被修改，请重新排版确保生产准确'
      const confirm = Vue.prototype.$antdConfirm({
        title: '温馨提示',
        content: dom,
        okText: '我知道了',
        cancelText: '',
        centered: true,
        closable: true,
        cancelButtonProps: {
          style: { display: 'none' },
        },
        onOk: () => {
          if (confirm) confirm.destroy()
          return
        },
        onCancel: () => {
          if (confirm) confirm.destroy()
          return
        },
      })
      resolve(false)
    } else {
      resolve(true)
    }
  })
}
