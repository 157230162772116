// 设置对接每个key的含义
export const equipmentFormTextObj = {
  activeCutEquip: '开料设备切换',
  activeCureEquip: '孔槽加工设备切换',
  nowCure: '开料机设置-开料机加工方式',
  special_hole_slot: '开料机设置-在开料机上加工的孔槽-多选项',
  special_hole_slot_way: '开料机设置-在开料机上加工的孔槽加工方式',
  aio_hole_slot_shunt: '一体机开料机设置-在开料机上加工的孔槽-多选项',
  aio_hole_slot_shunt_way: '一体机开料机设置-在开料机上加工的孔槽加工方式',
  engraving_process_setting: '开料机设置-加工设置',
  aio_hole_slot_process: '开料机设置-在一体机上加工的孔槽加工设置',
  firstEquipment: '设备一',
  secondEquipment: '设置二',
  specialHoles_enable: '单独孔',
  specialHoles_diameter: '孔直径范围',
  specialHoles_deep: '孔深度范围',

  specialSlots_enable: '单独槽',
  specialSlots_width: '槽宽度范围',
  specialSlots_deep: '槽深度范围',
  allow_turn_plate_process: '允许开料机翻版加工特殊孔槽',
  non_hole_slot_of_special_shape: '开料机上加工板件异形部分',
  dock56_drill_still_process: '开料机上已加工孔槽在五/六面钻上',
  short_edge_range: '最大短边尺寸',
  long_edge_range: '最大长边尺寸',
  in_range_all: '允许短边尺寸',
  range_list: '允许短边尺寸',
  is_strentch_as_special_slot: '拉直器槽类型',
}
