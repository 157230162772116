var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"paring-off",attrs:{"id":"paring-off"}},[_c('span',{staticClass:"head-title inline-block pl0"},[_vm._v(_vm._s(_vm.$t('cuttingDock.cuttingParams.edgeSetting'))+" "),(_setup.props.isShowSwitch)?_c('a-switch',{staticClass:"ml10",attrs:{"default-checked":"","id":"trim_setting_switch"},model:{value:(_setup.trimmingSetting[_setup.propertyName.TRIM]),callback:function ($$v) {_vm.$set(_setup.trimmingSetting, _setup.propertyName.TRIM, $$v)},expression:"trimmingSetting[propertyName.TRIM]"}}):_vm._e(),(_setup.props.isShowSwitch)?_c('span',{staticClass:"fs14 ml20 span-text"},[_vm._v(_vm._s(_vm.$t('cuttingDock.cuttingParams.offTip')))]):_vm._e()],1),(_setup.trimmingSetting[_setup.propertyName.TRIM] || !_setup.props.isShowSwitch || _vm.isShowSecondaryCatalog)?_c('div',{staticClass:"paring-off-setting-img flex color-o-9 ml8"},[_c('div',{staticClass:"paring-off-setting pr20"},[_c('div',{staticClass:"start-point flex mb20"},[_c('span',{staticClass:"mr14 pt8"},[_vm._v(_vm._s(_vm.$t('cuttingDock.cuttingParams.edgeCutDirection')))]),_c('div',{staticClass:"flex flex-dir--top"},[_c('div',{staticClass:"table-board-radio p0 flex-cross--center flex-wrap--wrap inline-block"},_vm._l((_setup.paringPointList),function(item,index){return _c('span',{key:index,staticClass:"table-board-span inline-block align-center w90 h38",class:{
              'is-not-allowed':
                _setup.propertyName.TRIM in _setup.trimmingSetting
                  ? !_setup.trimmingSetting[_setup.propertyName.TRIM]
                  : false,
              'active-radio':
                _setup.trimmingSetting[_setup.propertyName.TRIM_SIDE] == item.value,
            },attrs:{"id":`trim_setting_parDir_${index}_btn`},on:{"click":function($event){return _setup.handleParDir(item.value)}}},[_vm._v(_vm._s(_setup.translateLang( item.label )))])}),0),_c('span',{staticClass:"edge-again-info fs14 mt5",staticStyle:{"width":"375px"}},[_vm._v(" ("+_vm._s(_vm.$t('cuttingDock.cuttingParams.lGapTip'))+") ")])])]),_c('div',{staticClass:"edge-long mb20"},[_c('span',{staticClass:"mr14"},[_vm._v(_vm._s(_vm.$t('cuttingDock.cuttingParams.onlyLong')))]),_c('a-checkbox',{attrs:{"id":"trim_setting_trimLSingle_checkbox","disabled":_setup.propertyName.TRIM in _setup.trimmingSetting
              ? !_setup.trimmingSetting[_setup.propertyName.TRIM]
              : false},model:{value:(_setup.trimmingSetting[_setup.propertyName.TRIM_L_SINGLE]),callback:function ($$v) {_vm.$set(_setup.trimmingSetting, _setup.propertyName.TRIM_L_SINGLE, $$v)},expression:"trimmingSetting[propertyName.TRIM_L_SINGLE]"}})],1),_c('div',{staticClass:"start-point mb20"},[_c('span',{staticClass:"mr20"},[_vm._v(_vm._s(_vm.$t('cuttingDock.cuttingParams.backCutOrder')))]),_c('a-radio-group',{attrs:{"disabled":_setup.propertyName.TRIM in _setup.trimmingSetting
              ? !_setup.trimmingSetting[_setup.propertyName.TRIM]
              : false},model:{value:(_setup.trimmingSetting[_setup.propertyName.TRIM_L_MOMENT]),callback:function ($$v) {_vm.$set(_setup.trimmingSetting, _setup.propertyName.TRIM_L_MOMENT, $$v)},expression:"trimmingSetting[propertyName.TRIM_L_MOMENT]"}},[_c('a-radio',{attrs:{"value":"切割开始","id":"trim_setting_trimLMoment_start_radio"}},[_c('span',{attrs:{"id":"trim_setting_trimLMoment_start_radio_label"}},[_vm._v(_vm._s(_vm.$t('cuttingDock.cuttingParams.backFirst')))])]),_c('a-radio',{attrs:{"value":"切割结束","id":"trim_setting_trimLMoment_end_radio"}},[_c('span',{attrs:{"id":"trim_setting_trimLMoment_end_radio_label"}},[_vm._v(_vm._s(_vm.$t('cuttingDock.cuttingParams.backSecond')))])])],1)],1),_c('div',{staticClass:"front-edge-again flex .flex-cross--center mb20"},[_c('span',{staticClass:"mr14 pt10"},[_vm._v(_vm._s(_vm.$t('cuttingDock.cuttingParams.frontReCut')))]),_c('div',[_c('a-input',{staticStyle:{"width":"100px"},attrs:{"suffix":"mm","disabled":_setup.propertyName.TRIM in _setup.trimmingSetting
                ? !_setup.trimmingSetting[_setup.propertyName.TRIM]
                : false,"id":"trim_setting_secondTrimValue_input"},on:{"blur":_setup.inputCheck},model:{value:(_setup.trimmingSetting[_setup.propertyName.SECOND_TRIM_VALUE]),callback:function ($$v) {_vm.$set(_setup.trimmingSetting, _setup.propertyName.SECOND_TRIM_VALUE, $$v)},expression:"trimmingSetting[propertyName.SECOND_TRIM_VALUE]"}}),_c('span',{staticClass:"edge-again-info fs14",staticStyle:{"font-weight":"400"}},[_vm._v(_vm._s(_vm.$t('cuttingDock.cuttingParams.frontReCutTip')))])],1)]),_c('div',{staticClass:"mb20"},[_c('span',{staticClass:"mr14 fs14"},[_vm._v(_vm._s(_vm.$t('cuttingDock.cuttingParams.useNewCut'))+" ")]),_c('a-checkbox',{attrs:{"id":"trim_setting_new_trim_checkbox","disabled":_setup.propertyName.TRIM in _setup.trimmingSetting
              ? !_setup.trimmingSetting[_setup.propertyName.TRIM]
              : false},model:{value:(_setup.trimmingSetting[_setup.propertyName.NEW_TRIM]),callback:function ($$v) {_vm.$set(_setup.trimmingSetting, _setup.propertyName.NEW_TRIM, $$v)},expression:"trimmingSetting[propertyName.NEW_TRIM]"}}),_c('span',{staticClass:"ml10 color-8",staticStyle:{"font-weight":"400"}},[_vm._v("("+_vm._s(_vm.$t('cuttingDock.cuttingParams.newCutTip'))+")")])],1),_c('div',{attrs:{"id":`singlePanelTrim_${_setup.props.source}`}},[_c('span',{staticClass:"mr14"},[_vm._v(_vm._s(_vm.$t('cuttingDock.cuttingParams.singleMachineLimit')))]),_c('a-checkbox',{attrs:{"id":"trim_setting_single_panel_front_path_checkbox","disabled":_setup.propertyName.TRIM in _setup.trimmingSetting
              ? !_setup.trimmingSetting[_setup.propertyName.TRIM]
              : false},model:{value:(_setup.trimmingSetting[_setup.propertyName.IS_FIRST_TRIM_SINGLE_PANEL]),callback:function ($$v) {_vm.$set(_setup.trimmingSetting, _setup.propertyName.IS_FIRST_TRIM_SINGLE_PANEL, $$v)},expression:"trimmingSetting[propertyName.IS_FIRST_TRIM_SINGLE_PANEL]"}}),_c('span',{staticClass:"color-8"},[_vm._v("（"+_vm._s(_vm.$t('cuttingDock.cuttingParams.singleMachineLimitTip'))+"）")]),(_setup.trimmingSetting[_setup.propertyName.IS_FIRST_TRIM_SINGLE_PANEL])?_c('div',{staticClass:"mt10"},_vm._l((_setup.paringPointList),function(item,index){return _c('span',{key:index,staticClass:"table-board-span inline-block align-center w90 h38",class:{
              'active-radio':
                _setup.trimmingSetting[_setup.propertyName.SINGLE_PANEL_TRIM_DIRECTION] ==
                item.value,
              'is-not-allowed':
                _setup.propertyName.TRIM in _setup.trimmingSetting
                  ? !_setup.trimmingSetting[_setup.propertyName.TRIM]
                  : false,
            },on:{"click":function($event){return _setup.handleClickDirection(item.value)}}},[_vm._v(_vm._s(_setup.translateLang(item.label)))])}),0):_vm._e()],1)]),_c('div',{staticClass:"paring-off-img"},[_c('img',{class:{
          'is-not-allowed':
            _setup.propertyName.TRIM in _setup.trimmingSetting
              ? !_setup.trimmingSetting[_setup.propertyName.TRIM]
              : false,
        },attrs:{"src":_setup.paringImg(_setup.trimmingSetting[_setup.propertyName.TRIM_SIDE]),"alt":""}})])]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }