<template>
  <div class="side_working h100">
    <div class="h100 flex">
      <AnchorNav :getContainer="() => container" :navs="navs" />
      <div class="wrap h100 y-auto x-auto" ref="wrap">
        <div style="min-width: 1500px">
          <div class="setItem">
            <span class="head-title" id="knife-table">{{
              $t('common.knivesStore')
            }}</span>
            <div class="main-table">
              <el-table
                :data="formData.knives"
                :cell-style="columnStyle"
                :row-style="changeStyle"
                :header-cell-style="headerStyle"
                :header-row-style="headerRowStyle"
                @cell-click="cellClick"
                @row-click="choseKnife"
                :row-class-name="tableRowClassName"
                :cell-class-name="tableCellClassName"
              >
                <el-table-column
                  v-for="(knife, indexs) in knivesColumns"
                  :key="indexs + '1'"
                  :label="$t(knife.label)"
                  :prop="knife.props"
                  :width="knife.width"
                >
                  <template slot-scope="scope">
                    <div
                      v-if="
                        scope.row.index === rowIndex &&
                        scope.column.index === columnIndex
                      "
                    >
                      <el-input
                        v-model="scope.row[knife.props]"
                        ref="editInput"
                        :type="
                          knife.props == 'startCode' || knife.props == 'endCode'
                            ? 'textarea'
                            : 'text'
                        "
                        size="mini"
                        resize="none"
                        autosize
                        @blur="
                          inputBlur(
                            scope.row[knife.props],
                            scope.$index,
                            indexs,
                            scope.row
                          )
                        "
                        @focus="getNowKnifeName(scope.row[knife.props], indexs)"
                        @input="handleInputCheck(scope.row, knife.props)"
                      />
                    </div>
                    <div
                      class="knives_type"
                      style="white-space: pre-line"
                      v-else
                    >
                      {{ scope.row[knife.props] }}
                    </div>
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <div class="operation-btn">
              <a-button @click="addNewKnife" class="mr12">{{
                $t('sideHoleMachine.insertRow')
              }}</a-button>
              <a-button @click="deleteKnife">{{
                $t('sideHoleMachine.deleteRow')
              }}</a-button>
            </div>
          </div>
          <div class="setItem">
            <span class="head-title" id="device-parameter-settings">{{
              $t('sideHoleMachine.equipParamsSetting')
            }}</span>
            <div class="text-area">
              <div style="display: flex; align-items: center">
                <div>
                  <span
                    >{{
                      $t('sideHoleMachine.equipmentParams.equipStartCode')
                    }}：</span
                  >
                  <a-textarea
                    v-model="formData.beginCode.templateString"
                    :placeholder="
                      $t(
                        'sideHoleMachine.equipmentParams.equipStartCodePlaceholder'
                      )
                    "
                    @drop="dropOver($event, 'beginCode', 'plank_info')"
                  ></a-textarea>
                </div>
                <div>
                  <span
                    >{{
                      $t('sideHoleMachine.equipmentParams.flipPauseCode')
                    }}：</span
                  >
                  <a-textarea
                    v-model="formData.pauseCode.templateString"
                    :placeholder="
                      $t(
                        'sideHoleMachine.equipmentParams.flipPauseCodePlaceholder'
                      )
                    "
                    @drop="dropOver($event, 'pauseCode', 'plank_info')"
                  ></a-textarea>
                </div>
              </div>
              <div style="display: flex; align-items: center">
                <div>
                  <span
                    >{{
                      $t('sideHoleMachine.equipmentParams.equipEndCode')
                    }}：</span
                  >
                  <a-textarea
                    v-model="formData.endCode.templateString"
                    :placeholder="
                      $t('sideHoleMachine.equipmentParams.equipEndCodeTip')
                    "
                    @drop="dropOver($event, 'endCode', 'plank_info')"
                  ></a-textarea>
                </div>
                <div class="container">
                  <div class="tag_title">
                    <span>{{ $t('sideHoleMachine.tagPool') }}</span>
                    <span>{{ $t('sideHoleMachine.dragTip') }}</span>
                  </div>
                  <div
                    class="tag_warp"
                    v-for="(items, index) in settingData.plank_info"
                    :key="index"
                  >
                    <span
                      v-for="item in items"
                      :key="item.id"
                      draggable="true"
                      :class="`tag-item${item.id}`"
                      :data-clipboard-text="'{' + item.label_name_show + '}'"
                      @dragstart.stop="startDrag($event, item, 'plank_info')"
                      >{{ item.label_name_show }}</span
                    >
                  </div>
                </div>
              </div>
            </div>
            <div class="bore_holeSet">
              <span>{{
                $t('sideHoleMachine.equipmentParams.sideChangeSetting')
              }}</span>
              <div class="hole_set_wap">
                <p>{{ $t('sideHoleMachine.equipmentParams.workTip') }}</p>
                <p style="color: #f00">
                  (XY轴互换的雕刻机需注意：请沿顺时针方向加工！)
                </p>
                <div>
                  <div class="top">
                    <div class="code_box">
                      <div>
                        <span
                          >{{
                            $t('sideHoleMachine.equipmentParams.frontTurnCode')
                          }}：</span
                        ><a-input
                          type="textarea"
                          :rows="5"
                          :placeholder="
                            $t(
                              'sideHoleMachine.equipmentParams.frontTurnCodePlaceholder'
                            )
                          "
                          v-model="formData.frontTurnCode"
                          @dragover="handleDragOver"
                        />
                      </div>
                      <div>
                        <span
                          >{{
                            $t(
                              'sideHoleMachine.equipmentParams.frontTurnEndCode'
                            )
                          }}：</span
                        ><a-input
                          type="textarea"
                          :rows="5"
                          :placeholder="
                            $t(
                              'sideHoleMachine.equipmentParams.frontTurnEndCodePlaceholder'
                            )
                          "
                          v-model="formData.frontTurnEndCode"
                          @dragover="handleDragOver"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="mid">
                    <div class="code_box">
                      <div>
                        <span
                          >{{
                            $t('sideHoleMachine.equipmentParams.leftTurnCode')
                          }}：</span
                        ><a-input
                          type="textarea"
                          :rows="5"
                          :placeholder="
                            $t(
                              'sideHoleMachine.equipmentParams.leftTurnCodePlaceholder'
                            )
                          "
                          v-model="formData.leftTurnCode"
                          @dragover="handleDragOver"
                        />
                      </div>
                      <div>
                        <span
                          >{{
                            $t(
                              'sideHoleMachine.equipmentParams.leftTurnEndCode'
                            )
                          }}：</span
                        ><a-input
                          type="textarea"
                          :rows="5"
                          :placeholder="
                            $t(
                              'sideHoleMachine.equipmentParams.leftTurnEndCodePlaceholder'
                            )
                          "
                          v-model="formData.leftTurnEndCode"
                          @dragover="handleDragOver"
                        />
                      </div>
                    </div>
                    <div class="hole_img">
                      <img src="../../assets/side_hole_img.png" alt="" />
                    </div>
                    <div class="code_box">
                      <div>
                        <span
                          >{{
                            $t('sideHoleMachine.equipmentParams.rightTurnCode')
                          }}：</span
                        ><a-input
                          type="textarea"
                          :rows="5"
                          :placeholder="
                            $t(
                              'sideHoleMachine.equipmentParams.rightTurnCodePlaceholder'
                            )
                          "
                          v-model="formData.rightTurnCode"
                          @dragover="handleDragOver"
                        />
                      </div>
                      <div>
                        <span
                          >{{
                            $t(
                              'sideHoleMachine.equipmentParams.rightTurnEndCode'
                            )
                          }}：</span
                        ><a-input
                          type="textarea"
                          :rows="5"
                          :placeholder="
                            $t(
                              'sideHoleMachine.equipmentParams.rightTurnEndCodePlaceholder'
                            )
                          "
                          v-model="formData.rightTurnEndCode"
                          @dragover="handleDragOver"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="bot">
                    <div class="code_box">
                      <div>
                        <span
                          >{{
                            $t('sideHoleMachine.equipmentParams.downTurnCode')
                          }}：</span
                        ><a-input
                          type="textarea"
                          :rows="5"
                          :placeholder="
                            $t(
                              'sideHoleMachine.equipmentParams.downTurnCodePlaceholder'
                            )
                          "
                          v-model="formData.backTurnCode"
                          @dragover="handleDragOver"
                        />
                      </div>
                      <div>
                        <span
                          >{{
                            $t(
                              'sideHoleMachine.equipmentParams.downTurnEndCode'
                            )
                          }}：</span
                        ><a-input
                          type="textarea"
                          :rows="5"
                          :placeholder="
                            $t(
                              'sideHoleMachine.equipmentParams.downTurnEndCodePlaceholder'
                            )
                          "
                          v-model="formData.backTurnEndCode"
                          @dragover="handleDragOver"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="setItem">
            <span class="head-title" id="punch-setting">
              <span class="mr10">{{ $t('sideHoleMachine.holeSetting') }}</span>
              <a-checkbox
                v-model="formData.holeEnable"
                id="engraving_setting_sideWorking_holeSetting_checkbox"
              ></a-checkbox>
            </span>
            <div class="knife_set" v-show="formData.holeEnable">
              <div class="left">
                <div class="chooseKnife">
                  <span>{{ $t('sideHoleMachine.knives') }}</span>
                  <a-select v-model="formData.holeKnife" style="width: 120px">
                    <a-select-option
                      v-for="(item, index) in formData.knives"
                      :key="index"
                      :value="item.id"
                      >{{ item.id }}</a-select-option
                    >
                  </a-select>
                </div>
                <span>{{ $t('sideHoleMachine.directives') }}</span>
                <a-textarea
                  v-model="formData.holeSetting.templateString"
                  @drop="dropOver($event, 'holeSetting', 'hole_setting')"
                ></a-textarea>
              </div>
              <div class="right">
                <div class="tag_title">
                  <span>{{ $t('sideHoleMachine.tagPool') }}</span>
                  <span>{{ $t('sideHoleMachine.dragTip') }}</span>
                </div>
                <div
                  class="tag_warp"
                  v-for="(items, index) in settingData.hole_setting"
                  :key="index"
                >
                  <span
                    v-for="item in items"
                    :key="item.id"
                    draggable="true"
                    :class="`tag-item${item.id}`"
                    :data-clipboard-text="'{' + item.label_name_show + '}'"
                    @dragstart.stop="startDrag($event, item, 'hole_setting')"
                    >{{ item.label_name_show }}</span
                  >
                </div>
              </div>
            </div>
          </div>
          <div class="setItem">
            <span class="head-title" id="slot-broaching">
              <span class="mr10">{{ $t('sideHoleMachine.slotSetting') }}</span>
              <a-checkbox
                v-model="formData.slotEnable"
                id="engraving_setting_sideWorking_slotSetting_checkbox"
              ></a-checkbox>
            </span>
            <div class="knife_set" v-show="formData.slotEnable">
              <div class="left">
                <div class="chooseKnife">
                  <span>{{ $t('sideHoleMachine.knives') }}</span>
                  <a-select v-model="formData.slotKnife" style="width: 120px">
                    <a-select-option
                      v-for="(item, index) in formData.knives"
                      :key="index"
                      :value="item.id"
                      >{{ item.id }}</a-select-option
                    >
                  </a-select>
                </div>
                <span>{{ $t('sideHoleMachine.directives') }}</span>
                <a-textarea
                  v-model="formData.slotSetting.templateString"
                  @drop="dropOver($event, 'slotSetting', 'slot_setting')"
                ></a-textarea>
              </div>
              <div class="right">
                <div class="tag_title">
                  <span>{{ $t('sideHoleMachine.tagPool') }}</span>
                  <span>{{ $t('sideHoleMachine.dragTip') }}</span>
                </div>
                <div
                  class="tag_warp"
                  v-for="(items, index) in settingData.slot_setting"
                  :key="index"
                >
                  <span
                    v-for="item in items"
                    :key="item.id"
                    draggable="true"
                    :class="`tag-item${item.id}`"
                    :data-clipboard-text="'{' + item.label_name_show + '}'"
                    @dragstart.stop="startDrag($event, item, 'slot_setting')"
                    >{{ item.label_name_show }}</span
                  >
                </div>
              </div>
            </div>
          </div>
          <div class="setItem">
            <span class="head-title" id="ramino-connecction">
              <span class="mr10">{{
                $t('sideHoleMachine.raminoSetting')
              }}</span>
              <a-checkbox
                v-model="formData.lmnEnable"
                id="engraving_setting_sideWorking_lmn_checkbox"
              ></a-checkbox>
            </span>
            <div class="knife_set" v-show="formData.lmnEnable">
              <div class="left">
                <div class="chooseKnife">
                  <span>{{ $t('sideHoleMachine.knives') }}</span>
                  <a-select v-model="formData.lmnKnife" style="width: 120px">
                    <a-select-option
                      v-for="(item, index) in formData.knives"
                      :key="index"
                      :value="item.id"
                      >{{ item.id }}</a-select-option
                    >
                  </a-select>
                </div>
                <span>{{ $t('sideHoleMachine.directives') }}</span>
                <a-textarea
                  v-model="formData.lmnSetting.templateString"
                  @drop="dropOver($event, 'lmnSetting', 'lmn_setting')"
                ></a-textarea>
              </div>
              <div class="right">
                <div class="tag_title">
                  <span>{{ $t('sideHoleMachine.tagPool') }}</span
                  >-
                  <span>{{ $t('sideHoleMachine.dragTip') }}</span>
                </div>
                <div
                  class="tag_warp"
                  v-for="(items, indexs) in settingData.lmn_setting"
                  :key="indexs"
                >
                  <span
                    v-for="item in items"
                    :key="item.id"
                    draggable="true"
                    :class="`tag-item${item.id}`"
                    :data-clipboard-text="'{' + item.label_name_show + '}'"
                    @dragstart.stop="startDrag($event, item, 'lmn_setting')"
                    >{{ item.label_name_show }}</span
                  >
                </div>
              </div>
            </div>
          </div>
          <div class="setItem">
            <span class="head-title" id="mortise-tenon-connection">
              <span class="mr10">{{
                $t('sideHoleMachine.mortiseTenonSetting')
              }}</span>
              <a-checkbox
                v-model="formData.mt_enable"
                id="engraving_setting_sideWorking_mt_checkbox"
              ></a-checkbox>
            </span>
            <a-radio-group
              class="ml20 pl4"
              v-model="mortiseTenonType"
              size="small"
              v-show="formData.mt_enable"
            >
              <a-radio-button value="straight">{{
                $t('sideHoleMachine.straight')
              }}</a-radio-button>
              <a-radio-button value="eccentric">{{
                $t('sideHoleMachine.eccentric')
              }}</a-radio-button>
            </a-radio-group>
            <div class="knife_set" v-show="formData.mt_enable">
              <div class="left" v-if="mortiseTenonType == 'straight'">
                <span>{{ $t('sideHoleMachine.slot') }}:</span>
                <a-textarea
                  v-model="formData.mtLineCutCode.templateString"
                  @drop="
                    dropOver($event, 'mtLineCutCode', 'mt_line_cut_setting')
                  "
                ></a-textarea>
                <span>{{ $t('sideHoleMachine.cirWork') }}:</span>
                <a-textarea
                  v-model="formData.mtArcCutCode.templateString"
                  @drop="dropOver($event, 'mtArcCutCode', 'mt_arc_cut_setting')"
                ></a-textarea>
                <div class="flex mt10">
                  <span style="width: 120px"
                    >{{ $t('sideHoleMachine.cirInter') }}:</span
                  >
                  <a-radio-group v-model="formData.mtArcInterpolation">
                    <a-radio :value="true">
                      {{ $t('sideHoleMachine.cirOption1') }}
                    </a-radio>
                    <a-radio :value="false">
                      {{ $t('sideHoleMachine.cirOption2') }}
                    </a-radio>
                  </a-radio-group>
                </div>
              </div>
              <div class="left" v-else>
                <span>{{ $t('sideHoleMachine.slot') }}:</span>
                <a-textarea
                  v-model="formData.mtSlantingLineCutCode.templateString"
                  @drop="
                    dropOver(
                      $event,
                      'mtSlantingLineCutCode',
                      'mt_slanting_line_cut_setting'
                    )
                  "
                ></a-textarea>
                <span>{{ $t('sideHoleMachine.cirWork') }}:</span>
                <a-textarea
                  v-model="formData.mtSlantingArcCutCode.templateString"
                  @drop="
                    dropOver(
                      $event,
                      'mtSlantingArcCutCode',
                      'mt_slanting_arc_cut_setting'
                    )
                  "
                ></a-textarea>
                <span>收刀指令:</span>
                <a-textarea
                  v-model="formData.mtSlantingCollectKnifeCode"
                ></a-textarea>
                <!-- 圆弧插补指令切割弧形 -->
                <div class="flex mt10">
                  <span>{{ $t('sideHoleMachine.useArcCode') }}：</span>
                  <a-checkbox v-model="formData.useArcCode"></a-checkbox>
                </div>
                <div class="flex mt10">
                  <span style="width: 120px"
                    >{{ $t('sideHoleMachine.cirInter') }}:</span
                  >
                  <a-radio-group v-model="formData.mtArcInterpolation">
                    <a-radio :value="true">
                      {{ $t('sideHoleMachine.cirOption1') }}
                    </a-radio>
                    <a-radio :value="false">
                      {{ $t('sideHoleMachine.cirOption2') }}
                    </a-radio>
                  </a-radio-group>
                </div>
                <div class="flex mt10">
                  <span>{{ $t('sideHoleMachine.pbSize') }}：</span>
                  <a-input
                    v-model="formData.dpsBrokenBoardSize"
                    style="width: 150px"
                    @input="handleInputCheckDP"
                    @blur="handleInputBlurDP"
                  ></a-input>
                </div>
              </div>
              <div class="right">
                <DataTagPool
                  :tag-list="mortiseTag"
                  :dropData.sync="dropData"
                  @dragStart="handleDragStart"
                />
              </div>
            </div>
          </div>

          <div class="setItem">
            <!-- 易木连接设置 -->
            <span class="head-title" id="ym-connection">
              <span class="mr10">{{ $t('sideHoleMachine.ymSetting') }}</span>
              <a-checkbox
                v-model="formData.ym_enable"
                id="engraving_setting_sideWorking_ym_checkbox"
              ></a-checkbox>
            </span>
            <div class="knife_set" v-show="formData.ym_enable">
              <div class="left">
                <div class="chooseKnife">
                  <span>
                    <!-- 加工刀具 -->
                    {{ $t('sideHoleMachine.knives') }}
                  </span>
                  <a-select
                    v-model="formData.YMSetting.knife"
                    style="width: 120px"
                    id="engraving_setting_sideWorking_ymSetting_knife_select"
                  >
                    <a-select-option
                      v-for="(item, index) in formData.knives"
                      :key="index"
                      :value="item.id"
                      >{{ item.id }}</a-select-option
                    >
                  </a-select>
                </div>
                <span>
                  <!-- 加工指令 -->
                  {{ $t('sideHoleMachine.directives') }}
                </span>
                <a-textarea
                  v-model="formData.YMSetting.cutCode.templateString"
                  id="engraving_setting_sideWorking_ymSetting_templateString_textarea"
                  @drop="dropOver($event, 'YMSetting', 'ym_setting')"
                ></a-textarea>
                <span>
                  <!-- 收刀指令 -->
                  {{ $t('sideHoleMachine.takeUpDirective') }}：
                </span>
                <a-textarea
                  v-model="formData.YMSetting.collectKnifeCode"
                  id="engraving_setting_sideWorking_ymSetting_collectKnifeCode_textarea"
                ></a-textarea>
              </div>
              <div class="right">
                <div class="tag_title">
                  <span>
                    <!-- 数据标签池 -->
                    {{ $t('sideHoleMachine.tagPool') }}
                  </span>
                  <span>
                    <!-- 可拖拽标签池内标签 -->
                    {{ $t('sideHoleMachine.dragTip') }}
                  </span>
                </div>
                <div
                  class="tag_warp"
                  v-for="(items, index) in settingData.slot_setting"
                  :key="index"
                >
                  <span
                    v-for="item in items"
                    :key="item.id"
                    draggable="true"
                    :class="`tag-item${item.id}`"
                    :data-clipboard-text="'{' + item.label_name_show + '}'"
                    @dragstart.stop="startDrag($event, item, 'ym_setting')"
                    >{{ item.label_name_show }}</span
                  >
                </div>
              </div>
            </div>
          </div>
          <div class="setItem">
            <span class="head-title" id="coordinate">{{
              $t('sideHoleMachine.coordinateValue')
            }}</span>
            <div class="coor">
              <div>
                <span>{{ $t('sideHoleMachine.coordinate.xPointSymbol') }}</span>
                <a-radio-group v-model="formData.xPointSymbol">
                  <a-radio value="正值">
                    {{ $t('sideHoleMachine.coordinate.upright') }}
                  </a-radio>
                  <a-radio value="负值">
                    {{ $t('sideHoleMachine.coordinate.negative') }}
                  </a-radio>
                </a-radio-group>
              </div>
              <div>
                <span>{{ $t('sideHoleMachine.coordinate.yPointSymbol') }}</span>
                <a-radio-group v-model="formData.yPointSymbol">
                  <a-radio value="正值">
                    {{ $t('sideHoleMachine.coordinate.upright') }}
                  </a-radio>
                  <a-radio value="负值">
                    {{ $t('sideHoleMachine.coordinate.negative') }}
                  </a-radio>
                </a-radio-group>
              </div>
              <div>
                <span>{{ $t('sideHoleMachine.coordinate.zPointSymbol') }}</span>
                <a-radio-group v-model="formData.zPointSymbol">
                  <a-radio value="正值">
                    {{ $t('sideHoleMachine.coordinate.upright') }}
                  </a-radio>
                  <a-radio value="负值">
                    {{ $t('sideHoleMachine.coordinate.negative') }}
                  </a-radio>
                </a-radio-group>
              </div>
            </div>
          </div>
          <!-- 坐标基准对调 -->
          <div class="setItem">
            <span class="head-title" id="coord-switch">
              {{ $t('sideHoleMachine.coordSwitch') }}
            </span>
            <div class="coor">
              <!-- 升降坐标基准对调 -->
              <span>{{ $t('sideHoleMachine.updownCoordSwitch') }}：</span
              ><a-checkbox
                v-model="formData.zElevationCoordReverse"
              ></a-checkbox>
            </div>
          </div>
          <div class="setItem">
            <span class="head-title" id="encoding-format">{{
              $t('sideHoleMachine.encodeFormat.format')
            }}</span>
            <div class="coor">
              <div>
                <span>{{ $t('sideHoleMachine.encodeFormat.format') }}</span>
                <a-radio-group v-model="formData.fileEncoding">
                  <a-radio value="utf-8">
                    {{ $t('sideHoleMachine.encodeFormat.formatOption1') }}
                  </a-radio>
                  <a-radio value="ANSI">
                    {{ $t('sideHoleMachine.encodeFormat.formatOption2') }}
                  </a-radio>
                </a-radio-group>
              </div>
              <div>
                <span>{{ $t('sideHoleMachine.encodeFormat.wrap') }}</span>
                <a-radio-group v-model="formData.platform">
                  <a-radio value="windows"> windows </a-radio>
                  <a-radio value="unix"> unix </a-radio>
                </a-radio-group>
              </div>
            </div>
          </div>
          <div class="setItem">
            <span class="head-title" id="suffix-format">{{
              $t('sideHoleMachine.suffixSetting')
            }}</span>
            <div class="suffix">
              <span>{{ $t('sideHoleMachine.suffix') }}</span
              ><a-input
                placeholder=".nc"
                v-model="formData.fileSuffix"
                @dragover="handleDragOver"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AnchorNav from '@/components/anchorNav/index.vue'
import DataTagPool from '@/components/dataTagPool'
import { sideWorkingNavs } from '@/util/anchorNavData.ts'
import {
  regReplaceCuttingParamsDiameter,
  regReplaceSideHole,
} from '@/util/regReplace'

export default {
  props: {
    obj: {
      type: [Array, Object],
      default: () => {},
    },
    settingData: {
      type: [Array, Object],
      default: () => [],
    },
  },
  components: {
    AnchorNav,
    DataTagPool,
  },
  data() {
    return {
      knivesColumns: [
        {
          label: 'cuttingDock.knives.number',
          props: 'id',
          width: 90,
          isCheck: false,
        },
        {
          label: 'cuttingDock.knives.diameter',
          props: 'diameter',
          width: 90,
          isCheck: false,
        },
        {
          label: 'cuttingDock.knives.xOffset',
          props: 'xOffset',
          isCheck: false,
        },
        {
          label: 'cuttingDock.knives.yOffset',
          props: 'yOffset',
          isCheck: false,
        },
        {
          label: 'cuttingDock.knives.zOffset',
          props: 'zOffset',
          isCheck: false,
        },
        {
          label: 'cuttingDock.knives.startCode',
          props: 'startCode',
          isCheck: false,
        },
        {
          label: 'cuttingDock.knives.endCode',
          props: 'endCode',
          isCheck: false,
        },
      ],
      navs: sideWorkingNavs,
      formData: {
        knives: [],
        beginCode: '',
        beginCodeSetting: {
          headerIds: {},
          templateString: '',
        },
        pauseCode: '',
        pauseCodeSetting: {
          headerIds: {},
          templateString: '',
        },
        endCode: '',
        endCodeSetting: {
          headerIds: {},
          templateString: '',
        },
        frontTurnCode: '',
        frontTurnEndCode: '',
        backTurnCode: '',
        backTurnEndCode: '',
        leftTurnCode: '',
        leftTurnEndCode: '',
        rightTurnCode: '',
        rightTurnEndCode: '',
        xPointSymbol: '正值',
        yPointSymbol: '正值',
        zPointSymbol: '正值',
        fileEncoding: '',
        platform: 'windows',
        fileSuffix: '',
        holeEnable: true,
        holeKnife: '',
        holeSetting: {
          headerIds: {},
          templateString: '',
        },
        YMSetting: {
          knife: '',
          cutCode: {
            headerIds: {},
            templateString: '',
          },
          collectKnifeCode: '',
        },
        slotEnable: true,
        slotKnife: '',
        slotSetting: {
          headerIds: {},
          templateString: '',
        },
        lmnEnable: true,
        lmnKnife: '',
        lmnSetting: {
          headerIds: {},
          templateString: '',
        },
        verticalEnable: false,
        verticalKnives: [],
        verticalHoleSetting: {
          headerIds: {},
          templateString: '',
        },
        verticalSlotSetting: {
          headerIds: {},
          templateString: '',
        },
        mtArcCutCode: {
          headerIds: {},
          templateString: '',
        },
        mtLineCutCode: {
          headerIds: {},
          templateString: '',
        },
        mtSlantingArcCutCode: { headerIds: {}, templateString: '' },
        mtSlantingLineCutCode: { headerIds: {}, templateString: '' },
        useArcCode: true,
        dpsBrokenBoardSize: 2,
        mtArcInterpolation: false,
        // 升降坐标基准对调
        zElevationCoordReverse: false,
        // 榫卯
        mt_enable: true,
        // 易木
        ym_enable: true,
      },
      // 记录行索引
      rowIndex: -1,
      // 记录列索引
      columnIndex: -1,
      currentRow: {},
      dragWord: '',
      // 记录当前刀具编号名
      copyKnifeId: '',
      // 记录拖动数据
      dropData: null,
      settingField: '',
      mortiseTenonType: 'straight',
    }
  },
  computed: {
    container() {
      return this.$refs.wrap
    },
    mortiseTag() {
      let arr = []
      if (this.mortiseTenonType == 'straight') {
        arr = [
          {
            title: this.$t('sideHoleMachine.slot'),
            arr: [...(this.settingData.mt_line_cut_setting ?? [])],
          },
          {
            title: this.$t('sideHoleMachine.cirWork'),
            arr: [...(this.settingData.mt_arc_cut_setting ?? [])],
          },
        ]
      } else {
        arr = [
          {
            title: this.$t('sideHoleMachine.slot'),
            arr: [...(this.settingData.mt_slanting_line_cut_setting ?? [])],
          },
          {
            title: this.$t('sideHoleMachine.cirWork'),
            arr: [...(this.settingData.mt_slanting_arc_cut_setting ?? [])],
          },
        ]
      }
      return arr
    },
  },
  methods: {
    handlePrevent(e) {
      e.preventDefault()
    },
    // 更改表头单元格样式
    headerStyle({ row, column }) {
      row
      if (column.property === 'id') {
        return 'padding-left: 16px; box-sizing: border-box;background: #fafafa;'
      }
      return 'background: #fafafa'
    },
    // 更改单元格样式
    columnStyle({ row, column }) {
      if (column.property === 'id') {
        row
        return 'padding-left: 16px;'
      }
    },
    // 更改行样式
    changeStyle({ row, rowIndex }) {
      row
      if (this.currentRow.index == rowIndex) {
        return {
          height: '54px',
          'border-top': '1px solid #EEEEEE',
          'font-size': '14px',
          color: '#000000',
          'font-weight': '400',
          background: 'rgba(24, 168, 199, 0.2)',
        }
      }
      return {
        height: '54px',
        'border-top': '1px solid #EEEEEE',
        'font-size': '14px',
        color: '#000000',
        'font-weight': '400',
      }
    },
    // 更改表头行样式
    headerRowStyle() {
      return {
        height: '54px',
        'font-size': '14px',
        color: '#000000',
        'font-weight': '500',
        'border-bottom': 'none',
      }
    },
    // 点击单元格切换成输入框
    cellClick(row, column) {
      // if(column.property == 'id'){
      //     return
      // }
      this.rowIndex = row.index
      this.columnIndex = column.index
      this.$nextTick(() => {
        this.$refs['editInput'][0].focus()
      })
    },
    tableRowClassName({ row, rowIndex }) {
      row.index = rowIndex
    },
    //把每一列的索引加到列数据中
    tableCellClassName({ column, columnIndex }) {
      column.index = columnIndex
    },
    choseKnife(row) {
      this.currentRow = row
    },
    getNowKnifeName(id, cellIndex) {
      if (!cellIndex) {
        this.copyKnifeId = id
      }
    },
    handleInputCheck(row, prop) {
      if (this.columnIndex == 1) {
        row[prop] = regReplaceSideHole(row[prop], this.columnIndex)
      } else if (
        this.columnIndex == 2 ||
        this.columnIndex == 3 ||
        this.columnIndex == 4
      ) {
        row[prop] = regReplaceSideHole(row[prop], this.columnIndex)
        if (!row[prop].indexOf('-') <= 0) {
          row[prop] =
            row[prop].indexOf('.') > 0
              ? row[prop].split('.')[0].substring(0, 4) +
                '.' +
                row[prop].split('.')[1]
              : row[prop].substring(0, 4)
        } else {
          row[prop] =
            row[prop].indexOf('.') > 0
              ? row[prop].split('.')[0].substring(0, 5) +
                '.' +
                row[prop].split('.')[1]
              : row[prop].substring(0, 5)
        }
      }
      // 刀径只能输入数字且不能为负数和0
      if (prop === 'diameter') {
        row[prop] = regReplaceCuttingParamsDiameter(row[prop])
      }
    },
    addNewKnife() {
      let data = {
        // 'T' + (this.recordKnivesNum + 1)
        id: '',
        diameter: '',
        xOffset: '',
        yOffset: '',
        zOffset: '',
        startCode: '',
        endCode: '',
      }
      this.recordKnivesNum += 1
      this.formData.knives.push(data)
      this.rowIndex = this.formData.knives.length - 1
      this.columnIndex = 1
      this.$nextTick(() => {
        this.$refs['editInput'][0].focus()
      })
    },
    deleteKnife() {
      this.formData.knives.splice(
        this.formData.knives.findIndex((v) => v.index == this.currentRow.index),
        1
      )
    },
    dropOver(event, key, settingField) {
      const specialKey = [
        'mtLineCutCode',
        'mtArcCutCode',
        'mtSlantingArcCutCode',
        'mtSlantingLineCutCode',
      ]
      let dragWord = ''
      if (specialKey.includes(key) && this.settingField === settingField) {
        const tag = this.settingData[settingField].find(
          (it) => it?.label_name_show === this.dropData?.label_name_show
        )
        if (tag) {
          dragWord = `{${this.dropData?.label_name_show}}`
        }
      } else if (
        settingField == 'ym_setting' &&
        this.settingField === settingField &&
        this.settingData['slot_setting']
          .flat(1)
          .findIndex(
            (item) =>
              item?.label_name_show ===
              this.dragWord.substring(1, this.dragWord.length - 1)
          ) !== -1
      ) {
        dragWord = this.dragWord
      } else if (
        this.settingField === settingField &&
        this.settingData[settingField]
          .flat(1)
          .findIndex(
            (item) =>
              item?.label_name_show ===
              this.dragWord.substring(1, this.dragWord.length - 1)
          ) !== -1
      ) {
        dragWord = this.dragWord
      }
      settingField == 'ym_setting'
        ? (this.formData[key].cutCode.templateString += dragWord)
        : (this.formData[key].templateString += dragWord)
      this.$nextTick(() => {
        event.preventDefault()
        var data = event.dataTransfer.getData('Text')
        let s = document.querySelector(`.${data}`).cloneNode(true)
        event.target.appendChild(s)
      })
    },
    // 拖拽标签
    startDrag(event, item, settingField) {
      this.dragWord = `{${item.label_name_show}}`
      this.settingField = settingField
      this.$nextTick(() => {
        event.dataTransfer.setData('Text', event.target.classList[0])
      })
    },
    inputBlur(e, i, cellIndex, row) {
      // 查询刀具编号是否重复，重复清空
      if (!cellIndex) {
        let saveKnifeId = []
        this.formData.knives.forEach((item, index) => {
          if (i != index) {
            saveKnifeId.push(item.id)
          }
        })
        saveKnifeId = saveKnifeId.filter((item) => item)
        if (saveKnifeId.indexOf(e) != -1) {
          this.$message({
            message: this.$t('sideHoleMachine.repeatErr'),
            duration: 1000,
          })
          this.formData.knives[i].id = this.copyKnifeId
        }
      }

      this.rowIndex = -1
      this.columnIndex = -1
      // 失去焦点判断刀直径是否为0
      if (row['diameter'] === 0) {
        row['diameter'] = ''
      }
      if (row['diameter']) {
        row['diameter'] = Number(row['diameter'])
      }
    },
    // 拖动放入时, 阻止事件冒泡
    handleDragOver(event) {
      event.preventDefault()
    },
    handleDragStart(val) {
      this.settingField = val.label_type
    },
    handleInputCheckDP() {
      const reg = /[^0-9.]/g
      this.formData.dpsBrokenBoardSize =
        this.formData.dpsBrokenBoardSize.replace(reg, '')

      if (this.formData.dpsBrokenBoardSize.length > 6) {
        this.formData.dpsBrokenBoardSize =
          this.formData.dpsBrokenBoardSize.slice(0, 6)
      }
    },
    handleInputBlurDP() {
      // 失去焦点，如果当前数据是空的，给成0
      if (!this.formData.dpsBrokenBoardSize) {
        this.formData.dpsBrokenBoardSize = '0'
      }
    },
  },
  watch: {
    obj() {
      let s = JSON.parse(JSON.stringify(this.obj))
      if (!s.YMSetting) {
        s = {
          ...s,
          YMSetting: {
            knife: '',
            cutCode: {
              headerIds: {},
              templateString: '',
            },
            collectKnifeCode: '',
          },
        }
      }
      this.formData = s
    },
  },
}
</script>

<style lang="less" scoped>
@import '~@/style/engraving.less';
.side_working {
  position: relative;
  // width: 1671px;
  // margin: 16px;
  background-color: #fff;
  .wrap {
    .navRightWrapWidth();
    .setItem {
      .main-table {
        overflow-y: auto;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        width: 50%;
        max-height: 407px;
        padding: 24px;
      }
      .operation-btn {
        box-sizing: border-box;
        padding: 24px;
      }
      .text-area {
        display: flex;
        flex-wrap: wrap;
        box-sizing: border-box;
        padding: 24px 24px 0 24px;
        div {
          margin-bottom: 24px;
          span {
            color: rgba(0, 0, 0, 0.8);
            font-weight: 600;
            cursor: default;
          }
          textarea {
            display: block;
            width: 466px;
            height: 113px;
            margin-top: 8px;
            border: 1px solid #dddddd;
            border-radius: 4px;
            resize: none;
          }
        }
        div:nth-child(2n + 1) {
          margin-right: 128px;
        }
        .container {
          width: 466px;
          .tag_title {
            margin: 0;
            padding: 10px 20px;
            background: #f0f0f0;
            & span:first-child {
              font-weight: 600;
              font-size: 20px;
            }
            & span:last-child {
              color: #7b7b7b;
            }
          }
          .tag_warp {
            margin: 0;
            padding: 20px;
            border: 1px solid #f0f0f0;
            > span {
              display: inline-block;
              margin-right: 20px;
              padding: 5px 20px;
              font-weight: 400;
              background: #eaf4f6;
              cursor: pointer;
            }
          }
        }
      }
      .bore_holeSet {
        box-sizing: border-box;
        padding: 24px 24px 0 24px;
        > span {
          color: rgba(0, 0, 0, 0.8);
          font-weight: 600;
          cursor: default;
        }
        .hole_set_wap {
          position: relative;
          padding: 20px 10px;
          border: 1px solid #ddd;
          > div {
            > div {
              display: flex;
              align-items: center;
              justify-content: center;
              &.mid {
                .hole_img {
                  padding: 20px;
                }
              }
              .ant-input {
                width: 200px;
                resize: none;
              }
              .code_box {
                div {
                  display: flex;
                  align-items: center;
                }
                > div:nth-child(1) {
                  margin-bottom: 20px;
                }
              }
            }
          }
        }
      }
      .knife_set {
        display: flex;
        box-sizing: border-box;
        padding: 24px 24px 0 24px;
        > div {
          > span {
            color: rgba(0, 0, 0, 0.8);
            font-weight: 600;
            cursor: default;
          }
          textarea {
            display: block;
            width: 466px;
            height: 113px;
            margin-top: 8px;
            border: 1px solid #dddddd;
            border-radius: 4px;
            resize: none;
          }
          &.left {
            width: 30%;
            margin-right: 50px;
            .chooseKnife {
              margin-bottom: 20px;
              span {
                color: rgba(0, 0, 0, 0.8);
                font-weight: 600;
              }
            }
          }
          &.right {
            width: 50%;
            .tag_title {
              padding: 10px 20px;
              background: #f0f0f0;
              span:nth-child(1) {
                font-weight: 600;
                font-size: 20px;
              }
              span:nth-child(2) {
                color: #7b7b7b;
              }
            }
            .tag_warp {
              padding: 20px;
              border: 1px solid #f0f0f0;
              border-top: none;
              > span {
                display: inline-block;
                margin-right: 20px;
                padding: 5px 20px;
                background-color: #eaf4f6;
                cursor: pointer;
              }
            }
          }
        }
      }
      .coor {
        box-sizing: border-box;
        padding: 24px 24px 0 24px;
        > div {
          margin-bottom: 20px;
        }
      }
      .suffix {
        box-sizing: border-box;
        padding: 24px 24px 0 24px;
        .ant-input {
          width: 100px;
        }
      }
    }
  }
}
</style>
<style lang="less">
.side_working {
  .ant-checkbox + span {
    color: #000 !important;
    font-size: 18px !important;
  }
}
</style>
