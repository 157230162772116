<template>
  <div class="electronic-saw flex flex-dir--top h100" @click="changeRecovery">
    <breadcrumb
      :title="$route.query.line || $t('main.head.productionLine')"
      :childTitle="$t('common.electronicSaw')"
      @goBackPage="goBackPage"
    >
      <div v-if="!previewInfo.isPreview">
        <a-button
          @click.native="handleOpenChangeRecord"
          id="equipment_electronic_saw_change_record"
          class="mr20"
        >
          修改记录
        </a-button>
        <a-button
          @click.native="saveElectronicSaw"
          :disabled="!ableSaveSetting"
          type="primary"
          id="electronicSaw_save_setting_btn"
          ><a-icon type="save" />{{ $t('common.saveSetting') }}</a-button
        >
      </div>
      <div v-if="previewInfo.isApply">
        <a-button
          @click.native="saveElectronicSaw"
          :disabled="!ableSaveSetting"
          type="primary"
          id="electronicSaw_save_setting_btn"
          ><a-icon type="save" />应用设置</a-button
        >
      </div>
    </breadcrumb>
    <div class="electronic-saw-content flex-box--1 h0 y-auto">
      <div class="custom-setting">
        <import-excel
          @getExcelData="getExcelData"
          :isCustomSetting="isCustomSetting"
        >
          <div class="import-form" id="electronicSaw_import_material_btn">
            <img :src="require('../../assets/icon/Import_12pt.svg')" alt="" />
            <span>{{ $t('electronicSaw.importMaterial') }}</span>
          </div>
        </import-excel>
      </div>
      <div :class="['settings', isCustomSetting ? '' : 'settings-diabled']">
        <div class="form-setting">
          <div class="form-setting-title">
            <span>{{ $t('electronicSaw.formSetting') }}</span>
          </div>
          <div class="form-setting-content">
            <div class="file-format form-setting-short">
              <span>{{ $t('electronicSaw.fileFormat') }}</span>
              <a-input
                placeholder=""
                v-model="formData.fileFormat"
                :disabled="!isCustomSetting"
                id="electronicSaw_fileFormat_input"
                @dragover="handleDragOver"
              />
            </div>
            <div class="form-setting-long">
              <span style="color: #bfbfbf">{{
                $t('electronicSaw.isSmallFile')
              }}</span>
              <a-checkbox
                v-model="formData.isSmallFile"
                :disabled="true"
                id="electronicSaw_smallFile_checkbox"
              ></a-checkbox>
            </div>
            <div class="file-format form-setting-short">
              <span>{{ $t('electronicSaw.fileGroupRule') }}</span>
              <a-checkbox
                v-model="formData.useCategoryFolder"
                id="electronicSaw_categoryFolder_checkbox"
              ></a-checkbox>
            </div>

            <div class="file-format form-setting-short">
              <span>{{ $t('electronicSaw.roomsByCategory') }}</span>
              <a-checkbox
                v-model="formData.rooms_by_category"
                id="electronicSaw_categoryRoom_checkbox"
              ></a-checkbox>
            </div>
            <div
              class="file-format form-setting-short"
              v-if="formData.useCategoryFolder || formData.rooms_by_category"
            >
              <span>{{ $t('electronicSaw.delimiter') }}</span>
              <a-input
                class="split-char ml10 mr5"
                @input="modifyDelimiter"
                :maxLength="1"
                v-model="formData.delimiter"
                id="electronicSaw_delimiter_input"
              ></a-input>
              <span class="color-a">{{ $t('fileManagerPage.splitTip') }}</span>
            </div>
            <div class="file-name form-setting-short flex">
              <div class="file_name_box">
                <span>{{ $t('electronicSaw.fileName') }}</span>
                <div
                  :class="[
                    'file_name_box_input',
                    !formData.fileName ? 'custom-head-err-boder ' : '',
                  ]"
                >
                  <a-input
                    :placeholder="$t('electronicSaw.fileNamePlaceholder')"
                    v-model="formData.fileName"
                    :disabled="!isCustomSetting"
                    id="electronicSaw_customMaterial_input"
                    @dragover="handleDragOver"
                  />
                  <div
                    v-if="changeCategory"
                    class="tags-box"
                    ref="draggableTags"
                  >
                    <div
                      v-for="(tag, index) in formData.file_name_list"
                      :key="index"
                      draggable="true"
                      :data-tag="tag"
                      @dragstart="handleDragStart(index)"
                      @dragover.prevent
                      @drop="handleDrop(index)"
                      @close="removeTag(index)"
                      :id="`electronicSaw_tag_item_${index}`"
                    >
                      <span class="tag">
                        {{ tag }}
                        <i
                          v-if="tag === '房间备注'"
                          class="el-icon-close"
                          @click="removeTag(index)"
                          :id="`sawsetting_exportSetting_tag_${index}_remove_btn`"
                        ></i>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="custom_filename">( {{ customFileName }} )</div>
              <div class="ml10 custom_filename" v-if="!formData.fileName">
                <span :class="[!formData.fileName && 'error-info-in']"
                  >({{ $t('electronicSaw.fineNameTip') }})</span
                >
              </div>
            </div>

            <div class="form-setting-long">
              <span>{{ $t('electronicSaw.outputFileEncoding') }}</span>
              <a-radio-group v-model="formData.outputFileEncoding">
                <a-radio
                  value="gbk"
                  id="electronicSaw_outputEncodeing_GBK_radio"
                  ><span id="electronicSaw_outputEncodeing_GBK_radio_label"
                    >GBK</span
                  ></a-radio
                >
                <a-radio
                  value="utf-8-sig"
                  id="electronicSaw_outputEncodeing_UTF8_radio"
                  ><span id="electronicSaw_outputEncodeing_UTF8_radio_label">{{
                    $t('electronicSaw.utf8')
                  }}</span></a-radio
                >
              </a-radio-group>
            </div>
            <div class="form-setting-long">
              <span>{{ $t('electronicSaw.cellTab') }}</span>
              <a-checkbox
                v-model="formData.cellTabs"
                id="electronicSaw_tableTab_checkbox"
              ></a-checkbox>
            </div>
            <div class="texture-setting form-setting-long">
              <span>{{ $t('electronicSaw.texture') }}</span>
              <div class="texture-setting-item">
                <span>{{ $t('common.reverse') }}=</span>
                <a-input
                  :placeholder="$t('common.inputPlaceholder')"
                  :disabled="!isCustomSetting"
                  v-model="formData.acrossTexture"
                  id="electronicSaw_acrossTexture_input"
                  @dragover="handleDragOver"
                ></a-input>
              </div>
              <div class="texture-setting-item">
                <span>{{ $t('common.normal') }}=</span>
                <a-input
                  :placeholder="$t('common.inputPlaceholder')"
                  :disabled="!isCustomSetting"
                  v-model="formData.verticalTexture"
                  id="electronicSaw_vertical_texture_input"
                  @dragover="handleDragOver"
                ></a-input>
              </div>
              <div class="texture-setting-item">
                <span>{{ $t('common.notCare') }}=</span>
                <a-input
                  :placeholder="$t('common.inputPlaceholder')"
                  :disabled="!isCustomSetting"
                  v-model="formData.noneTexture"
                  id="electronicSaw_none_texture_input"
                  @dragover="handleDragOver"
                ></a-input>
              </div>
            </div>
            <!-- 电子开料锯生成标签显示异形 -->
            <div class="saw-special-shape">
              <span>{{ $t('electronicSaw.special') }}</span>
              <div class="special-shape-item">
                <span>{{ $t('common.isSpecial') }}=</span>
                <a-input
                  :placeholder="$t('common.inputPlaceholder')"
                  v-model="formData.abnormal"
                  id="electronicSaw_special_shape_input"
                ></a-input>
              </div>
              <div class="special-shape-item">
                <span>{{ $t('electronicSaw.notSpecial') }}=</span>
                <a-input
                  :placeholder="$t('common.inputPlaceholder')"
                  v-model="formData.not_abnormal"
                  id="electronicSaw_noSpecial_shape_input"
                >
                </a-input>
              </div>
            </div>
            <div class="form-setting-long">
              <span class="small-title mr10"
                >{{ $t('electronicSaw.genSimpleLabelBarcode') }}
              </span>
              <a-checkbox
                v-model="formData.genSimpleLabelBarcode"
                id="electronicSaw_genSimpleLabelBarcode_checkbox"
              ></a-checkbox>
            </div>
            <!--电子锯板件条码唯一 -->
            <div class="form-setting-long">
              <span class="small-title mr10"
                >{{ $t('electronicSaw.uniqueBarCode') }}
              </span>
              <a-checkbox
                :disabled="formData.genSimpleLabelBarcode"
                v-model="formData.uniqueBarCode"
                id="electronicSaw_uniqueBarCode_checkbox"
              ></a-checkbox>
              <span class="color-a"
                >（{{ $t('electronicSaw.uniqueBarCodeTip') }}）</span
              >
            </div>
          </div>
        </div>
        <div class="custom-form">
          <div class="custom-form-title">
            <span>{{ $t('electronicSaw.customeForm') }}</span>
          </div>
          <div class="custom-form-content">
            <div class="tag-box">
              <div class="custom-form-head">
                <span>{{ $t('electronicSaw.tagPool') }}</span>
                <span>{{ $t('electronicSaw.tagPoolTips') }}</span>
              </div>
              <div
                class="custom-form-main"
                v-for="(item, index) in tagList"
                :key="index"
              >
                <div class="tag-item">
                  <span class="tag-title">{{ item.title }}：</span>
                  <div class="tags">
                    <div
                      v-for="(items, indexs) in item.arr"
                      :key="indexs + '1'"
                      :class="['item', `tag-item${indexs}`]"
                      @click="copyTag(indexs)"
                      data-clipboard-action="copy"
                      :data-clipboard-text="'{' + items.label_name_show + '}'"
                      @dragstart.stop="startDrag($event, items)"
                      @mousedown.stop="isRecovery = false"
                      :id="`electronicSaw_tagLake_item_${index}_${indexs}`"
                      draggable="true"
                    >
                      <span>{{ items.label_name_show }}</span>
                      <div
                        :class="{
                          hidden: true,
                          flag: items.label_name === 'process_label',
                          'special-flag': isCustomUser,
                        }"
                      >
                        {{ isCustomUser ? '定制功能' : '限免' }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="main-table" ref="main_table">
              <div class="table-head">
                <div
                  v-for="(items, indexs) in formatHead"
                  :key="indexs + '1'"
                  class="table-head-item"
                >
                  <span
                    class="table-format-head"
                    :ref="`format_table_headtext${indexs}`"
                    v-if="items.id != headIndex"
                    @click="editHead(items.id)"
                    :id="`electronicSaw_format_table_head_${indexs}`"
                    >{{ items.head }}</span
                  >
                  <a-input
                    v-else
                    v-model="items.head"
                    ref="format_table_head"
                    @blur="modifyHead"
                    @keypress.enter="modifyHead"
                    :id="`electronicSaw_format_table_head_${indexs}_input`"
                  ></a-input>
                </div>
              </div>
              <div class="table-format">
                <div
                  class="table-format-item"
                  v-for="(item, index) in formatInput"
                  :key="index"
                >
                  <a-input
                    :disabled="!isCustomSetting"
                    v-model="item.word"
                    @drop="dropOver($event, item)"
                    @dragover="dragOver($event, index)"
                    :ref="`format_table_input${index}`"
                    @focus="editFormatInput(index)"
                    @blur="cancelEditFormat(index)"
                    :id="`electronicSaw_format_input_${index}`"
                  ></a-input>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="form-example">
          <div class="form-example-topbar">
            <div class="topbar-left">
              <div class="show-table-header">
                <span @click="changeShowTableHeader">{{
                  $t('electronicSaw.displayHead')
                }}</span>
                <a-checkbox
                  v-model="showTableHeader"
                  :disabled="!isCustomSetting"
                  id="electronicSaw_showTableHeader_checkbox"
                ></a-checkbox>
              </div>
            </div>
            <div class="topbar-right">
              <a-button
                :disabled="!isCustomSetting"
                @click="addNewHead"
                class="mr12"
                id="electronicSaw_addNewHead_btn"
                >{{ $t('electronicSaw.addTableHead') }}</a-button
              >
              <a-button
                :disabled="!isCustomSetting"
                @click="deleteHead"
                class="mr12"
                id="electronicSaw_deleteHead_btn"
                >{{ $t('electronicSaw.deleteTableHead') }}</a-button
              >
              <a-button
                :disabled="!isCustomSetting"
                @click="generateExample"
                class="mr12"
                id="electronicSaw_generaExample_btn"
                >{{ $t('electronicSaw.generateExample') }}</a-button
              >
            </div>
          </div>
          <div class="example-table">
            <div class="title">
              <span>{{ $t('electronicSaw.importExample') }}</span>
              <span>{{ $t('electronicSaw.importExampleTip') }}</span>
            </div>
            <div class="example-table-main">
              <el-table
                :data="importExampleTable"
                :cell-style="changeCellStyle"
                :row-style="changeRowStyle"
                :header-row-style="changeHeaderRowStyle"
                :header-cell-style="changeHeaderCellStyle"
                v-if="importExampleTable.length > 0"
              >
                <el-table-column
                  :label="item.label"
                  :prop="item.prop"
                  v-for="(item, index) in importExampleColumn"
                  :key="index"
                ></el-table-column>
              </el-table>
              <div v-else class="empty-box">
                <img :src="require('../../assets/icon/缺省.svg')" alt="" />
                <span>{{ $t('electronicSaw.importExampleErrTip') }}</span>
              </div>
            </div>
          </div>
          <div class="example-table example-table-create">
            <div class="title">
              <span>{{ $t('electronicSaw.outputExample') }}</span>
              <span>{{ $t('electronicSaw.outputExampleTip') }}</span>
            </div>
            <div class="example-table-main">
              <el-table
                :data="exampleTables"
                :cell-style="changeCellStyle"
                :row-style="changeRowStyle"
                :header-row-style="changeHeaderRowStyle"
                :header-cell-style="changeHeaderCellStyle"
                v-if="exampleTables.length > 0"
              >
                <el-table-column
                  :label="item.head"
                  :prop="item.prop"
                  v-for="(item, index) in formatHead"
                  :key="index"
                ></el-table-column>
              </el-table>
              <div v-else class="empty-box">
                <img :src="require('../../assets/icon/缺省.svg')" alt="" />
                <span>{{ $t('electronicSaw.outputExampleErrTip') }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <m-lock-modal
      :visible.sync="isShowVisible"
      :status.sync="lockStatus"
      :tempStatus.sync="tempStatus"
      :verifyPassword="verifyPassword"
      :lineName="$route.query.line"
      @getLockPassword="handleGetLockPassword"
      @handleChangePasswordState="handleChangePasswordState"
    ></m-lock-modal>
    <!-- 修改记录弹窗 -->
    <m-change-record-modal
      :visible.sync="showRecordModal"
      operate_equipment="electronicSaw"
      @success="handleSuccess"
      ref="recordModalRef"
    />
  </div>
</template>

<script>
import { buryPoint } from '@/apis/common/buryPoint'
import { getLockStatus } from '@/apis/productionLineLock'
import importExcel from '@/components/importExcel.vue'
import breadcrumb from '@/components/topBreadCrumb.vue'
import store from '@/store'
import {
  addOperateTableToRecord,
  compareDiffForm,
  genFinalRecord,
  genUniId,
} from '@/util/commonFuncs.js'
import {
  scrollToElementFn, // genUniId,
} from '@/util/commonFuncs.js'
import { operateRecordTableType, webId } from '@/util/configure'
import {
  electronicFormTextObj,
  electronicFormValueObj,
} from '@/util/operateRecordConfigure/electronicSawConfigure'
import { saveDataJsonToOssForRecord } from '@/util/saveFile'
import MChangeRecordModal from '@/views/equipment/component/m-change-record-modal.vue'
import MLockModal from '@/views/equipment/component/m-lock-modal.vue'
import _ from 'lodash'
import { mapState } from 'vuex'

export default {
  components: {
    breadcrumb,
    importExcel,
    MLockModal,
    MChangeRecordModal,
  },
  data() {
    return {
      tagsData: {
        normalTags: ['材质', '颜色', '厚度'],
        roomTags: ['房间名', '房间备注'],
      },
      changeCategory: false,
      // 当前拖动的标签索引
      dragItem: null,
      // 记录是否为自定义设置
      isCustomSetting: true,
      // 所有设置
      formData: {
        file_name_list: ['材质', '颜色', '厚度'],
        delimiter: '_',
        fileFormat: 'xls',
        fileName: '',
        smallFile: false,
        // 记录是否为导出小板图片文件
        isSmallFile: false,
        // 横纹
        acrossTexture: '',
        // 竖纹
        verticalTexture: '',
        // 无纹理
        noneTexture: '',
        // 输出清单编码格式
        outputFileEncoding: 'gbk',
        useCategoryFolder: false,
        rooms_by_category: false,
        // 单元格制表符
        cellTabs: true,
        genSimpleLabelBarcode: false,
        uniqueBarCode: false,
        // 异形
        abnormal: '',
        // 不是异形
        not_abnormal: '',
      },
      // 标签池
      tagList: [],
      // 格式表头
      formatHead: [{ head: '默认表头', id: 1 }],
      // 记录当前点击的表头
      headIndex: -1,
      // 格式输入框
      formatInput: [{ word: '' }],
      // 是否显示表头
      showTableHeader: false,
      importExampleTable: [],
      exampleTables: [],
      // 记录导入的表格列信息
      importExampleColumn: [],
      // 记录当前拖动的值
      dragWord: '',
      // 是否复原输入框
      isRecovery: true,
      // 是否为天工免费版
      showTiangong: false,
      // 记录表单格式的输入框下标
      nowInputIndex: -1,
      // 记录是否可以进行保存
      ableSaveSetting: false,
      // 原始表单
      oriFormData: {},
      lockStatus: 1, // 锁状态
      verifyPassword: true,
      lockPassword: '',
      tempStatus: 1, //默认状态
      isShowVisible: false,
      showRecordModal: false,
      specialUserIds: [483319, 739146, 322626, 900582], //322626为用户的id，其余为测试
    }
  },
  methods: {
    handleDragStart(index) {
      this.dragItem = index
    },
    handleDrop(targetIndex) {
      // if (this.formData.useCategoryFolder || !this.formData.rooms_by_category) {
      if (this.dragItem !== null) {
        const draggedItem = this.formData.file_name_list[this.dragItem]
        this.formData.file_name_list.splice(this.dragItem, 1)
        this.formData.file_name_list.splice(targetIndex, 0, draggedItem)
        this.dragItem = null
        // }
      }
    },
    removeTag(index) {
      this.formData.file_name_list.splice(index, 1)
    },
    // 点击分类文件夹需要实时改动文件名
    handleChanges(useCategoryFolder, rooms_by_category) {
      if (useCategoryFolder && rooms_by_category) {
        this.changeCategory = true
        this.formData.file_name_list = _.cloneDeep(this.tagsData.normalTags)
      } else if (useCategoryFolder) {
        this.changeCategory = true
        this.formData.file_name_list = _.cloneDeep(this.tagsData.normalTags)
      } else if (rooms_by_category) {
        this.changeCategory = true
        this.formData.file_name_list = _.cloneDeep(this.tagsData.roomTags)
        this.tagsData.roomTags = ['房间名', '房间备注']
      } else {
        this.changeCategory = false
        this.formData.file_name_list = []
      }
    },
    // 分隔符限制
    modifyDelimiter() {
      let reg = /[^-_]/
      this.formData.delimiter = this.formData.delimiter.replace(
        new RegExp(reg, 'g'),
        ''
      )
    },
    async handleGetLockPassword() {
      await this.saveElectronicSaw()
    },
    handleChangePasswordState(state) {
      this.verifyPassword = state
    },
    changeRecovery() {
      this.isRecovery = true
    },
    // 回到设备对接页面
    goBackPage() {
      this.nowPath = ''
      let path = ''
      if (this.showTiangong) {
        path = '/equipment?from=tiangong'
      } else {
        path = '/equipment'
      }
      if (this.previewInfo.isPreview) {
        path = `${path}-preview`
      }

      this.$router.push(path)
    },
    // 更改是否为自定义设置
    onChange(checked) {
      this.isCustomSetting = checked
    },
    // 更改列的样式
    changeCellStyle({ row, column, rowIndex, columnIndex }) {
      if (column.property == 'qrcode') {
        return 'padding-left: 16px;'
      }
    },
    // 更改行的样式
    changeRowStyle({ row, rowIndex }) {
      if (!this.isCustomSetting) {
        return {
          height: '52px',
          'font-size': '14px',
          'font-weight': '500',
          color: '#bfbfbf',
        }
      } else {
        return {
          height: '52px',
          'font-size': '14px',
          'font-weight': '500',
        }
      }
    },
    // 更改表头行的样式
    changeHeaderRowStyle({ row, rowIndex }) {
      if (this.showTableHeader) {
        return {
          display: 'none',
        }
      }
      if (!this.isCustomSetting) {
        return {
          background: '#F0F0F0',
          height: '52px',
          'font-size': '14px',
          'font-weight': '500',
          color: '#bfbfbf',
        }
      }
      return {
        background: '#F0F0F0',
        height: '52px',
        'font-size': '14px',
        'font-weight': '500',
      }
    },
    // 更改表头列的样式
    changeHeaderCellStyle({ row, column, rowIndex, columnIndex }) {
      if (column.property == 'qrcode') {
        return 'padding-left: 16px;'
      }
    },
    // 点击文字切换选中状态
    changeShowTableHeader() {
      this.showTableHeader = !this.showTableHeader
    },
    // 复制标签
    copyTag(index) {
      this.isRecovery = false
      var clipboard = new this.$copy(`.tag-item${index}`)
      clipboard.on('success', (e) => {
        this.$message({
          type: 'success',
          message: '复制成功!',
          offset: 70,
          duration: 1000,
        })
        clipboard.destroy()
      })
      clipboard.on('error', (e) => {
        this.$message({
          type: 'success',
          message: this.$t('cuttingDock.NCExportSettings.copyErr'),
          offset: 70,
          duration: 1000,
        })
        clipboard.destroy()
      })
    },
    // 添加表头
    addNewHead() {
      let id
      // 如果删除了默认表头, 则设置默认id为0, 否则设置当前id最大值+1
      if (this.formatHead.length == 0) {
        id = 0
      } else {
        id = this.formatHead[this.formatHead.length - 1].id
      }
      // 给新加项添加唯一webId
      const webIdTemp = genUniId()
      this.formatHead.push({
        head: '',
        id: id + 1,
        [webId]: webIdTemp,
      })
      // 生成表头的同时, 生成对应的输入框
      this.formatInput.push({
        word: '',
        [webId]: webIdTemp,
      })
      // 将新生成的表头自动显示输入框并获取焦点
      this.headIndex = id + 1
      this.$nextTick(() => {
        this.$refs.format_table_head[0].focus()
      })
      this.showScroll()
    },
    // 删除选中的表头, 同时删除对应的输入框
    deleteHead() {
      if (this.formatInput.length == 0) {
        this.$message({
          type: 'info',
          message: this.$t('electronicSaw.deleteHeadErrTip'),
          duration: 1000,
          offset: 70,
        })
      } else {
        this.formatInput.pop()
        this.formatHead.pop()
      }
      this.showScroll()
    },
    // 点击表头显示输入框并获取焦点
    editHead(index) {
      this.headIndex = index
      this.$nextTick(() => {
        this.$refs.format_table_head[0].focus()
      })
    },
    // 结束编辑, 隐藏输入框
    modifyHead() {
      this.headIndex = -1
    },
    // 根据设置的格式生成表单样例
    generateExample() {
      let list = []
      let obj = {}
      this.tagList.forEach((v) => {
        list = [...list, ...v.arr]
      })
      // 将数据拆开替换成对应参考数据
      for (let j in this.formatInput) {
        this.formatInput[j][`prop${j}`] = this.formatInput[j].word
        // 获取输入框内存在的格式对象, 并记录下来
        if (this.formatInput[j].word != '') {
          let temp = this.formatInput[j].word.match(/\{(.+?)\}/g)
          if (temp != null) {
            let s = temp.map((item) => item.replace(/(\{|\})/g, ''))
            // 重新取键名, 将替换后的数据存进新的键值
            list.forEach((x) => {
              s.forEach((v) => {
                if (x.label_name_show == v) {
                  let ss = this.formatInput[j][`prop${j}`].replace(
                    `{${x.label_name_show}}`,
                    x.val
                  )
                  this.formatInput[j][`prop${j}`] = ss
                }
              })
            })
            this.formatHead[j].prop = `prop${j}`
            obj[`prop${j}`] = this.formatInput[j][`prop${j}`]
          } else {
            this.formatHead[j].prop = `props${j}`
            obj[`props${j}`] = this.formatInput[j][`prop${j}`]
          }
        }
      }
      // 将数据放进一个对象, 保证最后生成的表格只有一行数据
      this.exampleTables = [obj]
    },
    // 获取电子锯配置
    getSettings() {
      let data = JSON.stringify({
        setting_id: this.$route.query.setting_id,
        setting_type: 'electronicSaw',
      })
      this.$token('/load_electronic_saw_setting', data, (res) => {
        if (res.status == 1) {
          this.dealElecSawSetting(res)
          this.oriHeaderSettings = JSON.parse(
            JSON.stringify(res.data.headerSettings)
          )
          this.oriFormData = JSON.parse(JSON.stringify(this.formData))
          // 后台应用生产线时进入
          if (this.previewProductLine?.electronic) {
            this.dealElecSawSetting({
              data: this.previewProductLine?.electronic,
            })
          }
          // this.ableSaveSetting = true
          // // TODO: 是否为自定义电子锯格式
          // this.isCustomSetting = true
          // this.formData.fileFormat = res.data.outputFileFormat
          // this.formData.fileName = res.data.fileName
          // this.formData.delimiter = res.data.delimiter
          // // TODO: 导出小板文件
          // this.formData.isSmallFile = false
          // this.formData.acrossTexture = res.data.textureSettings.reverse
          // this.formData.verticalTexture = res.data.textureSettings.normal
          // this.formData.noneTexture = res.data.textureSettings.notcare
          // this.formData.outputFileEncoding = res.data.outputFileEncoding
          // this.formData.useCategoryFolder = res.data.useCategoryFolder
          // this.formData.rooms_by_category = res.data.rooms_by_category
          // if (this.formData.useCategoryFolder) {
          //   this.tagsData.normalTags = res.data.file_name_list
          // } else if (this.formData.rooms_by_category) {
          //   this.tagsData.roomTags = res.data.file_name_list
          // }
          // this.formData.file_name_list = res.data.file_name_list
          // this.formData.cellTabs = res.data.cellTabs
          // this.formData.genSimpleLabelBarcode = res.data.genSimpleLabelBarcode
          // this.formData.uniqueBarCode = res.data.uniqueBarCode
          // // 获取电子锯的是否异形配置
          // this.formData.abnormal = res.data.abnormal_show?.abnormal
          // this.formData.not_abnormal = res.data.abnormal_show?.not_abnormal
          // this.formatHead = []
          // this.formatInput = []
          // let id = 0
          // res.data.headerSettings.forEach((v) => {
          //   let webIdTemp = v.webId
          //   if (!v.webId) webIdTemp = genUniId()
          //   v[webId] = webIdTemp
          //   this.formatHead.push({
          //     head: v.importHeader,
          //     id: id,
          //     [webId]: webIdTemp, // 添加唯一id
          //   })
          //   this.formatInput.push({
          //     word: v.headerString,
          //     [webId]: webIdTemp, // 添加唯一id
          //   })
          //   id = id + 1
          // })
          // this.oriHeaderSettings = JSON.parse(
          //   JSON.stringify(res.data.headerSettings)
          // )
          // this.oriFormData = JSON.parse(JSON.stringify(this.formData))
          // this.showScroll()
        } else if (res.result) {
          this.$message({
            type: 'error',
            message: res?.msg || this.$t('lineManager.permissionTip'),
            duration: 1500,
            offset: 70,
          })
        }
      })
    },
    saveDataJsonToOssForRecordFn(record, option, cb) {
      saveDataJsonToOssForRecord(record, 'equipment_docking', option, cb)
    },
    compareElectronic(current, ori) {
      const record = []
      const compareMsg = [
        {
          title: '电子锯表单设置',
          keys: [
            'fileFormat',
            'isSmallFile',
            'useCategoryFolder',
            'rooms_by_category',
            'fileName',
            'outputFileEncoding',
            'acrossTexture',
            'verticalTexture',
            'noneTexture',
            'cellTabs',
            'genSimpleLabelBarcode',
            'uniqueBarCode',
            // 新增是否异形部分
            'abnormal',
            'not_abnormal',
          ],
        },
        {
          title: '自定义表单',
          keys: ['formatHead', 'formatInput'],
        },
      ]

      // 比较form的不同项
      const formChangeArr = compareDiffForm(
        current,
        ori,
        compareMsg,
        electronicFormTextObj,
        electronicFormValueObj
      )

      if (formChangeArr.length) {
        record.push(...formChangeArr)
      }
      // 生成当前list
      let currentList = []
      // this.formatHead.forEach((item, index) => {
      //   currentList.push({
      //     ...item,
      //     ...this.formatInput[index],
      //     importHeader: item.head,
      //     headerString: this.formatInput[index].word,
      //   })
      // })
      // if (!current.headerSettings?.length) {
      // } else {
      //   currentList = current.headerSettings
      // }
      // 给record添加表格操作记录
      addOperateTableToRecord(
        current.headerSettings, // 当前板件列表
        this.oriHeaderSettings, // 原始板件列表
        ['importHeader', 'headerString'],
        operateRecordTableType.CUSTOMER_FORM_TABLE,
        '自定义表单',
        record,
        'webId',
        'importHeader'
      )
      return record
    },
    // 生成最终的操作记录
    genFinalRecord(data = null, callback = null) {
      // 获取到表单数据(还原时，传入的data为传入后端的数据，不只是formData)
      const newFormData = this.dealKeys(data.setting_value)
      const finalRecord = this.compareElectronic(newFormData, this.oriFormData)
      if (finalRecord.length) {
        this.saveDataJsonToOssForRecordFn(
          {
            key: '电子锯',
            value: finalRecord,
          },
          {
            id: this.$route.query.setting_id,
            operate_equipment: 'electronicSaw',
            save_data: data,
            setting_id: this.$route.query.setting_id,
            operatorPhone: this.previewInfo.operatorPhone,
          },
          () => {
            if (!this.previewInfo.isApply) {
              // 重置 数据
              this.getSettings()
            }
            // 重新获取操作记录数据
            callback && callback()
          }
        )
      }
    },
    // 保存配置
    saveElectronicSaw() {
      // 校验 开料清单(必传)
      const fileNameNode = document.getElementById(
        'electronicSaw_customMaterial_input'
      )
      if (!this.formData.fileName) {
        scrollToElementFn(fileNameNode)
        return
      }

      // 判断锁定状态，打开弹窗
      // 根据获取的状态 锁定状态
      this.lockStatus = this.tempStatus
      if (this.showTiangong) {
        this.$message({
          message: this.$t('cuttingDock.permissionErr'),
          type: 'info',
          duration: 1500,
          offset: 60,
        })
        return
      }
      let list = []
      this.tagList.forEach((v) => {
        list = [...list, ...v.arr]
      })
      // 将数据处理成接口所需数据结构
      let arr = []
      for (let i in this.formatHead) {
        arr.push({
          importHeader: this.formatHead[i].head,
          headerString: this.formatInput[i].word,
          webId: this.formatHead[i]['webId'],
        })
        arr[i].headerIds = {}
        if (this.formatInput[i].word != '') {
          let words = this.formatInput[i].word.match(/\{(.+?)\}/g)
          let s = words ? words.map((item) => item.replace(/(\{|\})/g, '')) : []
          list.forEach((x) => {
            s.forEach((v) => {
              if (x.label_name_show == v) {
                arr[i].headerIds[v] = x.id
              }
            })
          })
        }
      }
      let obj = {
        ...store.state.saveInfo,
        platform: 'bluen',
        setting_id: this.$route.query.setting_id,
        setting_type: 'electronicSaw',
        setting_value: {
          electronicSawCustomizeFormat: this.isCustomSetting,
          fileName: this.formData.fileName,
          file_name_list: this.formData.file_name_list,
          delimiter: this.formData.delimiter,
          exportImage: this.formData.isSmallFile,
          outputFileFormat: this.formData.fileFormat,
          outputFileEncoding: this.formData.outputFileEncoding,
          textureSettings: {
            reverse: this.formData.acrossTexture,
            normal: this.formData.verticalTexture,
            notcare: this.formData.noneTexture,
          },
          rooms_by_category: this.formData.rooms_by_category,
          useCategoryFolder: this.formData.useCategoryFolder,
          genSimpleLabelBarcode: this.formData.genSimpleLabelBarcode,
          uniqueBarCode: this.formData.uniqueBarCode,
          headerSettings: arr,
          cellTabs: this.formData.cellTabs,
          abnormal_show: {
            abnormal: this.formData.abnormal,
            not_abnormal: this.formData.not_abnormal,
          },
        },
      }
      this.ableSaveSetting = false
      this.$store.commit('setCurrentProductLineName', this.$route.query.line)
      const settingVals = obj.setting_value.headerSettings.map(
        (setting) => setting.headerString
      )
      const buryPointPayloadList = []
      if (settingVals.some((val) => val.includes('是否异形'))) {
        buryPointPayloadList.push({
          function_module: 'electronic_saw',
          function_point: 'is_abnormal',
        })
      }
      if (settingVals.some((val) => val.includes('文件导出日期'))) {
        buryPointPayloadList.push({
          function_module: 'electronic_saw',
          function_point: 'file_export_date',
        })
      }
      try {
        buryPoint(buryPointPayloadList)
      } catch (error) {
        throw error
      }
      this.$token(
        '/save_electronic_saw_setting',
        JSON.stringify(obj),
        (res) => {
          this.ableSaveSetting = true
          // 生成最终的操作记录
          if (res.status == 1) {
            try {
              this.genFinalRecord(obj)
            } catch (error) {
              console.error('error', error)
            }
            this.isShowVisible = false
            if (res.data.phone && res.data.request_ip) {
              let recordData = {
                key: '保存设置',
                operateArr: [
                  {
                    key: '生产线设置',
                    dataArr: [
                      {
                        type: 'form',
                        current: {
                          phone: res.data.phone,
                          time: new Date().toLocaleString(),
                          id: store.state.userInfo.id,
                          ip: res.data.request_ip,
                        },
                        ori: {
                          phone: '',
                          time: '',
                          id: '',
                          ip: '',
                        },
                        compareMsg: [
                          {
                            title: '',
                            keys: ['phone', 'time', 'id', 'ip'],
                          },
                        ],
                        formTextObj: {
                          phone: '手机号',
                          time: '保存时间',
                          id: '用户id',
                          ip: '物理地址',
                        },
                        formValueObj: {},
                      },
                    ],
                  },
                ],
              }
              genFinalRecord(recordData, 'equipment_docking', '', () => {})
            }
            this.$message({
              message: this.$t('common.saveSuccess'),
              type: 'success',
              duration: 1500,
              offset: 70,
            })
          } else if (res.status == -1) {
            if (store.state.saveInfo.phone) {
              this.$message({
                message: res.msg,
                type: 'error',
                duration: 1500,
                offset: 70,
              })
            }
            this.showSaveModal()
          } else if (res.result) {
            this.$message({
              type: 'info',
              message: this.$t('lineManager.permissionTip'),
              duration: 1500,
              offset: 70,
            })
          }
          store.commit('setSaveInfo', {})
        }
      )
    },
    showSaveModal() {
      this.lockStatus = this.tempStatus
      this.verifyPassword = true
      this.isShowVisible = true
      return
    },
    // 获取表格配置
    getExcelData(val, type) {
      // 设置表头
      this.formatHead = []
      this.formatInput = []
      if (type == 'xls' || type == 'xlsx') {
        // 将导入的文件格式设为电子锯导出格式
        this.formData.fileFormat = type
        // 获取excel表格中前三行数据当做导入样例显示
        let example = val.slice(0, 3)
        this.importExampleTable = example
        // 清空用于渲染表格的列数组
        this.importExampleColumn = []
        // 获取表头
        let arr = Object.keys(val[0])
        // 设置表头索引
        let id = 0
        // 设置对应表头, 对应输入框和对应的用于渲染的列数组
        arr.forEach((v) => {
          this.formatHead.push({
            head: v,
            id: id,
          })
          this.formatInput.push({
            word: '',
          })
          this.importExampleColumn.push({
            label: v,
            prop: v,
          })
          id = id + 1
        })
      } else {
        this.formData.fileFormat = 'csv'
        this.importExampleColumn = []
        this.importExampleTable = []
        // 由于csv获取到的数组是文本, 所以需要根据换行符拆分, 拿到csv文件的表头
        let allData = val.target.result.split(/\n/)
        // 获取前三行数据
        let data = allData.slice(1, 4)
        // 获取表头
        let headData = allData[0].split(',')
        // 设置渲染的数组
        data.forEach((v) => {
          let obj = {}
          let temp = v.split(',')
          for (let i in headData) {
            obj[headData[i]] = temp[i]
          }
          this.importExampleTable.push(obj)
        })
        // 为表头设置索引
        let id = 0
        headData.forEach((v) => {
          this.formatHead.push({
            head: v,
            id: id,
          })
          this.formatInput.push({
            word: '',
          })
          this.importExampleColumn.push({
            label: v,
            prop: v,
          })
          id = id + 1
        })
      }
      this.showScroll()
    },
    // 显示滚动条
    showScroll() {
      this.$nextTick(() => {
        if (
          this.$refs.main_table.offsetWidth < this.$refs.main_table.scrollWidth
        ) {
          this.$refs.main_table.style.overflowX = 'scroll'
        } else {
          this.$refs.main_table.style.overflowX = ''
        }
      })
    },
    // 拖拽标签
    startDrag(event, item) {
      this.isRecovery = false
      this.dragWord = `{${item.label_name_show}}`
      this.$nextTick(() => {
        event.dataTransfer.setData('Text', event.target.classList[0])
      })
    },
    // 放入时触发函数, 深度拷贝拖动的标签dom, 将克隆之后的标签放入盒子, 不影响之前的dom
    dropOver(event, item) {
      event.target.focus()
      item.word += this.dragWord
      this.$nextTick(() => {
        event.preventDefault()
        var data = event.dataTransfer.getData('Text')
        let s = document.querySelector(`.${data}`).cloneNode(true)
        event.target.appendChild(s)
      })
    },
    // 拖动放入时, 放宽输入框
    dragOver(event, index) {
      this.$nextTick(() => {
        // 记录当前输入框的编号, 如果从来没有记录过则进行记录, 如果记录过则将记录的输入框宽度恢复原样
        if (this.nowInputIndex != -1) {
          try {
            const tableInput =
              this.$refs[`format_table_input${this.nowInputIndex}`][0]
            if (tableInput) {
              tableInput.$el.style.width = '92px'
            }

            const tableHeadtext =
              this.$refs[`format_table_headtext${this.nowInputIndex}`][0]
            if (tableHeadtext) {
              tableHeadtext.style.width = '92px'
            }
          } catch (error) {
            console.error('error', error)
          }

          this.nowInputIndex = index
        } else {
          this.nowInputIndex = index
        }
        event.target.focus()
        const tableInput = this.$refs[`format_table_input${index}`][0]
        if (tableInput) {
          tableInput.$el.style.width = '290px'
        }

        const tableHeadtext = this.$refs[`format_table_headtext${index}`][0]
        if (tableHeadtext) {
          tableHeadtext.style.width = '290px'
        }
        this.showScroll()
      })
    },
    // 获取焦点时, 放宽输入框
    editFormatInput(index) {
      this.$nextTick(() => {
        this.$refs[`format_table_input${index}`][0].$el.style.width = '290px'
        this.$refs[`format_table_headtext${index}`][0].style.width = '290px'
        this.showScroll()
      })
    },
    // 失去焦点时, 还原输入框
    cancelEditFormat(index) {
      setTimeout(() => {
        if (this.isRecovery) {
          this.$nextTick(() => {
            const tableInput = this.$refs[`format_table_input${index}`][0]
            if (tableInput) {
              tableInput.$el.style.width = '92px'
            }
            const tableHeadtext = this.$refs[`format_table_headtext${index}`][0]
            if (tableHeadtext) {
              tableHeadtext.style.width = '92px'
            }
            this.showScroll()
          })
        }
      }, 150)
    },
    // 加载标签池
    getLakeTags() {
      this.$get(
        '/get_electronic_saw_label',
        {
          label_class: 'electronicSaw',
        },
        (res) => {
          let obj = res.data
          let keys = Object.keys(obj)
          let list = []
          keys.forEach((v) => {
            switch (v) {
              case 'room_info':
                list.push({
                  title: '订单及房间信息',
                  arr: obj[v],
                })
                break
              case 'plank_info':
                list.push({
                  title: '板件信息',
                  arr: obj[v],
                })
                break
              case 'material_info':
                list.push({
                  title: '材料信息',
                  arr: obj[v],
                })
                break
              case 'edge_info':
                list.push({
                  title: '封边信息',
                  arr: obj[v],
                })
                break
              case 'other_info':
                list.push({
                  title: '其他信息',
                  arr: obj[v],
                })
                break
              case 'five_side_bar_code':
                list.push({
                  title: '五面钻/PTP条码',
                  arr: obj[v],
                })
                break
              case 'six_side_bar_code':
                list.push({
                  title: '六面钻/侧孔机条码',
                  arr: obj[v],
                })
                break
              default:
                break
            }
          })
          this.tagList = list
          this.tagList.forEach((v) => {
            v.arr.forEach((x) => {
              switch (x.label_name) {
                case 'orderNo':
                  x.val = '0001-01'
                  break
                case 'address':
                  x.val = '春新路38号'
                  break
                case 'roomName':
                  x.val = '主卧'
                  break
                case 'loc':
                  x.val = '地柜'
                  break
                case 'name':
                  x.val = '横隔板01'
                  break
                case 'plankNum':
                  x.val = '1234567891234'
                  break
                case 'realRectHeight':
                  x.val = '600'
                  break
                case 'realRectWidth':
                  x.val = '300'
                  break
                case 'oRectHeight':
                  x.val = '601'
                  break
                case 'oRectWidth':
                  x.val = '301'
                  break
                case 'thick':
                  x.val = '18'
                  break
                case 'amount':
                  x.val = '1'
                  break
                case 'matCode':
                  x.val = '多层实木'
                  break
                case 'texture':
                  x.val = 'T01'
                  break
                case 'texDir':
                  x.val = '横纹'
                  break
                case 'up_edge':
                  x.val = '0.5'
                  break
                case 'down_edge':
                  x.val = '0.5'
                  break
                case 'left_edge':
                  x.val = '0.5'
                  break
                case 'right_edge':
                  x.val = '0.5'
                  break
                case 'customer_name':
                  x.val = '张三'
                  break
                case 'orderId':
                  x.val = '1'
                  break
                case 'oRectArea':
                  x.val = '180901.00'
                  break
                case 'realRectArea':
                  x.val = '180000.00'
                  break
                case 'plankID':
                  x.val = '1'
                  break
                case 'onlyPlankID':
                  x.val = '1'
                  break
                case 'handleSlopes':
                  x.val = ''
                  break
                case 'frontBarCode':
                  x.val = '6160479583801'
                  break
                case 'oppositeBarCode':
                  x.val = '6160479583801K'
                  break
                case 'partName':
                  x.val = '主卧_柜A_横隔板02'
                  break
                case 'is_abnormal':
                  x.val = '否'
                  break
                case 'file_export_date':
                  x.val = '2008-08-08 14:22:09'
                  break
                case 'process_label':
                  x.val = '默认铰链'
                  break
                case 'specialFlag':
                  x.val = '三角形'
                  break
                case 'plank_remarks':
                  x.val = '主卧板件'
                  break
                case 'groupNo':
                  x.val = 'FG20240606-2-33'
                  break
                case 'remark':
                  x.val = '房间备注'
                  break
                case 'customer':
                  x.val = '老板良客户'
                  break
                case 'all_edge':
                  x.val = '0.5|0.5|0.5|0.5'
              }
            })
          })
        }
      )
    },
    handleGetLockStatus() {
      //TODO 进入页面，获取生产线设置锁的状态
      getLockStatus().then((res) => {
        // 拿到的锁状态赋值，当前是锁定还是解锁状态
        this.lockStatus = res?.data?.is_lock || 0
        this.tempStatus = res?.data?.is_lock || 0
      })
    },
    // 打开修改记录弹窗
    handleOpenChangeRecord() {
      this.showRecordModal = true
    },
    // 还原成功的回调
    handleSuccess(data) {
      this.genFinalRecord(data.save_data, () => {
        this.$refs['recordModalRef']?.getData({ isReset: true })
      })
    },

    // 表单字段与接口字段映射
    dealKeys(data) {
      if (!data) return
      let temp = {}
      const map = {
        fileName: 'fileName',
        exportImage: 'isSmallFile',
        outputFileFormat: 'fileFormat',
        outputFileEncoding: 'outputFileEncoding',
        reverse: 'acrossTexture',
        normal: 'verticalTexture',
        notcare: 'noneTexture',
        useCategoryFolder: 'useCategoryFolder',
        rooms_by_category: 'rooms_by_category',
        genSimpleLabelBarcode: 'genSimpleLabelBarcode',
        uniqueBarCode: 'uniqueBarCode',
        cellTabs: 'cellTabs',
        abnormal: 'abnormal',
        not_abnormal: 'not_abnormal',
        headerSettings: 'headerSettings',
      }
      // 此处已经确定数据结构，因此未使用递归
      Object.entries(data).forEach(([key, value]) => {
        if (map[key]) {
          temp[map[key]] = value
        }
        if (typeof value === 'object') {
          Object.entries(value).forEach(([k, v]) => {
            if (map[k]) {
              temp[map[k]] = v
            }
          })
        }
      })
      return temp
    },
    handleDragOver(event) {
      event.preventDefault()
    },

    // 处理电子锯设置
    dealElecSawSetting(res) {
      this.ableSaveSetting = true
      // TODO: 是否为自定义电子锯格式
      this.isCustomSetting = true
      this.formData.fileFormat = res.data.outputFileFormat
      this.formData.fileName = res.data.fileName
      this.formData.delimiter = res.data.delimiter
      // TODO: 导出小板文件
      this.formData.isSmallFile = false
      this.formData.acrossTexture = res.data.textureSettings.reverse
      this.formData.verticalTexture = res.data.textureSettings.normal
      this.formData.noneTexture = res.data.textureSettings.notcare
      this.formData.outputFileEncoding = res.data.outputFileEncoding
      this.formData.useCategoryFolder = res.data.useCategoryFolder
      this.formData.rooms_by_category = res.data.rooms_by_category
      if (this.formData.useCategoryFolder) {
        this.tagsData.normalTags = res.data.file_name_list
      } else if (this.formData.rooms_by_category) {
        this.tagsData.roomTags = res.data.file_name_list
      }
      this.formData.file_name_list = res.data.file_name_list
      this.formData.cellTabs = res.data.cellTabs
      this.formData.genSimpleLabelBarcode = res.data.genSimpleLabelBarcode
      this.formData.uniqueBarCode = res.data.uniqueBarCode
      // 获取电子锯的是否异形配置
      this.formData.abnormal = res.data.abnormal_show?.abnormal
      this.formData.not_abnormal = res.data.abnormal_show?.not_abnormal
      this.formatHead = []
      this.formatInput = []
      let id = 0
      res.data.headerSettings.forEach((v) => {
        let webIdTemp = v.webId
        if (!v.webId) webIdTemp = genUniId()
        v[webId] = webIdTemp
        this.formatHead.push({
          head: v.importHeader,
          id: id,
          [webId]: webIdTemp, // 添加唯一id
        })
        this.formatInput.push({
          word: v.headerString,
          [webId]: webIdTemp, // 添加唯一id
        })
        id = id + 1
      })
      // this.oriHeaderSettings = JSON.parse(
      //   JSON.stringify(res.data.headerSettings)
      // )
      // this.oriFormData = JSON.parse(JSON.stringify(this.formData))
      this.showScroll()
    },
  },
  mounted() {
    this.handleGetLockStatus()
    if (this.$route.query.from) {
      if (this.$route.query.from == 'tiangong') {
        this.showTiangong = true
      }
    } else {
      this.showTiangong = false
      this.getLakeTags()
      this.getSettings()
    }
    // if (!window.sessionStorage.getItem("token")) {
    //   this.$message({
    //     type: "info",
    //     message: "身份信息已丢失, 请重新从柜柜中打开此页面!",
    //     duration: 1500,
    //     offset: 70,
    //   });
    // }
  },
  computed: {
    ...mapState(['userInfo']),
    ...mapState('equipEnvironment', ['previewProductLine', 'previewInfo']),
    // 是否是定制用户
    isCustomUser() {
      return this.specialUserIds.includes(this.userInfo?.id)
    },
    // 自定义文件名
    customFileName() {
      const delimiter = this.formData.delimiter
      return (
        this.formData.fileName +
        (this.formData.file_name_list.length ? delimiter : '') +
        this.formData.file_name_list.join(delimiter)
      )
    },
  },
  watch: {
    'formData.useCategoryFolder'(newVal) {
      this.handleChanges(newVal, this.formData.rooms_by_category)
    },
    'formData.rooms_by_category'(newVal) {
      this.handleChanges(this.formData.useCategoryFolder, newVal)
    },
    'formData.genSimpleLabelBarcode'(newVal) {
      if (newVal) this.formData.uniqueBarCode = false
    },
  },
}
</script>

<style scoped lang="less">
.electronic-saw {
  .import-form {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 122px;
    height: 32px;
    line-height: 32px;
    background: #18a8c7;
    border-radius: 4px;

    img {
      margin-right: 8px;
    }

    span {
      color: #fff;
      font-size: 16px;
    }
  }

  .import-form.import-form-diabled {
    background: #f5f5f5;
  }

  .electronic-saw-content {
    margin: 16px;

    .custom-setting {
      display: flex;
      align-items: center;
      box-sizing: border-box;
      height: 93px;
      // margin-bottom: 24px;
      margin-bottom: 16px;
      padding: 32px 24px 33px;
      background: #fff;

      span {
        margin-right: 16px;
        color: #fff;
        font-size: 16px;
      }
    }

    .settings {
      background: #fff;

      ul,
      li {
        list-style: none;
      }

      .form-setting {
        .form-setting-title {
          box-sizing: border-box;
          padding: 24px 24px 16px;
          border-bottom: 1px solid #e9e9e9;

          & > span {
            color: #000;
            font-size: 18px;
          }
        }

        .form-setting-content {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          box-sizing: border-box;
          padding: 32px 24px 0;

          & > div {
            margin-bottom: 16px;
          }

          .form-setting-short {
            width: 42%;
          }

          .form-setting-long {
            width: 58%;
          }

          .file-format {
            width: 100%;

            .ant-input {
              width: 92px;
            }
          }

          .file-name {
            width: 100%;
            .file_name_box {
              display: flex;
              align-items: center;
              justify-content: center;
              .file_name_box_input {
                border: solid 1px #d9d9d9;
                display: flex;
                border-radius: 4px;
                align-items: center;
                min-width: 290px;
                min-height: 42px;
              }
              .custom-head-err-boder {
                border-color: #ff0000;
                // box-shadow: none !important;
                &:focus {
                  box-shadow: 0 0 0 2px rgba(245, 34, 45, 0.16) !important;
                }
              }
            }
            .custom_filename {
              display: flex;
              align-items: center;
              margin-left: 10px;
              .error-info-in {
                color: red;
              }
            }
            .ant-input {
              border: none;
              // width: 209px;
            }
          }

          .texture-setting,
          .saw-special-shape {
            display: flex;
            align-items: center;
            width: 100%;

            .texture-setting-item,
            .special-shape-item {
              margin-right: 24px;

              .ant-input {
                width: 93px;
              }
            }
          }
        }
      }

      .custom-form {
        .custom-form-title {
          box-sizing: border-box;
          padding: 24px 24px 16px;
          border-bottom: 1px solid #e9e9e9;

          & > span {
            color: #000;
            font-size: 18px;
          }
        }

        .custom-form-content {
          box-sizing: border-box;
          padding: 24px;

          .tag-box {
            box-sizing: border-box;
            overflow: hidden;
            border: 1px solid #e9e9e9;
            border-radius: 4px;

            .custom-form-head {
              box-sizing: border-box;
              height: 44px;
              padding: 11px 24px;
              background: #e9e9e9;

              span:first-child {
                color: #000;
                font-weight: 600;
                font-size: 16px;
              }

              span:last-child {
                color: #8c8c8c;
                font-size: 14px;
              }
            }

            .custom-form-main {
              box-sizing: border-box;
              padding: 0 24px;

              &:last-child .tag-item {
                border-bottom: none;
              }

              .tag-item {
                display: flex;
                align-items: flex-start;
                box-sizing: border-box;
                padding: 24px 0 16px;
                border-bottom: 1px solid #e9e9e9;

                .tag-title {
                  user-select: none;
                }

                & > span {
                  display: inline-block;
                  width: 160px;
                  height: 42px;
                  font-size: 16px;
                  line-height: 42px;
                  text-align: right;
                }

                .tags {
                  display: flex;
                  flex: 1;
                  flex-wrap: wrap;
                  align-items: center;
                  & > .item {
                    display: inline-block;
                    min-width: 102px;
                    height: 32px;
                    margin: 5px 8px 5px 0;
                    color: #5a5a5a;
                    font-size: 14px;
                    line-height: 32px;
                    text-align: center;
                    background: #eaf4f6;
                    cursor: pointer;
                    position: relative;
                    .hidden {
                      display: none;
                    }
                    .flag {
                      line-height: normal;
                      display: block;
                      width: fit-content;
                      color: #000;
                      background-color: rgb(249, 224, 199);
                      position: absolute;
                      left: 80%;
                      top: -5px;
                      z-index: 1000;
                      white-space: nowrap;
                      padding: 2px 5px;
                      font-size: 12px;
                      border-radius: 3px;
                    }
                    .special-flag {
                      color: #fff;
                      background-color: #fcdb3f;
                    }
                  }
                }
              }
            }
          }

          .main-table {
            margin-top: 24px;
            border-radius: 4px;

            .table-head {
              display: flex;
              width: fit-content;
              min-width: 100%;
              height: 52px;
              background: #fafafa;
              cursor: pointer;

              .table-head-item {
                display: flex;
                flex-direction: column;
                justify-content: center;

                .ant-input {
                  width: 100px;
                }
              }

              .table-format-head {
                display: inline-block;
                width: 100px;
                height: 52px;
                margin-right: 16px;
                line-height: 52px;
                text-align: left;

                &:hover {
                  color: #18a8c7;
                }
              }
            }

            .table-format {
              display: flex;
              margin-top: 16px;

              .ant-input {
                width: 100px;
                height: 42px;
                margin-right: 16px;
                border: 1px solid #ddd;
                border-radius: 4px;

                &:focus {
                  border-color: #18a8c7;
                }

                &:hover {
                  border-color: #18a8c7;
                }
              }
            }
          }
        }
      }

      .form-example {
        box-sizing: border-box;
        padding: 24px;

        .form-example-topbar {
          display: flex;
          align-items: center;
          justify-content: space-between;
          box-sizing: border-box;
          margin-bottom: 32px;
          padding-top: 24px;
          border-top: 1px solid #e9e9e9;

          .topbar-left {
            display: flex;
            align-items: flex-end;

            .show-table-header {
              margin-left: 16px;
              cursor: pointer;
            }
          }
        }

        .example-table {
          box-sizing: border-box;
          padding: 16px;
          background: #fafafa;

          &.example-table-create {
            margin-top: 16px;
          }

          .title {
            display: flex;
            align-items: center;
            margin-bottom: 16px;

            span:first-child {
              margin-right: 8px;
              color: #000;
              font-size: 16px;
            }

            span:last-child {
              color: #8c8c8c;
              font-size: 14px;
            }
          }

          .example-table-main {
            min-height: 211px;

            .empty-box {
              display: flex;
              flex-direction: column;
              align-items: center;
              text-align: center;

              span {
                margin-top: 16px;
                color: #ccc;
                font-size: 14px;
              }
            }
          }

          .qrcode-box {
            display: -webkit-box; //将对象作为弹性伸缩盒子模型显示。
            width: 126px;
            overflow: hidden; //超出的文本隐藏
            text-overflow: ellipsis; //溢出用省略号显示
            -webkit-box-orient: vertical; //从上到下垂直排列子元素（设置伸缩盒子的子元素排列方式）
            -webkit-line-clamp: 2; //这个属性不是css的规范属性，需要组合上面两个属性，表示显示的行数。
          }
        }
      }
    }

    .settings.settings-diabled {
      span {
        color: #bfbfbf !important;
        cursor: default;
      }
    }
  }
}

.guaguagua {
  width: 100px;
  height: 50px;
  border: 1px solid #000;
}
.tags-box {
  display: flex;
  justify-items: center;
  align-items: center;
  margin-left: 10px;
  .tag {
    display: inline-block;
    min-width: 102px;
    height: 32px;
    margin-right: 8px;
    color: #5a5a5a;
    font-size: 14px;
    line-height: 32px;
    text-align: center;
    background: #eaf4f6;
    & > i {
      margin-left: 8px;
      cursor: pointer;
    }
  }
}
</style>
