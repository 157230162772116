import { languageMap } from '@/views/materialsList/config/languageMap'

interface IPropList {
  // 字段名、插槽名
  prop: string
  // 表头插槽名
  headSlotName: string
  // 宽度
  width: string
  // 最小宽度
  minWidth: string
  //最大宽度
  maxWidth: string
  //内容
  temp?: string
  // 表头名
  label?: string
  // 特殊类型
  interactionType?: string
  // 宽度类型
  widthType?: string
  // 是否显示tooltip
  tooltip?: boolean
  // 是否需要验证数据是否为空
  verify?: boolean
  // 单选对应字段
  selectProp?: string
}

// 根据allTableHeaderList的数据中的label字段，生成表头名的数据类型
export type AllTableHeaderListType = typeof allTableHeaderList[number]['label']

// 料单
const headPropList: IPropList[] = [
  {
    prop: 'select',
    selectProp: 'isSelected',
    headSlotName: 'headSelect',
    label: 'common.select',
    widthType: 'width',
    width: '64px',
    minWidth: '64px',
    maxWidth: '150px',
    temp: '选择',
  },
  {
    prop: 'plankID',
    headSlotName: 'headPlankID',
    label: 'common.plankNo',
    widthType: 'width',
    width: '86px',
    minWidth: '30px',
    maxWidth: '150px',
    temp: '1',
  },
  {
    prop: 'roomName',
    headSlotName: 'headRoomName',
    label: 'common.room',
    interactionType: 'input',
    widthType: 'width',
    width: '80px',
    minWidth: '60px',
    maxWidth: '150px',
    temp: '主卧',
  },
  {
    prop: 'loc',
    headSlotName: 'headLoc',
    label: 'common.loc',
    interactionType: 'input',
    widthType: 'width',
    width: '63px',
    minWidth: '60px',
    maxWidth: '150px',
    temp: '柜A',
  },

  {
    prop: 'partName',
    headSlotName: 'headPartName',
    label: 'common.plankName',
    interactionType: 'input',
    widthType: 'width',
    width: '159px',
    minWidth: '100px',
    maxWidth: '150px',
    temp: '主卧_柜A_中立板01',
    tooltip: true,
  },
  {
    prop: 'matCode',
    headSlotName: 'headMatCode',
    label: 'common.matCode',
    interactionType: 'input',
    widthType: 'width',
    width: '110px',
    minWidth: '60px',
    maxWidth: '150px',
    temp: '多层实木',
    tooltip: true,
    verify: true,
  },
  {
    prop: 'is_high_gloss_plank',
    headSlotName: 'headHighPlank',
    label: 'common.heighGlossPlank',
    interactionType: 'select',
    widthType: 'width',
    width: '110px',
    minWidth: '60px',
    maxWidth: '150px',
    temp: '高光板',
    tooltip: true,
    verify: true,
  },
  {
    prop: 'texture',
    headSlotName: 'headTexture',
    label: 'common.color',
    interactionType: 'input',
    widthType: 'width',
    width: '100px',
    minWidth: '60px',
    maxWidth: '150px',
    temp: 'T01',
    tooltip: true,
    verify: true,
  },
  {
    prop: 'thick',
    headSlotName: 'headThick',
    label: 'common.thick',
    interactionType: 'input',
    widthType: 'width',
    width: '70px',
    temp: '18',
    minWidth: '60px',
    maxWidth: '150px',
    verify: true,
  },
  {
    prop: 'specHeight',
    headSlotName: 'headSpecHeight',
    label: 'common.finishedHeight',
    interactionType: 'input',
    widthType: 'width',
    temp: '2440',
    width: '106px',
    minWidth: '60px',
    maxWidth: '150px',
    verify: true,
  },
  {
    prop: 'specWidth',
    headSlotName: 'headSpecWidth',
    label: 'common.finishedWidth',
    interactionType: 'input',
    widthType: 'width',
    width: '106px',
    temp: '1220',
    minWidth: '60px',
    maxWidth: '150px',
    verify: true,
  },
  {
    prop: 'amount',
    headSlotName: 'headAmount',
    label: 'common.count',
    interactionType: 'input',
    widthType: 'width',
    width: '70px',
    temp: '1',
    minWidth: '30px',
    maxWidth: '150px',
    verify: true,
  },
  {
    prop: 'texDir',
    headSlotName: 'headTexDir',
    label: 'common.texture',
    widthType: 'width',
    width: '116px',
    temp: '竖纹',
    minWidth: '60px',
    maxWidth: '150px',
  },
  {
    prop: 'hsInfo',
    headSlotName: 'headHsInfo',
    label: 'common.holeSlotsStastic',
    widthType: 'width',
    width: '126px',
    temp: '1孔1槽',
    minWidth: '110px',
    maxWidth: '150px',
  },
  {
    prop: 'frontEdge',
    headSlotName: 'headFrontEdge',
    label: 'common.edge.front',
    interactionType: 'input',
    widthType: 'width',
    width: '70px',
    minWidth: '30px',
    temp: '0',
    maxWidth: '150px',
  },
  {
    prop: 'backEdge',
    headSlotName: 'headBackEdge',
    label: 'common.edge.back',
    interactionType: 'input',
    widthType: 'width',
    width: '70px',
    temp: '0',
    maxWidth: '150px',
    minWidth: '30px',
  },
  {
    prop: 'leftEdge',
    headSlotName: 'headLeftEdge',
    label: 'common.edge.left',
    interactionType: 'input',
    widthType: 'width',
    temp: '0',
    width: '70px',
    minWidth: '30px',
    maxWidth: '150px',
  },
  {
    prop: 'rightEdge',
    headSlotName: 'headRightEdge',
    label: 'common.edge.right',
    interactionType: 'input',
    widthType: 'width',
    width: '70px',
    minWidth: '30px',
    temp: '0',
    maxWidth: '150px',
  },
  {
    prop: 'specialShape',
    headSlotName: 'headSpecialShape',
    label: 'common.isSpecial',
    widthType: 'width',
    width: '85px',
    temp: '异形',
    minWidth: '85px',
    maxWidth: '150px',
  },
  {
    prop: 'type',
    headSlotName: 'headType',
    label: 'common.isDoorPlank',
    widthType: 'width',
    width: '180px',
    minWidth: '150px',
    temp: '门板',
    maxWidth: '150px',
  },
]

// 生产文件管理
const productionFillManageList: IPropList[] = [
  {
    prop: 'select',
    selectProp: 'isSelect',
    headSlotName: 'headSelect',
    label: 'common.select',
    widthType: 'width',
    width: '64px',
    minWidth: '64px',
    maxWidth: '150px',
  },
  {
    prop: 'index',
    headSlotName: 'headIndex',
    label: 'common.inde',
    widthType: 'width',
    width: '80px',
    minWidth: '64px',
    maxWidth: '150px',
  },
  {
    prop: 'address',
    headSlotName: 'headAddress',
    label: 'common.orderAddress',
    widthType: 'width',
    width: '340px',
    minWidth: '120px',
    maxWidth: '150px',
    tooltip: true,
  },
  {
    prop: 'order_id',
    headSlotName: 'headOrderId',
    label: 'common.orderNo',
    widthType: 'width',
    width: '180px',
    minWidth: '100px',
    maxWidth: '150px',
    tooltip: true,
  },
  {
    prop: 'product_line',
    headSlotName: 'headProductLine',
    label: 'common.productionLine',
    widthType: 'width',
    width: '280px',
    minWidth: '100px',
    maxWidth: '150px',
    tooltip: true,
  },
  {
    prop: 'create_time',
    headSlotName: 'headSaveTime',
    label: 'taskPage.record.saveDate',
    widthType: 'width',
    width: '200px',
    minWidth: '100px',
    maxWidth: '150px',
    tooltip: true,
  },
  {
    prop: 'operate',
    headSlotName: 'headOperate',
    label: 'common.operation',
    widthType: 'width',
    width: '350px',
    minWidth: '300px',
    maxWidth: '150px',
  },
]
// 补件
const bujianList: IPropList[] = [
  {
    prop: 'plankID',
    headSlotName: 'headPlankID',
    label: 'common.plankNo',
    widthType: 'width',
    width: '80px',
    minWidth: '44px',
    maxWidth: '150px',
  },
  {
    prop: 'matCode',
    headSlotName: 'headMatCode',
    label: 'common.matCode',
    widthType: 'width',
    width: '280px',
    minWidth: '100px',
    maxWidth: '150px',
    tooltip: true,
  },
  {
    prop: 'texture',
    headSlotName: 'headTexture',
    label: 'common.color',
    widthType: 'width',
    width: '180px',
    minWidth: '80px',
    maxWidth: '150px',
    tooltip: true,
  },
  {
    prop: 'oSize',
    headSlotName: 'headOSize',
    label: 'materialPage.plankSize',
    widthType: 'width',
    width: '280px',
    minWidth: '100px',
    maxWidth: '150px',
    tooltip: true,
  },
  {
    prop: 'plankNum',
    headSlotName: 'headPlankNum',
    label: 'common.plankNum',
    widthType: 'width',
    width: '280px',
    minWidth: '100px',
    maxWidth: '150px',
    tooltip: true,
  },
  {
    prop: 'operate',
    headSlotName: 'headOperate',
    label: 'common.operation',
    widthType: 'width',
    width: '350px',
    maxWidth: '150px',
    minWidth: '100px',
  },
]
// 补件仓库
const bujianStoreList: IPropList[] = [
  {
    prop: 'select',
    selectProp: 'isSelect',
    headSlotName: 'headSelect',
    label: 'common.select',
    widthType: 'width',
    width: '64px',
    minWidth: '64px',
    maxWidth: '150px',
  },
  {
    prop: 'plankID',
    headSlotName: 'headPlankId',
    label: 'common.plankNo',
    widthType: 'width',
    width: '100px',
    minWidth: '44px',
    maxWidth: '150px',
  },
  {
    prop: 'matCode',
    headSlotName: 'headMatCode',
    label: 'common.matCode',
    widthType: 'width',
    width: '200px',
    minWidth: '100px',
    tooltip: true,
    maxWidth: '150px',
  },
  {
    prop: 'texture',
    headSlotName: 'headTexture',
    label: 'common.color',
    widthType: 'width',
    width: '180px',
    minWidth: '100px',
    maxWidth: '150px',
    tooltip: true,
  },
  {
    prop: 'sizeStr',
    headSlotName: 'headOSize',
    label: 'materialPage.plankSize2',
    widthType: 'width',
    maxWidth: '150px',
    width: '180px',
    minWidth: '100px',
    tooltip: true,
  },
  {
    prop: 'plankNum',
    headSlotName: 'headPlankNum',
    label: 'common.plankNum',
    widthType: 'width',
    maxWidth: '150px',
    width: '180px',
    minWidth: '100px',
    tooltip: true,
  },
  {
    prop: 'create_time',
    headSlotName: 'headSaveTime',
    label: 'taskPage.record.saveDate',
    widthType: 'width',
    maxWidth: '150px',
    width: '200px',
    minWidth: '100px',
    tooltip: true,
  },
  {
    prop: 'operate',
    headSlotName: 'headOperate',
    label: 'common.operation',
    widthType: 'width',
    width: '350px',
    maxWidth: '150px',
    minWidth: '200px',
  },
]

//所有表头的数据
const allTableHeaderList = [
  {
    prop: 'select',
    selectProp: 'isSelected',
    headSlotName: 'headSelect',
    label: languageMap.get('选择'),
    widthType: 'width',
    width: '64px',
    minWidth: '64px',
    maxWidth: '150px',
    temp: '选择',
    sort: 0,
  },
  {
    prop: 'loc',
    headSlotName: 'headLoc',
    label: languageMap.get('柜体'),
    interactionType: 'input',
    widthType: 'width',
    width: '63px',
    minWidth: '30px',
    maxWidth: '150px',
    temp: '柜A',
    sort: 1,
  },
  {
    prop: 'customer_name',
    headSlotName: 'headCustomerName',
    label: languageMap.get('客户'),
    interactionType: 'input',
    widthType: 'width',
    width: '63px',
    minWidth: '30px',
    maxWidth: '150px',
    temp: '张总',
    sort: 2,
  },
  {
    prop: 'frontEdge',
    headSlotName: 'headFrontEdge',
    label: languageMap.get('前封边'),
    interactionType: 'input',
    widthType: 'width',
    width: '70px',
    minWidth: '30px',
    maxWidth: '150px',
    temp: '0',
    sort: 3,
  },
  {
    prop: 'roomName',
    headSlotName: 'headRoomName',
    label: languageMap.get('房间'),
    interactionType: 'input',
    widthType: 'width',
    width: '80px',
    minWidth: '30px',
    maxWidth: '150px',
    temp: '主卧',
    sort: 4,
  },
  {
    prop: 'plankID',
    headSlotName: 'headPlankID',
    label: languageMap.get('板号'),
    widthType: 'width',
    width: '86px',
    minWidth: '30px',
    maxWidth: '150px',
    temp: '1',
    sort: 5,
  },
  {
    prop: 'partName',
    headSlotName: 'headPartName',
    label: languageMap.get('板件名称'),
    interactionType: 'input',
    widthType: 'width',
    width: '159px',
    minWidth: '100px',
    maxWidth: '150px',
    tooltip: true,
    temp: '主卧_柜A_中立板01',
    sort: 6,
  },
  {
    prop: 'hsInfo',
    headSlotName: 'headHsInfo',
    label: languageMap.get('孔槽数量'),
    widthType: 'width',
    width: '126px',
    minWidth: '110px',
    maxWidth: '150px',
    temp: '1孔1槽',
    sort: 7,
  },
  {
    prop: 'matCode',
    headSlotName: 'headMatCode',
    label: languageMap.get('材质'),
    interactionType: 'input',
    widthType: 'width',
    width: '110px',
    minWidth: '60px',
    maxWidth: '150px',
    tooltip: true,
    verify: true,
    temp: '多层实木',
    require: true,
    sort: 8,
  },
  {
    prop: 'orderNo',
    headSlotName: 'headOrderNo',
    label: languageMap.get('订单号'),
    interactionType: 'input',
    widthType: 'width',
    width: '80px',
    minWidth: '30px',
    maxWidth: '150px',
    temp: '211006-35',
    sort: 9,
  },
  {
    prop: 'plank_remarks',
    headSlotName: 'headPlankRemarks',
    interactionType: 'input',
    label: languageMap.get('板件备注'),
    widthType: 'width',
    width: '200px',
    minWidth: '180px',
    maxWidth: '230px',
    tooltip: true,
    temp: '板件备注',
  },
  {
    prop: 'remark',
    headSlotName: 'headRemark',
    interactionType: 'input',
    label: languageMap.get('房间备注'),
    widthType: 'width',
    width: '200px',
    minWidth: '180px',
    maxWidth: '230px',
    tooltip: true,
    temp: '房间备注',
  },
  {
    prop: 'openDir',
    headSlotName: 'headOpenDir',
    interactionType: 'input',
    label: languageMap.get('开门方向'),
    widthType: 'width',
    width: '110px',
    minWidth: '60px',
    maxWidth: '200px',
    tooltip: true,
    temp: '开门方向',
  },
  {
    prop: 'backEdge',
    headSlotName: 'headBackEdge',
    label: languageMap.get('后封边'),
    interactionType: 'input',
    widthType: 'width',
    width: '70px',
    minWidth: '30px',
    maxWidth: '150px',
    temp: '0',
    sort: 10,
  },
  {
    prop: 'texture',
    headSlotName: 'headTexture',
    label: languageMap.get('颜色'),
    interactionType: 'input',
    widthType: 'width',
    width: '100px',
    minWidth: '60px',
    maxWidth: '150px',
    tooltip: true,
    verify: true,
    temp: 'T01',
    require: true,
    sort: 11,
  },
  {
    prop: 'address',
    headSlotName: 'headAddress',
    label: languageMap.get('项目地址'),
    interactionType: 'input',
    widthType: 'width',
    width: '120px',
    minWidth: '100px',
    maxWidth: '150px',
    temp: '订单一',
    sort: 12,
  },
  {
    prop: 'leftEdge',
    headSlotName: 'headLeftEdge',
    label: languageMap.get('左封边'),
    interactionType: 'input',
    widthType: 'width',
    width: '70px',
    minWidth: '30px',
    maxWidth: '150px',
    temp: '0',
    sort: 13,
  },
  {
    prop: 'thick',
    headSlotName: 'headThick',
    label: languageMap.get('厚度'),
    interactionType: 'input',
    widthType: 'width',
    width: '78px',
    minWidth: '30px',
    maxWidth: '150px',
    verify: true,
    temp: '18',
    require: true,
    sort: 14,
  },
  {
    prop: 'plankNum',
    headSlotName: 'headPlankNum',
    label: languageMap.get('板件条码'),
    // interactionType: 'input',
    widthType: 'width',
    width: '120px',
    minWidth: '100px',
    maxWidth: '150px',
    temp: '6100200300400',
    sort: 15,
  },
  {
    prop: 'type',
    headSlotName: 'headType',
    label: languageMap.get('门板'),
    widthType: 'width',
    width: '63px',
    minWidth: '30px',
    maxWidth: '150px',
    temp: '门板',
    sort: 16,
  },
  {
    prop: 'rightEdge',
    headSlotName: 'headRightEdge',
    label: languageMap.get('右封边'),
    interactionType: 'input',
    widthType: 'width',
    width: '70px',
    minWidth: '30px',
    maxWidth: '150px',
    temp: '0',
    sort: 17,
  },
  {
    prop: 'specialShape',
    headSlotName: 'headSpecialShape',
    label: languageMap.get('异形'),
    widthType: 'width',
    width: '63px',
    minWidth: '30px',
    maxWidth: '150px',
    temp: '异形',
    sort: 18,
  },
  {
    prop: 'specHeight',
    headSlotName: 'headSpecHeight',
    label: languageMap.get('成品长度'),
    interactionType: 'input',
    widthType: 'width',
    width: '106px',
    minWidth: '60px',
    maxWidth: '150px',
    verify: true,
    temp: '2440',
    require: true,
    sort: 19,
  },
  {
    prop: 'specWidth',
    headSlotName: 'headSpecWidth',
    label: languageMap.get('成品宽度'),
    interactionType: 'input',
    widthType: 'width',
    width: '126px',
    minWidth: '60px',
    maxWidth: '150px',
    verify: true,
    temp: '1220',
    require: true,
    sort: 20,
  },
  {
    prop: 'amount',
    headSlotName: 'headAmount',
    label: languageMap.get('数量'),
    interactionType: 'input',
    widthType: 'width',
    width: '78px',
    minWidth: '30px',
    maxWidth: '150px',
    verify: true,
    temp: '1',
    require: true,
    sort: 21,
  },
  {
    prop: 'texDir',
    headSlotName: 'headTexDir',
    label: languageMap.get('纹理'),
    widthType: 'width',
    width: '126px',
    minWidth: '60px',
    maxWidth: '150px',
    temp: '竖纹',
    require: true,
    sort: 22,
  },
  {
    prop: 'is_high_gloss_plank',
    headSlotName: 'headIsHighGlossPlank',
    label: languageMap.get('高光板'),
    widthType: 'width',
    width: '126px',
    minWidth: '60px',
    maxWidth: '150px',
    temp: false,
    require: true,
    sort: 23,
  },
] as const
export {
  headPropList,
  productionFillManageList,
  bujianList,
  bujianStoreList,
  allTableHeaderList,
  IPropList,
}
