<template>
  <div class="priority-canvas">
    <div class="paiban-bread">
      <span class="material-list" @click="gotoMaterial">{{
        $t('main.sideBar.materialList')
      }}</span>
      <span>/</span>
      <span class="center-bread" @click="gotoPaiban">{{
        $t('main.sideBar.paibanResult')
      }}</span>
      <span>/</span>
      <span class="center-bread" @click="gotoSubtle">{{
        $t('arrangedPage.subtleArrange')
      }}</span>
      <span>/</span>
      <span class="final-bread">{{ $t('arrangedPage.editCutOrder') }}</span>
    </div>
    <div class="canvas-box">
      <canvas ref="priorityCanvas" @click="clickCanvas"></canvas>
    </div>
    <div class="operation-box">
      <div>
        <a-button @click="cancelChange">{{ $t('common.cancel') }}</a-button>
        <a-button @click="resetChange">{{ $t('common.reset') }}</a-button>
        <a-button @click="saveChange" class="confirm-btn">{{
          $t('common.confirm')
        }}</a-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex'

// 默认缩放比例
const defaultScale = 5
// 大板和大板边框的默认偏移值
const defaultDeviation = 5
// 下刀顺序距边的默认距离
const defaultTextWidth = 3
// 下刀点距边的默认距离
const defaultDotWidth = 5
export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    bigpart: {
      type: [Object, Array],
    },
    trimSide: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      ctx: {},
      startTop: 100,
      startLeft: 0,
      scaleWidth: 0,
      scaleHeight: 0,
      plankWidth: 0,
      plankHeight: 0,
      priorityNum: 1,
      scalePercent: 1,
    }
  },
  computed: {
    ...mapState(['ncSetting']),
  },
  methods: {
    startDraw() {
      this.$nextTick(() => {
        let dom = this.$refs.priorityCanvas
        this.ctx = dom.getContext('2d')
        let recordHeight = 0
        let recordWidth = 0
        this.plankWidth = this.ncSetting.drawPlankWidth
        this.plankHeight = this.ncSetting.drawPlankHeight
        if (this.bigpart.surplusInfo) {
          let surplusWidth = this.bigpart.surplusInfo.width
          let surplusHeight = this.bigpart.surplusInfo.height
          recordHeight =
            (Number(surplusHeight) / defaultScale) * this.scalePercent
          recordWidth =
            (Number(surplusWidth) / defaultScale) * this.scalePercent
        } else {
          recordHeight =
            (Number(this.plankHeight) / defaultScale) * this.scalePercent
          recordWidth =
            (Number(this.plankWidth) / defaultScale) * this.scalePercent
        }
        this.bigpart.priorityWidth = recordWidth
        this.bigpart.priorityHeight = recordHeight
        this.drawOneBigpart()
      })
    },
    gotoMaterial() {
      this.$router.push('/materialList')
    },
    gotoPaiban() {
      this.$emit('goBackPaiban')
      this.$emit('update:visible', false)
    },
    gotoSubtle() {
      this.cancelChange()
    },
    // 将size通过scale进行转换
    tranlateSizeFromScale(size) {
      return (size / defaultScale) * this.scalePercent
    },
    drawOneBigpart() {
      let windowWidth = document.body.offsetWidth
      this.plankWidth = this.ncSetting.drawPlankWidth
      this.plankHeight = this.ncSetting.drawPlankheight
      this.startLeft = windowWidth / 2 - this.bigpart.priorityWidth / 2
      let strokeColor = '#333'
      let strokeLine = 1
      let left = this.startLeft
      let top = this.startTop
      // 边框是比真正的大板要大一圈的圆角矩形, 而且顶部还有其他信息, 所以长宽多10, 起始点y要多30
      this.strokeRoundRect(
        this.ctx,
        left,
        top,
        this.bigpart.priorityWidth + 2 * defaultDeviation,
        this.bigpart.priorityHeight + 2 * defaultDeviation,
        5,
        strokeLine,
        strokeColor
      )
      this.ctx.fillStyle = '#ECECEC'
      if (
        this.bigpart.surplusInfo &&
        Object.keys(this.bigpart.surplusInfo).length > 0
      ) {
        let surplus = this.bigpart.surplusInfo
        let newLeft = left + defaultDeviation
        let newTop = top + defaultDeviation
        if (surplus.shape && surplus.shape == 'lshape') {
          let x3 = this.ncSetting.xyReverse
            ? Number(surplus.y5)
            : Number(surplus.x3)
          let y3 = this.ncSetting.xyReverse
            ? Number(surplus.x5)
            : Number(surplus.y3)
          let x4 = this.ncSetting.xyReverse
            ? Number(surplus.y4)
            : Number(surplus.x4)
          let y4 = this.ncSetting.xyReverse
            ? Number(surplus.x4)
            : Number(surplus.y4)
          let x5 = this.ncSetting.xyReverse
            ? Number(surplus.y3)
            : Number(surplus.x5)
          let y5 = this.ncSetting.xyReverse
            ? Number(surplus.x3)
            : Number(surplus.y5)
          let newWidth = surplus.width
          let newHeight = surplus.height
          if (
            this.ncSetting.xyReverse &&
            (this.ncSetting.startPosition == '右下角' ||
              this.ncSetting.startPosition == '左上角')
          ) {
            x3 = newWidth - x3
            x4 = newWidth - x4
            x5 = newWidth - x5

            this.ctx.beginPath()
            this.ctx.moveTo(newLeft, newTop)

            this.ctx.lineTo(
              newLeft + this.tranlateSizeFromScale(newWidth),
              newTop
            )
            this.ctx.lineTo(
              newLeft + this.tranlateSizeFromScale(newWidth),
              newTop + this.tranlateSizeFromScale(newHeight)
            )

            this.ctx.lineTo(
              newLeft + this.tranlateSizeFromScale(x3),
              newTop + this.tranlateSizeFromScale(y3)
            )

            this.ctx.lineTo(
              newLeft + this.tranlateSizeFromScale(x4),
              newTop + this.tranlateSizeFromScale(y4)
            )

            this.ctx.lineTo(
              newLeft + this.tranlateSizeFromScale(x5),
              newTop + this.tranlateSizeFromScale(y5)
            )
            // }
          } else {
            y3 = newHeight - y3
            y4 = newHeight - y4
            y5 = newHeight - y5

            this.ctx.beginPath()
            // 根据修边方向(xy互换不处理,高光板只生效右下，左上修边) 设置不同缺口方向
            if (
              this.trimSide === 'topRight' &&
              !this.ncSetting.xyReverse &&
              !this.bigpart.parts[0].is_high_gloss_plank
            ) {
              this.ctx.moveTo(
                newLeft,
                newTop + this.tranlateSizeFromScale(newHeight)
              )

              this.ctx.lineTo(
                newLeft + this.tranlateSizeFromScale(x3),
                newTop +
                  this.tranlateSizeFromScale(newHeight) -
                  this.tranlateSizeFromScale(y3)
              )

              this.ctx.lineTo(
                newLeft + this.tranlateSizeFromScale(x4),
                newTop +
                  this.tranlateSizeFromScale(newHeight) -
                  this.tranlateSizeFromScale(y4)
              )

              this.ctx.lineTo(
                newLeft + this.tranlateSizeFromScale(x5),
                newTop +
                  this.tranlateSizeFromScale(newHeight) -
                  this.tranlateSizeFromScale(y5)
              )
              this.ctx.lineTo(
                newLeft + this.tranlateSizeFromScale(newWidth),
                newTop
              )
              this.ctx.lineTo(newLeft, newTop)
            } else if (
              this.trimSide === 'bottomLeft' &&
              !this.ncSetting.xyReverse &&
              !this.bigpart.parts[0].is_high_gloss_plank
            ) {
              this.ctx.moveTo(
                newLeft + this.tranlateSizeFromScale(newWidth),
                newTop
              )

              this.ctx.lineTo(
                newLeft +
                  this.tranlateSizeFromScale(newWidth) -
                  this.tranlateSizeFromScale(x3),
                newTop + this.tranlateSizeFromScale(y3)
              )

              this.ctx.lineTo(
                newLeft +
                  this.tranlateSizeFromScale(newWidth) -
                  this.tranlateSizeFromScale(x4),
                newTop + this.tranlateSizeFromScale(y4)
              )

              this.ctx.lineTo(
                newLeft +
                  this.tranlateSizeFromScale(newWidth) -
                  this.tranlateSizeFromScale(x5),
                newTop + this.tranlateSizeFromScale(y5)
              )
              this.ctx.lineTo(
                newLeft,
                newTop + this.tranlateSizeFromScale(newHeight)
              )
              this.ctx.lineTo(
                newLeft + this.tranlateSizeFromScale(newWidth),
                newTop + this.tranlateSizeFromScale(newHeight)
              )
            } else if (
              this.trimSide === 'topLeft' &&
              !this.ncSetting.xyReverse
            ) {
              this.ctx.moveTo(
                newLeft + this.tranlateSizeFromScale(newWidth),
                newTop + this.tranlateSizeFromScale(newHeight)
              )

              this.ctx.lineTo(
                newLeft +
                  this.tranlateSizeFromScale(newWidth) -
                  this.tranlateSizeFromScale(x3),
                newTop +
                  this.tranlateSizeFromScale(newHeight) -
                  this.tranlateSizeFromScale(y3)
              )

              this.ctx.lineTo(
                newLeft +
                  this.tranlateSizeFromScale(newWidth) -
                  this.tranlateSizeFromScale(x4),
                newTop +
                  this.tranlateSizeFromScale(newHeight) -
                  this.tranlateSizeFromScale(y4)
              )

              this.ctx.lineTo(
                newLeft +
                  this.tranlateSizeFromScale(newWidth) -
                  this.tranlateSizeFromScale(x5),
                newTop +
                  this.tranlateSizeFromScale(newHeight) -
                  this.tranlateSizeFromScale(y5)
              )
              this.ctx.lineTo(newLeft, newTop)
              this.ctx.lineTo(
                newLeft + this.tranlateSizeFromScale(newWidth),
                newTop
              )
            } else {
              this.ctx.moveTo(newLeft, newTop)

              this.ctx.lineTo(
                newLeft + this.tranlateSizeFromScale(x3),
                newTop + this.tranlateSizeFromScale(y3)
              )

              this.ctx.lineTo(
                newLeft + this.tranlateSizeFromScale(x4),
                newTop + this.tranlateSizeFromScale(y4)
              )

              this.ctx.lineTo(
                newLeft + this.tranlateSizeFromScale(x5),
                newTop + this.tranlateSizeFromScale(y5)
              )
              this.ctx.lineTo(
                newLeft + this.tranlateSizeFromScale(newWidth),
                newTop + this.tranlateSizeFromScale(newHeight)
              )
              this.ctx.lineTo(
                newLeft,
                newTop + this.tranlateSizeFromScale(newHeight)
              )
            }
          }

          this.ctx.closePath()
          this.ctx.fill()
        } else {
          this.ctx.fillRect(
            newLeft,
            newTop,
            (surplus.width / defaultScale) * this.scalePercent,
            (surplus.height / defaultScale) * this.scalePercent
          )
        }
      } else {
        // 绘制真正的大板
        this.ctx.fillRect(
          left + this.deviation,
          top + this.padding + this.deviation,
          this.bigpart.priorityWidth,
          this.bigpart.priorityHeight
        )
      }
      // // 绘制真正的大板
      // this.ctx.fillRect(left + defaultDeviation, top + defaultDeviation, this.bigpart.priorityWidth, this.bigpart.priorityHeight);
      // 绘制小板
      for (let i = 0; i < this.bigpart.parts.length; ++i) {
        let part = this.bigpart.parts[i]
        this.drawParts(
          this.ctx,
          part,
          left + defaultDeviation,
          top + defaultDeviation
        )
      }
    },

    /**
     * 绘制小板
     * @param { Object } part 小板数据
     * @param { Number } startX 大板的绘制起始横坐标
     * @param { Number } startY 大板的绘制起始纵坐标
     */
    drawParts(ctx, part, startX, startY, onlyOnePart = false) {
      if (part.holeSlotMerge) {
        ctx.fillStyle = '#F808'
      } else {
        ctx.fillStyle = 'rgba(238, 238, 161, 0.8)'
      }
      if (part.plankMerge) {
        ctx.fillStyle = '#f008'
      }
      if (part.specialType) {
        ctx.fillStyle = '#ffc8c8'
      }
      ctx.strokeStyle = '#0008'
      ctx.lineWidth = 1
      let rect = {
        x: startX + part.startX / defaultScale,
        y: startY + part.startY / defaultScale,
        width: part.rect.width / defaultScale,
        height: part.rect.height / defaultScale,
      }
      if (part.path) {
        ctx.beginPath()
        for (let i = 0; i < part.path.length; ++i) {
          if (part.holeSlotMerge) {
            ctx.fillStyle = '#F808'
          } else {
            ctx.fillStyle = 'rgba(238, 238, 161, 0.8)'
          }
          if (part.plankMerge) {
            ctx.fillStyle = '#f008'
          }
          if (part.specialType) {
            ctx.fillStyle = '#ffc8c8'
          }
          for (let k = 0; k < part.path[i].length; ++k) {
            let x = rect.x + part.path[i][k].x / defaultScale
            let y = rect.y + part.path[i][k].y / defaultScale
            if (k == 0) {
              ctx.moveTo(x, y)
            } else {
              ctx.lineTo(x, y)
            }
          }
          ctx.closePath()
        }
        ctx.stroke()
        ctx.fill()
      } else {
        ctx.fillRect(rect.x, rect.y, rect.width, rect.height)
        ctx.strokeRect(rect.x, rect.y, rect.width, rect.height)
      }
      // 绘制孔位
      this.drawAllHoles(ctx, part, rect)
      // 绘制拉槽
      this.drawAllSlots(ctx, part, rect)
      // 绘制下刀点
      this.drawCutOrigin(ctx, part, rect)
      // // 绘制下刀顺序
      this.drawCutOrder(ctx, part, rect)
    },

    /**
     * 绘制孔位
     * @param { Object } part 小板数据
     * @param { Object } rect 小板的绘制属性
     */
    drawAllHoles(ctx, part, rect) {
      if (part.holes.length > 0) {
        for (let i = 0; i < part.holes.length; ++i) {
          let hole = part.holes[i]
          if (hole.side == 1) {
            ctx.strokeStyle = '#f008'
          } else {
            ctx.strokeStyle = '#00f8'
          }
          let circle = {
            x: rect.x + hole.center.x / defaultScale,
            y: rect.y + hole.center.y / defaultScale,
            r: hole.diameter / 2 / defaultScale,
          }
          ctx.beginPath()
          ctx.arc(circle.x, circle.y, circle.r, 0, Math.PI * 2, true)
          ctx.closePath()
          ctx.stroke()
        }
      }
    },

    /**
     * 绘制拉槽
     * @param { Object } part 小板数据
     * @param { Object } rect 小板的绘制属性
     */
    drawAllSlots(ctx, part, rect) {
      if (part.slots.length > 0) {
        for (let i = 0; i < part.slots.length; ++i) {
          let slot = part.slots[i]
          if (slot.side == 1) {
            ctx.strokeStyle = '#f008'
          } else {
            ctx.strokeStyle = '#00f8'
          }
          let slotInfo = {}
          // 拉槽两点pt1, pt2为拉槽平行边的中点, 所以计算起始点需要减去拉槽的宽度的一半
          // 如果拉槽两点的横坐标相等 则为竖拉槽
          slotInfo.width = slot.width / defaultScale
          if (slot.pt1.x === slot.pt2.x) {
            // 找出更小的纵坐标, 因为canvas是从左上角绘制的
            slotInfo.x =
              rect.x + slot.pt1.x / defaultScale - slot.width / defaultScale / 2
            slotInfo.long = Math.abs((slot.pt1.y - slot.pt2.y) / defaultScale)
            if (slot.pt1.y > slot.pt2.y) {
              slotInfo.y = rect.y + slot.pt2.y / defaultScale
            } else {
              slotInfo.y = rect.y + slot.pt1.y / defaultScale
            }
            ctx.strokeRect(
              slotInfo.x,
              slotInfo.y,
              slotInfo.width,
              slotInfo.long
            )
          }
          // 如果拉槽两点的纵坐标相等, 则为横拉槽
          if (slot.pt1.y === slot.pt2.y) {
            // 找出更小的横坐标
            slotInfo.y =
              rect.y + slot.pt1.y / defaultScale - slot.width / defaultScale / 2
            slotInfo.long = Math.abs((slot.pt1.x - slot.pt2.x) / defaultScale)
            if (slot.pt1.x > slot.pt2.x) {
              slotInfo.x = rect.x + slot.pt2.x / defaultScale
            } else {
              slotInfo.x = rect.x + slot.pt1.x / defaultScale
            }
            ctx.strokeRect(
              slotInfo.x,
              slotInfo.y,
              slotInfo.long,
              slotInfo.width
            )
          }
        }
      }
    },

    /**
     * 绘制下刀点
     * @param { Object } part 小板数据
     * @param { Object } rect 小板的绘制属性
     */
    drawCutOrigin(ctx, part, rect) {
      this.ctx.fillStyle = '#9845d9'
      let position = part.cutOrigin
      let cutOrigin = {
        r: 2,
      }
      switch (position) {
        case 'leftTop':
          cutOrigin.x = rect.x + defaultDotWidth
          cutOrigin.y = rect.y + defaultDotWidth
          break
        case 'leftBottom':
          cutOrigin.x = rect.x + defaultDotWidth
          cutOrigin.y = rect.y + rect.height - defaultDotWidth
          break
        case 'rightTop':
          cutOrigin.x = rect.x + rect.width - defaultDotWidth
          cutOrigin.y = rect.y + defaultDotWidth
          break
        case 'rightBottom':
          cutOrigin.x = rect.x + rect.width - defaultDotWidth
          cutOrigin.y = rect.y + rect.height - defaultDotWidth
          break
      }
      ctx.beginPath()
      ctx.arc(cutOrigin.x, cutOrigin.y, cutOrigin.r, 0, Math.PI * 2, true)
      ctx.closePath()
      ctx.fill()
    },

    /**
     * 绘制下刀顺序
     * @param { Object } part 小板数据
     * @param { Object } rect 小板的绘制属性
     */
    drawCutOrder(ctx, part, rect) {
      if (part.showPriority) {
        ctx.fillStyle = '#f00'
        let fontSize = 18
        ctx.font = `bold ${fontSize}px 'PingFangSC-Regular, PingFang SC'`
        // 文字的绘制, 是以起始点开始, 向上绘制(不知道为什么这个是反的)
        let text = {
          x: rect.x + defaultTextWidth,
          y: rect.y + defaultTextWidth + fontSize,
        }
        ctx.fillText(part.showPriority, text.x, text.y)
      }
    },

    /**
     * 该方法用来绘制一个有填充色的圆角矩形
     * @param cxt:canvas的上下文环境
     * @param x:左上角x轴坐标
     * @param y:左上角y轴坐标
     * @param width:矩形的宽度
     * @param height:矩形的高度
     * @param radius:圆的半径
     * @param fillColor:填充颜色
     **/
    fillRoundRect(cxt, x, y, width, height, radius, /*optional*/ fillColor) {
      //圆的直径必然要小于矩形的宽高
      if (2 * radius > width || 2 * radius > height) {
        return false
      }

      cxt.save()
      cxt.translate(x, y)
      //绘制圆角矩形的各个边
      this.drawRoundRectPath(cxt, width, height, radius)
      cxt.fillStyle = fillColor || '#000' //若是给定了值就用给定的值否则给予默认值
      cxt.fill()
      cxt.restore()
    },

    /**
     * 该方法用来绘制圆角矩形
     * @param cxt:canvas的上下文环境
     * @param x:左上角x轴坐标
     * @param y:左上角y轴坐标
     * @param width:矩形的宽度
     * @param height:矩形的高度
     * @param radius:圆的半径
     * @param lineWidth:线条粗细
     * @param strokeColor:线条颜色
     **/
    strokeRoundRect(
      cxt,
      x,
      y,
      width,
      height,
      radius,
      /*optional*/ lineWidth,
      /*optional*/ strokeColor
    ) {
      //圆的直径必然要小于矩形的宽高
      if (2 * radius > width || 2 * radius > height) {
        return false
      }

      cxt.save()
      cxt.translate(x, y)
      //绘制圆角矩形的各个边
      this.drawRoundRectPath(cxt, width, height, radius)
      cxt.lineWidth = lineWidth || 1 //若是给定了值就用给定的值否则给予默认值2
      cxt.strokeStyle = strokeColor || '#000'
      cxt.stroke()
      cxt.restore()
    },

    drawRoundRectPath(cxt, width, height, radius) {
      cxt.beginPath(0)
      //从右下角顺时针绘制，弧度从0到1/2PI
      cxt.arc(width - radius, height - radius, radius, 0, Math.PI / 2)
      //矩形下边线
      cxt.lineTo(radius, height)
      //左下角圆弧，弧度从1/2PI到PI
      cxt.arc(radius, height - radius, radius, Math.PI / 2, Math.PI)
      //矩形左边线
      cxt.lineTo(0, radius)
      //左上角圆弧，弧度从PI到3/2PI
      cxt.arc(radius, radius, radius, Math.PI, (Math.PI * 3) / 2)
      //上边线
      cxt.lineTo(width - radius, 0)
      //右上角圆弧
      cxt.arc(width - radius, radius, radius, (Math.PI * 3) / 2, Math.PI * 2)
      //右边线
      cxt.lineTo(width, height - radius)
      cxt.closePath()
    },

    clickCanvas(e) {
      let mousePoint = {
        x: e.offsetX,
        y: e.offsetY,
      }
      // 如果找到了, 则判断点击的位置
      if (
        mousePoint.x <=
          this.startLeft + this.bigpart.priorityWidth + 2 * defaultDeviation &&
        mousePoint.y <=
          this.startTop + this.bigpart.priorityHeight + 2 * defaultDeviation
      ) {
        // 判断点击的是否为大板, 还是顶部信息
        if (
          mousePoint.y >= this.startTop + defaultDeviation &&
          mousePoint.x >= this.startLeft + defaultDeviation
        ) {
          for (let i = 0; i < this.bigpart.parts.length; ++i) {
            let part = this.bigpart.parts[i]
            let rect = {
              x: this.startLeft + defaultDeviation + part.startX / defaultScale,
              y: this.startTop + defaultDeviation + part.startY / defaultScale,
            }
            let newPos = {
              x: (mousePoint.x - rect.x) * defaultScale,
              y: (mousePoint.y - rect.y) * defaultScale,
            }
            let curvePath = []
            if (part.path) {
              curvePath = part.path[0]
            } else {
              curvePath = [
                { x: 0, y: 0 },
                { x: part.rect.width, y: 0 },
                { x: part.rect.width, y: part.rect.height },
                { x: 0, y: part.rect.height },
              ]
            }
            let isInpoly = this.isInPolygon(newPos, curvePath)
            // 普通矩形或者异形
            if (!part.path || (part.path && part.path.length <= 1)) {
              if (isInpoly) {
                if (!part.hasOwnProperty('showPriority')) {
                  this.$emit('changeBigPartPriotity', part, this.priorityNum)
                  this.priorityNum++
                  this.renderAll()
                }
              }
            }
            // 异形嵌套
            else if (part.path && part.path.length >= 1) {
              if (isInpoly && !this.isInPolygon(newPos, part.path[1])) {
                if (!part.hasOwnProperty('showPriority')) {
                  this.$emit('changeBigPartPriotity', part, this.priorityNum)
                  this.priorityNum++
                  this.renderAll()
                }
              }
            }
          }
        }
      }
    },

    // 判断点是否在多边形内部
    isInPolygon(checkPoint, polygonPoints) {
      let counter = 0
      let xinters
      let p1, p2
      let pointCount = polygonPoints.length
      p1 = polygonPoints[0]

      for (let i = 1; i <= pointCount; i++) {
        p2 = polygonPoints[i % pointCount]
        if (
          checkPoint.x > Math.min(p1.x, p2.x) &&
          checkPoint.x <= Math.max(p1.x, p2.x)
        ) {
          if (checkPoint.y <= Math.max(p1.y, p2.y)) {
            if (p1.x != p2.x) {
              xinters =
                ((checkPoint.x - p1.x) * (p2.y - p1.y)) / (p2.x - p1.x) + p1.y
              if (p1.y == p2.y || checkPoint.y <= xinters) {
                counter++
              }
            }
          }
        }
        p1 = p2
      }
      if (counter % 2 === 0) {
        return false
      } else {
        return true
      }
    },

    renderAll() {
      this.ctx.clearRect(0, 0, 1392, 744)
      this.drawOneBigpart()
    },

    cancelChange() {
      this.$emit('resetPriorityChange')
      this.$emit('update:visible', false)
    },
    resetChange() {
      this.$emit('resetPriorityChange')
      this.priorityNum = 1
      this.renderAll()
    },
    saveChange() {
      let flag = true
      for (let i = 0; i < this.bigpart.parts.length; ++i) {
        if (!this.bigpart.parts[i].hasOwnProperty('showPriority')) {
          flag = false
          break
        }
      }
      if (!flag) {
        this.$message({
          message: '请对所有板件设置切割顺序之后再进行此操作!',
          type: 'error',
        })
        return
      }
      this.$emit('updatePriority')
    },
  },
  mounted() {
    this.startDraw()
    const box = document.querySelector('.priority-canvas')
    // 更新canvas宽度
    this.$refs['priorityCanvas'].width = box.offsetWidth - 48
    this.$refs['priorityCanvas'].height = box.offsetHeight
  },
}
</script>

<style scoped lang="less">
.priority-canvas {
  position: absolute;
  top: 0px;
  left: 0;
  z-index: 2;
  width: 100%;
  height: 100%;
  padding: 24px;
  background: #eee;
  .paiban-bread {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 38px;
    padding: 0 16px;
    color: #333;
    font-size: 14px;
    line-height: 38px;
    background: #fafafa;
    cursor: pointer;
    .final-bread {
      color: #999;
      cursor: default;
    }
  }
  .canvas-box {
    height: 100%;
    background: #fff;
  }
  .operation-box {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 2;
    width: 100%;
    height: 76px;
    text-align: right;
    background: #eee;
    > div {
      height: 60px;
      margin-top: 16px;
      padding-right: 32px;
      line-height: 60px;
      background: #fff;
      .confirm-btn {
        color: #fff;
        background: #18a8c7;
        border: none;
      }
    }
  }
}
</style>
