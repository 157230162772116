import { translate } from '@/util/commonFun'

import { languageMap } from './languageMap'

// 余料表头
export const surplusHeaders = [
  { label: '余料名称', props: 'name', width: '7', unit: '' },
  { label: '形状', props: 'shape', width: '4', unit: '' },
  { label: '类型', props: 'type', width: '7', unit: '' },
  { label: '颜色', props: 'color', width: '4', unit: '' },
  { label: '厚度', props: 'thick', width: '6', unit: 'mm' },
  { label: '长度', props: 'long', width: '6', unit: 'mm' },
  {
    label: '短边长度',
    props: 'min_long',
    width: '8',
    unit: 'mm',
  },
  { label: '宽度', props: 'width', width: '6', unit: 'mm' },
  {
    label: '短边宽度',
    props: 'min_width',
    width: '7',
    unit: 'mm',
  },
  {
    label: '高光板',
    props: 'is_high_gloss_plank',
    width: '7',
    unit: '',
  },
  { label: '添加时间', props: 'create_time', width: '7', unit: '' },
  { label: '所在余料库', props: 'branch_name', width: '7', unit: '' },
  { label: '备注', props: 'remark', width: '7', unit: '' },
  { label: '面积', props: 'area', width: '5', unit: 'm²' },
  { label: '数量', props: 'showAmount', width: '6', unit: '' },
] as const

// 表头映射表
export const tableHeaderMappingList = {
  [translate(languageMap.get('板号'))]: 'plankID',
  [translate(languageMap.get('材质'))]: 'matCode',
  [translate(languageMap.get('颜色'))]: 'texture',
  [translate(languageMap.get('板件名称'))]: 'partName',
  [translate(languageMap.get('板件长度'))]: 'specHeight',
  [translate(languageMap.get('板件宽度'))]: 'specWidth',
  [translate(languageMap.get('数量'))]: 'amount',
  [translate(languageMap.get('面积'))]: 'area',
  [translate(languageMap.get('左封边'))]: 'leftEdge',
  [translate(languageMap.get('右封边'))]: 'rightEdge',
  [translate(languageMap.get('前封边'))]: 'frontEdge',
  [translate(languageMap.get('后封边'))]: 'backEdge',
  [translate(languageMap.get('纹理'))]: 'texDir',
  [translate(languageMap.get('厚度'))]: 'thick',
  [translate(languageMap.get('项目地址'))]: 'address',
  [translate(languageMap.get('板件条码'))]: 'oriPlankNum',
  [translate(languageMap.get('客户'))]: 'customer_name',
  [translate(languageMap.get('房间'))]: 'roomName',
  [translate(languageMap.get('订单号'))]: 'orderNo',
  [translate(languageMap.get('柜体'))]: 'loc',
  [translate(languageMap.get('孔槽数量'))]: 'hsInfo',
  [translate(languageMap.get('异形'))]: 'specialShape',
  [translate(languageMap.get('门板'))]: 'type',
  [translate(languageMap.get('板件备注'))]: 'plank_remarks',
  [translate(languageMap.get('房间备注'))]: 'remark',
  [translate(languageMap.get('高光板'))]: 'is_high_gloss_plank',
  [translate(languageMap.get('开门方向'))]: 'openDir',
}

// 高光板下拉框配置项
export const highPlankOptions = [
  { value: true, label: '高光板' },
  { value: false, label: '非高光' },
]

// 纹理配置项
export const texDirOptions = [
  { value: 'reverse', label: '横纹' },
  { value: 'normal', label: '竖纹' },
  { value: 'notcare', label: '无纹理' },
]

// 门板配置项
export const doorOptions = [
  { value: 'Plank', label: '非门板' },
  { value: 'SingleDoor', label: '门板' },
]
